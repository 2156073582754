import { useMemo } from 'react';
import { getGroupStyles, insertIntro, processMessages, } from '../../utils';
import { useChatContext } from '../../../../context/ChatContext';
import { useComponentContext } from '../../../../context/ComponentContext';
export var useEnrichedMessages = function (args) {
    var channel = args.channel, disableDateSeparator = args.disableDateSeparator, groupStyles = args.groupStyles, headerPosition = args.headerPosition, hideDeletedMessages = args.hideDeletedMessages, hideNewMessageSeparator = args.hideNewMessageSeparator, maxTimeBetweenGroupedMessages = args.maxTimeBetweenGroupedMessages, messages = args.messages, noGroupByUser = args.noGroupByUser, reviewProcessedMessage = args.reviewProcessedMessage;
    var client = useChatContext('useEnrichedMessages').client;
    var HeaderComponent = useComponentContext('useEnrichedMessages').HeaderComponent;
    var lastRead = useMemo(function () { var _a; return (_a = channel.lastRead) === null || _a === void 0 ? void 0 : _a.call(channel); }, [channel]);
    var enableDateSeparator = !disableDateSeparator;
    var messagesWithDates = !enableDateSeparator && !hideDeletedMessages && hideNewMessageSeparator
        ? messages
        : processMessages({
            enableDateSeparator: enableDateSeparator,
            hideDeletedMessages: hideDeletedMessages,
            hideNewMessageSeparator: hideNewMessageSeparator,
            lastRead: lastRead,
            messages: messages,
            reviewProcessedMessage: reviewProcessedMessage,
            userId: client.userID || '',
        });
    if (HeaderComponent) {
        messagesWithDates = insertIntro(messagesWithDates, headerPosition);
    }
    var groupStylesFn = groupStyles || getGroupStyles;
    var messageGroupStyles = useMemo(function () {
        return messagesWithDates.reduce(function (acc, message, i) {
            var style = groupStylesFn(message, messagesWithDates[i - 1], messagesWithDates[i + 1], noGroupByUser, maxTimeBetweenGroupedMessages);
            if (style)
                acc[message.id] = style;
            return acc;
        }, {});
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxTimeBetweenGroupedMessages, messagesWithDates, noGroupByUser]);
    return { messageGroupStyles: messageGroupStyles, messages: messagesWithDates };
};
