import { __awaiter, __generator } from "tslib";
import { DEFAULT_UPLOAD_SIZE_LIMIT_BYTES } from '../../../constants/limits';
export var accentsMap = {
    a: 'á|à|ã|â|À|Á|Ã|Â',
    c: 'ç|Ç',
    e: 'é|è|ê|É|È|Ê',
    i: 'í|ì|î|Í|Ì|Î',
    n: 'ñ|Ñ',
    o: 'ó|ò|ô|ő|õ|Ó|Ò|Ô|Õ',
    u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
};
export var removeDiacritics = function (text) {
    if (!text)
        return '';
    return Object.keys(accentsMap).reduce(function (acc, current) { return acc.replace(new RegExp(accentsMap[current], 'g'), current); }, text);
};
export var calculateLevenshtein = function (query, name) {
    if (query.length === 0)
        return name.length;
    if (name.length === 0)
        return query.length;
    var matrix = [];
    var i;
    for (i = 0; i <= name.length; i++) {
        matrix[i] = [i];
    }
    var j;
    for (j = 0; j <= query.length; j++) {
        matrix[0][j] = j;
    }
    for (i = 1; i <= name.length; i++) {
        for (j = 1; j <= query.length; j++) {
            if (name.charAt(i - 1) === query.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            }
            else {
                matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
                Math.min(matrix[i][j - 1] + 1, // insertion
                matrix[i - 1][j] + 1)); // deletion
            }
        }
    }
    return matrix[name.length][query.length];
};
export var searchLocalUsers = function (params) {
    var ownUserId = params.ownUserId, query = params.query, text = params.text, useMentionsTransliteration = params.useMentionsTransliteration, users = params.users;
    var matchingUsers = users.filter(function (user) {
        if (user.id === ownUserId)
            return false;
        if (!query)
            return true;
        var updatedId = removeDiacritics(user.id).toLowerCase();
        var updatedName = removeDiacritics(user.name).toLowerCase();
        var updatedQuery = removeDiacritics(query).toLowerCase();
        if (useMentionsTransliteration) {
            (function () { return __awaiter(void 0, void 0, void 0, function () {
                var transliterate;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, import('@stream-io/transliterate')];
                        case 1:
                            transliterate = (_a.sent()).default;
                            updatedName = transliterate(user.name || '').toLowerCase();
                            updatedQuery = transliterate(query).toLowerCase();
                            updatedId = transliterate(user.id).toLowerCase();
                            return [2 /*return*/];
                    }
                });
            }); })();
        }
        var maxDistance = 3;
        var lastDigits = text.slice(-(maxDistance + 1)).includes('@');
        if (updatedName) {
            var levenshtein_1 = calculateLevenshtein(updatedQuery, updatedName);
            if (updatedName.includes(updatedQuery) || (levenshtein_1 <= maxDistance && lastDigits)) {
                return true;
            }
        }
        var levenshtein = calculateLevenshtein(updatedQuery, updatedId);
        return updatedId.includes(updatedQuery) || (levenshtein <= maxDistance && lastDigits);
    });
    return matchingUsers;
};
export var checkUploadPermissions = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var addNotification, file, getAppSettings, t, uploadType, appSettings, _a, allowed_file_extensions, allowed_mime_types, blocked_file_extensions, blocked_mime_types, size_limit, sendNotAllowedErrorNotification, allowed, blocked, allowed, blocked, sizeLimit;
    var _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                addNotification = params.addNotification, file = params.file, getAppSettings = params.getAppSettings, t = params.t, uploadType = params.uploadType;
                appSettings = null;
                return [4 /*yield*/, getAppSettings()];
            case 1:
                appSettings = _d.sent();
                _a = (uploadType === 'image'
                    ? (_b = appSettings === null || appSettings === void 0 ? void 0 : appSettings.app) === null || _b === void 0 ? void 0 : _b.image_upload_config
                    : (_c = appSettings === null || appSettings === void 0 ? void 0 : appSettings.app) === null || _c === void 0 ? void 0 : _c.file_upload_config) || {}, allowed_file_extensions = _a.allowed_file_extensions, allowed_mime_types = _a.allowed_mime_types, blocked_file_extensions = _a.blocked_file_extensions, blocked_mime_types = _a.blocked_mime_types, size_limit = _a.size_limit;
                sendNotAllowedErrorNotification = function () {
                    return addNotification(t("Upload type: \"{{ type }}\" is not allowed", { type: file.type || 'unknown type' }), 'error');
                };
                if (allowed_file_extensions === null || allowed_file_extensions === void 0 ? void 0 : allowed_file_extensions.length) {
                    allowed = allowed_file_extensions.some(function (ext) {
                        return file.name.toLowerCase().endsWith(ext.toLowerCase());
                    });
                    if (!allowed) {
                        sendNotAllowedErrorNotification();
                        return [2 /*return*/, false];
                    }
                }
                if (blocked_file_extensions === null || blocked_file_extensions === void 0 ? void 0 : blocked_file_extensions.length) {
                    blocked = blocked_file_extensions.some(function (ext) {
                        return file.name.toLowerCase().endsWith(ext.toLowerCase());
                    });
                    if (blocked) {
                        sendNotAllowedErrorNotification();
                        return [2 /*return*/, false];
                    }
                }
                if (allowed_mime_types === null || allowed_mime_types === void 0 ? void 0 : allowed_mime_types.length) {
                    allowed = allowed_mime_types.some(function (type) { var _a; return type.toLowerCase() === ((_a = file.type) === null || _a === void 0 ? void 0 : _a.toLowerCase()); });
                    if (!allowed) {
                        sendNotAllowedErrorNotification();
                        return [2 /*return*/, false];
                    }
                }
                if (blocked_mime_types === null || blocked_mime_types === void 0 ? void 0 : blocked_mime_types.length) {
                    blocked = blocked_mime_types.some(function (type) { var _a; return type.toLowerCase() === ((_a = file.type) === null || _a === void 0 ? void 0 : _a.toLowerCase()); });
                    if (blocked) {
                        sendNotAllowedErrorNotification();
                        return [2 /*return*/, false];
                    }
                }
                sizeLimit = size_limit || DEFAULT_UPLOAD_SIZE_LIMIT_BYTES;
                if (file.size && file.size > sizeLimit) {
                    addNotification(t('File is too large: {{ size }}, maximum upload size is {{ limit }}', {
                        limit: prettifyFileSize(sizeLimit),
                        size: prettifyFileSize(file.size),
                    }), 'error');
                    return [2 /*return*/, false];
                }
                return [2 /*return*/, true];
        }
    });
}); };
export function prettifyFileSize(bytes, precision) {
    if (precision === void 0) { precision = 3; }
    var units = ['B', 'kB', 'MB', 'GB'];
    var exponent = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), units.length - 1);
    var mantissa = bytes / Math.pow(1024, exponent);
    var formattedMantissa = precision === 0 ? Math.round(mantissa).toString() : mantissa.toPrecision(precision);
    return "".concat(formattedMantissa, " ").concat(units[exponent]);
}
