"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HamburgerIcon = void 0;
const styled_components_1 = require("../styled-components");
const HamburgerIcon = (0, styled_components_1.createIcon)({
    viewBox: '0 0 24 24',
    d: 'M22 5H2V7.47961H22V5ZM22 10.4795H2V12.9591H22V10.4795ZM2 15.959H22V18.4386H2V15.959Z',
});
exports.HamburgerIcon = HamburgerIcon;
HamburgerIcon.displayName = 'HamburgerIcon';
