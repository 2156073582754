import { createObserver } from './Observer';
import { Subscription } from './Subscription';
var Observable = /** @class */ (function () {
    function Observable(producer) {
        this._closed = false;
        if (producer)
            this._producer = producer;
    }
    Object.defineProperty(Observable.prototype, "closed", {
        get: function () {
            return this._closed;
        },
        enumerable: false,
        configurable: true
    });
    Observable.prototype.subscribe = function (observerOrNext) {
        var _this = this;
        var _a;
        var observer = createObserver(observerOrNext);
        if (!this.closed) {
            (_a = this._producer) === null || _a === void 0 ? void 0 : _a.call(this, observer);
        }
        return new Subscription(function () {
            _this._closed = true;
        });
    };
    return Observable;
}());
export { Observable };
