"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const styled_components_1 = require("../../Icons/styled-components");
exports.default = (0, react_1.styled)(styled_components_1.StyledIcon, {
    // @ts-ignore
    'w': '100%',
    'h': '100%',
    'justifyContent': 'center',
    'alignItems': 'center',
    ':checked': {
        color: `$muted.50`,
    },
    ':disabled': {
        opacity: 0.4,
    },
    '_dark': {
        ':checked': {
            color: `$muted.900`,
        },
        ':disabled': {
            opacity: 0.4,
        },
    },
    'variants': {
        size: {
            xs: {
                height: 12,
                width: 12,
            },
            sm: {
                height: 16,
                width: 16,
            },
            md: {
                height: 18,
                width: 18,
            },
            lg: {
                height: 20,
                width: 20,
            },
            xl: {
                height: 24,
                width: 24,
            },
        },
    },
}, {
    componentName: 'CheckboxIcon',
    ancestorStyle: ['_icon'],
}, {
    propertyTokenMap: {
        stroke: 'colors',
    },
});
