import React from 'react';
import { useMessageBounceContext, useTranslationContext } from '../../context';
export function MessageBouncePrompt(_a) {
    var children = _a.children, onClose = _a.onClose;
    var _b = useMessageBounceContext('MessageBouncePrompt'), handleDelete = _b.handleDelete, handleEdit = _b.handleEdit, handleRetry = _b.handleRetry;
    var t = useTranslationContext('MessageBouncePrompt').t;
    function createHandler(handle) {
        return function (e) {
            handle(e);
            onClose === null || onClose === void 0 ? void 0 : onClose(e);
        };
    }
    return (React.createElement("div", { className: 'str-chat__message-bounce-prompt', "data-testid": 'message-bounce-prompt' },
        React.createElement("div", { className: 'str-chat__message-bounce-prompt-header' }, children !== null && children !== void 0 ? children : t('This message did not meet our content guidelines')),
        React.createElement("div", { className: 'str-chat__message-bounce-actions' },
            React.createElement("button", { className: 'str-chat__message-bounce-edit', "data-testid": 'message-bounce-edit', onClick: createHandler(handleEdit), type: 'button' }, t('Edit Message')),
            React.createElement("button", { className: 'str-chat__message-bounce-send', "data-testid": 'message-bounce-send', onClick: createHandler(handleRetry) }, t('Send Anyway')),
            React.createElement("button", { className: 'str-chat__message-bounce-delete', "data-testid": 'message-bounce-delete', onClick: createHandler(handleDelete) }, t('Delete')))));
}
