"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormControl = void 0;
const react_1 = __importStar(require("react"));
const form_control_1 = require("@gluestack-ui/form-control");
const styled_components_1 = require("./styled-components");
const usePropResolution_1 = require("../../hooks/usePropResolution");
const AccessibleFormControl = (0, form_control_1.createFormControl)({
    Root: styled_components_1.Root,
    Error: styled_components_1.Error,
    ErrorText: styled_components_1.ErrorText,
    ErrorIcon: styled_components_1.ErrorIcon,
    Label: styled_components_1.Label,
    LabelText: styled_components_1.LabelText,
    LabelAstrick: styled_components_1.LabelAstrick,
    Helper: styled_components_1.Helper,
    HelperText: styled_components_1.HelperText,
});
const FormControlTemp = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(props);
    return (react_1.default.createElement(AccessibleFormControl, Object.assign({}, resolvedProps, { ref: ref }), children));
});
const FormControlTempLabel = (0, react_1.forwardRef)((_a, ref) => {
    var { children, _astrick } = _a, props = __rest(_a, ["children", "_astrick"]);
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(Object.assign(Object.assign({}, props), { _labelText: _astrick }));
    return (react_1.default.createElement(AccessibleFormControl.Label, Object.assign({}, resolvedProps, { ref: ref }),
        react_1.default.createElement(AccessibleFormControl.Label.Text, null, children)));
});
const FormControlTempHelper = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(props);
    return (react_1.default.createElement(AccessibleFormControl.Helper, Object.assign({}, resolvedProps, { ref: ref }),
        react_1.default.createElement(AccessibleFormControl.Helper.Text, null, children)));
});
const FormControlTempError = (0, react_1.forwardRef)((_a, ref) => {
    var { children, leftIcon, rightIcon, startIcon, endIcon, _stack } = _a, props = __rest(_a, ["children", "leftIcon", "rightIcon", "startIcon", "endIcon", "_stack"]);
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(Object.assign(Object.assign({}, props), _stack));
    return (react_1.default.createElement(AccessibleFormControl.Error, Object.assign({}, resolvedProps, { ref: ref }), leftIcon !== null && leftIcon !== void 0 ? leftIcon : (startIcon && startIcon),
        typeof children === 'string' && (react_1.default.createElement(AccessibleFormControl.Error.Text, null, children)), rightIcon !== null && rightIcon !== void 0 ? rightIcon : (endIcon && endIcon)));
});
const FormControlNew = FormControlTemp;
FormControlNew.Label = FormControlTempLabel;
FormControlNew.HelperText = FormControlTempHelper;
FormControlNew.ErrorMessage = FormControlTempError;
exports.FormControl = FormControlNew;
