import { __assign, __rest } from "tslib";
import clsx from 'clsx';
import throttle from 'lodash.throttle';
import React from 'react';
import { EmptyStateIndicator as DefaultEmptyStateIndicator } from '../EmptyStateIndicator';
import { LoadingIndicator as DefaultLoadingIndicator } from '../Loading';
import { isMessageEdited, Message } from '../Message';
import { useComponentContext } from '../../context';
import { isDateSeparatorMessage } from './utils';
var PREPEND_OFFSET = Math.pow(10, 7);
export function calculateItemIndex(virtuosoIndex, numItemsPrepended) {
    return virtuosoIndex + numItemsPrepended - PREPEND_OFFSET;
}
export function calculateFirstItemIndex(numItemsPrepended) {
    return PREPEND_OFFSET - numItemsPrepended;
}
export var makeItemsRenderedHandler = function (renderedItemsActions, processedMessages) {
    return throttle(function (items) {
        var renderedMessages = items
            .map(function (item) {
            if (!item.originalIndex)
                return undefined;
            return processedMessages[calculateItemIndex(item.originalIndex, PREPEND_OFFSET)];
        })
            .filter(function (msg) { return !!msg; });
        renderedItemsActions.forEach(function (action) {
            return action(renderedMessages);
        });
    }, 200);
};
// using 'display: inline-block'
// traps CSS margins of the item elements, preventing incorrect item measurements
export var Item = function (_a) {
    var _b;
    var _c;
    var context = _a.context, props = __rest(_a, ["context"]);
    if (!context)
        return React.createElement(React.Fragment, null);
    var message = context.processedMessages[calculateItemIndex(props['data-item-index'], context.numItemsPrepended)];
    var groupStyles = context.messageGroupStyles[message.id];
    return (React.createElement("div", __assign({}, props, { className: ((_c = context === null || context === void 0 ? void 0 : context.customClasses) === null || _c === void 0 ? void 0 : _c.virtualMessage) ||
            clsx('str-chat__virtual-list-message-wrapper str-chat__li', (_b = {},
                _b["str-chat__li--".concat(groupStyles)] = groupStyles,
                _b)) })));
};
export var Header = function (_a) {
    var context = _a.context;
    var _b = useComponentContext('VirtualizedMessageListHeader').LoadingIndicator, LoadingIndicator = _b === void 0 ? DefaultLoadingIndicator : _b;
    if (!(context === null || context === void 0 ? void 0 : context.loadingMore))
        return null;
    return LoadingIndicator ? (React.createElement("div", { className: 'str-chat__virtual-list__loading' },
        React.createElement(LoadingIndicator, { size: 20 }))) : ((context === null || context === void 0 ? void 0 : context.head) || null);
};
export var EmptyPlaceholder = function (_a) {
    var context = _a.context;
    var _b = useComponentContext('VirtualizedMessageList').EmptyStateIndicator, EmptyStateIndicator = _b === void 0 ? DefaultEmptyStateIndicator : _b;
    return (React.createElement(React.Fragment, null, EmptyStateIndicator && (React.createElement(EmptyStateIndicator, { listType: (context === null || context === void 0 ? void 0 : context.threadList) ? 'thread' : 'message' }))));
};
export var Footer = function () {
    var TypingIndicator = useComponentContext('VirtualizedMessageList').TypingIndicator;
    return TypingIndicator ? React.createElement(TypingIndicator, { avatarSize: 24 }) : null;
};
export var messageRenderer = function (virtuosoIndex, _data, virtuosoContext) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var additionalMessageInputProps = virtuosoContext.additionalMessageInputProps, closeReactionSelectorOnClick = virtuosoContext.closeReactionSelectorOnClick, customMessageActions = virtuosoContext.customMessageActions, customMessageRenderer = virtuosoContext.customMessageRenderer, DateSeparator = virtuosoContext.DateSeparator, firstUnreadMessageId = virtuosoContext.firstUnreadMessageId, formatDate = virtuosoContext.formatDate, lastReadDate = virtuosoContext.lastReadDate, lastReadMessageId = virtuosoContext.lastReadMessageId, lastReceivedMessageId = virtuosoContext.lastReceivedMessageId, MessageUIComponent = virtuosoContext.Message, messageActions = virtuosoContext.messageActions, messageGroupStyles = virtuosoContext.messageGroupStyles, MessageSystem = virtuosoContext.MessageSystem, numItemsPrepended = virtuosoContext.numItemsPrepended, ownMessagesReadByOthers = virtuosoContext.ownMessagesReadByOthers, messageList = virtuosoContext.processedMessages, reactionDetailsSort = virtuosoContext.reactionDetailsSort, shouldGroupByUser = virtuosoContext.shouldGroupByUser, sortReactionDetails = virtuosoContext.sortReactionDetails, sortReactions = virtuosoContext.sortReactions, _j = virtuosoContext.unreadMessageCount, unreadMessageCount = _j === void 0 ? 0 : _j, UnreadMessagesSeparator = virtuosoContext.UnreadMessagesSeparator, virtuosoRef = virtuosoContext.virtuosoRef;
    var streamMessageIndex = calculateItemIndex(virtuosoIndex, numItemsPrepended);
    if (customMessageRenderer) {
        return customMessageRenderer(messageList, streamMessageIndex);
    }
    var message = messageList[streamMessageIndex];
    if (!message)
        return React.createElement("div", { style: { height: '1px' } }); // returning null or zero height breaks the virtuoso
    if (isDateSeparatorMessage(message)) {
        return DateSeparator ? React.createElement(DateSeparator, { date: message.date, unread: message.unread }) : null;
    }
    if (message.type === 'system') {
        return MessageSystem ? React.createElement(MessageSystem, { message: message }) : null;
    }
    var groupedByUser = shouldGroupByUser &&
        streamMessageIndex > 0 &&
        ((_a = message.user) === null || _a === void 0 ? void 0 : _a.id) === ((_b = messageList[streamMessageIndex - 1].user) === null || _b === void 0 ? void 0 : _b.id);
    var maybePrevMessage = messageList[streamMessageIndex - 1];
    var maybeNextMessage = messageList[streamMessageIndex + 1];
    // FIXME: firstOfGroup & endOfGroup should be derived from groupStyles which apply a more complex logic
    var firstOfGroup = shouldGroupByUser &&
        (((_c = message.user) === null || _c === void 0 ? void 0 : _c.id) !== ((_d = maybePrevMessage === null || maybePrevMessage === void 0 ? void 0 : maybePrevMessage.user) === null || _d === void 0 ? void 0 : _d.id) ||
            (maybePrevMessage && isMessageEdited(maybePrevMessage)));
    var endOfGroup = shouldGroupByUser &&
        (((_e = message.user) === null || _e === void 0 ? void 0 : _e.id) !== ((_f = maybeNextMessage === null || maybeNextMessage === void 0 ? void 0 : maybeNextMessage.user) === null || _f === void 0 ? void 0 : _f.id) || isMessageEdited(message));
    var createdAtTimestamp = message.created_at && new Date(message.created_at).getTime();
    var lastReadTimestamp = lastReadDate === null || lastReadDate === void 0 ? void 0 : lastReadDate.getTime();
    var isFirstMessage = streamMessageIndex === 0;
    var isNewestMessage = lastReadMessageId === lastReceivedMessageId;
    var isLastReadMessage = message.id === lastReadMessageId ||
        (!unreadMessageCount && createdAtTimestamp === lastReadTimestamp);
    var isFirstUnreadMessage = firstUnreadMessageId === message.id ||
        (!!unreadMessageCount &&
            createdAtTimestamp &&
            lastReadTimestamp &&
            createdAtTimestamp > lastReadTimestamp &&
            isFirstMessage);
    var showUnreadSeparatorAbove = !lastReadMessageId && isFirstUnreadMessage;
    var showUnreadSeparatorBelow = isLastReadMessage && !isNewestMessage && (firstUnreadMessageId || !!unreadMessageCount);
    return (React.createElement(React.Fragment, null,
        showUnreadSeparatorAbove && (React.createElement("div", { className: 'str-chat__unread-messages-separator-wrapper' },
            React.createElement(UnreadMessagesSeparator, { unreadCount: unreadMessageCount }))),
        React.createElement(Message, { additionalMessageInputProps: additionalMessageInputProps, autoscrollToBottom: (_g = virtuosoRef.current) === null || _g === void 0 ? void 0 : _g.autoscrollToBottom, closeReactionSelectorOnClick: closeReactionSelectorOnClick, customMessageActions: customMessageActions, endOfGroup: endOfGroup, firstOfGroup: firstOfGroup, formatDate: formatDate, groupedByUser: groupedByUser, groupStyles: [(_h = messageGroupStyles[message.id]) !== null && _h !== void 0 ? _h : ''], lastReceivedId: lastReceivedMessageId, message: message, Message: MessageUIComponent, messageActions: messageActions, reactionDetailsSort: reactionDetailsSort, readBy: ownMessagesReadByOthers[message.id] || [], sortReactionDetails: sortReactionDetails, sortReactions: sortReactions }),
        showUnreadSeparatorBelow && (React.createElement("div", { className: 'str-chat__unread-messages-separator-wrapper' },
            React.createElement(UnreadMessagesSeparator, { unreadCount: unreadMessageCount })))));
};
