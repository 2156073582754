import { __spreadArray } from "tslib";
import { useEffect } from 'react';
import { useChatContext } from '../../../context/ChatContext';
export var useChannelTruncatedListener = function (setChannels, customHandler, forceUpdate) {
    var client = useChatContext('useChannelTruncatedListener').client;
    useEffect(function () {
        var handleEvent = function (event) {
            setChannels(function (channels) { return __spreadArray([], channels, true); });
            if (customHandler && typeof customHandler === 'function') {
                customHandler(setChannels, event);
            }
            if (forceUpdate) {
                forceUpdate();
            }
        };
        client.on('channel.truncated', handleEvent);
        return function () {
            client.off('channel.truncated', handleEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customHandler]);
};
