import { __assign, __rest, __spreadArray } from "tslib";
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Modal } from '../Modal';
import { useFetchReactions } from './hooks/useFetchReactions';
import { LoadingIndicator } from '../Loading';
import { Avatar } from '../Avatar';
import { useMessageContext } from '../../context';
var defaultReactionDetailsSort = { created_at: -1 };
export function ReactionsListModal(_a) {
    var _b, _c;
    var handleFetchReactions = _a.handleFetchReactions, onSelectedReactionTypeChange = _a.onSelectedReactionTypeChange, propReactionDetailsSort = _a.reactionDetailsSort, reactions = _a.reactions, selectedReactionType = _a.selectedReactionType, propSortReactionDetails = _a.sortReactionDetails, modalProps = __rest(_a, ["handleFetchReactions", "onSelectedReactionTypeChange", "reactionDetailsSort", "reactions", "selectedReactionType", "sortReactionDetails"]);
    var selectedReaction = reactions.find(function (_a) {
        var reactionType = _a.reactionType;
        return reactionType === selectedReactionType;
    });
    var SelectedEmojiComponent = (_b = selectedReaction === null || selectedReaction === void 0 ? void 0 : selectedReaction.EmojiComponent) !== null && _b !== void 0 ? _b : null;
    var _d = useMessageContext('ReactionsListModal'), contextReactionDetailsSort = _d.reactionDetailsSort, contextSortReactionDetails = _d.sortReactionDetails;
    var legacySortReactionDetails = propSortReactionDetails !== null && propSortReactionDetails !== void 0 ? propSortReactionDetails : contextSortReactionDetails;
    var reactionDetailsSort = (_c = propReactionDetailsSort !== null && propReactionDetailsSort !== void 0 ? propReactionDetailsSort : contextReactionDetailsSort) !== null && _c !== void 0 ? _c : defaultReactionDetailsSort;
    var _e = useFetchReactions({
        handleFetchReactions: handleFetchReactions,
        reactionType: selectedReactionType,
        shouldFetch: modalProps.open,
        sort: reactionDetailsSort,
    }), areReactionsLoading = _e.isLoading, reactionDetails = _e.reactions;
    var reactionDetailsWithLegacyFallback = useMemo(function () {
        return legacySortReactionDetails
            ? __spreadArray([], reactionDetails, true).sort(legacySortReactionDetails)
            : reactionDetails;
    }, [legacySortReactionDetails, reactionDetails]);
    return (React.createElement(Modal, __assign({}, modalProps, { className: clsx('str-chat__message-reactions-details-modal', modalProps.className) }),
        React.createElement("div", { className: 'str-chat__message-reactions-details', "data-testid": 'reactions-list-modal' },
            React.createElement("div", { className: 'str-chat__message-reactions-details-reaction-types' }, reactions.map(function (_a) {
                var EmojiComponent = _a.EmojiComponent, reactionCount = _a.reactionCount, reactionType = _a.reactionType;
                return EmojiComponent && (React.createElement("div", { className: clsx('str-chat__message-reactions-details-reaction-type', {
                        'str-chat__message-reactions-details-reaction-type--selected': selectedReactionType === reactionType,
                    }), "data-testid": "reaction-details-selector-".concat(reactionType), key: reactionType, onClick: function () {
                        return onSelectedReactionTypeChange === null || onSelectedReactionTypeChange === void 0 ? void 0 : onSelectedReactionTypeChange(reactionType);
                    } },
                    React.createElement("span", { className: 'emoji str-chat__message-reaction-emoji str-chat__message-reaction-emoji--with-fallback' },
                        React.createElement(EmojiComponent, null)),
                    "\u00A0",
                    React.createElement("span", { className: 'str-chat__message-reaction-count' }, reactionCount)));
            })),
            SelectedEmojiComponent && (React.createElement("div", { className: 'emoji str-chat__message-reaction-emoji str-chat__message-reaction-emoji--with-fallback str-chat__message-reaction-emoji-big' },
                React.createElement(SelectedEmojiComponent, null))),
            React.createElement("div", { className: 'str-chat__message-reactions-details-reacting-users', "data-testid": 'all-reacting-users' }, areReactionsLoading ? (React.createElement(LoadingIndicator, null)) : (reactionDetailsWithLegacyFallback.map(function (_a) {
                var user = _a.user;
                return (React.createElement("div", { className: 'str-chat__message-reactions-details-reacting-user', key: user === null || user === void 0 ? void 0 : user.id },
                    React.createElement(Avatar, { "data-testid": 'avatar', image: user === null || user === void 0 ? void 0 : user.image, name: (user === null || user === void 0 ? void 0 : user.name) || (user === null || user === void 0 ? void 0 : user.id) }),
                    React.createElement("span", { className: 'str-chat__user-item--name', "data-testid": 'reaction-user-username' }, (user === null || user === void 0 ? void 0 : user.name) || (user === null || user === void 0 ? void 0 : user.id))));
            }))))));
}
