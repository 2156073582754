"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
// @ts-nocheck
const react_1 = __importStar(require("react"));
const button_1 = require("@gluestack-ui/button");
const styled_components_1 = require("./styled-components");
const usePropResolution_1 = require("../../hooks/usePropResolution");
const AccessibleButton = (0, button_1.createButton)({
    Root: styled_components_1.Root,
    Text: styled_components_1.Text,
    Group: styled_components_1.Group,
    Spinner: styled_components_1.Spinner,
    Icon: styled_components_1.Icon,
});
function getLoadingProps(props) {
    const sxProps = props === null || props === void 0 ? void 0 : props.sx;
    if (sxProps[':loading']) {
        return sxProps[':loading'];
    }
    else {
        return {};
    }
}
const NewButton = (0, react_1.forwardRef)((_a, ref) => {
    var _b, _c;
    var { children, _stack, isLoading, isDisabled, isLoadingText, spinnerPlacement = 'start', leftIcon, rightIcon, startIcon, endIcon, colorScheme = 'primary', variant = 'solid' } = _a, props = __rest(_a, ["children", "_stack", "isLoading", "isDisabled", "isLoadingText", "spinnerPlacement", "leftIcon", "rightIcon", "startIcon", "endIcon", "colorScheme", "variant"]);
    const resolvedPropForGluestack = (0, usePropResolution_1.usePropResolution)(Object.assign(Object.assign({}, props), _stack));
    const loadingProps = getLoadingProps(resolvedPropForGluestack);
    return (react_1.default.createElement(AccessibleButton, Object.assign({ colorScheme: colorScheme, variant: variant }, resolvedPropForGluestack, { ref: ref, isDisabled: isLoading || isDisabled }, (isLoading && loadingProps)),
        !isLoading && ((_b = (leftIcon && leftIcon)) !== null && _b !== void 0 ? _b : (startIcon && startIcon)),
        isLoading && spinnerPlacement === 'start' && (react_1.default.createElement(AccessibleButton.Spinner, null)),
        isLoading ? (isLoadingText && (react_1.default.createElement(AccessibleButton.Text, Object.assign({}, (loadingProps._text && loadingProps._text)), isLoadingText))) : children && typeof children === 'string' ? (react_1.default.createElement(AccessibleButton.Text, null, children)) : (children && { children }),
        isLoading && spinnerPlacement === 'end' && (react_1.default.createElement(AccessibleButton.Spinner, null)),
        !isLoading && ((_c = (rightIcon && rightIcon)) !== null && _c !== void 0 ? _c : (endIcon && endIcon))));
});
const NewGroupButton = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const resolvedPropForGluestack = (0, usePropResolution_1.usePropResolution)(props);
    return (react_1.default.createElement(AccessibleButton.Group, Object.assign({}, resolvedPropForGluestack, { ref: ref }), children));
});
const ButtonTemp = NewButton;
ButtonTemp.Group = NewGroupButton;
exports.Button = ButtonTemp;
