import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { useTranslationContext } from '../../context/TranslationContext';
export var EmojiIconLarge = function () {
    var t = useTranslationContext('EmojiIconLarge').t;
    return (React.createElement("svg", { height: '28', viewBox: '0 0 28 28', width: '28', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("title", null, t('Open emoji picker')),
        React.createElement("g", { clipRule: 'evenodd', fillRule: 'evenodd' },
            React.createElement("path", { d: 'M14 4.4C8.6 4.4 4.4 8.6 4.4 14c0 5.4 4.2 9.6 9.6 9.6c5.4 0 9.6-4.2 9.6-9.6c0-5.4-4.2-9.6-9.6-9.6zM2 14c0-6.6 5.4-12 12-12s12 5.4 12 12s-5.4 12-12 12s-12-5.4-12-12zM12.8 11c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8s1.8.8 1.8 1.8zM18.8 11c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8s1.8.8 1.8 1.8zM8.6 15.4c.6-.4 1.2-.2 1.6.2c.6.8 1.6 1.8 3 2c1.2.4 2.8.2 4.8-2c.4-.4 1.2-.6 1.6 0c.4.4.6 1.2 0 1.6c-2.2 2.6-4.8 3.4-7 3c-2-.4-3.6-1.8-4.4-3c-.4-.6-.2-1.2.4-1.8z' }))));
};
export var EmojiIconSmall = function () {
    var t = useTranslationContext('EmojiIconSmall').t;
    return (React.createElement("svg", { height: '14', viewBox: '0 0 14 14', width: '14', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("title", null, t('Open emoji picker')),
        React.createElement("g", { clipRule: 'evenodd', fillRule: 'evenodd' },
            React.createElement("path", { d: 'M6.7 1.42C3.73 1.42 1.42 3.73 1.42 6.7c0 2.97 2.31 5.28 5.28 5.28c2.97 0 5.28-2.31 5.28-5.28c0-2.97-2.31-5.28-5.28-5.28zM.1 6.7c0-3.63 2.97-6.6 6.6-6.6s6.6 2.97 6.6 6.6s-2.97 6.6-6.6 6.6s-6.6-2.97-6.6-6.6zM6.04 5.05c0 .55-.44.99-.99.99s-.99-.44-.99-.99s.44-.99.99-.99s.99.44.99.99zM9.34 5.05c0 .55-.44.99-.99.99s-.99-.44-.99-.99s.44-.99.99-.99s.99.44.99.99zM3.73 7.47c.33-.22.66-.11.88.11c.33.44.88.99 1.65 1.1c.66.22 1.54.11 2.64-1.1c.22-.22.66-.33.88 0c.22.22.33.66 0 .88c-1.21 1.43-2.64 1.87-3.85 1.65c-1.1-.22-1.98-.99-2.42-1.65c-.22-.33-.11-.66.22-.99z' }))));
};
// ThemingV2 icon
export var EmojiPickerIcon = function () { return (React.createElement("svg", { preserveAspectRatio: 'xMinYMin', viewBox: '0 0 28 28', width: '100%', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("g", { clipRule: 'evenodd', fillRule: 'evenodd' },
        React.createElement("path", { d: 'M14 4.4C8.6 4.4 4.4 8.6 4.4 14c0 5.4 4.2 9.6 9.6 9.6c5.4 0 9.6-4.2 9.6-9.6c0-5.4-4.2-9.6-9.6-9.6zM2 14c0-6.6 5.4-12 12-12s12 5.4 12 12s-5.4 12-12 12s-12-5.4-12-12zM12.8 11c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8s1.8.8 1.8 1.8zM18.8 11c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8s1.8.8 1.8 1.8zM8.6 15.4c.6-.4 1.2-.2 1.6.2c.6.8 1.6 1.8 3 2c1.2.4 2.8.2 4.8-2c.4-.4 1.2-.6 1.6 0c.4.4.6 1.2 0 1.6c-2.2 2.6-4.8 3.4-7 3c-2-.4-3.6-1.8-4.4-3c-.4-.6-.2-1.2.4-1.8z' })))); };
export var FileUploadIcon = function () {
    var t = useTranslationContext('FileUploadIcon').t;
    return (React.createElement("svg", { height: '14', viewBox: '0 0 14 14', width: '14', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("title", null, t('Attach files')),
        React.createElement("path", { d: 'M7 .5c3.59 0 6.5 2.91 6.5 6.5s-2.91 6.5-6.5 6.5S.5 10.59.5 7 3.41.5 7 .5zm0 12c3.031 0 5.5-2.469 5.5-5.5S10.031 1.5 7 1.5A5.506 5.506 0 0 0 1.5 7c0 3.034 2.469 5.5 5.5 5.5zM7.506 3v3.494H11v1.05H7.506V11h-1.05V7.544H3v-1.05h3.456V3h1.05z', fillRule: 'nonzero' })));
};
export var FileUploadIconFlat = function () {
    var t = useTranslationContext('FileUploadIconFlat').t;
    return (React.createElement("svg", { height: '14', viewBox: '0 0 14 14', width: '14', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("title", null, t('Attach files')),
        React.createElement("path", { d: 'M1.667.333h10.666c.737 0 1.334.597 1.334 1.334v10.666c0 .737-.597 1.334-1.334 1.334H1.667a1.333 1.333 0 0 1-1.334-1.334V1.667C.333.93.93.333 1.667.333zm2 1.334a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333zm-2 9.333v1.333h10.666v-4l-2-2-4 4-2-2L1.667 11z', fillRule: 'nonzero' })));
};
export var LoadingIndicatorIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 20 : _b;
    var id = useMemo(function () { return nanoid(); }, []);
    return (React.createElement("div", { className: 'str-chat__loading-indicator' },
        React.createElement("svg", { "data-testid": 'loading-indicator', height: size, viewBox: '0 0 30 30', width: size, xmlns: 'http://www.w3.org/2000/svg' },
            React.createElement("defs", null,
                React.createElement("linearGradient", { id: "".concat(id, "-linear-gradient"), x1: '50%', x2: '50%', y1: '0%', y2: '100%' },
                    React.createElement("stop", { offset: '0%', stopColor: '#FFF', stopOpacity: '0' }),
                    React.createElement("stop", { "data-testid": 'stop-color', offset: '100%', stopOpacity: '1' }))),
            React.createElement("path", { d: 'M2.518 23.321l1.664-1.11A12.988 12.988 0 0 0 15 28c7.18 0 13-5.82 13-13S22.18 2 15 2V0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-5.206 0-9.792-2.652-12.482-6.679z', fill: "url(#".concat(id, "-linear-gradient)"), fillRule: 'evenodd' }))));
};
// ThemingV2 icon
export var UploadIcon = function () { return (React.createElement("svg", { "data-testid": 'attach', fill: 'none', height: '24', viewBox: '0 0 24 24', width: '24', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("g", { clipPath: 'url(#clip0_10878_5)' },
        React.createElement("path", { d: 'M12.9997 6.99993L10.9997 6.99993L10.9997 10.9999L6.99972 10.9999L6.99972 12.9999L10.9997 12.9999L10.9997 16.9999L12.9997 16.9999L12.9997 12.9999L16.9997 12.9999L16.9997 10.9999L12.9997 10.9999L12.9997 6.99993ZM11.9997 1.99992C6.47972 1.99992 1.99972 6.47993 1.99972 11.9999C1.99972 17.5199 6.47972 21.9999 11.9997 21.9999C17.5197 21.9999 21.9997 17.5199 21.9997 11.9999C21.9997 6.47993 17.5197 1.99992 11.9997 1.99992ZM11.9997 19.9999C7.58972 19.9999 3.99972 16.4099 3.99972 11.9999C3.99972 7.58993 7.58972 3.99993 11.9997 3.99993C16.4097 3.99993 19.9997 7.58993 19.9997 11.9999C19.9997 16.4099 16.4097 19.9999 11.9997 19.9999Z', fill: 'black' })),
    React.createElement("defs", null,
        React.createElement("clipPath", { id: 'clip0_10878_5' },
            React.createElement("rect", { fill: 'white', height: '24', width: '24' }))))); };
export var CloseIcon = function () { return (React.createElement("svg", { "data-testid": 'close-no-outline', fill: 'none', height: '24', viewBox: '0 0 24 24', width: '24', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { d: 'M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z', fill: 'black' }))); };
export var RetryIcon = function () { return (React.createElement("svg", { "data-testid": 'retry', fill: 'none', height: '24', viewBox: '0 0 24 24', width: '24', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { d: 'M17.6449 6.35C16.1949 4.9 14.2049 4 11.9949 4C7.57488 4 4.00488 7.58 4.00488 12C4.00488 16.42 7.57488 20 11.9949 20C15.7249 20 18.8349 17.45 19.7249 14H17.6449C16.8249 16.33 14.6049 18 11.9949 18C8.68488 18 5.99488 15.31 5.99488 12C5.99488 8.69 8.68488 6 11.9949 6C13.6549 6 15.1349 6.69 16.2149 7.78L12.9949 11H19.9949V4L17.6449 6.35Z', fill: 'black' }))); };
export var DownloadIcon = function () { return (React.createElement("svg", { "data-testid": 'download', fill: 'none', height: '24', viewBox: '0 0 24 24', width: '24', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { d: 'M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM19 18H6C3.79 18 2 16.21 2 14C2 11.95 3.53 10.24 5.56 10.03L6.63 9.92L7.13 8.97C8.08 7.14 9.94 6 12 6C14.62 6 16.88 7.86 17.39 10.43L17.69 11.93L19.22 12.04C20.78 12.14 22 13.45 22 15C22 16.65 20.65 18 19 18ZM13.45 10H10.55V13H8L12 17L16 13H13.45V10Z', fill: 'black' }))); };
export var LinkIcon = function () { return (React.createElement("svg", { fill: 'none', height: '11', viewBox: '0 0 20 11', width: '20', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { clipRule: 'evenodd', d: 'M1.9 5.5C1.9 3.79 3.29 2.4 5 2.4H8.05C8.57467 2.4 9 1.97467 9 1.45C9 0.925329 8.57467 0.5 8.05 0.5H5C2.24 0.5 0 2.74 0 5.5C0 8.26 2.24 10.5 5 10.5H8.05C8.57467 10.5 9 10.0747 9 9.55C9 9.02533 8.57467 8.6 8.05 8.6H5C3.29 8.6 1.9 7.21 1.9 5.5ZM6 5.5C6 6.05228 6.44772 6.5 7 6.5H13C13.5523 6.5 14 6.05228 14 5.5C14 4.94772 13.5523 4.5 13 4.5H7C6.44772 4.5 6 4.94772 6 5.5ZM15 0.5H11.95C11.4253 0.5 11 0.925329 11 1.45C11 1.97467 11.4253 2.4 11.95 2.4H15C16.71 2.4 18.1 3.79 18.1 5.5C18.1 7.21 16.71 8.6 15 8.6H11.95C11.4253 8.6 11 9.02533 11 9.55C11 10.0747 11.4253 10.5 11.95 10.5H15C17.76 10.5 20 8.26 20 5.5C20 2.74 17.76 0.5 15 0.5Z', fill: '#005DFF', fillRule: 'evenodd' }))); };
export var SendIconV1 = function () {
    var t = useTranslationContext('SendButton').t;
    return (React.createElement("svg", { height: '17', viewBox: '0 0 18 17', width: '18', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("title", null, t('Send')),
        React.createElement("path", { d: 'M0 17.015l17.333-8.508L0 0v6.617l12.417 1.89L0 10.397z', fill: '#006cff', fillRule: 'evenodd' })));
};
export var SendIconV2 = function () {
    var t = useTranslationContext('SendButton').t;
    return (React.createElement("svg", { "data-testid": 'send', fill: 'currentColor', height: '24', viewBox: '0 0 24 24', width: '24', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("title", null, t('Send')),
        React.createElement("path", { d: 'M4.00952 22L24 12L4.00952 2L4 9.77778L18.2857 12L4 14.2222L4.00952 22Z' })));
};
export var MicIcon = function () { return (React.createElement("svg", { fill: 'currentColor', viewBox: '0 0 14 20', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { d: 'M7 12.5C8.66 12.5 10 11.16 10 9.5V3.5C10 1.84 8.66 0.5 7 0.5C5.34 0.5 4 1.84 4 3.5V9.5C4 11.16 5.34 12.5 7 12.5Z' }),
    React.createElement("path", { d: 'M12 9.5C12 12.26 9.76 14.5 7 14.5C4.24 14.5 2 12.26 2 9.5H0C0 13.03 2.61 15.93 6 16.42V19.5H8V16.42C11.39 15.93 14 13.03 14 9.5H12Z' }))); };
export var BinIcon = function () { return (React.createElement("svg", { fill: 'currentColor', viewBox: '0 0 32 32', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { d: 'M8.00033 25.3333C8.00033 26.8 9.20033 28 10.667 28H21.3337C22.8003 28 24.0003 26.8 24.0003 25.3333V12C24.0003 10.5333 22.8003 9.33333 21.3337 9.33333H10.667C9.20033 9.33333 8.00033 10.5333 8.00033 12V25.3333ZM24.0003 5.33333H20.667L19.7203 4.38667C19.4803 4.14667 19.1337 4 18.787 4H13.2137C12.867 4 12.5203 4.14667 12.2803 4.38667L11.3337 5.33333H8.00033C7.26699 5.33333 6.66699 5.93333 6.66699 6.66667C6.66699 7.4 7.26699 8 8.00033 8H24.0003C24.7337 8 25.3337 7.4 25.3337 6.66667C25.3337 5.93333 24.7337 5.33333 24.0003 5.33333Z' }))); };
export var PauseIcon = function () { return (React.createElement("svg", { "data-testid": 'str-chat__pause-icon', fill: 'currentColor', viewBox: '0 0 16 20', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { d: 'M0 19.3333H5.33333V0.666626H0V19.3333ZM10.6667 0.666626V19.3333H16V0.666626H10.6667Z' }))); };
export var PlayIcon = function () { return (React.createElement("svg", { "data-testid": 'str-chat__play-icon', fill: 'currentColor', viewBox: '0 0 14 18', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { d: 'M0.236328 2.09338V15.9067C0.236328 16.9601 1.39633 17.6001 2.28966 17.0267L13.143 10.1201C13.9697 9.60005 13.9697 8.40005 13.143 7.86672L2.28966 0.973385C1.39633 0.400051 0.236328 1.04005 0.236328 2.09338Z' }))); };
export var CheckSignIcon = function () { return (React.createElement("svg", { fill: 'currentColor', viewBox: '0 0 18 14', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { d: 'M5.79457 10.875L2.32457 7.40502C1.93457 7.01502 1.30457 7.01502 0.91457 7.40502C0.52457 7.79502 0.52457 8.42502 0.91457 8.81502L5.09457 12.995C5.48457 13.385 6.11457 13.385 6.50457 12.995L17.0846 2.41502C17.4746 2.02502 17.4746 1.39502 17.0846 1.00502C16.6946 0.615024 16.0646 0.615024 15.6746 1.00502L5.79457 10.875Z' }))); };
