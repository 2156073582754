"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMediaQuery = void 0;
//@ts-nocheck
const react_native_1 = require("react-native");
function useMediaQuery(query) {
    const { height, width } = (0, react_native_1.useWindowDimensions)();
    return iterateQuery(query, height, width);
}
exports.useMediaQuery = useMediaQuery;
function queryResolver(query, width, height) {
    for (const queryKey in query) {
        if (!calculateQuery(queryKey, query[queryKey], height, width)) {
            return false;
        }
    }
    return true;
}
function iterateQuery(query, height, width) {
    const queryResults = [];
    if (Array.isArray(query)) {
        query.forEach((subQuery) => {
            queryResults.push(queryResolver(subQuery, width, height));
        });
    }
    else {
        queryResults.push(queryResolver(query, width, height));
    }
    return queryResults;
}
function calculateQuery(key, val, height, width) {
    let retval;
    if (!width && !height && !val) {
        return;
    }
    switch (key) {
        case 'maxWidth':
            retval = val ? width <= val : undefined;
            break;
        case 'minWidth':
            retval = val ? width >= val : undefined;
            break;
        case 'maxHeight':
            retval = val ? height <= val : undefined;
            break;
        case 'minHeight':
            retval = val ? height >= val : undefined;
            break;
        case 'orientation':
            if (val) {
                if (width > height) {
                    retval = val === 'landscape';
                }
                else {
                    retval = val === 'portrait';
                }
            }
            break;
        default:
            break;
    }
    return retval;
}
