"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
const plugins_1 = require("../../../plugins");
exports.default = (0, react_1.styled)(react_native_1.View, {
    alignItems: 'center',
    justifyContent: 'flex-start',
    space: 3,
    flexShrink: 1,
    borderRadius: '$sm',
    padding: '$3',
    _icon: { props: { size: 'sm' } },
    variants: {
        variant: {
            'solid': {},
            'left-accent': {},
            'top-accent': {},
            'outline': {},
            'subtle': {},
            'outline-light': {},
        },
    },
    defaultProps: {
        variant: 'subtle',
    },
}, {
    descendantStyle: ['_icon', '_text'],
}, {
    plugins: [new plugins_1.ColorSchemeResolver(colorSchemeResolveFn)],
});
function colorSchemeResolveFn(_a) {
    var props = __rest(_a, []);
    let value = {};
    if (props.colorScheme) {
        const color = props.colorScheme;
        const variant = props.variant;
        switch (variant) {
            case 'solid':
                value = {
                    backgroundColor: `$${color}.700`,
                    _dark: {
                        backgroundColor: `$${color}.600`,
                    },
                    _icon: { color: `$muted.50` },
                };
                break;
            case 'outline-light':
                value = {
                    // @ts-ignore
                    borderWidth: 1,
                    _icon: { color: `$${color}.700` },
                    borderColor: `$${color}.700.alpha0.4`,
                    _dark: {
                        _icon: { color: `$${color}.600` },
                        borderColor: `$${color}.600.alpha0.4`,
                    },
                };
                break;
            case 'subtle':
                value = {
                    backgroundColor: `$${color}.200`,
                    _icon: { color: `$${color}.700` },
                    _dark: {
                        backgroundColor: `$${color}.200`,
                        _icon: { color: `$${color}.600` },
                    },
                };
                break;
            case 'outline':
                value = {
                    // @ts-ignore
                    borderWidth: 1,
                    _icon: { color: `$${color}.700` },
                    borderColor: `$${color}.700`,
                    _dark: {
                        _icon: { color: `$${color}.600` },
                        borderColor: `$${color}.600`,
                    },
                };
                break;
            case 'top-accent':
                value = {
                    borderTopWidth: 4,
                    backgroundColor: `$${color}.200`,
                    _icon: { color: `$${color}.700` },
                    borderColor: `$${color}.700`,
                    _dark: {
                        backgroundColor: `$${color}.200`,
                        _icon: { color: `$${color}.600` },
                        borderColor: `$${color}.600`,
                    },
                };
                break;
            case 'left-accent':
                value = {
                    borderLeftWidth: 4,
                    backgroundColor: `$${color}.200`,
                    _icon: { color: `$${color}.700` },
                    borderColor: `$${color}.700`,
                    _dark: {
                        backgroundColor: `$${color}.200`,
                        _icon: { color: `$${color}.600` },
                        borderColor: `$${color}.600`,
                    },
                };
                break;
            default:
                value = {};
        }
        // check for size prop also
        // const variantCallbacks? = getVariant('Text');
        // value = variantCallbacks[variant](props);
    }
    return value;
}
