import { __awaiter, __generator } from "tslib";
import { renderAudio, toAudioBuffer } from './audioProcessing';
var WAV_HEADER_LENGTH_BYTES = 44;
var BYTES_PER_SAMPLE = 2;
var RIFF_FILE_MAX_BYTES = 4294967295;
var HEADER = {
    AUDIO_FORMAT: { offset: 20, value: 1 },
    BITS_PER_SAMPLE: { offset: 34, value: BYTES_PER_SAMPLE * 8 },
    BLOCK_ALIGN: { offset: 32 },
    BYTE_RATE: { offset: 28 },
    CHANNEL_COUNT: { offset: 22 },
    CHUNK_ID: { offset: 0, value: 0x52494646 },
    CHUNK_SIZE: { offset: 4 },
    FILE_FORMAT: { offset: 8, value: 0x57415645 },
    SAMPLE_RATE: { offset: 24 },
    SUBCHUNK1_ID: { offset: 12, value: 0x666d7420 },
    SUBCHUNK1_SIZE: { offset: 16, value: 16 },
    SUBCHUNK2_ID: { offset: 36, value: 0x64617461 },
    SUBCHUNK2_SIZE: { offset: 40 }, // actual audio data size
};
var fourCharsToInt = function (chars) {
    return (chars.charCodeAt(0) << 24) |
        (chars.charCodeAt(1) << 16) |
        (chars.charCodeAt(2) << 8) |
        chars.charCodeAt(3);
};
var WAV_HEADER_FLAGS = {
    data: fourCharsToInt('data'),
    fmt: fourCharsToInt('fmt '),
    RIFF: fourCharsToInt('RIFF'),
    WAVE: fourCharsToInt('WAVE'),
};
var writeWavHeader = function (_a) {
    var arrayBuffer = _a.arrayBuffer, channelCount = _a.channelCount, sampleRate = _a.sampleRate;
    var byteRate = sampleRate * channelCount * BYTES_PER_SAMPLE; // bytes/sec
    var blockAlign = channelCount * BYTES_PER_SAMPLE;
    var dataView = new DataView(arrayBuffer);
    /*
     * The maximum size of a RIFF file is 4294967295 bytes and since the header takes up 44 bytes there are 4294967251 bytes left for the
     * data chunk.
     */
    var dataChunkSize = Math.min(dataView.byteLength - WAV_HEADER_LENGTH_BYTES, RIFF_FILE_MAX_BYTES - WAV_HEADER_LENGTH_BYTES);
    dataView.setUint32(HEADER.CHUNK_ID.offset, HEADER.CHUNK_ID.value); // "RIFF"
    dataView.setUint32(HEADER.CHUNK_SIZE.offset, arrayBuffer.byteLength - 8, true); // adjustment for the first two headers - chunk id + file size
    dataView.setUint32(HEADER.FILE_FORMAT.offset, HEADER.FILE_FORMAT.value); // "WAVE"
    dataView.setUint32(HEADER.SUBCHUNK1_ID.offset, HEADER.SUBCHUNK1_ID.value); // "fmt "
    dataView.setUint32(HEADER.SUBCHUNK1_SIZE.offset, HEADER.SUBCHUNK1_SIZE.value, true);
    dataView.setUint16(HEADER.AUDIO_FORMAT.offset, HEADER.AUDIO_FORMAT.value, true);
    dataView.setUint16(HEADER.CHANNEL_COUNT.offset, channelCount, true);
    dataView.setUint32(HEADER.SAMPLE_RATE.offset, sampleRate, true);
    dataView.setUint32(HEADER.BYTE_RATE.offset, byteRate, true);
    dataView.setUint16(HEADER.BLOCK_ALIGN.offset, blockAlign, true);
    dataView.setUint16(HEADER.BITS_PER_SAMPLE.offset, HEADER.BITS_PER_SAMPLE.value, true);
    dataView.setUint32(HEADER.SUBCHUNK2_ID.offset, HEADER.SUBCHUNK2_ID.value); // "data"
    dataView.setUint32(HEADER.SUBCHUNK2_SIZE.offset, dataChunkSize, true);
};
export var readWavHeader = function (dataView) {
    var header = dataView.getUint32(0, false);
    if (WAV_HEADER_FLAGS.RIFF !== header) {
        console.error('Missing RIFF header in WAVE file');
        return;
    }
    if (WAV_HEADER_FLAGS.WAVE !== dataView.getUint32(HEADER.FILE_FORMAT.offset, false)) {
        console.error('Missing WAVE header in WAVE file');
        return;
    }
    if (WAV_HEADER_FLAGS.fmt !== dataView.getUint32(HEADER.SUBCHUNK1_ID.offset, false)) {
        console.error('Missing fmt header in WAVE file');
        return;
    }
    return {
        audioDataSizeBytes: dataView.getUint32(HEADER.SUBCHUNK2_SIZE.offset, true),
        audioDataStartOffset: WAV_HEADER_LENGTH_BYTES,
        channelCount: dataView.getUint16(HEADER.CHANNEL_COUNT.offset, true),
        sampleRate: dataView.getUint32(HEADER.SAMPLE_RATE.offset, true),
    };
};
var splitDataByChannel = function (audioBuffer) {
    return Array.from({ length: audioBuffer.numberOfChannels }, function (_, i) { return audioBuffer.getChannelData(i); });
};
/**
 * In a WAV file, samples for each channel are usually interleaved, meaning samples from each channel are grouped together sequentially.
 * For example, in a stereo audio file (2 channels), samples alternate between the left and right channels.
 * @param arrayBuffer
 * @param dataByChannel
 */
var writeWavAudioData = function (_a) {
    var arrayBuffer = _a.arrayBuffer, dataByChannel = _a.dataByChannel;
    var dataView = new DataView(arrayBuffer);
    var channelCount = dataByChannel.length;
    dataByChannel.forEach(function (channelData, channelIndex) {
        var writeOffset = WAV_HEADER_LENGTH_BYTES + channelCount * channelIndex;
        channelData.forEach(function (float32Value) {
            dataView.setInt16(writeOffset, float32Value < 0 ? Math.max(-1, float32Value) * 32768 : Math.min(1, float32Value) * 32767, true);
            writeOffset += channelCount * BYTES_PER_SAMPLE;
        });
    });
};
export var encodeToWaw = function (file, sampleRate) { return __awaiter(void 0, void 0, void 0, function () {
    var audioBuffer, _a, numberOfSamples, fileSizeBytes, arrayBuffer;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = renderAudio;
                return [4 /*yield*/, toAudioBuffer(file)];
            case 1: return [4 /*yield*/, _a.apply(void 0, [_b.sent(), sampleRate])];
            case 2:
                audioBuffer = _b.sent();
                numberOfSamples = audioBuffer.duration * sampleRate;
                fileSizeBytes = numberOfSamples * audioBuffer.numberOfChannels * BYTES_PER_SAMPLE + WAV_HEADER_LENGTH_BYTES;
                arrayBuffer = new ArrayBuffer(fileSizeBytes);
                writeWavHeader({ arrayBuffer: arrayBuffer, channelCount: audioBuffer.numberOfChannels, sampleRate: sampleRate });
                writeWavAudioData({ arrayBuffer: arrayBuffer, dataByChannel: splitDataByChannel(audioBuffer) });
                return [2 /*return*/, new Blob([arrayBuffer], { type: 'audio/wav' })];
        }
    });
}); };
