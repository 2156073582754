import { __assign, __rest } from "tslib";
import React, { useEffect, useRef } from 'react';
import { deprecationAndReplacementWarning } from '../../utils/deprecationWarning';
import { DEFAULT_LOAD_PAGE_SCROLL_THRESHOLD } from '../../constants/limits';
/**
 * Prevents Chrome hangups
 * See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
 */
var mousewheelListener = function (event) {
    if (event instanceof WheelEvent && event.deltaY === 1) {
        event.preventDefault();
    }
};
export var InfiniteScroll = function (props) {
    var children = props.children, _a = props.element, element = _a === void 0 ? 'div' : _a, hasMore = props.hasMore, hasMoreNewer = props.hasMoreNewer, hasNextPage = props.hasNextPage, hasPreviousPage = props.hasPreviousPage, head = props.head, _b = props.initialLoad, initialLoad = _b === void 0 ? true : _b, isLoading = props.isLoading, listenToScroll = props.listenToScroll, loader = props.loader, loadMore = props.loadMore, loadMoreNewer = props.loadMoreNewer, loadNextPage = props.loadNextPage, loadPreviousPage = props.loadPreviousPage, _c = props.threshold, threshold = _c === void 0 ? DEFAULT_LOAD_PAGE_SCROLL_THRESHOLD : _c, _d = props.useCapture, useCapture = _d === void 0 ? false : _d, elementProps = __rest(props, ["children", "element", "hasMore", "hasMoreNewer", "hasNextPage", "hasPreviousPage", "head", "initialLoad", "isLoading", "listenToScroll", "loader", "loadMore", "loadMoreNewer", "loadNextPage", "loadPreviousPage", "threshold", "useCapture"]);
    var loadNextPageFn = loadNextPage || loadMoreNewer;
    var loadPreviousPageFn = loadPreviousPage || loadMore;
    var hasNextPageFlag = hasNextPage || hasMoreNewer;
    var hasPreviousPageFlag = hasPreviousPage || hasMore;
    var scrollComponent = useRef();
    var previousOffset = useRef();
    var previousReverseOffset = useRef();
    var scrollListenerRef = useRef();
    scrollListenerRef.current = function () {
        var element = scrollComponent.current;
        if (!element || element.offsetParent === null) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        var parentElement = element.parentElement;
        var offset = element.scrollHeight - parentElement.scrollTop - parentElement.clientHeight;
        var reverseOffset = parentElement.scrollTop;
        if (listenToScroll) {
            listenToScroll(offset, reverseOffset, threshold);
        }
        if (isLoading)
            return;
        if (previousOffset.current === offset && previousReverseOffset.current === reverseOffset)
            return;
        previousOffset.current = offset;
        previousReverseOffset.current = reverseOffset;
        // FIXME: this triggers loadMore call when a user types messages in thread and the scroll container expands
        if (reverseOffset < Number(threshold) &&
            typeof loadPreviousPageFn === 'function' &&
            hasPreviousPageFlag) {
            loadPreviousPageFn();
        }
        if (offset < Number(threshold) && typeof loadNextPageFn === 'function' && hasNextPageFlag) {
            loadNextPageFn();
        }
    };
    useEffect(function () {
        deprecationAndReplacementWarning([
            [{ hasMoreNewer: hasMoreNewer }, { hasNextPage: hasNextPage }],
            [{ loadMoreNewer: loadMoreNewer }, { loadNextPage: loadNextPage }],
            [{ hasMore: hasMore }, { hasPreviousPage: hasPreviousPage }],
            [{ loadMore: loadMore }, { loadPreviousPage: loadPreviousPage }],
        ], 'InfiniteScroll');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        var _a;
        var scrollElement = (_a = scrollComponent.current) === null || _a === void 0 ? void 0 : _a.parentNode;
        if (!scrollElement)
            return;
        var scrollListener = function () { var _a; return (_a = scrollListenerRef.current) === null || _a === void 0 ? void 0 : _a.call(scrollListenerRef); };
        scrollElement.addEventListener('scroll', scrollListener, useCapture);
        scrollElement.addEventListener('resize', scrollListener, useCapture);
        scrollListener();
        return function () {
            scrollElement.removeEventListener('scroll', scrollListener, useCapture);
            scrollElement.removeEventListener('resize', scrollListener, useCapture);
        };
    }, [initialLoad, useCapture]);
    useEffect(function () {
        var _a;
        var scrollElement = (_a = scrollComponent.current) === null || _a === void 0 ? void 0 : _a.parentNode;
        if (scrollElement) {
            scrollElement.addEventListener('wheel', mousewheelListener, { passive: false });
        }
        return function () {
            if (scrollElement) {
                scrollElement.removeEventListener('wheel', mousewheelListener, useCapture);
            }
        };
    }, [useCapture]);
    var attributes = __assign(__assign({}, elementProps), { ref: function (element) {
            scrollComponent.current = element;
        } });
    var childrenArray = [loader, children];
    if (head) {
        childrenArray.unshift(head);
    }
    return React.createElement(element, attributes, childrenArray);
};
