import { __awaiter, __generator } from "tslib";
import { readFileAsArrayBuffer } from '../../ReactFileUtilities';
/**
 * In the context of resampling audio data, AudioContext is used to decode the input audio file into an AudioBuffer,
 * which is a fundamental data structure representing audio data.
 * @param file
 */
export var toAudioBuffer = function (file) { return __awaiter(void 0, void 0, void 0, function () {
    var audioCtx, arrayBuffer, decodedData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                audioCtx = new AudioContext();
                return [4 /*yield*/, readFileAsArrayBuffer(file)];
            case 1:
                arrayBuffer = _a.sent();
                return [4 /*yield*/, audioCtx.decodeAudioData(arrayBuffer)];
            case 2:
                decodedData = _a.sent();
                if (!(audioCtx.state !== 'closed')) return [3 /*break*/, 4];
                return [4 /*yield*/, audioCtx.close()];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/, decodedData];
        }
    });
}); };
/**
 * OfflineAudioContext is a specialized type of AudioContext that does not render audio in real-time and is used for offline audio processing tasks.
 * It allows performing audio processing and rendering without actually playing the audio through speakers or outputting it to a destination.
 * In the context of resampling audio data, OfflineAudioContext is used to resample the decoded AudioBuffer from a file to the desired sample rate.
 * It provides more flexibility and control over audio processing, as it can operate at different sample rates and durations compared to real-time audio contexts.
 * @param audioBuffer
 * @param sampleRate
 */
export var renderAudio = function (audioBuffer, sampleRate) { return __awaiter(void 0, void 0, void 0, function () {
    var offlineAudioCtx, source;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                offlineAudioCtx = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.duration * sampleRate, sampleRate);
                source = offlineAudioCtx.createBufferSource();
                source.buffer = audioBuffer;
                source.connect(offlineAudioCtx.destination);
                source.start();
                return [4 /*yield*/, offlineAudioCtx.startRendering()];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
