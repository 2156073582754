export function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,/\\^$|#]/g, '\\$&');
}
export var detectHttp = /(http(s?):\/\/)?(www\.)?/;
export var messageCodeBlocks = function (message) {
    var codeRegex = /```[a-z]*\n[\s\S]*?\n```|`[a-z]*[\s\S]*?`/gm;
    var matches = message.match(codeRegex);
    return matches || [];
};
export var matchMarkdownLinks = function (message) {
    var regexMdLinks = /\[([^[]+)\](\(.*\))/gm;
    var matches = message.match(regexMdLinks);
    var singleMatch = /\[([^[]+)\]\((.*)\)/;
    var links = matches
        ? matches.map(function (match) {
            var i = singleMatch.exec(match);
            return i && [i[1], i[2]];
        })
        : [];
    return links.flat();
};
