"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
exports.default = (0, react_1.styled)(react_native_1.TextInput, {
    backgroundColor: 'transparent',
    flex: 1,
    width: '$full',
    height: '$full',
    // @ts-ignore
    py: '$2',
    // @ts-ignore
    px: '$3',
    color: '$text.900',
    props: {
        multiline: true,
        placeholderTextColor: '$text.400',
    },
    _dark: {
        color: '$text.50',
        props: {
            placeholderTextColor: '$text.600',
        },
    },
    _web: {
        'outlineWidth': '$0',
        'cursor': 'text',
        ':disabled': {
            cursor: 'not-allowed',
        },
    },
}, {
    componentName: 'TextareaInput',
    ancestorStyle: ['_input'],
    resolveProps: ['placeholderTextColor'],
}, {
    propertyTokenMap: {
        placeholderTextColor: 'colors',
    },
});
