"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Avatar = void 0;
const avatar_1 = require("@gluestack-ui/avatar");
const styled_components_1 = require("./styled-components");
const react_1 = __importStar(require("react"));
const usePropResolution_1 = require("../../hooks/usePropResolution");
const AccessibleAvatar = (0, avatar_1.createAvatar)({
    Root: styled_components_1.Root,
    Badge: styled_components_1.Badge,
    Group: styled_components_1.Group,
    Image: styled_components_1.Image,
    FallbackText: styled_components_1.FallbackText,
});
const AvatarTemp = (0, react_1.forwardRef)((_a, ref) => {
    var { children, source, size } = _a, props = __rest(_a, ["children", "source", "size"]);
    const resolvedPropForGluestack = (0, usePropResolution_1.usePropResolution)(props);
    const GuiChildren = react_1.Children.map(children, (child) => {
        if (typeof child === 'string') {
            return (react_1.default.createElement(AccessibleAvatar.FallbackText, null, child));
        }
        else {
            return child;
        }
    });
    return (react_1.default.createElement(AccessibleAvatar, Object.assign({ size: size }, resolvedPropForGluestack, { ref: ref }),
        GuiChildren,
        react_1.default.createElement(AccessibleAvatar.Image, { source: source })));
});
const AvatarGroupTemp = (0, react_1.forwardRef)((_a, ref) => {
    var { children, max = -1, space } = _a, props = __rest(_a, ["children", "max", "space"]);
    // TODO: fix later
    // let spaceStyleObj = {};
    // if (space) spaceStyleObj = { _avatar: { marginLeft: 0 } };
    const resolvedPropForGluestack = (0, usePropResolution_1.usePropResolution)(Object.assign(Object.assign({}, props), { space }));
    const remainingAvatar = () => {
        const remainingAvatarNumber = children.length - max;
        return (react_1.default.createElement(AccessibleAvatar, null,
            react_1.default.createElement(AccessibleAvatar.FallbackText, null, `+ ${remainingAvatarNumber}`)));
    };
    return (react_1.default.createElement(AccessibleAvatar.Group, Object.assign({}, resolvedPropForGluestack, { ref: ref }), max && max < children.length && max > 0
        ? [...children.slice(0, max), remainingAvatar()].map((child, index) => {
            return (0, react_1.cloneElement)(child, { key: index });
        })
        : children));
});
const AvatarBadgeTemp = (0, react_1.forwardRef)((props, ref) => {
    const resolvedPropForGluestack = (0, usePropResolution_1.usePropResolution)(props);
    return react_1.default.createElement(AccessibleAvatar.Badge, Object.assign({}, resolvedPropForGluestack, { ref: ref }));
});
const AvatarNew = AvatarTemp;
AvatarNew.Group = AvatarGroupTemp;
AvatarNew.Badge = AvatarBadgeTemp;
exports.Avatar = AvatarNew;
