import React from 'react';
import { FileIcon } from '../ReactFileUtilities';
import { useTranslationContext } from '../../context';
export var UnsupportedAttachment = function (_a) {
    var attachment = _a.attachment;
    var t = useTranslationContext('UnsupportedAttachment').t;
    return (React.createElement("div", { className: 'str-chat__message-attachment-unsupported', "data-testid": 'attachment-unsupported' },
        React.createElement(FileIcon, { className: 'str-chat__file-icon', version: '2' }),
        React.createElement("div", { className: 'str-chat__message-attachment-unsupported__metadata' },
            React.createElement("div", { className: 'str-chat__message-attachment-unsupported__title', "data-testid": 'unsupported-attachment-title' }, attachment.title || t('Unsupported attachment')))));
};
export var NullComponent = function () { return null; };
