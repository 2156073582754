import { __assign, __rest } from "tslib";
import React from 'react';
import { useTranslationContext } from '../../context/TranslationContext';
import { getDateString } from '../../i18n/utils';
var UnMemoizedDateSeparator = function (props) {
    var _a = props.calendar, calendar = _a === void 0 ? true : _a, messageCreatedAt = props.date, formatDate = props.formatDate, _b = props.position, position = _b === void 0 ? 'right' : _b, unread = props.unread, restTimestampFormatterOptions = __rest(props, ["calendar", "date", "formatDate", "position", "unread"]);
    var _c = useTranslationContext('DateSeparator'), t = _c.t, tDateTimeParser = _c.tDateTimeParser;
    var formattedDate = getDateString(__assign(__assign({ calendar: calendar }, restTimestampFormatterOptions), { formatDate: formatDate, messageCreatedAt: messageCreatedAt, t: t, tDateTimeParser: tDateTimeParser, timestampTranslationKey: 'timestamp/DateSeparator' }));
    return (React.createElement("div", { className: 'str-chat__date-separator', "data-testid": 'date-separator' },
        (position === 'right' || position === 'center') && (React.createElement("hr", { className: 'str-chat__date-separator-line' })),
        React.createElement("div", { className: 'str-chat__date-separator-date' }, unread ? "".concat(t('New'), " - ").concat(formattedDate) : formattedDate),
        (position === 'left' || position === 'center') && (React.createElement("hr", { className: 'str-chat__date-separator-line' }))));
};
/**
 * A simple date separator between messages.
 */
export var DateSeparator = React.memo(UnMemoizedDateSeparator);
