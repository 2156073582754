import { __assign, __rest } from "tslib";
import React from 'react';
import { useMessageContext } from '../../context/MessageContext';
import { Timestamp as DefaultTimestamp } from './Timestamp';
import { useComponentContext } from '../../context';
export var defaultTimestampFormat = 'h:mmA';
var UnMemoizedMessageTimestamp = function (props) {
    var propMessage = props.message, timestampProps = __rest(props, ["message"]);
    var contextMessage = useMessageContext('MessageTimestamp').message;
    var _a = useComponentContext('MessageTimestamp').Timestamp, Timestamp = _a === void 0 ? DefaultTimestamp : _a;
    var message = propMessage || contextMessage;
    return React.createElement(Timestamp, __assign({ timestamp: message.created_at }, timestampProps));
};
export var MessageTimestamp = React.memo(UnMemoizedMessageTimestamp);
