"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.View, {
    padding: '$4',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderTopWidth: 1,
    borderColor: '$muted.300',
    _dark: {
        borderColor: '$muted.700',
    },
}, {
    componentName: 'ModalFooter',
});
