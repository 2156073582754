"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Image = exports.ImageTemp = void 0;
const react_1 = __importStar(require("react"));
const styled_components_1 = require("./styled-components");
const image_1 = require("@gluestack-ui/image");
const usePropResolution_1 = require("../../hooks/usePropResolution");
const Text_1 = require("../Text");
const AccessibleImage = (0, image_1.createImage)({ Root: styled_components_1.Root });
exports.ImageTemp = (0, react_1.forwardRef)((_a, ref) => {
    var { source, src, fallbackElement, alt, fallbackSource, ignoreFallback, _alt } = _a, props = __rest(_a, ["source", "src", "fallbackElement", "alt", "fallbackSource", "ignoreFallback", "_alt"]);
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(props);
    const altResolvedProps = (0, usePropResolution_1.usePropResolution)(_alt);
    const finalSource = (0, react_1.useRef)(null);
    const getSource = (0, react_1.useCallback)(() => {
        if (source) {
            finalSource.current = source;
        }
        else if (src) {
            finalSource.current = { uri: src };
        }
        return finalSource.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source === null || source === void 0 ? void 0 : source.uri, src]);
    const [renderedSource, setSource] = (0, react_1.useState)(getSource());
    const [alternate, setAlternate] = (0, react_1.useState)(false);
    const [fallbackSourceFlag, setfallbackSourceFlag] = (0, react_1.useState)(true);
    react_1.default.useEffect(() => {
        setSource(getSource());
        return () => {
            finalSource.current = null;
        };
    }, [source === null || source === void 0 ? void 0 : source.uri, src, getSource]);
    const onImageLoadError = (0, react_1.useCallback)((event) => {
        props.onError && props.onError(event);
        console.warn(event.nativeEvent.error);
        if (!ignoreFallback &&
            fallbackSource &&
            fallbackSource !== renderedSource &&
            fallbackSourceFlag) {
            setfallbackSourceFlag(false);
            setSource(fallbackSource);
        }
        else {
            setAlternate(true);
        }
    }, [
        fallbackSource,
        fallbackSourceFlag,
        ignoreFallback,
        props,
        renderedSource,
    ]);
    if (typeof alt !== 'string') {
        console.warn('Please pass alt prop to Image component');
    }
    if (alternate) {
        if (fallbackElement) {
            if (react_1.default.isValidElement(fallbackElement)) {
                return fallbackElement;
            }
        }
        else
            return react_1.default.createElement(Text_1.Text, Object.assign({}, altResolvedProps), alt);
    }
    return (react_1.default.createElement(AccessibleImage, Object.assign({ alt: alt, source: renderedSource, accessibilityLabel: alt, onError: onImageLoadError }, resolvedProps, { ref: ref })));
});
exports.Image = exports.ImageTemp;
