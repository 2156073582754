"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Popover = void 0;
const popover_1 = require("@gluestack-ui/popover");
const styled_components_1 = require("./styled-components");
const Heading_1 = require("../Heading");
const Text_1 = require("../Text");
const Icons_1 = require("../Icons");
const react_1 = __importStar(require("react"));
const animation_resolver_1 = require("@gluestack-style/animation-resolver");
const Box_1 = require("../Box");
const AccessiblePopover = (0, popover_1.createPopover)({
    Root: styled_components_1.Root,
    Arrow: styled_components_1.Arrow,
    Content: styled_components_1.Content,
    Header: styled_components_1.Header,
    Footer: styled_components_1.Footer,
    Body: styled_components_1.Body,
    Backdrop: styled_components_1.Backdrop,
    CloseButton: styled_components_1.CloseButton,
    //@ts-ignore
    AnimatePresence: animation_resolver_1.AnimatePresence,
});
const PopoverNew = (0, react_1.forwardRef)((_a, ref) => {
    var { children, overlayVisible = true, backdropVisible = true } = _a, props = __rest(_a, ["children", "overlayVisible", "backdropVisible"]);
    return (react_1.default.createElement(AccessiblePopover, Object.assign({}, props, { ref: ref }),
        overlayVisible && backdropVisible && react_1.default.createElement(AccessiblePopover.Backdrop, null),
        children));
});
const AccessiblePopoverContent = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(AccessiblePopover.Content, Object.assign({}, props, { ref: ref }), children));
});
const AccessiblePopoverCloseButton = (0, react_1.forwardRef)((_a, ref) => {
    var props = __rest(_a, []);
    return (react_1.default.createElement(AccessiblePopover.CloseButton, Object.assign({}, props, { ref: ref }),
        react_1.default.createElement(Icons_1.CloseIcon, null)));
});
const AccessiblePopoverHeader = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(AccessiblePopover.Header, Object.assign({}, props, { ref: ref }), typeof children === 'string' ? (react_1.default.createElement(Heading_1.Heading, null, children)) : (children)));
});
const AccessiblePopoverFooter = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(AccessiblePopover.Footer, Object.assign({}, props, { ref: ref }), children));
});
const AccessiblePopoverBody = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(AccessiblePopover.Body, Object.assign({}, props, { ref: ref }), typeof children === 'string' ? react_1.default.createElement(Text_1.Text, null, children) : children));
});
const AccessiblePopoverArrow = (0, react_1.forwardRef)((_a, ref) => {
    var props = __rest(_a, []);
    return react_1.default.createElement(Box_1.Box, Object.assign({}, props, { ref: ref }));
});
PopoverNew.Content = AccessiblePopoverContent;
PopoverNew.CloseButton = AccessiblePopoverCloseButton;
PopoverNew.Header = AccessiblePopoverHeader;
PopoverNew.Footer = AccessiblePopoverFooter;
PopoverNew.Body = AccessiblePopoverBody;
PopoverNew.Arrow = AccessiblePopoverArrow;
exports.Popover = PopoverNew;
