"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorSchemeResolver = void 0;
const react_1 = __importStar(require("react"));
const react_2 = require("@gluestack-style/react");
const utils_1 = require("../utils");
class ColorSchemeResolver {
    // no other use
    register() { }
    constructor(callback, from) {
        this.name = 'ColorSchemeResolver';
        this.callback = callback;
        this.from = from;
    }
    // @ts-ignore
    inputMiddleWare(_styledObj = {}, _shouldUpdate = true, _ = false, Component, ...args) {
        var _a;
        if (_shouldUpdate)
            this.themed = _styledObj;
        (_a = args === null || args === void 0 ? void 0 : args[1]) === null || _a === void 0 ? true : delete _a.plugins;
        this.styledComponentConfig = args;
        return [_styledObj, _shouldUpdate, _, Component];
    }
    componentMiddleWare({ Component }) {
        if (Component.displayName === 'COLOR_SCHEME_COMPONENT') {
            return Component;
        }
        const StyledComponent = (0, react_2.styled)(Component, {}, ...this.styledComponentConfig);
        const ColorSchemeResolvedComponent = (0, react_1.forwardRef)((_a, ref) => {
            var { key } = _a, componentProps = __rest(_a, ["key"]);
            const colorSchemeSx = {};
            const colorSchemePassingPropsSx = {};
            const { sx, colorScheme } = componentProps, restProps = __rest(componentProps, ["sx", "colorScheme"]);
            if (colorScheme) {
                const colorSchemeStyle = this.callback(componentProps);
                Object.keys(colorSchemeStyle).forEach((styleKey) => {
                    if (styleKey.startsWith('_') ||
                        styleKey.startsWith(':') ||
                        styleKey.startsWith('@')) {
                        colorSchemeSx[styleKey] = colorSchemeStyle[styleKey];
                    }
                    else {
                        colorSchemePassingPropsSx[styleKey] = colorSchemeStyle[styleKey];
                    }
                });
            }
            if ((restProps === null || restProps === void 0 ? void 0 : restProps.componentName) === 'switch') {
                const { thumbColor, activeThumbColor, trackColor, ios_backgroundColor } = restProps, restModifiedProps = __rest(restProps, ["thumbColor", "activeThumbColor", "trackColor", "ios_backgroundColor"]);
                delete restModifiedProps.componentName;
                const sxPassed = {
                    props: {
                        trackColor,
                        thumbColor,
                        activeThumbColor,
                        ios_backgroundColor,
                    },
                };
                const finalStyle = (0, utils_1.deepMerge)((0, utils_1.deepMerge)(colorSchemeSx, sxPassed), sx);
                const toBeAppliedSx = (0, utils_1.deepMerge)(finalStyle, colorSchemePassingPropsSx);
                return (react_1.default.createElement(StyledComponent, Object.assign({}, restModifiedProps, { key: key !== null && key !== void 0 ? key : key + '_' + colorScheme, ref: ref, sx: toBeAppliedSx })));
            }
            const finalStyle = (0, utils_1.deepMerge)(colorSchemeSx, sx);
            const toBeAppliedSx = Object.assign(Object.assign({}, finalStyle), { props: {
                    sx: colorSchemePassingPropsSx,
                } });
            return (react_1.default.createElement(StyledComponent, Object.assign({}, restProps, { key: key !== null && key !== void 0 ? key : key + '_' + colorScheme, ref: ref, sx: toBeAppliedSx })));
        });
        //@ts-ignore
        ColorSchemeResolvedComponent.isStyledComponent = true;
        ColorSchemeResolvedComponent.displayName = 'COLOR_SCHEME_COMPONENT';
        return ColorSchemeResolvedComponent;
    }
}
exports.ColorSchemeResolver = ColorSchemeResolver;
