import { __rest } from "tslib";
import React from 'react';
import { PauseIcon, PlayIcon } from '../../MessageInput/icons';
import { RecordingTimer } from './RecordingTimer';
import { useAudioController } from '../../Attachment/hooks/useAudioController';
import { WaveProgressBar } from '../../Attachment';
export var AudioRecordingPreview = function (_a) {
    var durationSeconds = _a.durationSeconds, mimeType = _a.mimeType, waveformData = _a.waveformData, props = __rest(_a, ["durationSeconds", "mimeType", "waveformData"]);
    var _b = useAudioController({
        durationSeconds: durationSeconds,
        mimeType: mimeType,
    }), audioRef = _b.audioRef, isPlaying = _b.isPlaying, progress = _b.progress, secondsElapsed = _b.secondsElapsed, seek = _b.seek, togglePlay = _b.togglePlay;
    var displayedDuration = secondsElapsed || durationSeconds;
    return (React.createElement(React.Fragment, null,
        React.createElement("audio", { ref: audioRef },
            React.createElement("source", { src: props.src, type: mimeType })),
        React.createElement("button", { className: 'str-chat__audio_recorder__toggle-playback-button', "data-testid": 'audio-recording-preview-toggle-play-btn', onClick: togglePlay }, isPlaying ? React.createElement(PauseIcon, null) : React.createElement(PlayIcon, null)),
        React.createElement(RecordingTimer, { durationSeconds: displayedDuration }),
        React.createElement("div", { className: 'str-chat__wave-progress-bar__track-container' },
            React.createElement(WaveProgressBar, { progress: progress, seek: seek, waveformData: waveformData || [] }))));
};
