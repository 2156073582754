import React from 'react';
import clsx from 'clsx';
import { Avatar as DefaultAvatar } from '../Avatar';
import { useComponentContext } from '../../context/ComponentContext';
import { useMessageContext } from '../../context/MessageContext';
import { useTranslationContext } from '../../context/TranslationContext';
import { useChannelActionContext } from '../../context/ChannelActionContext';
export var QuotedMessage = function () {
    var _a, _b, _c;
    var _d = useComponentContext('QuotedMessage'), Attachment = _d.Attachment, ContextAvatar = _d.Avatar;
    var _e = useMessageContext('QuotedMessage'), isMyMessage = _e.isMyMessage, message = _e.message;
    var _f = useTranslationContext('QuotedMessage'), t = _f.t, userLanguage = _f.userLanguage;
    var jumpToMessage = useChannelActionContext('QuotedMessage').jumpToMessage;
    var Avatar = ContextAvatar || DefaultAvatar;
    var quoted_message = message.quoted_message;
    if (!quoted_message)
        return null;
    var quotedMessageDeleted = quoted_message.deleted_at || quoted_message.type === 'deleted';
    var quotedMessageText = quotedMessageDeleted
        ? t('This message was deleted...')
        : ((_a = quoted_message.i18n) === null || _a === void 0 ? void 0 : _a["".concat(userLanguage, "_text")]) ||
            quoted_message.text;
    var quotedMessageAttachment = ((_b = quoted_message.attachments) === null || _b === void 0 ? void 0 : _b.length) && !quotedMessageDeleted
        ? quoted_message.attachments[0]
        : null;
    if (!quotedMessageText && !quotedMessageAttachment)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: clsx('str-chat__quoted-message-preview quoted-message', { mine: isMyMessage() }), "data-testid": 'quoted-message', onClickCapture: function (e) {
                e.stopPropagation();
                e.preventDefault();
                jumpToMessage(quoted_message.id);
            } },
            quoted_message.user && (React.createElement(Avatar, { image: quoted_message.user.image, name: quoted_message.user.name || quoted_message.user.id, size: 20, user: quoted_message.user })),
            React.createElement("div", { className: 'quoted-message-inner str-chat__quoted-message-bubble', "data-testid": 'quoted-message-contents' },
                quotedMessageAttachment && (React.createElement(Attachment, { attachments: [quotedMessageAttachment], isQuoted: true })),
                React.createElement("div", { "data-testid": 'quoted-message-text' }, quotedMessageText))),
        ((_c = message.attachments) === null || _c === void 0 ? void 0 : _c.length) ? React.createElement(Attachment, { attachments: message.attachments }) : null));
};
