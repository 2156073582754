import React from 'react';
import { useChatContext } from '../../context/ChatContext';
export var MESSAGE_LIST_MAIN_PANEL_CLASS = 'str-chat__main-panel-inner';
export var MessageListMainPanel = function (_a) {
    var children = _a.children;
    var themeVersion = useChatContext('MessageListMainPanel').themeVersion;
    if (themeVersion === '2')
        return React.createElement("div", { className: MESSAGE_LIST_MAIN_PANEL_CLASS }, children);
    return React.createElement(React.Fragment, null, children);
};
