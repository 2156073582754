"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
exports.default = (0, react_1.styled)(react_native_1.View, {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$primary.600',
    _dark: {
        backgroundColor: '$primary.400',
    },
    height: '$full',
    // @ts-ignore
    borderRadius: '$full',
}, {
    componentName: 'ProgressFilledTrack',
    ancestorStyle: ['_filledTrack'],
});
