"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.View, {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    variants: {
        size: {
            xs: {
                _content: {
                    width: '60%',
                    maxWidth: 280,
                },
            },
            sm: {
                _content: {
                    width: '65%',
                    maxWidth: 320,
                },
            },
            md: {
                _content: {
                    width: '75%',
                    maxWidth: 380,
                },
            },
            lg: {
                _content: {
                    width: '80%',
                    maxWidth: 520,
                },
            },
            xl: {
                _content: {
                    width: '90%',
                    maxWidth: 580,
                },
            },
            full: {
                _content: {
                    width: '100%',
                },
            },
        },
    },
    defaultProps: { size: 'md' },
    _web: {
        pointerEvents: 'box-none',
    },
}, {
    componentName: 'AlertDialog',
    descendantStyle: ['_content'],
});
