"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextChildStyleResolver = void 0;
const react_1 = __importStar(require("react"));
const react_2 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
const NBsupport_1 = require("../utils/NBsupport");
const utils_1 = require("../utils");
class TextChildStyleResolver {
    // no other use
    register() { }
    constructor() {
        this.name = 'TextStyleResolver';
    }
    // @ts-ignore
    inputMiddleWare(_styledObj = {}, _shouldUpdate = true, _ = false, Component, ...args) {
        var _a;
        if (_shouldUpdate)
            this.themed = _styledObj;
        (_a = args === null || args === void 0 ? void 0 : args[1]) === null || _a === void 0 ? true : delete _a.plugins;
        this.styledComponentConfig = args;
        return [_styledObj, _shouldUpdate, _, Component];
    }
    componentMiddleWare({ Component, GluestackStyleSheet }) {
        // const styles: any = [];
        // const nativeStyleMap = GluestackStyleSheet.getStyleMap();
        // styleCSSIds.forEach((cssId: any) => {
        //   const nativeStyle = nativeStyleMap.get(cssId);
        //   if (nativeStyle) {
        //     const styleSheet = nativeStyle?.resolved;
        //     if (styleSheet) {
        //       styles.push(styleSheet);
        //     }
        //   }
        // });
        // const stylesObj = StyleSheet.flatten(styles);
        const StyledComponent = (0, react_2.styled)(Component, {}, ...this.styledComponentConfig);
        const TextStyledResolvedComponent = (0, react_1.forwardRef)((_a, ref) => {
            var _b;
            var { key, children, style } = _a, componentProps = __rest(_a, ["key", "children", "style"]);
            const styles = [];
            const nativeStyleMap = GluestackStyleSheet.getStyleMap();
            (_b = componentProps === null || componentProps === void 0 ? void 0 : componentProps.dataSet) === null || _b === void 0 ? void 0 : _b.style.split(' ').forEach((cssId) => {
                const nativeStyle = nativeStyleMap.get(cssId);
                if (nativeStyle) {
                    const styleSheet = nativeStyle === null || nativeStyle === void 0 ? void 0 : nativeStyle.resolved;
                    if (styleSheet) {
                        styles.push(styleSheet);
                    }
                }
            });
            delete componentProps['data-style'];
            const stylesObj = react_native_1.StyleSheet.flatten(styles);
            const styleObj = react_native_1.StyleSheet.flatten(style);
            const resolvedStyle = resolveStyleForNative(Object.assign(Object.assign({}, styleObj), stylesObj));
            return (react_1.default.createElement(StyledComponent, Object.assign({}, componentProps, { sx: Object.assign(Object.assign({}, stylesObj), { props: { style: resolvedStyle }, _text: (0, NBsupport_1.filterProps)(resolvedStyle) }), key: key + (0, utils_1.stableHash)(resolvedStyle), ref: ref }), children));
        });
        //@ts-ignore
        TextStyledResolvedComponent.isStyledComponent = true;
        TextStyledResolvedComponent.displayName = 'TEXT_PLUGIN_COMPONENT';
        return TextStyledResolvedComponent;
    }
}
exports.TextChildStyleResolver = TextChildStyleResolver;
function resolveStyleForNative(styleSheet) {
    if (styleSheet) {
        if (Array.isArray(styleSheet)) {
            styleSheet.map((styleObj) => resolveStyleForNative(styleObj));
        }
        else {
            let { fontSize, lineHeight, letterSpacing } = styleSheet;
            if (String(fontSize).endsWith('rem')) {
                fontSize = parseFloat(fontSize) * 16;
            }
            if (String(lineHeight).endsWith('rem')) {
                lineHeight = parseFloat(lineHeight) * 16;
            }
            else if (String(lineHeight).endsWith('em')) {
                lineHeight = parseFloat(lineHeight) * fontSize;
            }
            if (String(letterSpacing).endsWith('rem')) {
                letterSpacing = parseFloat(letterSpacing) * 16;
            }
            else if (String(letterSpacing).endsWith('em')) {
                letterSpacing = parseFloat(letterSpacing) * fontSize;
            }
            return Object.assign(Object.assign({}, styleSheet), { fontSize, lineHeight, letterSpacing });
        }
    }
    return styleSheet;
}
