import { __assign, __rest } from "tslib";
import React, { forwardRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { DownloadButton } from '../Attachment';
export var BaseImage = forwardRef(function BaseImage(_a, ref) {
    var props = __rest(_a, []);
    var propsClassName = props.className, propsOnError = props.onError;
    var _b = useState(false), error = _b[0], setError = _b[1];
    useEffect(function () { return function () {
        setError(false);
    }; }, [props.src]);
    return (React.createElement(React.Fragment, null,
        React.createElement("img", __assign({ "data-testid": 'str-chat__base-image' }, props, { className: clsx(propsClassName, 'str-chat__base-image', {
                'str-chat__base-image--load-failed': error,
            }), onError: function (e) {
                setError(true);
                propsOnError === null || propsOnError === void 0 ? void 0 : propsOnError(e);
            }, ref: ref })),
        error && React.createElement(DownloadButton, { assetUrl: props.src })));
});
