"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
exports.default = (0, react_1.styled)(react_native_1.View, {
    flexDirection: 'row',
}, {
    componentName: 'InputGroup',
    descendantStyle: [
        '_input',
        '_icon',
        '_inputBox',
        '_inputleftaddon',
        '_inputrightaddon',
    ],
});
