import { __awaiter, __generator } from "tslib";
import { validateAndGetMessage } from '../utils';
import { useChannelStateContext, useTranslationContext } from '../../../context';
export var useMarkUnreadHandler = function (message, notifications) {
    if (notifications === void 0) { notifications = {}; }
    var getErrorNotification = notifications.getErrorNotification, getSuccessNotification = notifications.getSuccessNotification, notify = notifications.notify;
    var channel = useChannelStateContext('useMarkUnreadHandler').channel;
    var t = useTranslationContext('useMarkUnreadHandler').t;
    return function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var successMessage, e_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    if (!(message === null || message === void 0 ? void 0 : message.id)) {
                        console.warn('Mark unread handler does not have access to message id');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, channel.markUnread({ message_id: message.id })];
                case 2:
                    _a.sent();
                    if (!notify)
                        return [2 /*return*/];
                    successMessage = getSuccessNotification && validateAndGetMessage(getSuccessNotification, [message]);
                    if (successMessage)
                        notify(successMessage, 'success');
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    if (!notify)
                        return [2 /*return*/];
                    errorMessage = getErrorNotification && validateAndGetMessage(getErrorNotification, [message]);
                    if (getErrorNotification && !errorMessage)
                        return [2 /*return*/];
                    notify(errorMessage ||
                        t('Error marking message unread. Cannot mark unread messages older than the newest 100 channel messages.'), 'error');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
