import { __assign, __rest } from "tslib";
import React from 'react';
import clsx from 'clsx';
import { useComponentContext, useMessageContext, useTranslationContext } from '../../context';
import { Timestamp as DefaultTimestamp } from './Timestamp';
import { isMessageEdited } from './utils';
export function MessageEditedTimestamp(_a) {
    var propMessage = _a.message, open = _a.open, timestampProps = __rest(_a, ["message", "open"]);
    var t = useTranslationContext('MessageEditedTimestamp').t;
    var contextMessage = useMessageContext('MessageEditedTimestamp').message;
    var _b = useComponentContext('MessageEditedTimestamp').Timestamp, Timestamp = _b === void 0 ? DefaultTimestamp : _b;
    var message = propMessage || contextMessage;
    if (!isMessageEdited(message)) {
        return null;
    }
    return (React.createElement("div", { className: clsx('str-chat__message-edited-timestamp', open
            ? 'str-chat__message-edited-timestamp--open'
            : 'str-chat__message-edited-timestamp--collapsed'), "data-testid": 'message-edited-timestamp' },
        t('Edited'),
        ' ',
        React.createElement(Timestamp, __assign({ timestamp: message.message_text_updated_at }, timestampProps))));
}
