"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.Pressable, {
    'position': 'absolute',
    'right': '$3',
    'top': '$3',
    'zIndex': 1,
    // @ts-ignore
    'rounded': '$sm',
    // @ts-ignore
    'p': '$2',
    '_icon': {
        color: '$muted.500',
    },
    ':hover': {
        backgroundColor: '$muted.200',
        _icon: {
            color: '$muted.700',
        },
    },
    ':active': {
        backgroundColor: '$muted.300',
        _icon: {
            color: '$muted.900',
        },
    },
    '_dark': {
        '_icon': {
            color: '$muted.400',
        },
        ':hover': {
            backgroundColor: 'muted.700',
        },
        ':active': {
            backgroundColor: 'muted.600',
            _icon: {
                color: '$muted.100',
            },
        },
    },
    ':focusVisible': {
        backgroundColor: '$muted.100',
        _icon: {
            color: '$muted.900',
        },
        _dark: {
            backgroundColor: '$muted.700',
            _icon: {
                color: '$muted.100',
            },
        },
    },
    '_web': {
        outlineWidth: 0,
        cursor: 'pointer',
    },
}, {
    componentName: 'AlertDialogCloseButton',
    descendantStyle: ['_icon', '_text'],
});
