import { __assign, __rest } from "tslib";
import React from 'react';
import clsx from 'clsx';
import { MESSAGE_ACTIONS } from '../Message/utils';
import { useChannelActionContext, useComponentContext, useMessageContext, useTranslationContext, } from '../../context';
import { CustomMessageActionsList as DefaultCustomMessageActionsList } from './CustomMessageActionsList';
var UnMemoizedMessageActionsBox = React.forwardRef(function (props, ref) {
    var getMessageActions = props.getMessageActions, handleDelete = props.handleDelete, handleEdit = props.handleEdit, handleFlag = props.handleFlag, handleMarkUnread = props.handleMarkUnread, handleMute = props.handleMute, handlePin = props.handlePin, isUserMuted = props.isUserMuted, mine = props.mine, _a = props.open, open = _a === void 0 ? false : _a, restDivProps = __rest(props, ["getMessageActions", "handleDelete", "handleEdit", "handleFlag", "handleMarkUnread", "handleMute", "handlePin", "isUserMuted", "mine", "open"]);
    var _b = useComponentContext('MessageActionsBox').CustomMessageActionsList, CustomMessageActionsList = _b === void 0 ? DefaultCustomMessageActionsList : _b;
    var setQuotedMessage = useChannelActionContext('MessageActionsBox').setQuotedMessage;
    var _c = useMessageContext('MessageActionsBox'), customMessageActions = _c.customMessageActions, message = _c.message, threadList = _c.threadList;
    var t = useTranslationContext('MessageActionsBox').t;
    var messageActions = getMessageActions();
    var handleQuote = function () {
        setQuotedMessage(message);
        var elements = message.parent_id
            ? document.querySelectorAll('.str-chat__thread .str-chat__textarea__textarea')
            : document.getElementsByClassName('str-chat__textarea__textarea');
        var textarea = elements.item(0);
        if (textarea instanceof HTMLTextAreaElement) {
            textarea.focus();
        }
    };
    var rootClassName = clsx('str-chat__message-actions-box', {
        'str-chat__message-actions-box--open': open,
    });
    var buttonClassName = 'str-chat__message-actions-list-item str-chat__message-actions-list-item-button';
    return (React.createElement("div", __assign({}, restDivProps, { className: rootClassName, "data-testid": 'message-actions-box', ref: ref }),
        React.createElement("div", { "aria-label": t('aria/Message Options'), className: 'str-chat__message-actions-list', role: 'listbox' },
            React.createElement(CustomMessageActionsList, { customMessageActions: customMessageActions, message: message }),
            messageActions.indexOf(MESSAGE_ACTIONS.quote) > -1 && (React.createElement("button", { "aria-selected": 'false', className: buttonClassName, onClick: handleQuote, role: 'option' }, t('Reply'))),
            messageActions.indexOf(MESSAGE_ACTIONS.pin) > -1 && !message.parent_id && (React.createElement("button", { "aria-selected": 'false', className: buttonClassName, onClick: handlePin, role: 'option' }, !message.pinned ? t('Pin') : t('Unpin'))),
            messageActions.indexOf(MESSAGE_ACTIONS.markUnread) > -1 && !threadList && !!message.id && (React.createElement("button", { "aria-selected": 'false', className: buttonClassName, onClick: handleMarkUnread, role: 'option' }, t('Mark as unread'))),
            messageActions.indexOf(MESSAGE_ACTIONS.flag) > -1 && (React.createElement("button", { "aria-selected": 'false', className: buttonClassName, onClick: handleFlag, role: 'option' }, t('Flag'))),
            messageActions.indexOf(MESSAGE_ACTIONS.mute) > -1 && (React.createElement("button", { "aria-selected": 'false', className: buttonClassName, onClick: handleMute, role: 'option' }, isUserMuted() ? t('Unmute') : t('Mute'))),
            messageActions.indexOf(MESSAGE_ACTIONS.edit) > -1 && (React.createElement("button", { "aria-selected": 'false', className: buttonClassName, onClick: handleEdit, role: 'option' }, t('Edit Message'))),
            messageActions.indexOf(MESSAGE_ACTIONS.delete) > -1 && (React.createElement("button", { "aria-selected": 'false', className: buttonClassName, onClick: handleDelete, role: 'option' }, t('Delete'))))));
});
/**
 * A popup box that displays the available actions on a message, such as edit, delete, pin, etc.
 */
export var MessageActionsBox = React.memo(UnMemoizedMessageActionsBox);
