"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.View, {
    flexDirection: 'column',
    variants: {
        size: {
            sm: {
                _labelText: {
                    // @ts-ignore
                    fontSize: '$sm',
                },
                _labelAstrick: {
                    // @ts-ignore
                    fontSize: '$sm',
                },
                _helperText: {
                    // @ts-ignore
                    fontSize: '$xs',
                },
                _errorText: {
                    // @ts-ignore
                    fontSize: '$xs',
                },
            },
            md: {
                _labelText: {
                    // @ts-ignore
                    fontSize: '$md',
                },
                _labelAstrick: {
                    // @ts-ignore
                    fontSize: '$md',
                },
                _helperText: {
                    // @ts-ignore
                    fontSize: '$sm',
                },
                _errorText: {
                    // @ts-ignore
                    fontSize: '$sm',
                },
            },
            lg: {
                _labelText: {
                    // @ts-ignore
                    fontSize: '$lg',
                },
                _labelAstrick: {
                    // @ts-ignore
                    fontSize: '$lg',
                },
                _helperText: {
                    // @ts-ignore
                    fontSize: '$md',
                },
                _errorText: {
                    // @ts-ignore
                    fontSize: '$md',
                },
            },
        },
    },
    defaultProps: {
        size: 'md',
    },
}, {
    componentName: 'FormControl',
    descendantStyle: [
        '_labelText',
        '_helperText',
        '_errorText',
        '_labelAstrick',
    ],
});
