import { __awaiter, __generator, __spreadArray } from "tslib";
import { useEffect } from 'react';
import uniqBy from 'lodash.uniqby';
import { getChannel } from '../../../utils/getChannel';
import { useChatContext } from '../../../context/ChatContext';
export var useNotificationMessageNewListener = function (setChannels, customHandler, allowNewMessagesFromUnfilteredChannels) {
    if (allowNewMessagesFromUnfilteredChannels === void 0) { allowNewMessagesFromUnfilteredChannels = true; }
    var client = useChatContext('useNotificationMessageNewListener').client;
    useEffect(function () {
        var handleEvent = function (event) { return __awaiter(void 0, void 0, void 0, function () {
            var channel_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(customHandler && typeof customHandler === 'function')) return [3 /*break*/, 1];
                        customHandler(setChannels, event);
                        return [3 /*break*/, 3];
                    case 1:
                        if (!(allowNewMessagesFromUnfilteredChannels && ((_a = event.channel) === null || _a === void 0 ? void 0 : _a.type))) return [3 /*break*/, 3];
                        return [4 /*yield*/, getChannel({
                                client: client,
                                id: event.channel.id,
                                type: event.channel.type,
                            })];
                    case 2:
                        channel_1 = _b.sent();
                        setChannels(function (channels) { return uniqBy(__spreadArray([channel_1], channels, true), 'cid'); });
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        client.on('notification.message_new', handleEvent);
        return function () {
            client.off('notification.message_new', handleEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customHandler]);
};
