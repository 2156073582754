"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareIcon = void 0;
const styled_components_1 = require("../styled-components");
const ShareIcon = (0, styled_components_1.createIcon)({
    viewBox: '0 0 24 24',
    d: 'M18.0621 16.1487C17.2993 16.1487 16.6113 16.4451 16.0895 16.9223L8.93445 12.7495C8.98988 12.523 9.02482 12.2868 9.02482 12.0458C9.02482 11.8048 8.98988 11.5698 8.93445 11.3433L16.0136 7.21147C16.5679 7.73368 17.3006 8.02461 18.0621 8.02482C19.7237 8.02482 21.0745 6.67406 21.0745 5.01241C21.0745 3.35076 19.7237 2 18.0621 2C16.4004 2 15.0496 3.35076 15.0496 5.01241C15.0496 5.2534 15.0846 5.48958 15.14 5.71491L8.06085 9.84673C7.50628 9.32504 6.7738 9.0342 6.01241 9.03338C4.35077 9.03338 3 10.3841 3 12.0458C3 13.7074 4.35077 15.0582 6.01241 15.0582C6.77392 15.058 7.50661 14.7671 8.06085 14.2449L15.2159 18.4176C15.1632 18.633 15.1361 18.8538 15.1352 19.0756C15.1354 19.6541 15.3072 20.2196 15.6288 20.7005C15.9503 21.1814 16.4073 21.5562 16.9418 21.7775C17.4764 21.9988 18.0645 22.0567 18.6319 21.9437C19.1993 21.8308 19.7205 21.5522 20.1296 21.1431C20.5387 20.734 20.8173 20.2128 20.9302 19.6454C21.0432 19.078 20.9853 18.4898 20.764 17.9553C20.5427 17.4208 20.1679 16.9638 19.687 16.6423C19.2061 16.3207 18.6406 16.1489 18.0621 16.1487Z',
});
exports.ShareIcon = ShareIcon;
ShareIcon.displayName = 'ShareIcon';
