import { __spreadArray } from "tslib";
import { useEffect } from 'react';
import { useChatContext } from '../../../context/ChatContext';
export var useChannelDeletedListener = function (setChannels, customHandler) {
    var client = useChatContext('useChannelDeletedListener').client;
    useEffect(function () {
        var handleEvent = function (event) {
            if (customHandler && typeof customHandler === 'function') {
                customHandler(setChannels, event);
            }
            else {
                setChannels(function (channels) {
                    var channelIndex = channels.findIndex(function (channel) { return channel.cid === event.cid; });
                    if (channelIndex < 0)
                        return __spreadArray([], channels, true);
                    // Remove the deleted channel from the list.s
                    channels.splice(channelIndex, 1);
                    return __spreadArray([], channels, true);
                });
            }
        };
        client.on('channel.deleted', handleEvent);
        return function () {
            client.off('channel.deleted', handleEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customHandler]);
};
