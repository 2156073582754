"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeBaseProvider = exports.GluestackUIStyledProvider = exports.GluestackUIProvider = exports.HooksContext = void 0;
const react_1 = __importStar(require("react"));
const provider_1 = require("@gluestack-ui/provider");
const react_2 = require("@gluestack-style/react");
const overlay_1 = require("@gluestack-ui/overlay");
const toast_1 = require("@gluestack-ui/toast");
const gluestack_ui_config_1 = require("../gluestack-ui.config");
const extendTheme_1 = require("../../utils/extendTheme");
const utils_1 = require("../../utils");
const GluestackUIStyledProvider = (0, provider_1.createProvider)({ StyledProvider: react_2.StyledProvider });
exports.GluestackUIStyledProvider = GluestackUIStyledProvider;
exports.HooksContext = (0, react_1.createContext)({});
const GluestackUIProvider = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(GluestackUIStyledProvider, Object.assign({}, props),
        react_1.default.createElement(overlay_1.OverlayProvider, null,
            react_1.default.createElement(toast_1.ToastProvider, null, children))));
};
exports.GluestackUIProvider = GluestackUIProvider;
const NativeBaseProvider = (_a) => {
    var _b, _c, _d, _e;
    var { children, config = {}, theme = {}, disableContrastText } = _a, props = __rest(_a, ["children", "config", "theme", "disableContrastText"]);
    const _enableRem = (_b = config === null || config === void 0 ? void 0 : config.enableRem) !== null && _b !== void 0 ? _b : false;
    const { colorMode: colorModeFromParent } = (_c = (0, react_1.useContext)(exports.HooksContext)) !== null && _c !== void 0 ? _c : undefined;
    const [colorModeNEW, setColorMode] = (0, react_1.useState)((_e = colorModeFromParent !== null && colorModeFromParent !== void 0 ? colorModeFromParent : (_d = theme === null || theme === void 0 ? void 0 : theme.config) === null || _d === void 0 ? void 0 : _d.initialColorMode) !== null && _e !== void 0 ? _e : 'light');
    // delete props.colorMode;
    const toggleColorMode = () => {
        setColorMode((prev) => (prev === 'light' ? 'dark' : 'light'));
    };
    const gluestackCompatibleTheme = (0, extendTheme_1.convertTheme)(theme);
    const mergedTheme = theme
        ? (0, utils_1.deepMerge)((0, utils_1.deepMerge)(gluestack_ui_config_1.config.theme, gluestackCompatibleTheme), theme)
        : (0, utils_1.deepMerge)(gluestack_ui_config_1.config.theme, gluestackCompatibleTheme);
    const newTheme = react_1.default.useMemo(() => {
        if (_enableRem) {
            return (0, utils_1.platformSpecificSpaceUnits)(mergedTheme);
        }
        return mergedTheme;
    }, [_enableRem, mergedTheme]);
    return (react_1.default.createElement(exports.HooksContext.Provider, { value: {
            colorMode: colorModeNEW,
            toggleColorMode: toggleColorMode,
            setColorMode: setColorMode,
            config: (config === null || config === void 0 ? void 0 : config.dependencies) ? config.dependencies : {},
            disableContrastText: disableContrastText,
        } },
        react_1.default.createElement(GluestackUIProvider, Object.assign({ colorMode: colorModeNEW, config: newTheme }, props), children)));
};
exports.NativeBaseProvider = NativeBaseProvider;
