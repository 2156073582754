import { __assign } from "tslib";
import React, { useEffect, useState } from 'react';
import { getImageDimensions } from './utils/utils';
export var SpriteImage = function (_a) {
    var columns = _a.columns, fallback = _a.fallback, height = _a.height, position = _a.position, rows = _a.rows, spriteUrl = _a.spriteUrl, style = _a.style, width = _a.width;
    var _b = useState([0, 0]), _c = _b[0], spriteWidth = _c[0], spriteHeight = _c[1], setSpriteDimensions = _b[1];
    useEffect(function () {
        getImageDimensions(spriteUrl).then(setSpriteDimensions).catch(console.error);
    }, [spriteUrl]);
    var x = position[0], y = position[1];
    if (!spriteHeight || !spriteWidth)
        return React.createElement(React.Fragment, null, fallback);
    return (React.createElement("div", { "data-testid": 'sprite-image', style: __assign(__assign(__assign(__assign(__assign({}, style), { '--str-chat__sprite-image-resize-ratio': 'var(--str-chat__sprite-image-resize-ratio-x, var(--str-chat__sprite-image-resize-ratio-y, 1))', '--str-chat__sprite-image-resize-ratio-x': 'calc(var(--str-chat__sprite-image-width) / var(--str-chat__sprite-item-width))', '--str-chat__sprite-image-resize-ratio-y': 'calc(var(--str-chat__sprite-image-height) / var(--str-chat__sprite-item-height))', '--str-chat__sprite-item-height': "".concat(spriteHeight / rows), '--str-chat__sprite-item-width': "".concat(spriteWidth / columns) }), (Number.isFinite(height) ? { '--str-chat__sprite-image-height': "".concat(height, "px") } : {})), (Number.isFinite(width) ? { '--str-chat__sprite-image-width': "".concat(width, "px") } : {})), { backgroundImage: "url('".concat(spriteUrl, "')"), backgroundPosition: "".concat(x * (100 / (columns - 1)), "% ").concat(y * (100 / (rows - 1)), "%"), backgroundSize: "".concat(columns * 100, "% ").concat(rows * 100, "%"), height: 'var(--str-chat__sprite-image-height, calc(var(--str-chat__sprite-item-height) * var(--str-chat__sprite-image-resize-ratio)))', width: 'var(--str-chat__sprite-image-width, calc(var(--str-chat__sprite-item-width) * var(--str-chat__sprite-image-resize-ratio)))' }) }));
};
