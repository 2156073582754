import { __awaiter, __generator } from "tslib";
import throttle from 'lodash.throttle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useChannelActionContext, useTranslationContext } from '../../../context';
var isSeekable = function (audioElement) {
    return !(audioElement.duration === Infinity || isNaN(audioElement.duration));
};
export var elementIsPlaying = function (audioElement) {
    return audioElement && !(audioElement.paused || audioElement.ended);
};
var logError = function (e) { return console.error('[AUDIO PLAYER]', e); };
var DEFAULT_PLAYBACK_RATES = [1.0, 1.5, 2.0];
export var useAudioController = function (_a) {
    var _b = _a === void 0 ? {} : _a, durationSeconds = _b.durationSeconds, mimeType = _b.mimeType, _c = _b.playbackRates, playbackRates = _c === void 0 ? DEFAULT_PLAYBACK_RATES : _c;
    var addNotification = useChannelActionContext('useAudioController').addNotification;
    var t = useTranslationContext('useAudioController').t;
    var _d = useState(false), isPlaying = _d[0], setIsPlaying = _d[1];
    var _e = useState(), playbackError = _e[0], setPlaybackError = _e[1];
    var _f = useState(true), canPlayRecord = _f[0], setCanPlayRecord = _f[1];
    var _g = useState(0), secondsElapsed = _g[0], setSecondsElapsed = _g[1];
    var _h = useState(0), playbackRateIndex = _h[0], setPlaybackRateIndex = _h[1];
    var playTimeout = useRef();
    var audioRef = useRef(null);
    var registerError = useCallback(function (e) {
        logError(e);
        setPlaybackError(e);
        addNotification(e.message, 'error');
    }, [addNotification]);
    var togglePlay = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!audioRef.current)
                        return [2 /*return*/];
                    clearTimeout(playTimeout.current);
                    playTimeout.current = undefined;
                    if (mimeType && !audioRef.current.canPlayType(mimeType)) {
                        registerError(new Error(t('Recording format is not supported and cannot be reproduced')));
                        setCanPlayRecord(false);
                        return [2 /*return*/];
                    }
                    if (!elementIsPlaying(audioRef.current)) return [3 /*break*/, 1];
                    audioRef.current.pause();
                    setIsPlaying(false);
                    return [3 /*break*/, 6];
                case 1:
                    playTimeout.current = setTimeout(function () {
                        if (!audioRef.current)
                            return;
                        try {
                            audioRef.current.pause();
                            setIsPlaying(false);
                        }
                        catch (e) {
                            registerError(new Error(t('Failed to play the recording')));
                        }
                    }, 2000);
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 6]);
                    return [4 /*yield*/, audioRef.current.play()];
                case 3:
                    _a.sent();
                    setIsPlaying(true);
                    return [3 /*break*/, 6];
                case 4:
                    e_1 = _a.sent();
                    registerError(e_1);
                    setIsPlaying(false);
                    return [3 /*break*/, 6];
                case 5:
                    clearTimeout(playTimeout.current);
                    playTimeout.current = undefined;
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [mimeType, registerError, t]);
    var increasePlaybackRate = function () {
        setPlaybackRateIndex(function (prev) {
            if (!audioRef.current)
                return prev;
            var nextIndex = prev === playbackRates.length - 1 ? 0 : prev + 1;
            audioRef.current.playbackRate = playbackRates[nextIndex];
            return nextIndex;
        });
    };
    var seek = useMemo(function () {
        return throttle(function (_a) {
            var clientX = _a.clientX, currentTarget = _a.currentTarget;
            if (!(currentTarget && audioRef.current))
                return;
            if (!isSeekable(audioRef.current)) {
                registerError(new Error(t('Cannot seek in the recording')));
                return;
            }
            var _b = currentTarget.getBoundingClientRect(), width = _b.width, x = _b.x;
            var ratio = (clientX - x) / width;
            if (ratio > 1 || ratio < 0)
                return;
            var currentTime = ratio * audioRef.current.duration;
            setSecondsElapsed(currentTime);
            audioRef.current.currentTime = currentTime;
        }, 16);
    }, [registerError, t]);
    useEffect(function () {
        if (!audioRef.current)
            return;
        var audioElement = audioRef.current;
        var handleEnded = function () {
            var _a, _b;
            setSecondsElapsed((_b = (_a = audioElement === null || audioElement === void 0 ? void 0 : audioElement.duration) !== null && _a !== void 0 ? _a : durationSeconds) !== null && _b !== void 0 ? _b : 0);
            setIsPlaying(false);
        };
        audioElement.addEventListener('ended', handleEnded);
        var handleError = function () {
            addNotification(t('Error reproducing the recording'), 'error');
            setIsPlaying(false);
        };
        audioElement.addEventListener('error', handleError);
        var handleTimeupdate = function () {
            setSecondsElapsed(audioElement === null || audioElement === void 0 ? void 0 : audioElement.currentTime);
        };
        audioElement.addEventListener('timeupdate', handleTimeupdate);
        return function () {
            audioElement.pause();
            audioElement.removeEventListener('ended', handleEnded);
            audioElement.removeEventListener('error', handleError);
            audioElement.removeEventListener('timeupdate', handleTimeupdate);
        };
    }, [addNotification, durationSeconds, t]);
    return {
        audioRef: audioRef,
        canPlayRecord: canPlayRecord,
        increasePlaybackRate: increasePlaybackRate,
        isPlaying: isPlaying,
        playbackError: playbackError,
        playbackRate: playbackRates[playbackRateIndex],
        progress: audioRef.current && secondsElapsed ? (secondsElapsed / audioRef.current.duration) * 100 : 0,
        secondsElapsed: secondsElapsed,
        seek: seek,
        togglePlay: togglePlay,
    };
};
