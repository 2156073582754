"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Actionsheet = void 0;
const react_1 = __importStar(require("react"));
const actionsheet_1 = require("@gluestack-ui/actionsheet");
const animation_resolver_1 = require("@gluestack-style/animation-resolver");
const styled_components_1 = require("./styled-components");
const react_2 = require("react");
const usePropResolution_1 = require("../../hooks/usePropResolution");
const Box_1 = require("../Box");
const AccessibleActionsheet = (0, actionsheet_1.createActionsheet)({
    Root: styled_components_1.Root,
    Content: styled_components_1.Content,
    Item: styled_components_1.Item,
    ItemText: styled_components_1.ItemText,
    DragIndicator: styled_components_1.DragIndicator,
    IndicatorWrapper: styled_components_1.IndicatorWrapper,
    Backdrop: styled_components_1.Backdrop,
    ScrollView: styled_components_1.ScrollView,
    VirtualizedList: styled_components_1.VirtualizedList,
    FlatList: styled_components_1.FlatList,
    SectionList: styled_components_1.SectionList,
    SectionHeaderText: styled_components_1.SectionHeaderText,
    Icon: styled_components_1.Icon,
    //@ts-ignore
    AnimatePresence: animation_resolver_1.AnimatePresence,
});
const ActionSheetContext = (0, react_1.createContext)({});
const NewActionsheet = (0, react_2.forwardRef)((_a, ref) => {
    var { isOpen, onClose, disableOverlay = false, hideDragIndicator = false, // use context
    _backdrop, useRNModal, children } = _a, props = __rest(_a, ["isOpen", "onClose", "disableOverlay", "hideDragIndicator", "_backdrop", "useRNModal", "children"]);
    const resolvedPropForGluestack = (0, usePropResolution_1.usePropResolution)(props);
    return (react_1.default.createElement(AccessibleActionsheet, Object.assign({ useRNModal: useRNModal, isOpen: isOpen, onClose: onClose }, resolvedPropForGluestack, { ref: ref }),
        !disableOverlay && react_1.default.createElement(AccessibleActionsheet.Backdrop, Object.assign({}, _backdrop)),
        react_1.default.createElement(ActionSheetContext.Provider, { value: { hideDragIndicator } }, children)));
});
const AccessibleActionsheetContent = (0, react_2.forwardRef)((_a, ref) => {
    var { children, 
    // space above DragIndicatorWrapper
    _dragIndicatorWrapperOffSet = null, 
    // DragIndicatorWrapper
    _dragIndicatorWrapper = null, 
    // DragIndicator
    _dragIndicator = null } = _a, props = __rest(_a, ["children", "_dragIndicatorWrapperOffSet", "_dragIndicatorWrapper", "_dragIndicator"]);
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(props);
    const resolvedPropsForDragIndicator = (0, usePropResolution_1.usePropResolution)(_dragIndicator);
    const resolvedPropsForDragIndicatorWrapperOffSet = (0, usePropResolution_1.usePropResolution)(_dragIndicatorWrapperOffSet);
    const resolvedPropsForDragIndicatorWrapper = (0, usePropResolution_1.usePropResolution)(_dragIndicatorWrapper);
    const { hideDragIndicator } = (0, react_1.useContext)(ActionSheetContext);
    return (react_1.default.createElement(AccessibleActionsheet.Content, Object.assign({}, resolvedProps, { ref: ref }),
        !hideDragIndicator && (react_1.default.createElement(react_1.default.Fragment, null,
            _dragIndicatorWrapperOffSet && (react_1.default.createElement(Box_1.Box, Object.assign({ py: "2" }, resolvedPropsForDragIndicatorWrapperOffSet))),
            react_1.default.createElement(AccessibleActionsheet.DragIndicatorWrapper, Object.assign({}, resolvedPropsForDragIndicatorWrapper),
                react_1.default.createElement(AccessibleActionsheet.DragIndicator, Object.assign({}, resolvedPropsForDragIndicator))))),
        children));
});
const AccessibleActionsheetItem = (0, react_2.forwardRef)((_a, ref) => {
    var _b, _c;
    var { children, leftIcon, rightIcon, startIcon, endIcon } = _a, props = __rest(_a, ["children", "leftIcon", "rightIcon", "startIcon", "endIcon"]);
    return (react_1.default.createElement(AccessibleActionsheet.Item, Object.assign({ variant: "actionsheetStyle" }, props, { ref: ref }), (_b = (leftIcon && leftIcon)) !== null && _b !== void 0 ? _b : (startIcon && startIcon),
        children && typeof children === 'string' ? (react_1.default.createElement(AccessibleActionsheet.ItemText, null, children)) : (children && { children }), (_c = (rightIcon && rightIcon)) !== null && _c !== void 0 ? _c : (endIcon && endIcon)));
});
const ActionsheetNew = NewActionsheet;
ActionsheetNew.Content = AccessibleActionsheetContent;
ActionsheetNew.Item = AccessibleActionsheetItem;
ActionsheetNew.ItemText = AccessibleActionsheet.ItemText;
ActionsheetNew.DragIndicator = AccessibleActionsheet.DragIndicator;
ActionsheetNew.DragIndicator = AccessibleActionsheet.DragIndicator;
ActionsheetNew.DragIndicatorWrapper =
    AccessibleActionsheet.DragIndicatorWrapper;
ActionsheetNew.Backdrop = AccessibleActionsheet.Backdrop;
ActionsheetNew.ScrollView = AccessibleActionsheet.ScrollView;
ActionsheetNew.VirtualizedList = AccessibleActionsheet.VirtualizedList;
ActionsheetNew.FlatList = AccessibleActionsheet.FlatList;
ActionsheetNew.SectionList = AccessibleActionsheet.SectionList;
ActionsheetNew.SectionHeaderText = AccessibleActionsheet.SectionHeaderText;
ActionsheetNew.Icon = AccessibleActionsheet.Icon;
exports.Actionsheet = ActionsheetNew;
