"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useContrastText = void 0;
const useToken_1 = require("./useToken");
function useContrastText(bg) {
    const colorHexCode = (0, useToken_1.useToken)('colors', bg);
    return getContrastColor(colorHexCode);
}
exports.useContrastText = useContrastText;
function getContrastColor(hexColor) {
    // Remove the hash (#) if it exists
    const cleanHexColor = hexColor.replace(/^#/, '');
    // Convert the hex color to RGB
    const r = parseInt(cleanHexColor.substring(0, 2), 16);
    const g = parseInt(cleanHexColor.substring(2, 4), 16);
    const b = parseInt(cleanHexColor.substring(4, 6), 16);
    // Calculate the luminance using the relative luminance formula
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    // Determine the contrast color based on luminance
    return luminance > 0.5 ? 'black' : 'white';
}
