import React, { useCallback } from 'react';
import { IconButton } from './IconButton';
import { CloseIcon, FilePlaceholderIcon } from './icons';
export var Thumbnail = function (_a) {
    var alt = _a.alt, handleClose = _a.handleClose, image = _a.image, _b = _a.size, size = _b === void 0 ? 100 : _b;
    var onClose = useCallback(function (event) { return handleClose === null || handleClose === void 0 ? void 0 : handleClose(event); }, [handleClose]);
    return (React.createElement("div", { className: 'rfu-thumbnail__wrapper', style: { height: size, width: size } },
        React.createElement("div", { className: 'rfu-thumbnail__overlay' }, handleClose ? (React.createElement(IconButton, { onClick: onClose },
            React.createElement(CloseIcon, null))) : null),
        image ? (React.createElement("img", { alt: alt !== null && alt !== void 0 ? alt : '', className: 'rfu-thumbnail__image', src: image })) : (React.createElement(FilePlaceholderIcon, { className: 'rfu-thumbnail__image', preserveAspectRatio: 'xMinYMin slice' }))));
};
