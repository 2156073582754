"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayIcon = void 0;
const styled_components_1 = require("../styled-components");
const PlayIcon = (0, styled_components_1.createIcon)({
    viewBox: '0 0 24 24',
    d: 'M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM9.6 17.4V6.6L16.8 12L9.6 17.4Z',
});
exports.PlayIcon = PlayIcon;
PlayIcon.displayName = 'PlayIcon';
