import { __assign, __rest } from "tslib";
import { find } from 'linkifyjs';
import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import { useChannelStateContext, useChatContext } from '../../../context';
import { LinkPreviewState, SetLinkPreviewMode } from '../types';
export var useLinkPreviews = function (_a) {
    var _b;
    var debounceURLEnrichmentMsInputContext = _a.debounceURLEnrichmentMs, dispatch = _a.dispatch, _c = _a.enrichURLForPreview, enrichURLForPreview = _c === void 0 ? false : _c, findURLFnInputContext = _a.findURLFn, linkPreviews = _a.linkPreviews, onLinkPreviewDismissedInputContext = _a.onLinkPreviewDismissed;
    var client = useChatContext().client;
    // FIXME: the value of channelConfig is stale due to omitting it from the memoization deps in useCreateChannelStateContext
    var _d = useChannelStateContext(), channelConfig = _d.channelConfig, debounceURLEnrichmentMsChannelContext = _d.debounceURLEnrichmentMs, findURLFnChannelContext = _d.findURLFn, onLinkPreviewDismissedChannelContext = _d.onLinkPreviewDismissed;
    var shouldDiscardEnrichQueries = useRef(false);
    var findURLFn = findURLFnInputContext !== null && findURLFnInputContext !== void 0 ? findURLFnInputContext : findURLFnChannelContext;
    var onLinkPreviewDismissed = onLinkPreviewDismissedInputContext !== null && onLinkPreviewDismissedInputContext !== void 0 ? onLinkPreviewDismissedInputContext : onLinkPreviewDismissedChannelContext;
    var debounceURLEnrichmentMs = (_b = debounceURLEnrichmentMsInputContext !== null && debounceURLEnrichmentMsInputContext !== void 0 ? debounceURLEnrichmentMsInputContext : debounceURLEnrichmentMsChannelContext) !== null && _b !== void 0 ? _b : 1500;
    var dismissLinkPreview = useCallback(function (linkPreview) {
        onLinkPreviewDismissed === null || onLinkPreviewDismissed === void 0 ? void 0 : onLinkPreviewDismissed(linkPreview);
        var previewToRemoveMap = new Map();
        linkPreview.state = LinkPreviewState.DISMISSED;
        previewToRemoveMap.set(linkPreview.og_scrape_url, linkPreview);
        dispatch({
            linkPreviews: previewToRemoveMap,
            mode: SetLinkPreviewMode.UPSERT,
            type: 'setLinkPreviews',
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onLinkPreviewDismissed]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var findAndEnqueueURLsToEnrich = useCallback(debounce(function (text, mode) {
        if (mode === void 0) { mode = SetLinkPreviewMode.SET; }
        var urls = findURLFn
            ? findURLFn(text)
            : find(text, 'url').reduce(function (acc, link) {
                if (link.isLink)
                    acc.push(link.href);
                return acc;
            }, []);
        shouldDiscardEnrichQueries.current = urls.length === 0;
        dispatch({
            linkPreviews: urls.reduce(function (acc, url) {
                acc.set(url, { og_scrape_url: url, state: LinkPreviewState.QUEUED });
                return acc;
            }, new Map()),
            mode: mode,
            type: 'setLinkPreviews',
        });
    }, debounceURLEnrichmentMs, { leading: false, trailing: true }), [debounceURLEnrichmentMs, shouldDiscardEnrichQueries, findURLFn]);
    var cancelURLEnrichment = useCallback(function () {
        findAndEnqueueURLsToEnrich.cancel();
        findAndEnqueueURLsToEnrich('');
        findAndEnqueueURLsToEnrich.flush();
    }, [findAndEnqueueURLsToEnrich]);
    useEffect(function () {
        var enqueuedLinks = Array.from(linkPreviews.values()).reduce(function (acc, linkPreview) {
            if (linkPreview.state === 'queued') {
                var loadingLinkPreview = __assign(__assign({}, linkPreview), { state: LinkPreviewState.LOADING });
                acc.set(linkPreview.og_scrape_url, loadingLinkPreview);
            }
            return acc;
        }, new Map());
        if (!enqueuedLinks.size)
            return;
        dispatch({
            linkPreviews: enqueuedLinks,
            mode: SetLinkPreviewMode.UPSERT,
            type: 'setLinkPreviews',
        });
        enqueuedLinks.forEach(function (linkPreview) {
            client
                .enrichURL(linkPreview.og_scrape_url)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .then(function (_a) {
                var duration = _a.duration, ogAttachment = __rest(_a, ["duration"]);
                if (shouldDiscardEnrichQueries.current)
                    return;
                var linkPreviewsMap = new Map();
                linkPreviewsMap.set(linkPreview.og_scrape_url, __assign(__assign({}, ogAttachment), { state: LinkPreviewState.LOADED }));
                dispatch({
                    linkPreviews: linkPreviewsMap,
                    mode: SetLinkPreviewMode.UPSERT,
                    type: 'setLinkPreviews',
                });
            })
                .catch(function () {
                var linkPreviewsMap = new Map();
                linkPreviewsMap.set(linkPreview.og_scrape_url, __assign(__assign({}, linkPreview), { state: LinkPreviewState.FAILED }));
                dispatch({
                    linkPreviews: linkPreviewsMap,
                    mode: SetLinkPreviewMode.UPSERT,
                    type: 'setLinkPreviews',
                });
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldDiscardEnrichQueries, linkPreviews]);
    return {
        cancelURLEnrichment: cancelURLEnrichment,
        dismissLinkPreview: dismissLinkPreview,
        findAndEnqueueURLsToEnrich: (channelConfig === null || channelConfig === void 0 ? void 0 : channelConfig.url_enrichment) && enrichURLForPreview ? findAndEnqueueURLsToEnrich : undefined,
    };
};
