import clsx from 'clsx';
import { CloseIcon, LoadingIndicatorIcon, RetryIcon } from '../icons';
import React, { useCallback, useState } from 'react';
import { BaseImage as DefaultBaseImage } from '../../Gallery';
import { useComponentContext } from '../../../context';
export var ImageAttachmentPreview = function (_a) {
    var _b;
    var attachment = _a.attachment, handleRetry = _a.handleRetry, removeAttachments = _a.removeAttachments;
    var _c = useComponentContext('ImagePreview').BaseImage, BaseImage = _c === void 0 ? DefaultBaseImage : _c;
    var _d = useState(false), previewError = _d[0], setPreviewError = _d[1];
    var _e = (_b = attachment.localMetadata) !== null && _b !== void 0 ? _b : {}, id = _e.id, uploadState = _e.uploadState;
    var handleLoadError = useCallback(function () { return setPreviewError(true); }, []);
    return (React.createElement("div", { className: clsx('str-chat__attachment-preview-image', {
            'str-chat__attachment-preview-image--error': previewError,
        }), "data-testid": 'attachment-preview-image' },
        React.createElement("button", { className: 'str-chat__attachment-preview-delete', "data-testid": 'image-preview-item-delete-button', disabled: uploadState === 'uploading', onClick: function () { return id && removeAttachments([id]); } },
            React.createElement(CloseIcon, null)),
        uploadState === 'failed' && (React.createElement("button", { className: 'str-chat__attachment-preview-error str-chat__attachment-preview-error-image', "data-testid": 'image-preview-item-retry-button', onClick: function () { return handleRetry(attachment); } },
            React.createElement(RetryIcon, null))),
        uploadState === 'uploading' && (React.createElement("div", { className: 'str-chat__attachment-preview-image-loading' },
            React.createElement(LoadingIndicatorIcon, { size: 17 }))),
        attachment.image_url && (React.createElement(BaseImage, { alt: attachment.title, className: 'str-chat__attachment-preview-thumbnail', onError: handleLoadError, src: attachment.image_url, title: attachment.title }))));
};
