import { useChatContext } from '../../../context/ChatContext';
export var useEditMessageHandler = function (doUpdateMessageRequest) {
    var _a = useChatContext('useEditMessageHandler'), channel = _a.channel, client = _a.client;
    return function (updatedMessage, options) {
        if (doUpdateMessageRequest && channel) {
            return Promise.resolve(doUpdateMessageRequest(channel.cid, updatedMessage, options));
        }
        return client.updateMessage(updatedMessage, undefined, options);
    };
};
