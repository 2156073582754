import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import uniqBy from 'lodash.uniqby';
import { MAX_QUERY_CHANNELS_LIMIT } from '../utils';
import { useChatContext } from '../../../context/ChatContext';
import { DEFAULT_INITIAL_CHANNEL_PAGE_SIZE } from '../../../constants/limits';
var RECOVER_LOADED_CHANNELS_THROTTLE_INTERVAL_IN_MS = 5000;
var MIN_RECOVER_LOADED_CHANNELS_THROTTLE_INTERVAL_IN_MS = 2000;
export var usePaginatedChannels = function (client, filters, sort, options, activeChannelHandler, recoveryThrottleIntervalMs, customQueryChannels) {
    if (recoveryThrottleIntervalMs === void 0) { recoveryThrottleIntervalMs = RECOVER_LOADED_CHANNELS_THROTTLE_INTERVAL_IN_MS; }
    var _a = useChatContext('usePaginatedChannels').channelsQueryState, error = _a.error, setError = _a.setError, setQueryInProgress = _a.setQueryInProgress;
    var _b = useState([]), channels = _b[0], setChannels = _b[1];
    var _c = useState(true), hasNextPage = _c[0], setHasNextPage = _c[1];
    var lastRecoveryTimestamp = useRef();
    var recoveryThrottleInterval = recoveryThrottleIntervalMs < MIN_RECOVER_LOADED_CHANNELS_THROTTLE_INTERVAL_IN_MS
        ? MIN_RECOVER_LOADED_CHANNELS_THROTTLE_INTERVAL_IN_MS
        : recoveryThrottleIntervalMs !== null && recoveryThrottleIntervalMs !== void 0 ? recoveryThrottleIntervalMs : RECOVER_LOADED_CHANNELS_THROTTLE_INTERVAL_IN_MS;
    // memoize props
    var filterString = useMemo(function () { return JSON.stringify(filters); }, [filters]);
    var sortString = useMemo(function () { return JSON.stringify(sort); }, [sort]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var queryChannels = function (queryType) {
        if (queryType === void 0) { queryType = 'load-more'; }
        return __awaiter(void 0, void 0, void 0, function () {
            var offset, newOptions, channelQueryResponse, newChannels, err_1;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        setError(null);
                        if (queryType === 'reload') {
                            setChannels([]);
                        }
                        setQueryInProgress(queryType);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, , 7]);
                        if (!customQueryChannels) return [3 /*break*/, 3];
                        return [4 /*yield*/, customQueryChannels({
                                currentChannels: channels,
                                queryType: queryType,
                                setChannels: setChannels,
                                setHasNextPage: setHasNextPage,
                            })];
                    case 2:
                        _c.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        offset = queryType === 'reload' ? 0 : channels.length;
                        newOptions = __assign({ limit: (_a = options === null || options === void 0 ? void 0 : options.limit) !== null && _a !== void 0 ? _a : MAX_QUERY_CHANNELS_LIMIT, message_limit: (_b = options === null || options === void 0 ? void 0 : options.message_limit) !== null && _b !== void 0 ? _b : DEFAULT_INITIAL_CHANNEL_PAGE_SIZE, offset: offset }, options);
                        return [4 /*yield*/, client.queryChannels(filters, sort || {}, newOptions)];
                    case 4:
                        channelQueryResponse = _c.sent();
                        newChannels = queryType === 'reload'
                            ? channelQueryResponse
                            : uniqBy(__spreadArray(__spreadArray([], channels, true), channelQueryResponse, true), 'cid');
                        setChannels(newChannels);
                        setHasNextPage(channelQueryResponse.length >= newOptions.limit);
                        // Set active channel only on load of first page
                        if (!offset && activeChannelHandler) {
                            activeChannelHandler(newChannels, setChannels);
                        }
                        _c.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_1 = _c.sent();
                        console.warn(err_1);
                        setError(err_1);
                        return [3 /*break*/, 7];
                    case 7:
                        setQueryInProgress(null);
                        return [2 /*return*/];
                }
            });
        });
    };
    var throttleRecover = useCallback(function () {
        var now = Date.now();
        var isFirstRecovery = !lastRecoveryTimestamp.current;
        var timeElapsedSinceLastRecoveryMs = lastRecoveryTimestamp.current
            ? now - lastRecoveryTimestamp.current
            : 0;
        if (!isFirstRecovery && timeElapsedSinceLastRecoveryMs < recoveryThrottleInterval && !error) {
            return;
        }
        lastRecoveryTimestamp.current = now;
        queryChannels('reload');
    }, [error, queryChannels, recoveryThrottleInterval]);
    var loadNextPage = function () {
        queryChannels();
    };
    useEffect(function () {
        if (client.recoverStateOnReconnect)
            return;
        var unsubscribe = client.on('connection.recovered', throttleRecover).unsubscribe;
        return function () {
            unsubscribe();
        };
    }, [client, throttleRecover]);
    useEffect(function () {
        queryChannels('reload');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterString, sortString]);
    return {
        channels: channels,
        hasNextPage: hasNextPage,
        loadNextPage: loadNextPage,
        setChannels: setChannels,
    };
};
