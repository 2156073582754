"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Label = void 0;
const react_native_1 = require("react-native");
const plugins_1 = require("../../../plugins");
const react_1 = require("@gluestack-style/react");
exports.Label = (0, react_1.styled)(react_native_1.Text, {
    color: '$textLight.700',
    fontWeight: '$normal',
    fontFamily: '$body',
    fontStyle: 'normal',
    // @ts-ignore
    letterSpacing: '$md',
    variants: {
        size: {
            'xs': {
                // @ts-ignore
                fontSize: '$xs',
                // @ts-ignore
                lineHeight: '$sm',
            },
            'sm': {
                // @ts-ignore
                fontSize: '$sm',
                // @ts-ignore
                lineHeight: '$sm',
            },
            'md': {
                // @ts-ignore
                fontSize: '$md',
                // @ts-ignore
                lineHeight: '$md',
            },
            'lg': {
                // @ts-ignore
                fontSize: '$lg',
                // @ts-ignore
                lineHeight: '$xl',
            },
            'xl': {
                // @ts-ignore
                fontSize: '$xl',
                // @ts-ignore
                lineHeight: '$xl',
            },
            '2xl': {
                // @ts-ignore
                fontSize: '$2xl',
                // @ts-ignore
                lineHeight: '$2xl',
            },
            '3xl': {
                // @ts-ignore
                fontSize: '$3xl',
                // @ts-ignore
                lineHeight: '$3xl',
            },
            '4xl': {
                // @ts-ignore
                fontSize: '$4xl',
                // @ts-ignore
                lineHeight: '$4xl',
            },
            '5xl': {
                // @ts-ignore
                fontSize: '$5xl',
                // @ts-ignore
                lineHeight: '$6xl',
            },
            '6xl': {
                // @ts-ignore
                fontSize: '$6xl',
                // @ts-ignore
                lineHeight: '$7xl',
            },
        },
    },
    defaultProps: {
        size: 'md',
    },
    _dark: {
        color: '$textDark.200',
    },
}, {
    componentName: 'MenuItemLabel',
    ancestorStyle: ['_text'],
}, {
    plugins: [new plugins_1.TextStyleResolver()],
});
