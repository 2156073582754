"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
exports.default = (0, react_1.styled)(react_native_1.ActivityIndicator, {
    props: {
        color: '$primary.600',
    },
    _dark: {
        props: {
            color: '$primary.400',
        },
    },
}, {
    componentName: 'Spinner',
    resolveProps: ['color'],
}, {
    propertyTokenMap: {
        //@ts-ignore
        size: 'size',
    },
});
