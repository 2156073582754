"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBreakpointValue = void 0;
const react_1 = require("@gluestack-style/react");
const useBreakpointValue = (breakpointValue) => {
    var _a, _b;
    let breakPointToResolve;
    const styleTheme = (0, react_1.useStyled)();
    const breakpoints = Object.keys((_b = (_a = styleTheme === null || styleTheme === void 0 ? void 0 : styleTheme.config) === null || _a === void 0 ? void 0 : _a.tokens) === null || _b === void 0 ? void 0 : _b.breakpoints);
    if (typeof breakpointValue === 'string' ||
        typeof breakpointValue === 'number' ||
        breakpointValue === undefined ||
        breakpointValue === null) {
        return breakpointValue;
    }
    if (Array.isArray(breakpointValue) ||
        (typeof breakpointValue === 'object' && breakpointValue !== null)) {
        if (Array.isArray(breakpointValue)) {
            breakPointToResolve = {};
            breakpointValue.forEach((value, index) => {
                breakPointToResolve[breakpoints[index]] = value;
            });
        }
        else {
            breakPointToResolve = breakpointValue;
        }
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return (0, react_1.useBreakpointValue)(breakPointToResolve);
};
exports.useBreakpointValue = useBreakpointValue;
