import React from 'react';
import { PictureIcon } from './icons';
import { UploadButton } from './UploadButton';
import { useTranslationContext } from '../../context';
/**
 * @deprecated will be removed in the next major release
 */
export var ImageUploadButton = function (_a) {
    var _b = _a.multiple, multiple = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, handleFiles = _a.handleFiles, _d = _a.children, children = _d === void 0 ? React.createElement(PictureIcon, null) : _d, _e = _a.resetOnChange, resetOnChange = _e === void 0 ? false : _e;
    var t = useTranslationContext('ImageUploadButton').t;
    return (React.createElement("div", { className: 'rfu-image-upload-button' },
        React.createElement("label", null,
            React.createElement(UploadButton, { accept: 'image/*', "aria-label": t('aria/Image input'), className: 'rfu-image-input', disabled: disabled, multiple: multiple, onFileChange: handleFiles, resetOnChange: resetOnChange }),
            children)));
};
