"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
const plugins_1 = require("../../../plugins");
exports.default = (0, react_1.styled)(react_native_1.Text, {
    color: '$text.50',
    fontWeight: '$normal',
    fontFamily: '$body',
    fontStyle: 'normal',
    // @ts-ignore
    fontSize: '$sm',
}, {
    componentName: 'ToastDescription',
    ancestorStyle: ['_description'],
}, {
    plugins: [new plugins_1.TextStyleResolver()],
});
