import { __extends } from "tslib";
import { Subject } from './Subject';
import { createObserver } from './Observer';
var BehaviorSubject = /** @class */ (function (_super) {
    __extends(BehaviorSubject, _super);
    function BehaviorSubject(_value) {
        var _this = _super.call(this) || this;
        _this._value = _value;
        return _this;
    }
    Object.defineProperty(BehaviorSubject.prototype, "value", {
        get: function () {
            var _a = this, _value = _a._value, thrownError = _a.thrownError;
            if (thrownError) {
                throw thrownError;
            }
            return _value;
        },
        enumerable: false,
        configurable: true
    });
    BehaviorSubject.prototype.subscribe = function (observerOrNext) {
        var observer = createObserver(observerOrNext);
        var subscription = _super.prototype.subscribe.call(this, observerOrNext);
        if (!subscription.closed)
            observer.next(this._value);
        return subscription;
    };
    BehaviorSubject.prototype.next = function (value) {
        _super.prototype.next.call(this, (this._value = value));
    };
    return BehaviorSubject;
}(Subject));
export { BehaviorSubject };
