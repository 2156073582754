import React from 'react';
import { FileSizeIndicator, PlaybackRateButton, PlayButton, WaveProgressBar } from './components';
import { useAudioController } from './hooks/useAudioController';
import { displayDuration } from './utils';
import { FileIcon } from '../ReactFileUtilities';
import { useTranslationContext } from '../../context';
var rootClassName = 'str-chat__message-attachment__voice-recording-widget';
export var VoiceRecordingPlayer = function (_a) {
    var attachment = _a.attachment, playbackRates = _a.playbackRates;
    var t = useTranslationContext('VoiceRecordingPlayer').t;
    var asset_url = attachment.asset_url, _b = attachment.duration, duration = _b === void 0 ? 0 : _b, mime_type = attachment.mime_type, _c = attachment.title, title = _c === void 0 ? t('Voice message') : _c, waveform_data = attachment.waveform_data;
    var _d = useAudioController({
        durationSeconds: duration !== null && duration !== void 0 ? duration : 0,
        mimeType: mime_type,
        playbackRates: playbackRates,
    }), audioRef = _d.audioRef, increasePlaybackRate = _d.increasePlaybackRate, isPlaying = _d.isPlaying, playbackRate = _d.playbackRate, progress = _d.progress, secondsElapsed = _d.secondsElapsed, seek = _d.seek, togglePlay = _d.togglePlay;
    if (!asset_url)
        return null;
    var displayedDuration = secondsElapsed || duration;
    return (React.createElement("div", { className: rootClassName, "data-testid": 'voice-recording-widget' },
        React.createElement("audio", { ref: audioRef },
            React.createElement("source", { "data-testid": 'audio-source', src: asset_url, type: mime_type })),
        React.createElement(PlayButton, { isPlaying: isPlaying, onClick: togglePlay }),
        React.createElement("div", { className: 'str-chat__message-attachment__voice-recording-widget__metadata' },
            React.createElement("div", { className: 'str-chat__message-attachment__voice-recording-widget__title', "data-testid": 'voice-recording-title', title: title }, title),
            React.createElement("div", { className: 'str-chat__message-attachment__voice-recording-widget__audio-state' },
                React.createElement("div", { className: 'str-chat__message-attachment__voice-recording-widget__timer' }, attachment.duration ? (displayDuration(displayedDuration)) : (React.createElement(FileSizeIndicator, { fileSize: attachment.file_size, maximumFractionDigits: 0 }))),
                React.createElement(WaveProgressBar, { progress: progress, seek: seek, waveformData: waveform_data || [] }))),
        React.createElement("div", { className: 'str-chat__message-attachment__voice-recording-widget__right-section' }, isPlaying ? (React.createElement(PlaybackRateButton, { disabled: !audioRef.current, onClick: increasePlaybackRate },
            playbackRate.toFixed(1),
            "x")) : (React.createElement(FileIcon, { big: true, mimeType: mime_type, size: 40, version: '2' })))));
};
export var QuotedVoiceRecording = function (_a) {
    var attachment = _a.attachment;
    var t = useTranslationContext().t;
    var title = attachment.title || t('Voice message');
    return (React.createElement("div", { className: rootClassName, "data-testid": 'quoted-voice-recording-widget' },
        React.createElement("div", { className: 'str-chat__message-attachment__voice-recording-widget__metadata' },
            title && (React.createElement("div", { className: 'str-chat__message-attachment__voice-recording-widget__title', "data-testid": 'voice-recording-title', title: title }, title)),
            React.createElement("div", { className: 'str-chat__message-attachment__voice-recording-widget__audio-state' },
                React.createElement("div", { className: 'str-chat__message-attachment__voice-recording-widget__timer' }, attachment.duration ? (displayDuration(attachment.duration)) : (React.createElement(FileSizeIndicator, { fileSize: attachment.file_size, maximumFractionDigits: 0 }))))),
        React.createElement(FileIcon, { big: true, mimeType: attachment.mime_type, size: 34, version: '2' })));
};
export var VoiceRecording = function (_a) {
    var attachment = _a.attachment, isQuoted = _a.isQuoted;
    return isQuoted ? (React.createElement(QuotedVoiceRecording, { attachment: attachment })) : (React.createElement(VoiceRecordingPlayer, { attachment: attachment }));
};
