import { __awaiter, __generator } from "tslib";
import { useCallback, useEffect } from 'react';
import { checkUploadPermissions } from './utils';
import { useChannelActionContext } from '../../../context/ChannelActionContext';
import { useChannelStateContext } from '../../../context/ChannelStateContext';
import { useChatContext } from '../../../context/ChatContext';
import { useTranslationContext } from '../../../context/TranslationContext';
export var useFileUploads = function (props, state, dispatch) {
    var doFileUploadRequest = props.doFileUploadRequest, errorHandler = props.errorHandler;
    var fileUploads = state.fileUploads;
    var channel = useChannelStateContext('useFileUploads').channel;
    var addNotification = useChannelActionContext('useFileUploads').addNotification;
    var getAppSettings = useChatContext('useFileUploads').getAppSettings;
    var t = useTranslationContext('useFileUploads').t;
    var uploadFile = useCallback(function (id) {
        dispatch({ id: id, state: 'uploading', type: 'setFileUpload' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var removeFile = useCallback(function (id) {
        // TODO: cancel upload if still uploading
        dispatch({ id: id, type: 'removeFileUpload' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var upload, file, id, canUpload, response, error_1, errorMessage, alreadyRemoved;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        upload = Object.values(fileUploads).find(function (fileUpload) { return fileUpload.state === 'uploading' && fileUpload.file; });
                        if (!upload)
                            return [2 /*return*/];
                        file = upload.file, id = upload.id;
                        return [4 /*yield*/, checkUploadPermissions({
                                addNotification: addNotification,
                                file: file,
                                getAppSettings: getAppSettings,
                                t: t,
                                uploadType: 'file',
                            })];
                    case 1:
                        canUpload = _a.sent();
                        if (!canUpload)
                            return [2 /*return*/, removeFile(id)];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 7, , 8]);
                        if (!doFileUploadRequest) return [3 /*break*/, 4];
                        return [4 /*yield*/, doFileUploadRequest(file, channel)];
                    case 3:
                        response = _a.sent();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, channel.sendFile(file)];
                    case 5:
                        response = _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        error_1 = _a.sent();
                        errorMessage = typeof error_1.message === 'string'
                            ? error_1.message
                            : t('Error uploading file');
                        addNotification(errorMessage, 'error');
                        alreadyRemoved = false;
                        if (!fileUploads[id]) {
                            alreadyRemoved = true;
                        }
                        else {
                            dispatch({ id: id, state: 'failed', type: 'setFileUpload' });
                        }
                        if (!alreadyRemoved && errorHandler) {
                            // TODO: verify if the parameters passed to the error handler actually make sense
                            errorHandler(error_1, 'upload-file', file);
                        }
                        return [2 /*return*/];
                    case 8:
                        // If doImageUploadRequest returns any falsy value, then don't create the upload preview.
                        // This is for the case if someone wants to handle failure on app level.
                        if (!response) {
                            removeFile(id);
                            return [2 /*return*/];
                        }
                        dispatch({
                            id: id,
                            state: 'finished',
                            thumb_url: response.thumb_url,
                            type: 'setFileUpload',
                            url: response.file,
                        });
                        return [2 /*return*/];
                }
            });
        }); })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileUploads, channel, doFileUploadRequest, errorHandler, removeFile]);
    return {
        removeFile: removeFile,
        uploadFile: uploadFile,
    };
};
