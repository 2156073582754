import { __spreadArray } from "tslib";
import { useEffect } from 'react';
import uniqBy from 'lodash.uniqby';
import { moveChannelUp } from '../utils';
import { useChatContext } from '../../../context/ChatContext';
export var useMessageNewListener = function (setChannels, customHandler, lockChannelOrder, allowNewMessagesFromUnfilteredChannels) {
    if (lockChannelOrder === void 0) { lockChannelOrder = false; }
    if (allowNewMessagesFromUnfilteredChannels === void 0) { allowNewMessagesFromUnfilteredChannels = true; }
    var client = useChatContext('useMessageNewListener').client;
    useEffect(function () {
        var handleEvent = function (event) {
            if (customHandler && typeof customHandler === 'function') {
                customHandler(setChannels, event);
            }
            else {
                setChannels(function (channels) {
                    var channelInList = channels.filter(function (channel) { return channel.cid === event.cid; }).length > 0;
                    if (!channelInList && allowNewMessagesFromUnfilteredChannels && event.channel_type) {
                        var channel = client.channel(event.channel_type, event.channel_id);
                        return uniqBy(__spreadArray([channel], channels, true), 'cid');
                    }
                    if (!lockChannelOrder)
                        return moveChannelUp({ channels: channels, cid: event.cid || '' });
                    return channels;
                });
            }
        };
        client.on('message.new', handleEvent);
        return function () {
            client.off('message.new', handleEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lockChannelOrder]);
};
