import React from 'react';
import { PlayButton } from '../../Attachment';
import { RecordingTimer } from '../../MediaRecorder';
import { CloseIcon, LoadingIndicatorIcon, RetryIcon } from '../icons';
import { FileIcon } from '../../ReactFileUtilities';
import { useAudioController } from '../../Attachment/hooks/useAudioController';
export var VoiceRecordingPreview = function (_a) {
    var _b, _c, _d;
    var attachment = _a.attachment, handleRetry = _a.handleRetry, removeAttachments = _a.removeAttachments;
    var _e = useAudioController({
        mimeType: attachment.mime_type,
    }), audioRef = _e.audioRef, isPlaying = _e.isPlaying, secondsElapsed = _e.secondsElapsed, togglePlay = _e.togglePlay;
    return (React.createElement("div", { className: 'str-chat__attachment-preview-voice-recording', "data-testid": 'attachment-preview-voice-recording' },
        React.createElement("audio", { ref: audioRef },
            React.createElement("source", { "data-testid": 'audio-source', src: attachment.asset_url, type: attachment.mime_type })),
        React.createElement(PlayButton, { isPlaying: isPlaying, onClick: togglePlay }),
        React.createElement("button", { className: 'str-chat__attachment-preview-delete', "data-testid": 'file-preview-item-delete-button', disabled: ((_b = attachment.localMetadata) === null || _b === void 0 ? void 0 : _b.uploadState) === 'uploading', onClick: function () { var _a; return ((_a = attachment.localMetadata) === null || _a === void 0 ? void 0 : _a.id) && removeAttachments([attachment.localMetadata.id]); } },
            React.createElement(CloseIcon, null)),
        ((_c = attachment.localMetadata) === null || _c === void 0 ? void 0 : _c.uploadState) === 'failed' && !!handleRetry && (React.createElement("button", { className: 'str-chat__attachment-preview-error str-chat__attachment-preview-error-file', "data-testid": 'file-preview-item-retry-button', onClick: function () { return handleRetry(attachment); } },
            React.createElement(RetryIcon, null))),
        React.createElement("div", { className: 'str-chat__attachment-preview-metadata' },
            React.createElement("div", { className: 'str-chat__attachment-preview-file-name', title: attachment.title }, attachment.title),
            typeof attachment.duration !== 'undefined' && (React.createElement(RecordingTimer, { durationSeconds: secondsElapsed || attachment.duration })),
            ((_d = attachment.localMetadata) === null || _d === void 0 ? void 0 : _d.uploadState) === 'uploading' && (React.createElement(LoadingIndicatorIcon, { size: 17 }))),
        React.createElement("div", { className: 'str-chat__attachment-preview-file-icon' },
            React.createElement(FileIcon, { filename: attachment.title, mimeType: attachment.mime_type, version: '2' }))));
};
