import { __assign, __awaiter, __generator, __rest } from "tslib";
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Virtuoso, } from 'react-virtuoso';
import { GiphyPreviewMessage as DefaultGiphyPreviewMessage } from './GiphyPreviewMessage';
import { useLastReadData } from './hooks';
import { useGiphyPreview, useMessageSetKey, useNewMessageNotification, usePrependedMessagesCount, useScrollToBottomOnNewMessage, useShouldForceScrollToBottom, useUnreadMessagesNotificationVirtualized, } from './hooks/VirtualizedMessageList';
import { useMarkRead } from './hooks/useMarkRead';
import { MessageNotification as DefaultMessageNotification } from './MessageNotification';
import { MessageListNotifications as DefaultMessageListNotifications } from './MessageListNotifications';
import { MessageListMainPanel } from './MessageListMainPanel';
import { getGroupStyles, getLastReceived, processMessages, } from './utils';
import { MessageSimple } from '../Message';
import { UnreadMessagesNotification as DefaultUnreadMessagesNotification } from './UnreadMessagesNotification';
import { calculateFirstItemIndex, calculateItemIndex, EmptyPlaceholder, Footer, Header, Item, makeItemsRenderedHandler, messageRenderer, } from './VirtualizedMessageListComponents';
import { useChannelActionContext, } from '../../context/ChannelActionContext';
import { useChannelStateContext, } from '../../context/ChannelStateContext';
import { useChatContext } from '../../context/ChatContext';
import { useComponentContext } from '../../context/ComponentContext';
import { DEFAULT_NEXT_CHANNEL_PAGE_SIZE } from '../../constants/limits';
function captureResizeObserverExceededError(e) {
    if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
        e.message === 'ResizeObserver loop limit exceeded') {
        e.stopImmediatePropagation();
    }
}
function useCaptureResizeObserverExceededError() {
    useEffect(function () {
        window.addEventListener('error', captureResizeObserverExceededError);
        return function () {
            window.removeEventListener('error', captureResizeObserverExceededError);
        };
    }, []);
}
function fractionalItemSize(element) {
    return element.getBoundingClientRect().height;
}
function findMessageIndex(messages, id) {
    return messages.findIndex(function (message) { return message.id === id; });
}
function calculateInitialTopMostItemIndex(messages, highlightedMessageId) {
    if (highlightedMessageId) {
        var index = findMessageIndex(messages, highlightedMessageId);
        if (index !== -1) {
            return { align: 'center', index: index };
        }
    }
    return messages.length - 1;
}
var VirtualizedMessageListWithContext = function (props) {
    var _a, _b;
    var additionalMessageInputProps = props.additionalMessageInputProps, _c = props.additionalVirtuosoProps, additionalVirtuosoProps = _c === void 0 ? {} : _c, channel = props.channel, channelUnreadUiState = props.channelUnreadUiState, closeReactionSelectorOnClick = props.closeReactionSelectorOnClick, customMessageActions = props.customMessageActions, customMessageRenderer = props.customMessageRenderer, defaultItemHeight = props.defaultItemHeight, _d = props.disableDateSeparator, disableDateSeparator = _d === void 0 ? true : _d, formatDate = props.formatDate, groupStyles = props.groupStyles, hasMoreNewer = props.hasMoreNewer, head = props.head, _e = props.hideDeletedMessages, hideDeletedMessages = _e === void 0 ? false : _e, _f = props.hideNewMessageSeparator, hideNewMessageSeparator = _f === void 0 ? false : _f, highlightedMessageId = props.highlightedMessageId, jumpToLatestMessage = props.jumpToLatestMessage, loadingMore = props.loadingMore, loadMore = props.loadMore, loadMoreNewer = props.loadMoreNewer, maxTimeBetweenGroupedMessages = props.maxTimeBetweenGroupedMessages, MessageUIComponentFromProps = props.Message, messageActions = props.messageActions, _g = props.messageLimit, messageLimit = _g === void 0 ? DEFAULT_NEXT_CHANNEL_PAGE_SIZE : _g, messages = props.messages, notifications = props.notifications, 
    // TODO: refactor to scrollSeekPlaceHolderConfiguration and components.ScrollSeekPlaceholder, like the Virtuoso Component
    _h = props.overscan, 
    // TODO: refactor to scrollSeekPlaceHolderConfiguration and components.ScrollSeekPlaceholder, like the Virtuoso Component
    overscan = _h === void 0 ? 0 : _h, read = props.read, _j = props.returnAllReadData, returnAllReadData = _j === void 0 ? false : _j, reviewProcessedMessage = props.reviewProcessedMessage, scrollSeekPlaceHolder = props.scrollSeekPlaceHolder, _k = props.scrollToLatestMessageOnFocus, scrollToLatestMessageOnFocus = _k === void 0 ? false : _k, _l = props.separateGiphyPreview, separateGiphyPreview = _l === void 0 ? false : _l, _m = props.shouldGroupByUser, shouldGroupByUser = _m === void 0 ? false : _m, showUnreadNotificationAlways = props.showUnreadNotificationAlways, reactionDetailsSort = props.reactionDetailsSort, sortReactionDetails = props.sortReactionDetails, sortReactions = props.sortReactions, _o = props.stickToBottomScrollBehavior, stickToBottomScrollBehavior = _o === void 0 ? 'smooth' : _o, suppressAutoscroll = props.suppressAutoscroll, threadList = props.threadList;
    var virtuosoComponentsFromProps = additionalVirtuosoProps.components, overridingVirtuosoProps = __rest(additionalVirtuosoProps, ["components"]);
    // Stops errors generated from react-virtuoso to bubble up
    // to Sentry or other tracking tools.
    useCaptureResizeObserverExceededError();
    var _p = useComponentContext('VirtualizedMessageList'), DateSeparator = _p.DateSeparator, _q = _p.GiphyPreviewMessage, GiphyPreviewMessage = _q === void 0 ? DefaultGiphyPreviewMessage : _q, _r = _p.MessageListNotifications, MessageListNotifications = _r === void 0 ? DefaultMessageListNotifications : _r, _s = _p.MessageNotification, MessageNotification = _s === void 0 ? DefaultMessageNotification : _s, MessageSystem = _p.MessageSystem, _t = _p.UnreadMessagesNotification, UnreadMessagesNotification = _t === void 0 ? DefaultUnreadMessagesNotification : _t, UnreadMessagesSeparator = _p.UnreadMessagesSeparator, _u = _p.VirtualMessage, MessageUIComponentFromContext = _u === void 0 ? MessageSimple : _u;
    var MessageUIComponent = MessageUIComponentFromProps || MessageUIComponentFromContext;
    var _v = useChatContext('VirtualizedMessageList'), client = _v.client, customClasses = _v.customClasses;
    var virtuoso = useRef(null);
    var lastRead = useMemo(function () { var _a; return (_a = channel.lastRead) === null || _a === void 0 ? void 0 : _a.call(channel); }, [channel]);
    var _w = useUnreadMessagesNotificationVirtualized({
        lastRead: channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.last_read,
        showAlways: !!showUnreadNotificationAlways,
        unreadCount: (_a = channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages) !== null && _a !== void 0 ? _a : 0,
    }), showUnreadMessagesNotification = _w.show, toggleShowUnreadMessagesNotification = _w.toggleShowUnreadMessagesNotification;
    var _x = useGiphyPreview(separateGiphyPreview), giphyPreviewMessage = _x.giphyPreviewMessage, setGiphyPreviewMessage = _x.setGiphyPreviewMessage;
    var processedMessages = useMemo(function () {
        if (typeof messages === 'undefined') {
            return [];
        }
        if (disableDateSeparator &&
            !hideDeletedMessages &&
            hideNewMessageSeparator &&
            !separateGiphyPreview) {
            return messages;
        }
        return processMessages({
            enableDateSeparator: !disableDateSeparator,
            hideDeletedMessages: hideDeletedMessages,
            hideNewMessageSeparator: hideNewMessageSeparator,
            lastRead: lastRead,
            messages: messages,
            reviewProcessedMessage: reviewProcessedMessage,
            setGiphyPreviewMessage: setGiphyPreviewMessage,
            userId: client.userID || '',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        disableDateSeparator,
        hideDeletedMessages,
        hideNewMessageSeparator,
        lastRead,
        messages,
        messages === null || messages === void 0 ? void 0 : messages.length,
        client.userID,
    ]);
    // get the mapping of own messages to array of users who read them
    var ownMessagesReadByOthers = useLastReadData({
        messages: processedMessages,
        read: read,
        returnAllReadData: returnAllReadData,
        userID: client.userID,
    });
    var lastReceivedMessageId = useMemo(function () { return getLastReceived(processedMessages); }, [
        processedMessages,
    ]);
    var groupStylesFn = groupStyles || getGroupStyles;
    var messageGroupStyles = useMemo(function () {
        return processedMessages.reduce(function (acc, message, i) {
            var style = groupStylesFn(message, processedMessages[i - 1], processedMessages[i + 1], !shouldGroupByUser, maxTimeBetweenGroupedMessages);
            if (style)
                acc[message.id] = style;
            return acc;
        }, {});
    }, 
    // processedMessages were incorrectly rebuilt with a new object identity at some point, hence the .length usage
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxTimeBetweenGroupedMessages, processedMessages.length, shouldGroupByUser, groupStylesFn]);
    var _y = useNewMessageNotification(processedMessages, client.userID, hasMoreNewer), atBottom = _y.atBottom, isMessageListScrolledToBottom = _y.isMessageListScrolledToBottom, newMessagesNotification = _y.newMessagesNotification, setIsMessageListScrolledToBottom = _y.setIsMessageListScrolledToBottom, setNewMessagesNotification = _y.setNewMessagesNotification;
    useMarkRead({
        isMessageListScrolledToBottom: isMessageListScrolledToBottom,
        messageListIsThread: !!threadList,
        unreadCount: (_b = channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages) !== null && _b !== void 0 ? _b : 0,
        wasMarkedUnread: !!(channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.first_unread_message_id),
    });
    var scrollToBottom = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!hasMoreNewer) return [3 /*break*/, 2];
                    return [4 /*yield*/, jumpToLatestMessage()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2:
                    if (virtuoso.current) {
                        virtuoso.current.scrollToIndex(processedMessages.length - 1);
                    }
                    setNewMessagesNotification(false);
                    return [2 /*return*/];
            }
        });
    }); }, [
        virtuoso,
        processedMessages,
        setNewMessagesNotification,
        // processedMessages were incorrectly rebuilt with a new object identity at some point, hence the .length usage
        processedMessages.length,
        hasMoreNewer,
        jumpToLatestMessage,
    ]);
    useScrollToBottomOnNewMessage({ messages: messages, scrollToBottom: scrollToBottom, scrollToLatestMessageOnFocus: scrollToLatestMessageOnFocus });
    var numItemsPrepended = usePrependedMessagesCount(processedMessages, !disableDateSeparator);
    var messageSetKey = useMessageSetKey({ messages: messages }).messageSetKey;
    var shouldForceScrollToBottom = useShouldForceScrollToBottom(processedMessages, client.userID);
    var handleItemsRendered = useMemo(function () { return makeItemsRenderedHandler([toggleShowUnreadMessagesNotification], processedMessages); }, [processedMessages, toggleShowUnreadMessagesNotification]);
    var followOutput = function (isAtBottom) {
        if (hasMoreNewer || suppressAutoscroll) {
            return false;
        }
        if (shouldForceScrollToBottom()) {
            return isAtBottom ? stickToBottomScrollBehavior : 'auto';
        }
        // a message from another user has been received - don't scroll to bottom unless already there
        return isAtBottom ? stickToBottomScrollBehavior : false;
    };
    var computeItemKey = useCallback(function (index, _, _a) {
        var numItemsPrepended = _a.numItemsPrepended, processedMessages = _a.processedMessages;
        return processedMessages[calculateItemIndex(index, numItemsPrepended)].id;
    }, []);
    var atBottomStateChange = function (isAtBottom) {
        atBottom.current = isAtBottom;
        setIsMessageListScrolledToBottom(isAtBottom);
        if (isAtBottom) {
            loadMoreNewer === null || loadMoreNewer === void 0 ? void 0 : loadMoreNewer(messageLimit);
            setNewMessagesNotification === null || setNewMessagesNotification === void 0 ? void 0 : setNewMessagesNotification(false);
        }
    };
    var atTopStateChange = function (isAtTop) {
        if (isAtTop) {
            loadMore === null || loadMore === void 0 ? void 0 : loadMore(messageLimit);
        }
    };
    useEffect(function () {
        var scrollTimeout;
        if (highlightedMessageId) {
            var index_1 = findMessageIndex(processedMessages, highlightedMessageId);
            if (index_1 !== -1) {
                scrollTimeout = setTimeout(function () {
                    var _a;
                    (_a = virtuoso.current) === null || _a === void 0 ? void 0 : _a.scrollToIndex({ align: 'center', index: index_1 });
                }, 0);
            }
        }
        return function () {
            clearTimeout(scrollTimeout);
        };
    }, [highlightedMessageId, processedMessages]);
    if (!processedMessages)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(MessageListMainPanel, null,
            !threadList && showUnreadMessagesNotification && (React.createElement(UnreadMessagesNotification, { unreadCount: channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages })),
            React.createElement("div", { className: (customClasses === null || customClasses === void 0 ? void 0 : customClasses.virtualizedMessageList) || 'str-chat__virtual-list' },
                React.createElement(Virtuoso, __assign({ atBottomStateChange: atBottomStateChange, atBottomThreshold: 100, atTopStateChange: atTopStateChange, atTopThreshold: 100, className: 'str-chat__message-list-scroll', components: __assign({ EmptyPlaceholder: EmptyPlaceholder, Footer: Footer, Header: Header, Item: Item }, virtuosoComponentsFromProps), computeItemKey: computeItemKey, context: {
                        additionalMessageInputProps: additionalMessageInputProps,
                        closeReactionSelectorOnClick: closeReactionSelectorOnClick,
                        customClasses: customClasses,
                        customMessageActions: customMessageActions,
                        customMessageRenderer: customMessageRenderer,
                        DateSeparator: DateSeparator,
                        firstUnreadMessageId: channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.first_unread_message_id,
                        formatDate: formatDate,
                        head: head,
                        lastReadDate: channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.last_read,
                        lastReadMessageId: channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.last_read_message_id,
                        lastReceivedMessageId: lastReceivedMessageId,
                        loadingMore: loadingMore,
                        Message: MessageUIComponent,
                        messageActions: messageActions,
                        messageGroupStyles: messageGroupStyles,
                        MessageSystem: MessageSystem,
                        numItemsPrepended: numItemsPrepended,
                        ownMessagesReadByOthers: ownMessagesReadByOthers,
                        processedMessages: processedMessages,
                        reactionDetailsSort: reactionDetailsSort,
                        shouldGroupByUser: shouldGroupByUser,
                        sortReactionDetails: sortReactionDetails,
                        sortReactions: sortReactions,
                        threadList: threadList,
                        unreadMessageCount: channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages,
                        UnreadMessagesSeparator: UnreadMessagesSeparator,
                        virtuosoRef: virtuoso,
                    }, firstItemIndex: calculateFirstItemIndex(numItemsPrepended), followOutput: followOutput, increaseViewportBy: { bottom: 200, top: 0 }, initialTopMostItemIndex: calculateInitialTopMostItemIndex(processedMessages, highlightedMessageId), itemContent: messageRenderer, itemSize: fractionalItemSize, itemsRendered: handleItemsRendered, key: messageSetKey, overscan: overscan, ref: virtuoso, style: { overflowX: 'hidden' }, totalCount: processedMessages.length }, overridingVirtuosoProps, (scrollSeekPlaceHolder ? { scrollSeek: scrollSeekPlaceHolder } : {}), (defaultItemHeight ? { defaultItemHeight: defaultItemHeight } : {}))))),
        React.createElement(MessageListNotifications, { hasNewMessages: newMessagesNotification, isMessageListScrolledToBottom: isMessageListScrolledToBottom, isNotAtLatestMessageSet: hasMoreNewer, MessageNotification: MessageNotification, notifications: notifications, scrollToBottom: scrollToBottom, threadList: threadList, unreadCount: threadList ? undefined : channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages }),
        giphyPreviewMessage && React.createElement(GiphyPreviewMessage, { message: giphyPreviewMessage })));
};
/**
 * The VirtualizedMessageList component renders a list of messages in a virtualized list.
 * It is a consumer of the React contexts set in [Channel](https://github.com/GetStream/stream-chat-react/blob/master/src/components/Channel/Channel.tsx).
 */
export function VirtualizedMessageList(props) {
    var _a = useChannelActionContext('VirtualizedMessageList'), jumpToLatestMessage = _a.jumpToLatestMessage, loadMore = _a.loadMore, loadMoreNewer = _a.loadMoreNewer;
    var _b = useChannelStateContext('VirtualizedMessageList'), channel = _b.channel, channelUnreadUiState = _b.channelUnreadUiState, hasMore = _b.hasMore, hasMoreNewer = _b.hasMoreNewer, highlightedMessageId = _b.highlightedMessageId, loadingMore = _b.loadingMore, loadingMoreNewer = _b.loadingMoreNewer, contextMessages = _b.messages, notifications = _b.notifications, read = _b.read, suppressAutoscroll = _b.suppressAutoscroll;
    var messages = props.messages || contextMessages;
    return (React.createElement(VirtualizedMessageListWithContext, __assign({ channel: channel, channelUnreadUiState: channelUnreadUiState, hasMore: !!hasMore, hasMoreNewer: !!hasMoreNewer, highlightedMessageId: highlightedMessageId, jumpToLatestMessage: jumpToLatestMessage, loadingMore: !!loadingMore, loadingMoreNewer: !!loadingMoreNewer, loadMore: loadMore, loadMoreNewer: loadMoreNewer, messages: messages, notifications: notifications, read: read, suppressAutoscroll: suppressAutoscroll }, props)));
}
