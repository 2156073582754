import { __awaiter, __generator } from "tslib";
import { Subscription } from '../observable/Subscription';
import { Subject } from '../observable/Subject';
import { BehaviorSubject } from '../observable/BehaviorSubject';
export var RecordingPermission;
(function (RecordingPermission) {
    RecordingPermission["CAM"] = "camera";
    RecordingPermission["MIC"] = "microphone";
})(RecordingPermission || (RecordingPermission = {}));
var MEDIA_TO_PERMISSION = {
    audio: RecordingPermission.MIC,
    video: RecordingPermission.CAM,
};
var BrowserPermission = /** @class */ (function () {
    function BrowserPermission(_a) {
        var mediaType = _a.mediaType;
        this.state = new BehaviorSubject(undefined);
        this.status = new BehaviorSubject(undefined);
        this.error = new Subject();
        this.changeSubscriptions = [];
        this.name = MEDIA_TO_PERMISSION[mediaType];
    }
    Object.defineProperty(BrowserPermission.prototype, "isWatching", {
        get: function () {
            return this.changeSubscriptions.some(function (subscription) { return !subscription.closed; });
        },
        enumerable: false,
        configurable: true
    });
    BrowserPermission.prototype.watch = function () {
        return __awaiter(this, void 0, void 0, function () {
            var status, handlePermissionChange;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.status.value) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.check()];
                    case 1:
                        _a.sent();
                        if (!this.status.value)
                            return [2 /*return*/];
                        _a.label = 2;
                    case 2:
                        status = this.status.value;
                        handlePermissionChange = function (e) {
                            var state = e.target.state;
                            _this.state.next(state);
                        };
                        status.addEventListener('change', handlePermissionChange);
                        this.changeSubscriptions.push(new Subscription(function () {
                            status.removeEventListener('change', handlePermissionChange);
                        }));
                        return [2 /*return*/];
                }
            });
        });
    };
    BrowserPermission.prototype.unwatch = function () {
        this.changeSubscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    BrowserPermission.prototype.check = function () {
        return __awaiter(this, void 0, void 0, function () {
            var permissionState, permissionStatus, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.name) {
                            this.error.next(new Error('Unknown media recording permission'));
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, navigator.permissions.query({
                                name: this.name,
                            })];
                    case 2:
                        permissionStatus = _a.sent();
                        permissionState = permissionStatus.state;
                        this.status.next(permissionStatus);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        // permission does not exist - cannot be queried
                        // an example would be Firefox - camera, neither microphone perms can be queried
                        permissionState = 'granted';
                        return [3 /*break*/, 4];
                    case 4:
                        this.state.next(permissionState);
                        return [2 /*return*/];
                }
            });
        });
    };
    return BrowserPermission;
}());
export { BrowserPermission };
