"use strict";
'use client';
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.themeTools = exports.Factory = exports.createProvider = exports.extendTheme = exports.AsForwarder = exports.flush = exports.useStyled = exports.StyledProvider = exports.config = void 0;
__exportStar(require("./components"), exports);
__exportStar(require("./hooks"), exports);
var gluestack_ui_config_1 = require("./components/gluestack-ui.config");
Object.defineProperty(exports, "config", { enumerable: true, get: function () { return gluestack_ui_config_1.config; } });
var react_1 = require("@gluestack-style/react");
Object.defineProperty(exports, "StyledProvider", { enumerable: true, get: function () { return react_1.StyledProvider; } });
Object.defineProperty(exports, "useStyled", { enumerable: true, get: function () { return react_1.useStyled; } });
Object.defineProperty(exports, "flush", { enumerable: true, get: function () { return react_1.flush; } });
Object.defineProperty(exports, "AsForwarder", { enumerable: true, get: function () { return react_1.AsForwarder; } });
var extendTheme_1 = require("./utils/extendTheme");
Object.defineProperty(exports, "extendTheme", { enumerable: true, get: function () { return extendTheme_1.extendTheme; } });
var provider_1 = require("@gluestack-ui/provider");
Object.defineProperty(exports, "createProvider", { enumerable: true, get: function () { return provider_1.createProvider; } });
var factory_1 = require("./factory");
Object.defineProperty(exports, "Factory", { enumerable: true, get: function () { return factory_1.Factory; } });
// export { extendTheme } from './extendTheme';
// modified createIcon will be exported which will not require root as an argument
// export { createIcon } from '@gluestack-ui/icon';
// TODO: remove this with proper functions
const themeTools = {};
exports.themeTools = themeTools;
