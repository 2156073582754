import { __assign, __awaiter, __generator, __rest } from "tslib";
import { useCallback } from 'react';
import { nanoid } from 'nanoid';
import { useImageUploads } from './useImageUploads';
import { useFileUploads } from './useFileUploads';
import { checkUploadPermissions } from './utils';
import { isLocalAttachment, isLocalImageAttachment, isUploadedImage } from '../../Attachment';
import { useChannelActionContext, useChannelStateContext, useChatContext, useTranslationContext, } from '../../../context';
var apiMaxNumberOfFiles = 10;
var ensureIsLocalAttachment = function (attachment) {
    if (isLocalAttachment(attachment)) {
        return attachment;
    }
    var localMetadata = attachment.localMetadata, rest = __rest(attachment, ["localMetadata"]);
    return __assign({ localMetadata: __assign(__assign({}, (localMetadata !== null && localMetadata !== void 0 ? localMetadata : {})), { id: (localMetadata === null || localMetadata === void 0 ? void 0 : localMetadata.id) || nanoid() }) }, rest);
};
export var useAttachments = function (props, state, dispatch, textareaRef) {
    var doFileUploadRequest = props.doFileUploadRequest, doImageUploadRequest = props.doImageUploadRequest, errorHandler = props.errorHandler, noFiles = props.noFiles;
    var fileUploads = state.fileUploads, imageUploads = state.imageUploads;
    var getAppSettings = useChatContext('useAttachments').getAppSettings;
    var t = useTranslationContext('useAttachments').t;
    var addNotification = useChannelActionContext('useAttachments').addNotification;
    var _a = useChannelStateContext('useAttachments'), channel = _a.channel, maxNumberOfFiles = _a.maxNumberOfFiles, multipleUploads = _a.multipleUploads;
    var _b = useFileUploads(props, state, dispatch), removeFile = _b.removeFile, uploadFile = _b.uploadFile;
    var _c = useImageUploads(props, state, dispatch), removeImage = _c.removeImage, uploadImage = _c.uploadImage;
    // Number of files that the user can still add. Should never be more than the amount allowed by the API.
    // If multipleUploads is false, we only want to allow a single upload.
    var maxFilesAllowed = !multipleUploads ? 1 : maxNumberOfFiles || apiMaxNumberOfFiles;
    // OG attachments should not be counted towards "numberOfImages"
    var numberOfImages = Object.values(imageUploads).filter(function (_a) {
        var og_scrape_url = _a.og_scrape_url, state = _a.state;
        return state !== 'failed' && !og_scrape_url;
    }).length;
    var numberOfFiles = Object.values(fileUploads).filter(function (_a) {
        var state = _a.state;
        return state !== 'failed';
    }).length;
    var numberOfUploads = numberOfImages + numberOfFiles;
    var maxFilesLeft = maxFilesAllowed - numberOfUploads;
    var uploadNewFiles = useCallback(function (files) {
        var _a;
        Array.from(files)
            .slice(0, maxFilesLeft)
            .forEach(function (file) {
            var _a;
            var id = nanoid();
            if (file.type.startsWith('image/') &&
                !file.type.endsWith('.photoshop') // photoshop files begin with 'image/'
            ) {
                dispatch({
                    file: file,
                    id: id,
                    previewUri: (_a = URL.createObjectURL) === null || _a === void 0 ? void 0 : _a.call(URL, file),
                    state: 'uploading',
                    type: 'setImageUpload',
                });
            }
            else if (file instanceof File && !noFiles) {
                dispatch({ file: file, id: id, state: 'uploading', type: 'setFileUpload' });
            }
        });
        (_a = textareaRef === null || textareaRef === void 0 ? void 0 : textareaRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxFilesLeft, noFiles]);
    var removeAttachments = useCallback(function (ids) {
        if (!ids.length)
            return;
        dispatch({ ids: ids, type: 'removeAttachments' });
    }, [dispatch]);
    var upsertAttachments = useCallback(function (attachments) {
        if (!attachments.length)
            return;
        dispatch({
            attachments: attachments.map(ensureIsLocalAttachment),
            type: 'upsertAttachments',
        });
    }, [dispatch]);
    var uploadAttachment = useCallback(function (att) { return __awaiter(void 0, void 0, void 0, function () {
        var localMetadata, attachment, isImage, id, file, canUpload, notificationText, response, doUploadRequest, error_1, finalError, failedAttachment, uploadedAttachment;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    localMetadata = att.localMetadata, attachment = __rest(att, ["localMetadata"]);
                    if (!(localMetadata === null || localMetadata === void 0 ? void 0 : localMetadata.file))
                        return [2 /*return*/, att];
                    isImage = isUploadedImage(attachment);
                    id = (_a = localMetadata === null || localMetadata === void 0 ? void 0 : localMetadata.id) !== null && _a !== void 0 ? _a : nanoid();
                    file = localMetadata.file;
                    return [4 /*yield*/, checkUploadPermissions({
                            addNotification: addNotification,
                            file: file,
                            getAppSettings: getAppSettings,
                            t: t,
                            uploadType: isImage ? 'image' : 'file',
                        })];
                case 1:
                    canUpload = _b.sent();
                    if (!canUpload) {
                        notificationText = t('Missing permissions to upload the attachment');
                        console.error(new Error(notificationText));
                        addNotification(notificationText, 'error');
                        return [2 /*return*/, att];
                    }
                    upsertAttachments([
                        __assign(__assign({}, attachment), { localMetadata: __assign(__assign({}, localMetadata), { id: id, uploadState: 'uploading' }) }),
                    ]);
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 7, , 8]);
                    doUploadRequest = isImage ? doImageUploadRequest : doFileUploadRequest;
                    if (!doUploadRequest) return [3 /*break*/, 4];
                    return [4 /*yield*/, doUploadRequest(file, channel)];
                case 3:
                    response = _b.sent();
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, channel[isImage ? 'sendImage' : 'sendFile'](file)];
                case 5:
                    response = _b.sent();
                    _b.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    error_1 = _b.sent();
                    finalError = { message: t('Error uploading attachment'), name: 'Error' };
                    if (typeof error_1.message === 'string') {
                        finalError = error_1;
                    }
                    else if (typeof error_1 === 'object') {
                        finalError = Object.assign(finalError, error_1);
                    }
                    console.error(finalError);
                    addNotification(finalError.message, 'error');
                    failedAttachment = __assign(__assign({}, attachment), { localMetadata: __assign(__assign({}, localMetadata), { uploadState: 'failed' }) });
                    upsertAttachments([failedAttachment]);
                    if (errorHandler) {
                        errorHandler(finalError, 'upload-attachment', file);
                    }
                    return [2 /*return*/, failedAttachment];
                case 8:
                    if (!response) {
                        // Copied this from useImageUpload / useFileUpload. Not sure how failure could be handled on app level.
                        // If doUploadRequest returns any falsy value, then don't create the upload preview.
                        // This is for the case if someone wants to handle failure on app level.
                        removeAttachments([id]);
                        return [2 /*return*/];
                    }
                    uploadedAttachment = __assign(__assign({}, attachment), { localMetadata: __assign(__assign({}, localMetadata), { uploadState: 'finished' }) });
                    if (isLocalImageAttachment(uploadedAttachment)) {
                        if (uploadedAttachment.localMetadata.previewUri) {
                            URL.revokeObjectURL(uploadedAttachment.localMetadata.previewUri);
                            delete uploadedAttachment.localMetadata.previewUri;
                        }
                        uploadedAttachment.image_url = response.file;
                    }
                    else {
                        uploadedAttachment.asset_url = response.file;
                    }
                    upsertAttachments([uploadedAttachment]);
                    return [2 /*return*/, uploadedAttachment];
            }
        });
    }); }, [
        addNotification,
        channel,
        doFileUploadRequest,
        doImageUploadRequest,
        errorHandler,
        getAppSettings,
        removeAttachments,
        t,
        upsertAttachments,
    ]);
    return {
        maxFilesLeft: maxFilesLeft,
        numberOfUploads: numberOfUploads,
        removeAttachments: removeAttachments,
        removeFile: removeFile,
        removeImage: removeImage,
        uploadAttachment: uploadAttachment,
        uploadFile: uploadFile,
        uploadImage: uploadImage,
        uploadNewFiles: uploadNewFiles,
        upsertAttachments: upsertAttachments,
    };
};
