"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToken = void 0;
/* eslint-disable react-hooks/rules-of-hooks */
const react_1 = require("@gluestack-style/react");
const useToken = (tokenScale, token) => {
    return typeof token === 'string' || typeof token === 'number'
        ? (0, react_1.useToken)(tokenScale, String(token))
        : token &&
            token.map((singleToken) => (0, react_1.useToken)(tokenScale, singleToken));
};
exports.useToken = useToken;
