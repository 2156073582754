"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const styled_components_1 = require("../../Icons/styled-components");
exports.default = (0, react_1.styled)(styled_components_1.StyledIcon, {
    width: '$4',
    height: '$4',
    marginRight: '$2',
    _icon: {
        color: '$backgroundLight.500',
        _dark: {
            color: '$backgroundDark.400',
        },
    },
}, {
    descendantStyle: ['_icon'],
});
