import React, { useCallback, useEffect } from 'react';
export var useScrollToBottomOnNewMessage = function (_a) {
    var messages = _a.messages, scrollToBottom = _a.scrollToBottom, scrollToLatestMessageOnFocus = _a.scrollToLatestMessageOnFocus;
    var _b = React.useState(false), newMessagesReceivedInBackground = _b[0], setNewMessagesReceivedInBackground = _b[1];
    var resetNewMessagesReceivedInBackground = useCallback(function () {
        setNewMessagesReceivedInBackground(false);
    }, []);
    useEffect(function () {
        setNewMessagesReceivedInBackground(true);
    }, [messages]);
    var scrollToBottomIfConfigured = useCallback(function (event) {
        if (!scrollToLatestMessageOnFocus ||
            !newMessagesReceivedInBackground ||
            event.target !== window)
            return;
        setTimeout(scrollToBottom, 100);
    }, [scrollToLatestMessageOnFocus, scrollToBottom, newMessagesReceivedInBackground]);
    useEffect(function () {
        if (typeof window !== 'undefined') {
            window.addEventListener('focus', scrollToBottomIfConfigured);
            window.addEventListener('blur', resetNewMessagesReceivedInBackground);
        }
        return function () {
            window.removeEventListener('focus', scrollToBottomIfConfigured);
            window.removeEventListener('blur', resetNewMessagesReceivedInBackground);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollToBottomIfConfigured]);
};
