import React, { useEffect } from 'react';
import { LoadingIndicator } from '../Loading';
import { deprecationAndReplacementWarning } from '../../utils/deprecationWarning';
import { useTranslationContext } from '../../context';
var UnMemoizedLoadMoreButton = function (_a) {
    var children = _a.children, isLoading = _a.isLoading, onClick = _a.onClick, refreshing = _a.refreshing;
    var t = useTranslationContext('UnMemoizedLoadMoreButton').t;
    var childrenOrDefaultString = children !== null && children !== void 0 ? children : t('Load more');
    var loading = typeof isLoading !== 'undefined' ? isLoading : refreshing;
    useEffect(function () {
        deprecationAndReplacementWarning([[{ refreshing: refreshing }, { isLoading: isLoading }]], 'LoadMoreButton');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement("div", { className: 'str-chat__load-more-button' },
        React.createElement("button", { "aria-label": t('aria/Load More Channels'), className: 'str-chat__load-more-button__button str-chat__cta-button', "data-testid": 'load-more-button', disabled: loading, onClick: onClick }, loading ? React.createElement(LoadingIndicator, null) : childrenOrDefaultString)));
};
export var LoadMoreButton = React.memo(UnMemoizedLoadMoreButton);
