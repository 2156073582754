import { __awaiter, __generator } from "tslib";
import React, { Suspense } from 'react';
import { useChatContext } from '../../context/ChatContext';
var MMLReact = React.lazy(function () { return __awaiter(void 0, void 0, void 0, function () {
    var mml;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, import('mml-react')];
            case 1:
                mml = _a.sent();
                return [2 /*return*/, { default: mml.MML }];
        }
    });
}); });
/**
 * A wrapper component around MML-React library
 */
export var MML = function (props) {
    var actionHandler = props.actionHandler, _a = props.align, align = _a === void 0 ? 'right' : _a, source = props.source;
    var theme = useChatContext('MML').theme;
    return (React.createElement(Suspense, { fallback: null },
        React.createElement(MMLReact, { className: "mml-align-".concat(align), Loading: null, onSubmit: actionHandler, source: source, Success: null, theme: (theme || '').replace(' ', '-') })));
};
