import { __assign, __awaiter, __generator } from "tslib";
import { defaultPinPermissions, validateAndGetMessage } from '../utils';
import { useChannelActionContext } from '../../../context/ChannelActionContext';
import { useChannelStateContext } from '../../../context/ChannelStateContext';
import { useChatContext } from '../../../context/ChatContext';
import { useTranslationContext } from '../../../context/TranslationContext';
export var usePinHandler = function (message, 
// @deprecated in favor of `channelCapabilities` - TODO: remove in next major release
_permissions, // eslint-disable-line
notifications) {
    if (_permissions === void 0) { _permissions = defaultPinPermissions; }
    if (notifications === void 0) { notifications = {}; }
    var getErrorNotification = notifications.getErrorNotification, notify = notifications.notify;
    var updateMessage = useChannelActionContext('usePinHandler').updateMessage;
    var _a = useChannelStateContext('usePinHandler').channelCapabilities, channelCapabilities = _a === void 0 ? {} : _a;
    var client = useChatContext('usePinHandler').client;
    var t = useTranslationContext('usePinHandler').t;
    var canPin = !!channelCapabilities['pin-message'];
    var handlePin = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var optimisticMessage, e_1, errorMessage, optimisticMessage, e_2, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    if (!message)
                        return [2 /*return*/];
                    if (!!message.pinned) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    optimisticMessage = __assign(__assign({}, message), { pinned: true, pinned_at: new Date(), pinned_by: client.user });
                    updateMessage(optimisticMessage);
                    return [4 /*yield*/, client.pinMessage(message)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    errorMessage = getErrorNotification && validateAndGetMessage(getErrorNotification, [message]);
                    if (notify)
                        notify(errorMessage || t('Error pinning message'), 'error');
                    updateMessage(message);
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 8];
                case 5:
                    _a.trys.push([5, 7, , 8]);
                    optimisticMessage = __assign(__assign({}, message), { pin_expires: null, pinned: false, pinned_at: null, pinned_by: null });
                    updateMessage(optimisticMessage);
                    return [4 /*yield*/, client.unpinMessage(message)];
                case 6:
                    _a.sent();
                    return [3 /*break*/, 8];
                case 7:
                    e_2 = _a.sent();
                    errorMessage = getErrorNotification && validateAndGetMessage(getErrorNotification, [message]);
                    if (notify)
                        notify(errorMessage || t('Error removing message pin'), 'error');
                    updateMessage(message);
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    return { canPin: canPin, handlePin: handlePin };
};
