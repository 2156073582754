import { __assign } from "tslib";
import { useEffect, useRef } from 'react';
import { useChannelActionContext, useChannelStateContext, useChatContext, } from '../../../context';
/**
 * Takes care of marking a channel read. The channel is read only if all the following applies:
 * 1. the message list is not rendered in a thread
 * 2. the message list is scrolled to the bottom
 * 3. the channel was not marked unread by the user
 * @param isMessageListScrolledToBottom
 * @param messageListIsThread
 * @param unreadCount
 * @param wasChannelMarkedUnread
 */
export var useMarkRead = function (_a) {
    var isMessageListScrolledToBottom = _a.isMessageListScrolledToBottom, messageListIsThread = _a.messageListIsThread, unreadCount = _a.unreadCount, wasMarkedUnread = _a.wasMarkedUnread;
    var client = useChatContext('useMarkRead').client;
    var _b = useChannelActionContext('useMarkRead'), markRead = _b.markRead, setChannelUnreadUiState = _b.setChannelUnreadUiState;
    var channel = useChannelStateContext('useMarkRead').channel;
    var previousRenderMessageListScrolledToBottom = useRef(isMessageListScrolledToBottom);
    useEffect(function () {
        var shouldMarkRead = function (unreadMessages) {
            return !document.hidden &&
                !wasMarkedUnread &&
                !messageListIsThread &&
                isMessageListScrolledToBottom &&
                unreadMessages > 0;
        };
        var onVisibilityChange = function () {
            if (shouldMarkRead(channel.countUnread()))
                markRead();
        };
        var handleMessageNew = function (event) {
            var _a, _b, _c, _d;
            var newMessageToCurrentChannel = event.cid === channel.cid;
            var isOwnMessage = ((_a = event.user) === null || _a === void 0 ? void 0 : _a.id) && event.user.id === ((_b = client.user) === null || _b === void 0 ? void 0 : _b.id);
            var mainChannelUpdated = !((_c = event.message) === null || _c === void 0 ? void 0 : _c.parent_id) || ((_d = event.message) === null || _d === void 0 ? void 0 : _d.show_in_channel);
            if (isOwnMessage)
                return;
            if (!isMessageListScrolledToBottom || wasMarkedUnread || document.hidden) {
                setChannelUnreadUiState(function (prev) {
                    var _a, _b;
                    var previousUnreadCount = (_a = prev === null || prev === void 0 ? void 0 : prev.unread_messages) !== null && _a !== void 0 ? _a : 0;
                    var previousLastMessage = getPreviousLastMessage(channel.state.messages, event.message);
                    return __assign(__assign({}, (prev || {})), { last_read: (_b = prev === null || prev === void 0 ? void 0 : prev.last_read) !== null && _b !== void 0 ? _b : (previousUnreadCount === 0 && (previousLastMessage === null || previousLastMessage === void 0 ? void 0 : previousLastMessage.created_at)
                            ? new Date(previousLastMessage.created_at)
                            : new Date(0)), unread_messages: previousUnreadCount + 1 });
                });
            }
            else if (newMessageToCurrentChannel &&
                mainChannelUpdated &&
                shouldMarkRead(channel.countUnread())) {
                markRead();
            }
        };
        client.on('message.new', handleMessageNew);
        document.addEventListener('visibilitychange', onVisibilityChange);
        var hasScrolledToBottom = previousRenderMessageListScrolledToBottom.current !== isMessageListScrolledToBottom &&
            isMessageListScrolledToBottom;
        if (hasScrolledToBottom && shouldMarkRead(channel.countUnread()))
            markRead();
        previousRenderMessageListScrolledToBottom.current = isMessageListScrolledToBottom;
        return function () {
            client.off('message.new', handleMessageNew);
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, [
        channel,
        client,
        isMessageListScrolledToBottom,
        markRead,
        messageListIsThread,
        setChannelUnreadUiState,
        unreadCount,
        wasMarkedUnread,
    ]);
};
function getPreviousLastMessage(messages, newMessage) {
    if (!newMessage)
        return;
    var previousLastMessage;
    for (var i = messages.length - 1; i >= 0; i--) {
        var msg = messages[i];
        if (!(msg === null || msg === void 0 ? void 0 : msg.id))
            break;
        if (msg.id !== newMessage.id) {
            previousLastMessage = msg;
            break;
        }
    }
    return previousLastMessage;
}
