"use strict";
// @ts-nocheck
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconButton = void 0;
const react_1 = __importStar(require("react"));
const button_1 = require("@gluestack-ui/button");
const styled_components_1 = require("./styled-components");
const Icons_1 = require("../Icons");
const usePropResolution_1 = require("../../hooks/usePropResolution");
const AccessibleIconButton = (0, button_1.createButton)({
    Root: styled_components_1.Root,
    Text: styled_components_1.Text,
    Group: styled_components_1.Group,
    Spinner: styled_components_1.Spinner,
    Icon: styled_components_1.Icon,
});
const NewIconButton = (0, react_1.forwardRef)((_a, ref) => {
    var { children, isDisabled, icon, _icon, colorScheme = 'primary', variant = 'ghost' } = _a, props = __rest(_a, ["children", "isDisabled", "icon", "_icon", "colorScheme", "variant"]);
    const resolvedPropForGluestack = (0, usePropResolution_1.usePropResolution)(props);
    return (react_1.default.createElement(AccessibleIconButton, Object.assign({ colorScheme: colorScheme, variant: variant }, resolvedPropForGluestack, { ref: ref, isDisabled: isDisabled }), icon ? (react_1.default.createElement(Icons_1.Icon, Object.assign({ as: () => icon }, _icon))) : (react_1.default.createElement(Icons_1.Icon, Object.assign({}, _icon), children))));
});
const IconButtonTemp = NewIconButton;
exports.IconButton = IconButtonTemp;
