import { __spreadArray } from "tslib";
import { visit } from 'unist-util-visit';
import { u } from 'unist-builder';
var visitor = function (node, index, parent) {
    if (typeof index === 'undefined' || index === 0)
        return;
    if (typeof parent === 'undefined')
        return;
    if (!node.position)
        return;
    var prevSibling = parent.children.at(index - 1);
    if (!(prevSibling === null || prevSibling === void 0 ? void 0 : prevSibling.position))
        return;
    if (node.position.start.line === prevSibling.position.start.line)
        return;
    var ownStartLine = node.position.start.line;
    var prevEndLine = prevSibling.position.end.line;
    // the -1 is adjustment for the single line break into which multiple line breaks are converted
    var countTruncatedLineBreaks = ownStartLine - prevEndLine - 1;
    if (countTruncatedLineBreaks < 1)
        return;
    var lineBreaks = Array.from({ length: countTruncatedLineBreaks }, function () {
        return u('break', { tagName: 'br' });
    });
    parent.children = __spreadArray(__spreadArray(__spreadArray([], parent.children.slice(0, index), true), lineBreaks, true), parent.children.slice(index), true);
    return;
};
var transform = function (tree) {
    visit(tree, visitor);
};
export var keepLineBreaksPlugin = function () { return transform; };
