import React, { useMemo } from 'react';
import { useMessageContext } from '../../context/MessageContext';
import { isDate, useTranslationContext } from '../../context/TranslationContext';
import { getDateString } from '../../i18n/utils';
export var defaultTimestampFormat = 'h:mmA';
export function Timestamp(props) {
    var calendar = props.calendar, calendarFormats = props.calendarFormats, customClass = props.customClass, _a = props.format, format = _a === void 0 ? defaultTimestampFormat : _a, timestamp = props.timestamp;
    var formatDate = useMessageContext('MessageTimestamp').formatDate;
    var _b = useTranslationContext('MessageTimestamp'), t = _b.t, tDateTimeParser = _b.tDateTimeParser;
    var normalizedTimestamp = timestamp && isDate(timestamp) ? timestamp.toISOString() : timestamp;
    var when = useMemo(function () {
        return getDateString({
            calendar: calendar,
            calendarFormats: calendarFormats,
            format: format,
            formatDate: formatDate,
            messageCreatedAt: normalizedTimestamp,
            t: t,
            tDateTimeParser: tDateTimeParser,
            timestampTranslationKey: 'timestamp/MessageTimestamp',
        });
    }, [calendar, calendarFormats, format, formatDate, normalizedTimestamp, t, tDateTimeParser]);
    if (!when) {
        return null;
    }
    return (React.createElement("time", { className: customClass, dateTime: normalizedTimestamp, title: normalizedTimestamp }, when));
}
