import { __assign, __rest } from "tslib";
import { isDate, isDayOrMoment, isNumberOrString, } from '../context';
export var notValidDateWarning = 'MessageTimestamp was called without a message, or message has invalid created_at date.';
export var noParsingFunctionWarning = 'MessageTimestamp was called but there is no datetime parsing function available';
export function getDateString(_a) {
    var calendar = _a.calendar, calendarFormats = _a.calendarFormats, format = _a.format, formatDate = _a.formatDate, messageCreatedAt = _a.messageCreatedAt, t = _a.t, tDateTimeParser = _a.tDateTimeParser, timestampTranslationKey = _a.timestampTranslationKey;
    if (!messageCreatedAt ||
        (typeof messageCreatedAt === 'string' && !Date.parse(messageCreatedAt))) {
        console.warn(notValidDateWarning);
        return null;
    }
    if (typeof formatDate === 'function') {
        return formatDate(new Date(messageCreatedAt));
    }
    if (t && timestampTranslationKey) {
        var options = {};
        if (typeof calendar !== 'undefined' && calendar !== null)
            options.calendar = calendar;
        if (typeof calendarFormats !== 'undefined' && calendarFormats !== null)
            options.calendarFormats = calendarFormats;
        if (typeof format !== 'undefined' && format !== null)
            options.format = format;
        var translatedTimestamp = t(timestampTranslationKey, __assign(__assign({}, options), { timestamp: new Date(messageCreatedAt) }));
        var translationKeyFound = timestampTranslationKey !== translatedTimestamp;
        if (translationKeyFound)
            return translatedTimestamp;
    }
    if (!tDateTimeParser) {
        console.warn(noParsingFunctionWarning);
        return null;
    }
    var parsedTime = tDateTimeParser(messageCreatedAt);
    if (isDayOrMoment(parsedTime)) {
        /**
         * parsedTime.calendar is guaranteed on the type but is only
         * available when a user calls dayjs.extend(calendar)
         */
        return calendar && parsedTime.calendar
            ? parsedTime.calendar(undefined, calendarFormats || undefined)
            : parsedTime.format(format || undefined);
    }
    if (isDate(parsedTime)) {
        return parsedTime.toDateString();
    }
    if (isNumberOrString(parsedTime)) {
        return parsedTime;
    }
    return null;
}
export var predefinedFormatters = {
    timestampFormatter: function (streamI18n) { return function (value, _, _a) {
        var calendarFormats = _a.calendarFormats, options = __rest(_a, ["calendarFormats"]);
        var parsedCalendarFormats;
        try {
            if (!options.calendar) {
                parsedCalendarFormats = {};
            }
            else if (typeof calendarFormats === 'string') {
                parsedCalendarFormats = JSON.parse(calendarFormats);
            }
            else if (typeof calendarFormats === 'object') {
                parsedCalendarFormats = calendarFormats;
            }
        }
        catch (e) {
            console.error('[TIMESTAMP FORMATTER]', e);
        }
        var result = getDateString(__assign(__assign({}, options), { calendarFormats: parsedCalendarFormats, messageCreatedAt: value, tDateTimeParser: streamI18n.tDateTimeParser }));
        if (!result || typeof result === 'number') {
            return JSON.stringify(value);
        }
        return result;
    }; },
};
