"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.View, {
    alignItems: 'center',
    justifyContent: 'center',
    variants: {
        size: {
            'xs': {
                height: 10,
                width: 10,
            },
            'sm': {
                height: 12,
                width: 12,
            },
            'md': {
                height: 16,
                width: 16,
            },
            'lg': {
                height: 24,
                width: 24,
            },
            'xl': {
                height: 32,
                width: 32,
            },
            '2xl': {
                height: 40,
                width: 40,
            },
        },
    },
    // defaultProps: { size: 'lg' },
}, {
    componentName: 'Square',
    descendantStyle: ['_text'],
    resolveProps: ['colors'],
}, {
    propertyTokenMap: {
        colors: 'colors',
    },
    propertyResolver: {
        colors: (rawValue, resolver) => {
            rawValue.forEach((color, index) => {
                rawValue[index] = resolver(`$${color}`);
            });
            return rawValue;
        },
    },
});
