import { __assign, __awaiter, __generator, __rest } from "tslib";
import React from 'react';
import { useEnrichedMessages, useMessageListElements, useScrollLocationLogic, useUnreadMessagesNotification, } from './hooks/MessageList';
import { useMarkRead } from './hooks/useMarkRead';
import { MessageNotification as DefaultMessageNotification } from './MessageNotification';
import { MessageListNotifications as DefaultMessageListNotifications } from './MessageListNotifications';
import { UnreadMessagesNotification as DefaultUnreadMessagesNotification } from './UnreadMessagesNotification';
import { useChannelActionContext, } from '../../context/ChannelActionContext';
import { useChannelStateContext, } from '../../context/ChannelStateContext';
import { useChatContext } from '../../context/ChatContext';
import { useComponentContext } from '../../context/ComponentContext';
import { MessageListContextProvider } from '../../context/MessageListContext';
import { EmptyStateIndicator as DefaultEmptyStateIndicator } from '../EmptyStateIndicator';
import { InfiniteScroll } from '../InfiniteScrollPaginator/InfiniteScroll';
import { LoadingIndicator as DefaultLoadingIndicator } from '../Loading';
import { defaultPinPermissions, MESSAGE_ACTIONS } from '../Message/utils';
import { TypingIndicator as DefaultTypingIndicator } from '../TypingIndicator';
import { MessageListMainPanel } from './MessageListMainPanel';
import { defaultRenderMessages } from './renderMessages';
import { DEFAULT_LOAD_PAGE_SCROLL_THRESHOLD, DEFAULT_NEXT_CHANNEL_PAGE_SIZE, } from '../../constants/limits';
var MessageListWithContext = function (props) {
    var _a;
    var channel = props.channel, channelUnreadUiState = props.channelUnreadUiState, _b = props.disableDateSeparator, disableDateSeparator = _b === void 0 ? false : _b, groupStyles = props.groupStyles, _c = props.hideDeletedMessages, hideDeletedMessages = _c === void 0 ? false : _c, _d = props.hideNewMessageSeparator, hideNewMessageSeparator = _d === void 0 ? false : _d, _e = props.internalInfiniteScrollProps, _f = _e === void 0 ? {} : _e, _g = _f.threshold, loadMoreScrollThreshold = _g === void 0 ? DEFAULT_LOAD_PAGE_SCROLL_THRESHOLD : _g, restInternalInfiniteScrollProps = __rest(_f, ["threshold"]), maxTimeBetweenGroupedMessages = props.maxTimeBetweenGroupedMessages, _h = props.messageActions, messageActions = _h === void 0 ? Object.keys(MESSAGE_ACTIONS) : _h, _j = props.messages, messages = _j === void 0 ? [] : _j, notifications = props.notifications, _k = props.noGroupByUser, noGroupByUser = _k === void 0 ? false : _k, _l = props.pinPermissions, pinPermissions = _l === void 0 ? defaultPinPermissions : _l, // @deprecated in favor of `channelCapabilities` - TODO: remove in next major release
    _m = props.returnAllReadData, // @deprecated in favor of `channelCapabilities` - TODO: remove in next major release
    returnAllReadData = _m === void 0 ? false : _m, _o = props.threadList, threadList = _o === void 0 ? false : _o, _p = props.unsafeHTML, unsafeHTML = _p === void 0 ? false : _p, headerPosition = props.headerPosition, read = props.read, _q = props.renderMessages, renderMessages = _q === void 0 ? defaultRenderMessages : _q, reviewProcessedMessage = props.reviewProcessedMessage, _r = props.messageLimit, messageLimit = _r === void 0 ? DEFAULT_NEXT_CHANNEL_PAGE_SIZE : _r, loadMoreCallback = props.loadMore, loadMoreNewerCallback = props.loadMoreNewer, _s = props.hasMoreNewer, hasMoreNewer = _s === void 0 ? false : _s, reactionDetailsSort = props.reactionDetailsSort, showUnreadNotificationAlways = props.showUnreadNotificationAlways, sortReactionDetails = props.sortReactionDetails, sortReactions = props.sortReactions, suppressAutoscroll = props.suppressAutoscroll, highlightedMessageId = props.highlightedMessageId, _t = props.jumpToLatestMessage, jumpToLatestMessage = _t === void 0 ? function () { return Promise.resolve(); } : _t;
    var _u = React.useState(null), listElement = _u[0], setListElement = _u[1];
    var _v = React.useState(null), ulElement = _v[0], setUlElement = _v[1];
    var customClasses = useChatContext('MessageList').customClasses;
    var _w = useComponentContext('MessageList'), _x = _w.EmptyStateIndicator, EmptyStateIndicator = _x === void 0 ? DefaultEmptyStateIndicator : _x, _y = _w.LoadingIndicator, LoadingIndicator = _y === void 0 ? DefaultLoadingIndicator : _y, _z = _w.MessageListNotifications, MessageListNotifications = _z === void 0 ? DefaultMessageListNotifications : _z, _0 = _w.MessageNotification, MessageNotification = _0 === void 0 ? DefaultMessageNotification : _0, _1 = _w.TypingIndicator, TypingIndicator = _1 === void 0 ? DefaultTypingIndicator : _1, _2 = _w.UnreadMessagesNotification, UnreadMessagesNotification = _2 === void 0 ? DefaultUnreadMessagesNotification : _2;
    var _3 = useScrollLocationLogic({
        hasMoreNewer: hasMoreNewer,
        listElement: listElement,
        loadMoreScrollThreshold: loadMoreScrollThreshold,
        messages: messages,
        scrolledUpThreshold: props.scrolledUpThreshold,
        suppressAutoscroll: suppressAutoscroll,
    }), hasNewMessages = _3.hasNewMessages, isMessageListScrolledToBottom = _3.isMessageListScrolledToBottom, onScroll = _3.onScroll, scrollToBottom = _3.scrollToBottom, wrapperRect = _3.wrapperRect;
    var showUnreadMessagesNotification = useUnreadMessagesNotification({
        isMessageListScrolledToBottom: isMessageListScrolledToBottom,
        showAlways: !!showUnreadNotificationAlways,
        unreadCount: channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages,
    }).show;
    useMarkRead({
        isMessageListScrolledToBottom: isMessageListScrolledToBottom,
        messageListIsThread: threadList,
        unreadCount: (_a = channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages) !== null && _a !== void 0 ? _a : 0,
        wasMarkedUnread: !!(channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.first_unread_message_id),
    });
    var _4 = useEnrichedMessages({
        channel: channel,
        disableDateSeparator: disableDateSeparator,
        groupStyles: groupStyles,
        headerPosition: headerPosition,
        hideDeletedMessages: hideDeletedMessages,
        hideNewMessageSeparator: hideNewMessageSeparator,
        maxTimeBetweenGroupedMessages: maxTimeBetweenGroupedMessages,
        messages: messages,
        noGroupByUser: noGroupByUser,
        reviewProcessedMessage: reviewProcessedMessage,
    }), messageGroupStyles = _4.messageGroupStyles, enrichedMessages = _4.messages;
    var elements = useMessageListElements({
        channelUnreadUiState: channelUnreadUiState,
        enrichedMessages: enrichedMessages,
        internalMessageProps: {
            additionalMessageInputProps: props.additionalMessageInputProps,
            closeReactionSelectorOnClick: props.closeReactionSelectorOnClick,
            customMessageActions: props.customMessageActions,
            disableQuotedMessages: props.disableQuotedMessages,
            formatDate: props.formatDate,
            getDeleteMessageErrorNotification: props.getDeleteMessageErrorNotification,
            getFlagMessageErrorNotification: props.getFlagMessageErrorNotification,
            getFlagMessageSuccessNotification: props.getFlagMessageSuccessNotification,
            getMarkMessageUnreadErrorNotification: props.getMarkMessageUnreadErrorNotification,
            getMarkMessageUnreadSuccessNotification: props.getMarkMessageUnreadSuccessNotification,
            getMuteUserErrorNotification: props.getMuteUserErrorNotification,
            getMuteUserSuccessNotification: props.getMuteUserSuccessNotification,
            getPinMessageErrorNotification: props.getPinMessageErrorNotification,
            Message: props.Message,
            messageActions: messageActions,
            messageListRect: wrapperRect,
            onlySenderCanEdit: props.onlySenderCanEdit,
            onMentionsClick: props.onMentionsClick,
            onMentionsHover: props.onMentionsHover,
            onUserClick: props.onUserClick,
            onUserHover: props.onUserHover,
            openThread: props.openThread,
            pinPermissions: pinPermissions,
            reactionDetailsSort: reactionDetailsSort,
            renderText: props.renderText,
            retrySendMessage: props.retrySendMessage,
            sortReactionDetails: sortReactionDetails,
            sortReactions: sortReactions,
            unsafeHTML: unsafeHTML,
        },
        messageGroupStyles: messageGroupStyles,
        read: read,
        renderMessages: renderMessages,
        returnAllReadData: returnAllReadData,
        threadList: threadList,
    });
    var messageListClass = (customClasses === null || customClasses === void 0 ? void 0 : customClasses.messageList) || 'str-chat__list';
    var threadListClass = threadList
        ? (customClasses === null || customClasses === void 0 ? void 0 : customClasses.threadList) || 'str-chat__list--thread str-chat__thread-list'
        : '';
    var loadMore = React.useCallback(function () {
        if (loadMoreCallback) {
            loadMoreCallback(messageLimit);
        }
    }, [loadMoreCallback, messageLimit]);
    var loadMoreNewer = React.useCallback(function () {
        if (loadMoreNewerCallback) {
            loadMoreNewerCallback(messageLimit);
        }
    }, [loadMoreNewerCallback, messageLimit]);
    var scrollToBottomFromNotification = React.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!hasMoreNewer) return [3 /*break*/, 2];
                    return [4 /*yield*/, jumpToLatestMessage()];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    scrollToBottom();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [scrollToBottom, hasMoreNewer]);
    React.useLayoutEffect(function () {
        if (highlightedMessageId) {
            var element = ulElement === null || ulElement === void 0 ? void 0 : ulElement.querySelector("[data-message-id='".concat(highlightedMessageId, "']"));
            element === null || element === void 0 ? void 0 : element.scrollIntoView({ block: 'center' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [highlightedMessageId]);
    var showEmptyStateIndicator = elements.length === 0 && !threadList;
    return (React.createElement(MessageListContextProvider, { value: { listElement: listElement, scrollToBottom: scrollToBottom } },
        React.createElement(MessageListMainPanel, null,
            !threadList && showUnreadMessagesNotification && (React.createElement(UnreadMessagesNotification, { unreadCount: channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages })),
            React.createElement("div", { className: "".concat(messageListClass, " ").concat(threadListClass), onScroll: onScroll, ref: setListElement, tabIndex: 0 }, showEmptyStateIndicator ? (React.createElement(EmptyStateIndicator, { key: 'empty-state-indicator', listType: threadList ? 'thread' : 'message' })) : (React.createElement(InfiniteScroll, __assign({ className: 'str-chat__reverse-infinite-scroll  str-chat__message-list-scroll', "data-testid": 'reverse-infinite-scroll', hasNextPage: props.hasMoreNewer, hasPreviousPage: props.hasMore, head: props.head, isLoading: props.loadingMore, loader: React.createElement("div", { className: 'str-chat__list__loading', key: 'loading-indicator' }, props.loadingMore && React.createElement(LoadingIndicator, { size: 20 })), loadNextPage: loadMoreNewer, loadPreviousPage: loadMore, threshold: loadMoreScrollThreshold }, restInternalInfiniteScrollProps),
                React.createElement("ul", { className: 'str-chat__ul', ref: setUlElement }, elements),
                React.createElement(TypingIndicator, { threadList: threadList }),
                React.createElement("div", { key: 'bottom' }))))),
        React.createElement(MessageListNotifications, { hasNewMessages: hasNewMessages, isMessageListScrolledToBottom: isMessageListScrolledToBottom, isNotAtLatestMessageSet: hasMoreNewer, MessageNotification: MessageNotification, notifications: notifications, scrollToBottom: scrollToBottomFromNotification, threadList: threadList, unreadCount: threadList ? undefined : channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages })));
};
/**
 * The MessageList component renders a list of Messages.
 * It is a consumer of the following contexts:
 * - [ChannelStateContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_state_context/)
 * - [ChannelActionContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_action_context/)
 * - [ComponentContext](https://getstream.io/chat/docs/sdk/react/contexts/component_context/)
 * - [TypingContext](https://getstream.io/chat/docs/sdk/react/contexts/typing_context/)
 */
export var MessageList = function (props) {
    var _a = useChannelActionContext('MessageList'), jumpToLatestMessage = _a.jumpToLatestMessage, loadMore = _a.loadMore, loadMoreNewer = _a.loadMoreNewer;
    var _b = useChannelStateContext('MessageList'), membersPropToNotPass = _b.members, // eslint-disable-line @typescript-eslint/no-unused-vars
    mutesPropToNotPass = _b.mutes, // eslint-disable-line @typescript-eslint/no-unused-vars
    watchersPropToNotPass = _b.watchers, // eslint-disable-line @typescript-eslint/no-unused-vars
    restChannelStateContext = __rest(_b, ["members", "mutes", "watchers"]);
    return (React.createElement(MessageListWithContext, __assign({ jumpToLatestMessage: jumpToLatestMessage, loadMore: loadMore, loadMoreNewer: loadMoreNewer }, restChannelStateContext, props)));
};
