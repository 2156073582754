"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const animation_resolver_1 = require("@gluestack-style/animation-resolver");
const legend_motion_animation_driver_1 = require("@gluestack-style/legend-motion-animation-driver");
exports.default = (0, react_1.styled)(animation_resolver_1.AnimatedView, {
    overflow: 'hidden',
    alignItems: 'center',
    // @ts-ignore
    borderTopLeftRadius: '$3xl',
    // @ts-ignore
    borderTopRightRadius: '$3xl',
    height: '100%',
    padding: '$2',
    backgroundColor: '$backgroundLight.0',
    _sectionHeaderBackground: {
        backgroundColor: '$backgroundLight.0',
    },
    _dark: {
        backgroundColor: '$backgroundDark.900',
        _sectionHeaderBackground: {
            backgroundColor: '$backgroundDark.900',
        },
    },
    _web: {
        userSelect: 'none',
    },
    defaultProps: {
        hardShadow: '5',
    },
}, {
    componentName: 'ActionsheetContent',
    descendantStyle: ['_sectionHeaderBackground'],
}, {
    plugins: [new animation_resolver_1.AnimationResolver(legend_motion_animation_driver_1.MotionAnimationDriver)],
});
