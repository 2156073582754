import { visit } from 'unist-util-visit';
var visitor = function (node) {
    if (node.type !== 'html')
        return;
    node.type = 'text';
};
var transform = function (tree) {
    visit(tree, visitor);
};
export var htmlToTextPlugin = function () { return transform; };
