import { __assign, __spreadArray } from "tslib";
import React from 'react';
import ReactMarkdown, { defaultUrlTransform } from 'react-markdown';
import { find } from 'linkifyjs';
import uniqBy from 'lodash.uniqby';
import remarkGfm from 'remark-gfm';
import { Anchor, Emoji, Mention } from './componentRenderers';
import { detectHttp, escapeRegExp, matchMarkdownLinks, messageCodeBlocks } from './regex';
import { emojiMarkdownPlugin, mentionsMarkdownPlugin } from './rehypePlugins';
import { htmlToTextPlugin, keepLineBreaksPlugin } from './remarkPlugins';
export var defaultAllowedTagNames = [
    'html',
    'text',
    'br',
    'p',
    'em',
    'strong',
    'a',
    'ol',
    'ul',
    'li',
    'code',
    'pre',
    'blockquote',
    'del',
    // custom types (tagNames)
    'emoji',
    'mention',
];
function formatUrlForDisplay(url) {
    try {
        return decodeURIComponent(url).replace(detectHttp, '');
    }
    catch (e) {
        return url;
    }
}
function encodeDecode(url) {
    try {
        return encodeURI(decodeURIComponent(url));
    }
    catch (error) {
        return url;
    }
}
var urlTransform = function (uri) { return (uri.startsWith('app://') ? uri : defaultUrlTransform(uri)); };
var getPluginsForward = function (plugins) { return plugins; };
export var markDownRenderers = {
    a: Anchor,
    emoji: Emoji,
    mention: Mention,
};
export var renderText = function (text, mentionedUsers, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.allowedTagNames, allowedTagNames = _c === void 0 ? defaultAllowedTagNames : _c, customMarkDownRenderers = _b.customMarkDownRenderers, _d = _b.getRehypePlugins, getRehypePlugins = _d === void 0 ? getPluginsForward : _d, _e = _b.getRemarkPlugins, getRemarkPlugins = _e === void 0 ? getPluginsForward : _e;
    // take the @ mentions and turn them into markdown?
    // translate links
    if (!text)
        return null;
    if (text.trim().length === 1)
        return React.createElement(React.Fragment, null, text);
    var newText = text;
    var markdownLinks = matchMarkdownLinks(newText);
    var codeBlocks = messageCodeBlocks(newText);
    // extract all valid links/emails within text and replace it with proper markup
    uniqBy(__spreadArray(__spreadArray([], find(newText, 'email'), true), find(newText, 'url'), true), 'value').forEach(function (_a) {
        var href = _a.href, type = _a.type, value = _a.value;
        var linkIsInBlock = codeBlocks.some(function (block) { return block === null || block === void 0 ? void 0 : block.includes(value); });
        // check if message is already  markdown
        var noParsingNeeded = markdownLinks &&
            markdownLinks.filter(function (text) {
                var strippedHref = href === null || href === void 0 ? void 0 : href.replace(detectHttp, '');
                var strippedText = text === null || text === void 0 ? void 0 : text.replace(detectHttp, '');
                if (!strippedHref || !strippedText)
                    return false;
                return strippedHref.includes(strippedText) || strippedText.includes(strippedHref);
            });
        if (noParsingNeeded.length > 0 || linkIsInBlock)
            return;
        try {
            // special case for mentions:
            // it could happen that a user's name matches with an e-mail format pattern.
            // in that case, we check whether the found e-mail is actually a mention
            // by naively checking for an existence of @ sign in front of it.
            if (type === 'email' && mentionedUsers) {
                var emailMatchesWithName = mentionedUsers.some(function (u) { return u.name === value; });
                if (emailMatchesWithName) {
                    newText = newText.replace(new RegExp(escapeRegExp(value), 'g'), function (match, position) {
                        var isMention = newText.charAt(position - 1) === '@';
                        // in case of mention, we leave the match in its original form,
                        // and we let `mentionsMarkdownPlugin` to do its job
                        return isMention ? match : "[".concat(match, "](").concat(encodeDecode(href), ")");
                    });
                    return;
                }
            }
            var displayLink = type === 'email' ? value : formatUrlForDisplay(href);
            newText = newText.replace(new RegExp(escapeRegExp(value), 'g'), "[".concat(displayLink, "](").concat(encodeDecode(href), ")"));
        }
        catch (e) {
            void e;
        }
    });
    var remarkPlugins = [
        htmlToTextPlugin,
        keepLineBreaksPlugin,
        [remarkGfm, { singleTilde: false }],
    ];
    var rehypePlugins = [emojiMarkdownPlugin];
    if (mentionedUsers === null || mentionedUsers === void 0 ? void 0 : mentionedUsers.length) {
        rehypePlugins.push(mentionsMarkdownPlugin(mentionedUsers));
    }
    return (React.createElement(ReactMarkdown, { allowedElements: allowedTagNames, components: __assign(__assign({}, markDownRenderers), customMarkDownRenderers), rehypePlugins: getRehypePlugins(rehypePlugins), remarkPlugins: getRemarkPlugins(remarkPlugins), skipHtml: true, unwrapDisallowed: true, urlTransform: urlTransform }, newText));
};
