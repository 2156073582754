import { __assign } from "tslib";
import React, { useState } from 'react';
import clsx from 'clsx';
import { MessageErrorIcon } from './icons';
import { MessageBouncePrompt as DefaultMessageBouncePrompt } from '../MessageBounce';
import { MessageDeleted as DefaultMessageDeleted } from './MessageDeleted';
import { MessageOptions as DefaultMessageOptions } from './MessageOptions';
import { MessageRepliesCountButton as DefaultMessageRepliesCountButton } from './MessageRepliesCountButton';
import { MessageStatus as DefaultMessageStatus } from './MessageStatus';
import { MessageText } from './MessageText';
import { MessageTimestamp as DefaultMessageTimestamp } from './MessageTimestamp';
import { areMessageUIPropsEqual, isMessageBounced, isMessageEdited, messageHasAttachments, messageHasReactions, } from './utils';
import { Avatar as DefaultAvatar } from '../Avatar';
import { CUSTOM_MESSAGE_TYPE } from '../../constants/messageTypes';
import { EditMessageForm as DefaultEditMessageForm, MessageInput } from '../MessageInput';
import { MML } from '../MML';
import { Modal } from '../Modal';
import { ReactionsList as DefaultReactionList, ReactionSelector as DefaultReactionSelector, } from '../Reactions';
import { MessageBounceModal } from '../MessageBounce/MessageBounceModal';
import { useChatContext } from '../../context/ChatContext';
import { useComponentContext } from '../../context/ComponentContext';
import { useMessageContext } from '../../context/MessageContext';
import { useTranslationContext } from '../../context';
import { MessageEditedTimestamp } from './MessageEditedTimestamp';
var MessageSimpleWithContext = function (props) {
    var _a;
    var additionalMessageInputProps = props.additionalMessageInputProps, clearEditingState = props.clearEditingState, editing = props.editing, endOfGroup = props.endOfGroup, firstOfGroup = props.firstOfGroup, groupedByUser = props.groupedByUser, handleAction = props.handleAction, handleOpenThread = props.handleOpenThread, handleRetry = props.handleRetry, highlighted = props.highlighted, isMyMessage = props.isMyMessage, isReactionEnabled = props.isReactionEnabled, message = props.message, onUserClick = props.onUserClick, onUserHover = props.onUserHover, reactionSelectorRef = props.reactionSelectorRef, renderText = props.renderText, showDetailedReactions = props.showDetailedReactions, threadList = props.threadList;
    var t = useTranslationContext('MessageSimple').t;
    var _b = useState(false), isBounceDialogOpen = _b[0], setIsBounceDialogOpen = _b[1];
    var _c = useState(false), isEditedTimestampOpen = _c[0], setEditedTimestampOpen = _c[1];
    var _d = useComponentContext('MessageSimple'), Attachment = _d.Attachment, _e = _d.Avatar, Avatar = _e === void 0 ? DefaultAvatar : _e, _f = _d.EditMessageInput, EditMessageInput = _f === void 0 ? DefaultEditMessageForm : _f, _g = _d.MessageDeleted, MessageDeleted = _g === void 0 ? DefaultMessageDeleted : _g, _h = _d.MessageBouncePrompt, MessageBouncePrompt = _h === void 0 ? DefaultMessageBouncePrompt : _h, _j = _d.MessageOptions, MessageOptions = _j === void 0 ? DefaultMessageOptions : _j, _k = _d.MessageRepliesCountButton, MessageRepliesCountButton = _k === void 0 ? DefaultMessageRepliesCountButton : _k, _l = _d.MessageStatus, MessageStatus = _l === void 0 ? DefaultMessageStatus : _l, _m = _d.MessageTimestamp, MessageTimestamp = _m === void 0 ? DefaultMessageTimestamp : _m, _o = _d.ReactionSelector, ReactionSelector = _o === void 0 ? DefaultReactionSelector : _o, _p = _d.ReactionsList, ReactionsList = _p === void 0 ? DefaultReactionList : _p, PinIndicator = _d.PinIndicator;
    var themeVersion = useChatContext('MessageSimple').themeVersion;
    var hasAttachment = messageHasAttachments(message);
    var hasReactions = messageHasReactions(message);
    if (message.customType === CUSTOM_MESSAGE_TYPE.date) {
        return null;
    }
    if (message.deleted_at || message.type === 'deleted') {
        return React.createElement(MessageDeleted, { message: message });
    }
    /** FIXME: isReactionEnabled should be removed with next major version and a proper centralized permissions logic should be put in place
     * With the current permissions implementation it would be sth like:
     * const messageActions = getMessageActions();
     * const canReact = messageActions.includes(MESSAGE_ACTIONS.react);
     */
    var canReact = isReactionEnabled;
    var canShowReactions = hasReactions;
    var showMetadata = !groupedByUser || endOfGroup;
    var showReplyCountButton = !threadList && !!message.reply_count;
    var allowRetry = message.status === 'failed' && message.errorStatusCode !== 403;
    var isBounced = isMessageBounced(message);
    var isEdited = isMessageEdited(message);
    var handleClick = undefined;
    if (allowRetry) {
        handleClick = function () { return handleRetry(message); };
    }
    else if (isBounced) {
        handleClick = function () { return setIsBounceDialogOpen(true); };
    }
    else if (isEdited) {
        handleClick = function () { return setEditedTimestampOpen(function (prev) { return !prev; }); };
    }
    var rootClassName = clsx('str-chat__message str-chat__message-simple', "str-chat__message--".concat(message.type), "str-chat__message--".concat(message.status), isMyMessage()
        ? 'str-chat__message--me str-chat__message-simple--me'
        : 'str-chat__message--other', message.text ? 'str-chat__message--has-text' : 'has-no-text', {
        'str-chat__message--has-attachment': hasAttachment,
        'str-chat__message--highlighted': highlighted,
        'str-chat__message--pinned pinned-message': message.pinned,
        'str-chat__message--with-reactions': canShowReactions,
        'str-chat__message-send-can-be-retried': (message === null || message === void 0 ? void 0 : message.status) === 'failed' && (message === null || message === void 0 ? void 0 : message.errorStatusCode) !== 403,
        'str-chat__message-with-thread-link': showReplyCountButton,
        'str-chat__virtual-message__wrapper--end': endOfGroup,
        'str-chat__virtual-message__wrapper--first': firstOfGroup,
        'str-chat__virtual-message__wrapper--group': groupedByUser,
    });
    return (React.createElement(React.Fragment, null,
        editing && (React.createElement(Modal, { className: 'str-chat__edit-message-modal', onClose: clearEditingState, open: editing },
            React.createElement(MessageInput, __assign({ clearEditingState: clearEditingState, grow: true, hideSendButton: true, Input: EditMessageInput, message: message }, additionalMessageInputProps)))),
        isBounceDialogOpen && (React.createElement(MessageBounceModal, { MessageBouncePrompt: MessageBouncePrompt, onClose: function () { return setIsBounceDialogOpen(false); }, open: isBounceDialogOpen })),
        React.createElement("div", { className: rootClassName, key: message.id },
            PinIndicator && React.createElement(PinIndicator, null),
            themeVersion === '1' && React.createElement(MessageStatus, null),
            message.user && (React.createElement(Avatar, { image: message.user.image, name: message.user.name || message.user.id, onClick: onUserClick, onMouseOver: onUserHover, user: message.user })),
            React.createElement("div", { className: clsx('str-chat__message-inner', {
                    'str-chat__simple-message--error-failed': allowRetry || isBounced,
                }), "data-testid": 'message-inner', onClick: handleClick, onKeyUp: handleClick },
                React.createElement(MessageOptions, null),
                React.createElement("div", { className: 'str-chat__message-reactions-host' },
                    canShowReactions && React.createElement(ReactionsList, { reverse: true }),
                    showDetailedReactions && canReact && React.createElement(ReactionSelector, { ref: reactionSelectorRef })),
                React.createElement("div", { className: 'str-chat__message-bubble' },
                    ((_a = message.attachments) === null || _a === void 0 ? void 0 : _a.length) && !message.quoted_message ? (React.createElement(Attachment, { actionHandler: handleAction, attachments: message.attachments })) : null,
                    React.createElement(MessageText, { message: message, renderText: renderText }),
                    message.mml && (React.createElement(MML, { actionHandler: handleAction, align: isMyMessage() ? 'right' : 'left', source: message.mml })),
                    themeVersion === '2' && React.createElement(MessageErrorIcon, null)),
                showReplyCountButton && themeVersion === '1' && (React.createElement(MessageRepliesCountButton, { onClick: handleOpenThread, reply_count: message.reply_count })),
                showMetadata && themeVersion === '1' && (React.createElement("div", { className: 'str-chat__message-data str-chat__message-simple-data' },
                    !isMyMessage() && message.user ? (React.createElement("span", { className: 'str-chat__message-simple-name' }, message.user.name || message.user.id)) : null,
                    React.createElement(MessageTimestamp, { calendar: true, customClass: 'str-chat__message-simple-timestamp' })))),
            showReplyCountButton && themeVersion === '2' && (React.createElement(MessageRepliesCountButton, { onClick: handleOpenThread, reply_count: message.reply_count })),
            showMetadata && themeVersion === '2' && (React.createElement("div", { className: 'str-chat__message-data str-chat__message-simple-data str-chat__message-metadata' },
                React.createElement(MessageStatus, null),
                !isMyMessage() && !!message.user && (React.createElement("span", { className: 'str-chat__message-simple-name' }, message.user.name || message.user.id)),
                React.createElement(MessageTimestamp, { calendar: true, customClass: 'str-chat__message-simple-timestamp' }),
                isEdited && (React.createElement("span", { className: 'str-chat__mesage-simple-edited' }, t('Edited'))),
                isEdited && React.createElement(MessageEditedTimestamp, { calendar: true, open: isEditedTimestampOpen }))))));
};
var MemoizedMessageSimple = React.memo(MessageSimpleWithContext, areMessageUIPropsEqual);
/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
export var MessageSimple = function (props) {
    var messageContext = useMessageContext('MessageSimple');
    return React.createElement(MemoizedMessageSimple, __assign({}, messageContext, props));
};
