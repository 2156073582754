import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { isMutableRef } from './utils/utils';
import { Avatar as DefaultAvatar } from '../Avatar';
import { useComponentContext } from '../../context/ComponentContext';
import { useMessageContext } from '../../context/MessageContext';
var UnMemoizedReactionSelector = React.forwardRef(function (props, ref) {
    var _a;
    var propAvatar = props.Avatar, _b = props.detailedView, detailedView = _b === void 0 ? true : _b, propHandleReaction = props.handleReaction, propLatestReactions = props.latest_reactions, propOwnReactions = props.own_reactions, propReactionGroups = props.reaction_groups, propReactionOptions = props.reactionOptions, _c = props.reverse, reverse = _c === void 0 ? false : _c;
    var _d = useComponentContext('ReactionSelector'), contextAvatar = _d.Avatar, contextReactionOptions = _d.reactionOptions;
    var _e = useMessageContext('ReactionSelector'), contextHandleReaction = _e.handleReaction, message = _e.message;
    var reactionOptions = propReactionOptions !== null && propReactionOptions !== void 0 ? propReactionOptions : contextReactionOptions;
    var Avatar = propAvatar || contextAvatar || DefaultAvatar;
    var handleReaction = propHandleReaction || contextHandleReaction;
    var latestReactions = propLatestReactions || (message === null || message === void 0 ? void 0 : message.latest_reactions) || [];
    var ownReactions = propOwnReactions || (message === null || message === void 0 ? void 0 : message.own_reactions) || [];
    var reactionGroups = propReactionGroups || (message === null || message === void 0 ? void 0 : message.reaction_groups) || {};
    var _f = useState(null), tooltipReactionType = _f[0], setTooltipReactionType = _f[1];
    var _g = useState(null), tooltipPositions = _g[0], setTooltipPositions = _g[1];
    var targetRef = useRef(null);
    var tooltipRef = useRef(null);
    var showTooltip = useCallback(function (event, reactionType) {
        targetRef.current = event.currentTarget;
        setTooltipReactionType(reactionType);
    }, []);
    var hideTooltip = useCallback(function () {
        setTooltipReactionType(null);
        setTooltipPositions(null);
    }, []);
    useEffect(function () {
        var _a, _b, _c;
        if (tooltipReactionType) {
            var tooltip = (_a = tooltipRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            var target = (_b = targetRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
            var container = isMutableRef(ref) ? (_c = ref.current) === null || _c === void 0 ? void 0 : _c.getBoundingClientRect() : null;
            if (!tooltip || !target || !container)
                return;
            var tooltipPosition = tooltip.width === container.width || tooltip.x < container.x
                ? 0
                : target.left + target.width / 2 - container.left - tooltip.width / 2;
            var arrowPosition = target.x - tooltip.x + target.width / 2 - tooltipPosition;
            setTooltipPositions({
                arrow: arrowPosition,
                tooltip: tooltipPosition,
            });
        }
    }, [tooltipReactionType, ref]);
    var getUsersPerReactionType = function (type) {
        return latestReactions
            .map(function (reaction) {
            var _a, _b;
            if (reaction.type === type) {
                return ((_a = reaction.user) === null || _a === void 0 ? void 0 : _a.name) || ((_b = reaction.user) === null || _b === void 0 ? void 0 : _b.id);
            }
            return null;
        })
            .filter(Boolean);
    };
    var iHaveReactedWithReaction = function (reactionType) {
        return ownReactions.find(function (reaction) { return reaction.type === reactionType; });
    };
    var getLatestUserForReactionType = function (type) {
        var _a;
        return ((_a = latestReactions.find(function (reaction) { return reaction.type === type && !!reaction.user; })) === null || _a === void 0 ? void 0 : _a.user) ||
            undefined;
    };
    return (React.createElement("div", { className: clsx('str-chat__reaction-selector str-chat__message-reaction-selector', {
            'str-chat__reaction-selector--reverse': reverse,
        }), "data-testid": 'reaction-selector', ref: ref },
        !!tooltipReactionType && detailedView && (React.createElement("div", { className: 'str-chat__reaction-selector-tooltip', ref: tooltipRef, style: {
                left: tooltipPositions === null || tooltipPositions === void 0 ? void 0 : tooltipPositions.tooltip,
                visibility: tooltipPositions ? 'visible' : 'hidden',
            } },
            React.createElement("div", { className: 'arrow', style: { left: tooltipPositions === null || tooltipPositions === void 0 ? void 0 : tooltipPositions.arrow } }), (_a = getUsersPerReactionType(tooltipReactionType)) === null || _a === void 0 ? void 0 :
            _a.map(function (user, i, users) { return (React.createElement("span", { className: 'latest-user-username', key: "key-".concat(i, "-").concat(user) }, "".concat(user).concat(i < users.length - 1 ? ', ' : ''))); }))),
        React.createElement("ul", { className: 'str-chat__message-reactions-list str-chat__message-reactions-options' }, reactionOptions.map(function (_a) {
            var _b, _c;
            var Component = _a.Component, reactionName = _a.name, reactionType = _a.type;
            var latestUser = getLatestUserForReactionType(reactionType);
            var count = (_c = (_b = reactionGroups[reactionType]) === null || _b === void 0 ? void 0 : _b.count) !== null && _c !== void 0 ? _c : 0;
            return (React.createElement("li", { key: reactionType },
                React.createElement("button", { "aria-label": "Select Reaction: ".concat(reactionName || reactionType), className: clsx('str-chat__message-reactions-list-item str-chat__message-reactions-option', {
                        'str-chat__message-reactions-option-selected': iHaveReactedWithReaction(reactionType),
                    }), "data-text": reactionType, onClick: function (event) { return handleReaction(reactionType, event); } },
                    !!count && detailedView && (React.createElement("div", { className: 'latest-user str-chat__message-reactions-last-user', onClick: hideTooltip, onMouseEnter: function (e) { return showTooltip(e, reactionType); }, onMouseLeave: hideTooltip }, latestUser ? (React.createElement(Avatar, { image: latestUser.image, name: latestUser.name, size: 20, user: latestUser })) : (React.createElement("div", { className: 'latest-user-not-found' })))),
                    React.createElement("span", { className: 'str-chat__message-reaction-emoji' },
                        React.createElement(Component, null)),
                    Boolean(count) && detailedView && (React.createElement("span", { className: 'str-chat__message-reactions-list-item__count' }, count || '')))));
        }))));
});
/**
 * Component that allows a user to select a reaction.
 */
export var ReactionSelector = React.memo(UnMemoizedReactionSelector);
