"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Backdrop = void 0;
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.Backdrop = (0, react_1.styled)(react_native_1.Pressable, {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    // use this for when you want to give background colour to backdrop
    // opacity: 0.5,
    // backgroundColor: '$backgroundLight500',
    _web: {
        cursor: 'default',
    },
}, {});
