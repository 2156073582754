import { __spreadArray } from "tslib";
import uniqBy from 'lodash.uniqby';
export var MAX_QUERY_CHANNELS_LIMIT = 30;
export var moveChannelUp = function (_a) {
    var activeChannel = _a.activeChannel, channels = _a.channels, cid = _a.cid;
    // get index of channel to move up
    var channelIndex = channels.findIndex(function (channel) { return channel.cid === cid; });
    if (!activeChannel && channelIndex <= 0)
        return channels;
    // get channel to move up
    var channel = activeChannel || channels[channelIndex];
    return uniqBy(__spreadArray([channel], channels, true), 'cid');
};
