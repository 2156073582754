import { useEffect, useState } from 'react';
export var useTimer = function (_a) {
    var startFrom = _a.startFrom;
    var _b = useState(), secondsLeft = _b[0], setSecondsLeft = _b[1];
    useEffect(function () {
        var countdownTimeout;
        if (typeof secondsLeft === 'number' && secondsLeft > 0) {
            countdownTimeout = setTimeout(function () {
                setSecondsLeft(secondsLeft - 1);
            }, 1000);
        }
        return function () {
            clearTimeout(countdownTimeout);
        };
    }, [secondsLeft]);
    useEffect(function () {
        setSecondsLeft(startFrom !== null && startFrom !== void 0 ? startFrom : 0);
    }, [startFrom]);
    return secondsLeft;
};
