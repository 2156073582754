import { __awaiter, __generator, __spreadArray } from "tslib";
import { useEffect } from 'react';
import uniqBy from 'lodash.uniqby';
import { getChannel } from '../../../utils/getChannel';
import { useChatContext } from '../../../context/ChatContext';
export var useChannelVisibleListener = function (setChannels, customHandler) {
    var client = useChatContext('useChannelVisibleListener').client;
    useEffect(function () {
        var handleEvent = function (event) { return __awaiter(void 0, void 0, void 0, function () {
            var channel_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(customHandler && typeof customHandler === 'function')) return [3 /*break*/, 1];
                        customHandler(setChannels, event);
                        return [3 /*break*/, 3];
                    case 1:
                        if (!(event.type && event.channel_type && event.channel_id)) return [3 /*break*/, 3];
                        return [4 /*yield*/, getChannel({
                                client: client,
                                id: event.channel_id,
                                type: event.channel_type,
                            })];
                    case 2:
                        channel_1 = _a.sent();
                        setChannels(function (channels) { return uniqBy(__spreadArray([channel_1], channels, true), 'cid'); });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        client.on('channel.visible', handleEvent);
        return function () {
            client.off('channel.visible', handleEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customHandler]);
};
