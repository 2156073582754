import { __assign } from "tslib";
import React from 'react';
import { DefaultTriggerProvider } from './DefaultTriggerProvider';
import { MessageInputFlat } from './MessageInputFlat';
import { useCooldownTimer } from './hooks/useCooldownTimer';
import { useCreateMessageInputContext } from './hooks/useCreateMessageInputContext';
import { useMessageInputState } from './hooks/useMessageInputState';
import { useChannelStateContext } from '../../context/ChannelStateContext';
import { useComponentContext } from '../../context/ComponentContext';
import { MessageInputContextProvider } from '../../context/MessageInputContext';
var MessageInputProvider = function (props) {
    var _a;
    var cooldownTimerState = useCooldownTimer();
    var messageInputState = useMessageInputState(props);
    var emojiSearchIndex = useComponentContext('MessageInput').emojiSearchIndex;
    var messageInputContextValue = useCreateMessageInputContext(__assign(__assign(__assign(__assign({}, cooldownTimerState), messageInputState), props), { emojiSearchIndex: (_a = props.emojiSearchIndex) !== null && _a !== void 0 ? _a : emojiSearchIndex }));
    return (React.createElement(MessageInputContextProvider, { value: messageInputContextValue }, props.children));
};
var UnMemoizedMessageInput = function (props) {
    var PropInput = props.Input;
    var dragAndDropWindow = useChannelStateContext().dragAndDropWindow;
    var _a = useComponentContext('MessageInput'), ContextInput = _a.Input, _b = _a.TriggerProvider, TriggerProvider = _b === void 0 ? DefaultTriggerProvider : _b;
    var Input = PropInput || ContextInput || MessageInputFlat;
    if (dragAndDropWindow)
        return (React.createElement(React.Fragment, null,
            React.createElement(TriggerProvider, null,
                React.createElement(Input, null))));
    return (React.createElement(MessageInputProvider, __assign({}, props),
        React.createElement(TriggerProvider, null,
            React.createElement(Input, null))));
};
/**
 * A high level component that has provides all functionality to the Input it renders.
 */
export var MessageInput = React.memo(UnMemoizedMessageInput);
