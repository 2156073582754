import { __assign } from "tslib";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FileUploadButton, ImageDropzone, UploadButton } from '../ReactFileUtilities';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { nanoid } from 'nanoid';
import { FileUploadIconFlat as DefaultFileUploadIcon, UploadIcon as DefaultUploadIcon, } from './icons';
import { CooldownTimer as DefaultCooldownTimer } from './CooldownTimer';
import { SendButton as DefaultSendButton } from './SendButton';
import { AudioRecorder as DefaultAudioRecorder, RecordingPermissionDeniedNotification as DefaultRecordingPermissionDeniedNotification, StartRecordingAudioButton as DefaultStartRecordingAudioButton, RecordingPermission, } from '../MediaRecorder';
import { QuotedMessagePreview as DefaultQuotedMessagePreview, QuotedMessagePreviewHeader, } from './QuotedMessagePreview';
import { AttachmentPreviewList as DefaultAttachmentPreviewList } from './AttachmentPreviewList';
import { LinkPreviewList as DefaultLinkPreviewList } from './LinkPreviewList';
import { UploadsPreview } from './UploadsPreview';
import { ChatAutoComplete } from '../ChatAutoComplete/ChatAutoComplete';
import { Tooltip } from '../Tooltip/Tooltip';
import { RecordingAttachmentType } from '../MediaRecorder/classes';
import { useChatContext } from '../../context/ChatContext';
import { useChannelActionContext } from '../../context/ChannelActionContext';
import { useChannelStateContext } from '../../context/ChannelStateContext';
import { useTranslationContext } from '../../context/TranslationContext';
import { useMessageInputContext } from '../../context/MessageInputContext';
import { useComponentContext } from '../../context/ComponentContext';
export var MessageInputFlat = function () {
    var quotedMessage = useChannelStateContext('MessageInputFlat').quotedMessage;
    var setQuotedMessage = useChannelActionContext('MessageInputFlat').setQuotedMessage;
    var _a = useChatContext('MessageInputFlat'), channel = _a.channel, themeVersion = _a.themeVersion;
    useEffect(function () {
        var handleQuotedMessageUpdate = function (e) {
            var _a;
            if (((_a = e.message) === null || _a === void 0 ? void 0 : _a.id) !== (quotedMessage === null || quotedMessage === void 0 ? void 0 : quotedMessage.id))
                return;
            if (e.type === 'message.deleted') {
                setQuotedMessage(undefined);
                return;
            }
            setQuotedMessage(e.message);
        };
        channel === null || channel === void 0 ? void 0 : channel.on('message.deleted', handleQuotedMessageUpdate);
        channel === null || channel === void 0 ? void 0 : channel.on('message.updated', handleQuotedMessageUpdate);
        return function () {
            channel === null || channel === void 0 ? void 0 : channel.off('message.deleted', handleQuotedMessageUpdate);
            channel === null || channel === void 0 ? void 0 : channel.off('message.updated', handleQuotedMessageUpdate);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channel, quotedMessage]);
    return themeVersion === '2' ? (React.createElement(MessageInputV2, null)) : (React.createElement(MessageInputV1, null));
};
var MessageInputV1 = function () {
    var _a = useChannelStateContext('MessageInputFlat'), acceptedFiles = _a.acceptedFiles, multipleUploads = _a.multipleUploads, quotedMessage = _a.quotedMessage;
    var t = useTranslationContext('MessageInputFlat').t;
    var _b = useMessageInputContext('MessageInputFlat'), cooldownRemaining = _b.cooldownRemaining, handleSubmit = _b.handleSubmit, hideSendButton = _b.hideSendButton, isUploadEnabled = _b.isUploadEnabled, maxFilesLeft = _b.maxFilesLeft, numberOfUploads = _b.numberOfUploads, setCooldownRemaining = _b.setCooldownRemaining, uploadNewFiles = _b.uploadNewFiles;
    var _c = useComponentContext('MessageInputFlat'), _d = _c.CooldownTimer, CooldownTimer = _d === void 0 ? DefaultCooldownTimer : _d, _e = _c.FileUploadIcon, FileUploadIcon = _e === void 0 ? DefaultFileUploadIcon : _e, _f = _c.QuotedMessagePreview, QuotedMessagePreview = _f === void 0 ? DefaultQuotedMessagePreview : _f, _g = _c.SendButton, SendButton = _g === void 0 ? DefaultSendButton : _g, _h = _c.AttachmentPreviewList, AttachmentPreviewList = _h === void 0 ? UploadsPreview : _h, EmojiPicker = _c.EmojiPicker;
    return (React.createElement("div", { className: clsx('str-chat__input-flat', 'str-chat__message-input', {
            'str-chat__input-flat--send-button-active': !!SendButton,
            'str-chat__input-flat-has-attachments': numberOfUploads,
            'str-chat__input-flat-quoted': quotedMessage && !quotedMessage.parent_id,
        }) },
        React.createElement(ImageDropzone, { accept: acceptedFiles, disabled: !isUploadEnabled || maxFilesLeft === 0 || !!cooldownRemaining, handleFiles: uploadNewFiles, maxNumberOfFiles: maxFilesLeft, multiple: multipleUploads },
            quotedMessage && !quotedMessage.parent_id && (React.createElement(QuotedMessagePreview, { quotedMessage: quotedMessage })),
            React.createElement("div", { className: 'str-chat__input-flat-wrapper' },
                isUploadEnabled && React.createElement(AttachmentPreviewList, null),
                React.createElement("div", { className: 'str-chat__input-flat--textarea-wrapper' },
                    EmojiPicker && React.createElement(EmojiPicker, null),
                    !!cooldownRemaining && (React.createElement("div", { className: 'str-chat__input-flat-cooldown' },
                        React.createElement(CooldownTimer, { cooldownInterval: cooldownRemaining, setCooldownRemaining: setCooldownRemaining }))),
                    React.createElement(ChatAutoComplete, null),
                    isUploadEnabled && !cooldownRemaining && (React.createElement("div", { className: 'str-chat__fileupload-wrapper', "data-testid": 'fileinput' },
                        React.createElement(Tooltip, null, maxFilesLeft
                            ? t('Attach files')
                            : t("You've reached the maximum number of files")),
                        React.createElement(FileUploadButton, { accepts: acceptedFiles, disabled: maxFilesLeft === 0, handleFiles: uploadNewFiles, multiple: multipleUploads },
                            React.createElement("span", { className: 'str-chat__input-flat-fileupload' },
                                React.createElement(FileUploadIcon, null)))))),
                !(cooldownRemaining || hideSendButton) && React.createElement(SendButton, { sendMessage: handleSubmit })))));
};
var MessageInputV2 = function () {
    var _a = useChannelStateContext('MessageInputV2'), _b = _a.acceptedFiles, acceptedFiles = _b === void 0 ? [] : _b, multipleUploads = _a.multipleUploads, quotedMessage = _a.quotedMessage;
    var t = useTranslationContext('MessageInputV2').t;
    var _c = useMessageInputContext('MessageInputV2'), asyncMessagesMultiSendEnabled = _c.asyncMessagesMultiSendEnabled, attachments = _c.attachments, cooldownRemaining = _c.cooldownRemaining, findAndEnqueueURLsToEnrich = _c.findAndEnqueueURLsToEnrich, handleSubmit = _c.handleSubmit, hideSendButton = _c.hideSendButton, isUploadEnabled = _c.isUploadEnabled, linkPreviews = _c.linkPreviews, maxFilesLeft = _c.maxFilesLeft, message = _c.message, numberOfUploads = _c.numberOfUploads, recordingController = _c.recordingController, setCooldownRemaining = _c.setCooldownRemaining, text = _c.text, uploadNewFiles = _c.uploadNewFiles;
    var _d = useComponentContext('MessageInputV2'), _e = _d.AudioRecorder, AudioRecorder = _e === void 0 ? DefaultAudioRecorder : _e, _f = _d.AttachmentPreviewList, AttachmentPreviewList = _f === void 0 ? DefaultAttachmentPreviewList : _f, _g = _d.CooldownTimer, CooldownTimer = _g === void 0 ? DefaultCooldownTimer : _g, _h = _d.FileUploadIcon, FileUploadIcon = _h === void 0 ? DefaultUploadIcon : _h, _j = _d.LinkPreviewList, LinkPreviewList = _j === void 0 ? DefaultLinkPreviewList : _j, _k = _d.QuotedMessagePreview, QuotedMessagePreview = _k === void 0 ? DefaultQuotedMessagePreview : _k, _l = _d.RecordingPermissionDeniedNotification, RecordingPermissionDeniedNotification = _l === void 0 ? DefaultRecordingPermissionDeniedNotification : _l, _m = _d.SendButton, SendButton = _m === void 0 ? DefaultSendButton : _m, _o = _d.StartRecordingAudioButton, StartRecordingAudioButton = _o === void 0 ? DefaultStartRecordingAudioButton : _o, EmojiPicker = _d.EmojiPicker;
    var _p = useState(false), showRecordingPermissionDeniedNotification = _p[0], setShowRecordingPermissionDeniedNotification = _p[1];
    var closePermissionDeniedNotification = useCallback(function () {
        setShowRecordingPermissionDeniedNotification(false);
    }, []);
    var id = useMemo(function () { return nanoid(); }, []);
    var accept = useMemo(function () {
        return acceptedFiles.reduce(function (mediaTypeMap, mediaType) {
            var _a;
            (_a = mediaTypeMap[mediaType]) !== null && _a !== void 0 ? _a : (mediaTypeMap[mediaType] = []);
            return mediaTypeMap;
        }, {});
    }, [acceptedFiles]);
    var _q = useDropzone({
        accept: accept,
        disabled: !isUploadEnabled || maxFilesLeft === 0,
        multiple: multipleUploads,
        noClick: true,
        onDrop: uploadNewFiles,
    }), getRootProps = _q.getRootProps, isDragActive = _q.isDragActive, isDragReject = _q.isDragReject;
    if (recordingController.recordingState)
        return React.createElement(AudioRecorder, null);
    // TODO: "!message" condition is a temporary fix for shared
    // state when editing a message (fix shared state issue)
    var displayQuotedMessage = !message && quotedMessage && !quotedMessage.parent_id;
    var recordingEnabled = !!(recordingController.recorder && navigator.mediaDevices); // account for requirement on iOS as per this bug report: https://bugs.webkit.org/show_bug.cgi?id=252303
    var isRecording = !!recordingController.recordingState;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", __assign({}, getRootProps({ className: 'str-chat__message-input' })),
            recordingEnabled &&
                recordingController.permissionState === 'denied' &&
                showRecordingPermissionDeniedNotification && (React.createElement(RecordingPermissionDeniedNotification, { onClose: closePermissionDeniedNotification, permissionName: RecordingPermission.MIC })),
            findAndEnqueueURLsToEnrich && (React.createElement(LinkPreviewList, { linkPreviews: Array.from(linkPreviews.values()) })),
            isDragActive && (React.createElement("div", { className: clsx('str-chat__dropzone-container', {
                    'str-chat__dropzone-container--not-accepted': isDragReject,
                }) },
                !isDragReject && React.createElement("p", null, t('Drag your files here')),
                isDragReject && React.createElement("p", null, t('Some of the files will not be accepted')))),
            displayQuotedMessage && React.createElement(QuotedMessagePreviewHeader, null),
            React.createElement("div", { className: 'str-chat__message-input-inner' },
                React.createElement("div", { className: 'str-chat__file-input-container', "data-testid": 'file-upload-button' },
                    React.createElement(UploadButton, { accept: acceptedFiles === null || acceptedFiles === void 0 ? void 0 : acceptedFiles.join(','), "aria-label": t('aria/File upload'), className: 'str-chat__file-input', "data-testid": 'file-input', disabled: !isUploadEnabled || maxFilesLeft === 0, id: id, multiple: multipleUploads, onFileChange: uploadNewFiles }),
                    React.createElement("label", { className: 'str-chat__file-input-label', htmlFor: id },
                        React.createElement(FileUploadIcon, null))),
                React.createElement("div", { className: 'str-chat__message-textarea-container' },
                    displayQuotedMessage && React.createElement(QuotedMessagePreview, { quotedMessage: quotedMessage }),
                    isUploadEnabled &&
                        !!(numberOfUploads ||
                            (attachments.length && attachments.length !== linkPreviews.size)) && React.createElement(AttachmentPreviewList, null),
                    React.createElement("div", { className: 'str-chat__message-textarea-with-emoji-picker' },
                        React.createElement(ChatAutoComplete, null),
                        EmojiPicker && React.createElement(EmojiPicker, null))),
                !hideSendButton && (React.createElement(React.Fragment, null, cooldownRemaining ? (React.createElement(CooldownTimer, { cooldownInterval: cooldownRemaining, setCooldownRemaining: setCooldownRemaining })) : (React.createElement(React.Fragment, null,
                    React.createElement(SendButton, { disabled: !numberOfUploads && !text.length && !attachments.length, sendMessage: handleSubmit }),
                    recordingEnabled && (React.createElement(StartRecordingAudioButton, { disabled: isRecording ||
                            (!asyncMessagesMultiSendEnabled &&
                                attachments.some(function (a) { return a.type === RecordingAttachmentType.VOICE_RECORDING; })), onClick: function () {
                            var _a;
                            (_a = recordingController.recorder) === null || _a === void 0 ? void 0 : _a.start();
                            setShowRecordingPermissionDeniedNotification(true);
                        } }))))))))));
};
