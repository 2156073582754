import { useCallback, useEffect, useRef, useState } from 'react';
// todo: provide start timestamp
export var useTimeElapsed = function (_a) {
    var _b = _a === void 0 ? {} : _a, startOnMount = _b.startOnMount;
    var _c = useState(0), secondsElapsed = _c[0], setSecondsElapsed = _c[1];
    var updateInterval = useRef();
    var startCounter = useCallback(function () {
        updateInterval.current = setInterval(function () {
            setSecondsElapsed(function (prev) { return prev + 1; });
        }, 1000);
    }, []);
    var stopCounter = useCallback(function () {
        clearInterval(updateInterval.current);
    }, []);
    useEffect(function () {
        if (!startOnMount)
            return;
        updateInterval.current = setInterval(function () {
            setSecondsElapsed(function (prev) { return prev + 1; });
        }, 1000);
        return function () {
            stopCounter();
        };
    }, [startOnMount, stopCounter]);
    return {
        secondsElapsed: secondsElapsed,
        startCounter: startCounter,
        stopCounter: stopCounter,
    };
};
