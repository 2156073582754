import { __assign } from "tslib";
import React from 'react';
import { Avatar as DefaultAvatar } from '../Avatar';
import { useTranslationContext } from '../../context/TranslationContext';
import { getDateString } from '../../i18n/utils';
/**
 * Component to display system and channel event messages
 */
var UnMemoizedEventComponent = function (props) {
    var _a, _b, _c;
    var calendar = props.calendar, calendarFormats = props.calendarFormats, _d = props.format, format = _d === void 0 ? 'dddd L' : _d, _e = props.Avatar, Avatar = _e === void 0 ? DefaultAvatar : _e, message = props.message;
    var _f = useTranslationContext('EventComponent'), t = _f.t, tDateTimeParser = _f.tDateTimeParser;
    var _g = message.created_at, created_at = _g === void 0 ? '' : _g, event = message.event, text = message.text, type = message.type;
    var getDateOptions = { messageCreatedAt: created_at.toString(), tDateTimeParser: tDateTimeParser };
    if (type === 'system')
        return (React.createElement("div", { className: 'str-chat__message--system', "data-testid": 'message-system' },
            React.createElement("div", { className: 'str-chat__message--system__text' },
                React.createElement("div", { className: 'str-chat__message--system__line' }),
                React.createElement("p", null, text),
                React.createElement("div", { className: 'str-chat__message--system__line' })),
            React.createElement("div", { className: 'str-chat__message--system__date' },
                React.createElement("strong", null, getDateString(__assign(__assign({}, getDateOptions), { calendar: calendar, calendarFormats: calendarFormats, format: format, t: t, timestampTranslationKey: 'timestamp/SystemMessage' }))))));
    if ((event === null || event === void 0 ? void 0 : event.type) === 'member.removed' || (event === null || event === void 0 ? void 0 : event.type) === 'member.added') {
        var name_1 = ((_a = event.user) === null || _a === void 0 ? void 0 : _a.name) || ((_b = event.user) === null || _b === void 0 ? void 0 : _b.id);
        var sentence = "".concat(name_1, " ").concat(event.type === 'member.added' ? 'has joined the chat' : 'was removed from the chat');
        return (React.createElement("div", { className: 'str-chat__event-component__channel-event' },
            React.createElement(Avatar, { image: (_c = event.user) === null || _c === void 0 ? void 0 : _c.image, name: name_1, user: event.user }),
            React.createElement("div", { className: 'str-chat__event-component__channel-event__content' },
                React.createElement("em", { className: 'str-chat__event-component__channel-event__sentence' }, sentence),
                React.createElement("div", { className: 'str-chat__event-component__channel-event__date' }, getDateString(__assign(__assign({}, getDateOptions), { format: 'LT' }))))));
    }
    return null;
};
export var EventComponent = React.memo(UnMemoizedEventComponent);
