import { __assign, __awaiter, __generator } from "tslib";
import { useChannelActionContext } from '../../../context/ChannelActionContext';
import { useChannelStateContext } from '../../../context/ChannelStateContext';
export var handleActionWarning = "Action handler was called, but it is missing one of its required arguments. \nMake sure the ChannelAction and ChannelState contexts are properly set and the hook is initialized with a valid message.";
export function useActionHandler(message) {
    var _this = this;
    var _a = useChannelActionContext('useActionHandler'), removeMessage = _a.removeMessage, updateMessage = _a.updateMessage;
    var channel = useChannelStateContext('useActionHandler').channel;
    return function (dataOrName, value, event) { return __awaiter(_this, void 0, void 0, function () {
        var messageID, formData, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (event)
                        event.preventDefault();
                    if (!message || !updateMessage || !removeMessage || !channel) {
                        console.warn(handleActionWarning);
                        return [2 /*return*/];
                    }
                    messageID = message.id;
                    formData = {};
                    // deprecated: value&name should be removed in favor of data obj
                    if (typeof dataOrName === 'string') {
                        formData[dataOrName] = value;
                    }
                    else {
                        formData = __assign({}, dataOrName);
                    }
                    if (!messageID) return [3 /*break*/, 2];
                    return [4 /*yield*/, channel.sendAction(messageID, formData)];
                case 1:
                    data = _a.sent();
                    if (data === null || data === void 0 ? void 0 : data.message) {
                        updateMessage(data.message);
                    }
                    else {
                        removeMessage(message);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
}
