import { __rest } from "tslib";
import React, { useState } from 'react';
import clsx from 'clsx';
import { useProcessReactions } from './hooks/useProcessReactions';
import { ReactionsListModal } from './ReactionsListModal';
import { useTranslationContext } from '../../context';
import { MAX_MESSAGE_REACTIONS_TO_FETCH } from '../Message/hooks';
var UnMemoizedReactionsList = function (props) {
    var handleFetchReactions = props.handleFetchReactions, reactionDetailsSort = props.reactionDetailsSort, _a = props.reverse, reverse = _a === void 0 ? false : _a, sortReactionDetails = props.sortReactionDetails, rest = __rest(props, ["handleFetchReactions", "reactionDetailsSort", "reverse", "sortReactionDetails"]);
    var _b = useProcessReactions(rest), existingReactions = _b.existingReactions, hasReactions = _b.hasReactions, totalReactionCount = _b.totalReactionCount;
    var _c = useState(null), selectedReactionType = _c[0], setSelectedReactionType = _c[1];
    var t = useTranslationContext('ReactionsList').t;
    var handleReactionButtonClick = function (reactionType) {
        if (totalReactionCount > MAX_MESSAGE_REACTIONS_TO_FETCH) {
            return;
        }
        setSelectedReactionType(reactionType);
    };
    if (!hasReactions)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "aria-label": t('aria/Reaction list'), className: clsx('str-chat__reaction-list str-chat__message-reactions-container', {
                'str-chat__reaction-list--reverse': reverse,
            }), "data-testid": 'reaction-list', role: 'figure' },
            React.createElement("ul", { className: 'str-chat__message-reactions' },
                existingReactions.map(function (_a) {
                    var EmojiComponent = _a.EmojiComponent, isOwnReaction = _a.isOwnReaction, reactionCount = _a.reactionCount, reactionType = _a.reactionType;
                    return EmojiComponent && (React.createElement("li", { className: clsx('str-chat__message-reaction', {
                            'str-chat__message-reaction-own': isOwnReaction,
                        }), key: reactionType },
                        React.createElement("button", { "aria-label": "Reactions: ".concat(reactionType), "data-testid": "reactions-list-button-".concat(reactionType), onClick: function () { return handleReactionButtonClick(reactionType); }, type: 'button' },
                            React.createElement("span", { className: 'str-chat__message-reaction-emoji' },
                                React.createElement(EmojiComponent, null)),
                            "\u00A0",
                            React.createElement("span", { className: 'str-chat__message-reaction-count', "data-testclass": 'reaction-list-reaction-count' }, reactionCount))));
                }),
                React.createElement("li", null,
                    React.createElement("span", { className: 'str-chat__reaction-list--counter' }, totalReactionCount)))),
        selectedReactionType !== null && (React.createElement(ReactionsListModal, { handleFetchReactions: handleFetchReactions, onClose: function () { return setSelectedReactionType(null); }, onSelectedReactionTypeChange: setSelectedReactionType, open: selectedReactionType !== null, reactions: existingReactions, selectedReactionType: selectedReactionType, sortReactionDetails: sortReactionDetails }))));
};
/**
 * Component that displays a list of reactions on a message.
 */
export var ReactionsList = React.memo(UnMemoizedReactionsList);
