import React from 'react';
import { useTranslationContext } from '../../context';
import { isMessageBounced } from './utils';
export function MessageErrorText(_a) {
    var message = _a.message, theme = _a.theme;
    var t = useTranslationContext('MessageText').t;
    if (message.type === 'error' && !isMessageBounced(message)) {
        return (React.createElement("div", { className: "str-chat__".concat(theme, "-message--error-message str-chat__message--error-message") }, t('Error · Unsent')));
    }
    if (message.status === 'failed') {
        return (React.createElement("div", { className: "str-chat__".concat(theme, "-message--error-message str-chat__message--error-message") }, message.errorStatusCode !== 403
            ? t('Message Failed · Click to try again')
            : t('Message Failed · Unauthorized')));
    }
    return null;
}
