import React from 'react';
import { FileIcon } from '../../ReactFileUtilities';
import { CloseIcon, DownloadIcon, LoadingIndicatorIcon, RetryIcon } from '../icons';
export var FileAttachmentPreview = function (_a) {
    var _b, _c, _d, _e;
    var attachment = _a.attachment, handleRetry = _a.handleRetry, removeAttachments = _a.removeAttachments;
    return (React.createElement("div", { className: 'str-chat__attachment-preview-file', "data-testid": 'attachment-preview-file' },
        React.createElement("div", { className: 'str-chat__attachment-preview-file-icon' },
            React.createElement(FileIcon, { filename: attachment.title, mimeType: attachment.mime_type, version: '2' })),
        React.createElement("button", { className: 'str-chat__attachment-preview-delete', "data-testid": 'file-preview-item-delete-button', disabled: ((_b = attachment.localMetadata) === null || _b === void 0 ? void 0 : _b.uploadState) === 'uploading', onClick: function () { var _a, _b; return ((_a = attachment.localMetadata) === null || _a === void 0 ? void 0 : _a.id) && removeAttachments([(_b = attachment.localMetadata) === null || _b === void 0 ? void 0 : _b.id]); } },
            React.createElement(CloseIcon, null)),
        ((_c = attachment.localMetadata) === null || _c === void 0 ? void 0 : _c.uploadState) === 'failed' && !!handleRetry && (React.createElement("button", { className: 'str-chat__attachment-preview-error str-chat__attachment-preview-error-file', "data-testid": 'file-preview-item-retry-button', onClick: function () { return handleRetry(attachment); } },
            React.createElement(RetryIcon, null))),
        React.createElement("div", { className: 'str-chat__attachment-preview-file-end' },
            React.createElement("div", { className: 'str-chat__attachment-preview-file-name', title: attachment.title }, attachment.title),
            ((_d = attachment.localMetadata) === null || _d === void 0 ? void 0 : _d.uploadState) === 'finished' && !!attachment.asset_url && (React.createElement("a", { className: 'str-chat__attachment-preview-file-download', download: true, href: attachment.asset_url, rel: 'noreferrer', target: '_blank' },
                React.createElement(DownloadIcon, null))),
            ((_e = attachment.localMetadata) === null || _e === void 0 ? void 0 : _e.uploadState) === 'uploading' && React.createElement(LoadingIndicatorIcon, { size: 17 }))));
};
