import { useCallback, useMemo } from 'react';
import { useComponentContext, useMessageContext } from '../../../context';
export var defaultReactionsSort = function (a, b) {
    if (a.firstReactionAt && b.firstReactionAt) {
        return +a.firstReactionAt - +b.firstReactionAt;
    }
    return a.reactionType.localeCompare(b.reactionType, 'en');
};
export var useProcessReactions = function (params) {
    var _a;
    var propOwnReactions = params.own_reactions, propReactionGroups = params.reaction_groups, propReactionOptions = params.reactionOptions, propReactions = params.reactions, propSortReactions = params.sortReactions;
    var _b = useMessageContext('useProcessReactions'), message = _b.message, contextSortReactions = _b.sortReactions;
    var contextReactionOptions = useComponentContext('useProcessReactions').reactionOptions;
    var reactionOptions = propReactionOptions !== null && propReactionOptions !== void 0 ? propReactionOptions : contextReactionOptions;
    var sortReactions = (_a = propSortReactions !== null && propSortReactions !== void 0 ? propSortReactions : contextSortReactions) !== null && _a !== void 0 ? _a : defaultReactionsSort;
    var latestReactions = propReactions || message.latest_reactions;
    var ownReactions = propOwnReactions || (message === null || message === void 0 ? void 0 : message.own_reactions);
    var reactionGroups = propReactionGroups || (message === null || message === void 0 ? void 0 : message.reaction_groups);
    var isOwnReaction = useCallback(function (reactionType) { var _a; return (_a = ownReactions === null || ownReactions === void 0 ? void 0 : ownReactions.some(function (reaction) { return reaction.type === reactionType; })) !== null && _a !== void 0 ? _a : false; }, [ownReactions]);
    var getEmojiByReactionType = useCallback(function (reactionType) { var _a, _b; return (_b = (_a = reactionOptions.find(function (_a) {
        var type = _a.type;
        return type === reactionType;
    })) === null || _a === void 0 ? void 0 : _a.Component) !== null && _b !== void 0 ? _b : null; }, [reactionOptions]);
    var isSupportedReaction = useCallback(function (reactionType) {
        return reactionOptions.some(function (reactionOption) { return reactionOption.type === reactionType; });
    }, [reactionOptions]);
    var getLatestReactedUserNames = useCallback(function (reactionType) {
        var _a;
        return (_a = latestReactions === null || latestReactions === void 0 ? void 0 : latestReactions.flatMap(function (reaction) {
            var _a, _b;
            if (reactionType && reactionType === reaction.type) {
                var username = ((_a = reaction.user) === null || _a === void 0 ? void 0 : _a.name) || ((_b = reaction.user) === null || _b === void 0 ? void 0 : _b.id);
                return username ? [username] : [];
            }
            return [];
        })) !== null && _a !== void 0 ? _a : [];
    }, [latestReactions]);
    var existingReactions = useMemo(function () {
        if (!reactionGroups) {
            return [];
        }
        var unsortedReactions = Object.entries(reactionGroups).flatMap(function (_a) {
            var reactionType = _a[0], _b = _a[1], count = _b.count, first_reaction_at = _b.first_reaction_at, last_reaction_at = _b.last_reaction_at;
            if (count === 0 || !isSupportedReaction(reactionType)) {
                return [];
            }
            var latestReactedUserNames = getLatestReactedUserNames(reactionType);
            return [
                {
                    EmojiComponent: getEmojiByReactionType(reactionType),
                    firstReactionAt: first_reaction_at ? new Date(first_reaction_at) : null,
                    isOwnReaction: isOwnReaction(reactionType),
                    lastReactionAt: last_reaction_at ? new Date(last_reaction_at) : null,
                    latestReactedUserNames: latestReactedUserNames,
                    reactionCount: count,
                    reactionType: reactionType,
                    unlistedReactedUserCount: count - latestReactedUserNames.length,
                },
            ];
        });
        return unsortedReactions.sort(sortReactions);
    }, [
        getEmojiByReactionType,
        getLatestReactedUserNames,
        isOwnReaction,
        isSupportedReaction,
        reactionGroups,
        sortReactions,
    ]);
    var hasReactions = existingReactions.length > 0;
    var totalReactionCount = useMemo(function () { return existingReactions.reduce(function (total, _a) {
        var reactionCount = _a.reactionCount;
        return total + reactionCount;
    }, 0); }, [existingReactions]);
    return {
        existingReactions: existingReactions,
        hasReactions: hasReactions,
        totalReactionCount: totalReactionCount,
    };
};
