"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
exports.default = (0, react_1.styled)(react_native_1.Pressable, {
    backgroundColor: '$muted.200',
    _dark: {
        backgroundColor: '$muted.700',
    },
    // @ts-ignore
    borderRadius: '$lg',
    overflow: 'hidden',
}, {
    componentName: 'SliderTrack',
    ancestorStyle: ['_track'],
});
