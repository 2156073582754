"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
const plugins_1 = require("../../../plugins");
exports.default = (0, react_1.styled)(react_native_1.Switch, {
    'borderRadius': '$full',
    ':disabled': {
        opacity: 0.4,
    },
    ':invalid': {
        _web: {
            boxShadow: `0 0 0 2px $error.600`,
        },
        _android: {
            borderColor: '$error.600',
            borderWidth: 2,
            borderRadius: 12,
        },
    },
    'props': {
        thumbColor: '$muted.50',
        activeThumbColor: '$muted.50',
        trackColor: { false: '$muted.300', true: '$primary.600' },
        // for ios specifically in unchecked state
        ios_backgroundColor: '$muted.300',
    },
    ':hover': {
        props: {
            trackColor: { false: '$muted.400', true: '$primary.700' },
            // for ios specifically in unchecked state
            ios_backgroundColor: '$muted.400',
        },
    },
    '_dark': {
        'props': {
            trackColor: { false: '$muted.700', true: '$primary.500' },
            // for ios specifically in unchecked state
            ios_backgroundColor: '$muted.700',
        },
        ':hover': {
            props: {
                trackColor: { false: '$muted.600', true: '$primary.400' },
                // for ios specifically in unchecked state
                ios_backgroundColor: '$muted.600',
            },
        },
        ':invalid': {
            _web: {
                boxShadow: `0 0 0 2px $error.500`,
            },
            _android: {
                borderColor: '$error.500',
                // @ts-ignore
                borderWidth: 2,
                // @ts-ignore
                borderRadius: 12,
            },
        },
    },
    '_web': {
        // @ts-ignore
        'borderWidth': 0,
        'outlineWidth': 10,
        'outlineColor': '$yellow.400',
        '_dark': {
            outlineWidth: 0,
        },
        ':focus': {
            outlineWidth: 0,
            outlineColor: '$yellow.400',
            _dark: {
                outlineWidth: 0,
            },
        },
        'cursor': 'pointer',
        ':disabled': {
            cursor: 'not-allowed',
        },
    },
    'variants': {
        colorScheme: {},
        size: {
            sm: {
                transform: [
                    {
                        scale: 0.75,
                    },
                ],
            },
            md: {},
            lg: {
                transform: [
                    {
                        scale: 1.25,
                    },
                ],
                margin: 1,
            },
        },
    },
    'defaultProps': {
        size: 'md',
        // @ts-ignore
        colorScheme: 'primary',
    },
}, {
    componentName: 'Switch',
    resolveProps: [
        'thumbColor',
        'trackColor',
        'activeThumbColor',
        'ios_backgroundColor',
    ],
}, {
    propertyTokenMap: {
        trackColor: 'colors',
        thumbColor: 'colors',
        activeThumbColor: 'colors',
        ios_backgroundColor: 'colors',
    },
    propertyResolver: {
        trackColor: (rawValue, resolver) => {
            const resolveColor = {
                true: resolver(rawValue.true),
                false: resolver(rawValue.false),
            };
            return resolveColor;
        },
    },
    plugins: [new plugins_1.ColorSchemeResolver(colorSchemeResolveFn)],
});
function colorSchemeResolveFn(_a) {
    var props = __rest(_a, []);
    if (props.colorScheme) {
        const color = props.colorScheme;
        const value = {
            'props': {
                thumbColor: '$muted.50',
                activeThumbColor: '$muted.50',
                trackColor: { false: '$muted.300', true: `$${color}.600` },
                // for ios specifically in unchecked state
                ios_backgroundColor: '$muted.300',
            },
            ':hover': {
                props: {
                    trackColor: { false: '$muted.400', true: `$${color}.700` },
                    // for ios specifically in unchecked state
                    ios_backgroundColor: '$muted.400',
                },
            },
            '_dark': {
                'props': {
                    trackColor: { false: '$muted.700', true: `$${color}.500` },
                    // for ios specifically in unchecked state
                    ios_backgroundColor: '$muted.700',
                },
                ':hover': {
                    props: {
                        trackColor: { false: '$muted.600', true: `$${color}.400` },
                        // for ios specifically in unchecked state
                        ios_backgroundColor: '$muted.600',
                    },
                },
            },
        };
        return value;
    }
    return {};
}
