import { __awaiter, __generator } from "tslib";
import { validateAndGetMessage } from '../utils';
import { useChatContext } from '../../../context/ChatContext';
import { useTranslationContext } from '../../../context/TranslationContext';
export var missingUseFlagHandlerParameterWarning = 'useFlagHandler was called but it is missing one or more necessary parameters.';
export var useFlagHandler = function (message, notifications) {
    if (notifications === void 0) { notifications = {}; }
    var client = useChatContext('useFlagHandler').client;
    var t = useTranslationContext('useFlagHandler').t;
    return function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var getErrorNotification, getSuccessNotification, notify, successMessage, e_1, errorMessage;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    event.preventDefault();
                    getErrorNotification = notifications.getErrorNotification, getSuccessNotification = notifications.getSuccessNotification, notify = notifications.notify;
                    if (!client || !t || !notify || !(message === null || message === void 0 ? void 0 : message.id)) {
                        console.warn(missingUseFlagHandlerParameterWarning);
                        return [2 /*return*/];
                    }
                    if ((_a = client.user) === null || _a === void 0 ? void 0 : _a.banned) {
                        return [2 /*return*/, notify(t('Error adding flag'), 'error')];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, client.flagMessage(message.id)];
                case 2:
                    _b.sent();
                    successMessage = getSuccessNotification && validateAndGetMessage(getSuccessNotification, [message]);
                    notify(successMessage || t('Message has been successfully flagged'), 'success');
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    errorMessage = getErrorNotification && validateAndGetMessage(getErrorNotification, [message]);
                    notify(errorMessage || t('Error adding flag'), 'error');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
