import { encodeToWaw } from './wav';
import { encodeToMp3 } from './mp3';
import { createFileFromBlobs, getExtensionFromMimeType } from '../../ReactFileUtilities';
export var transcode = function (_a) {
    var blob = _a.blob, sampleRate = _a.sampleRate, targetMimeType = _a.targetMimeType;
    var file = createFileFromBlobs({
        blobsArray: [blob],
        fileName: "audio_recording_".concat(new Date().toISOString(), ".").concat(getExtensionFromMimeType(blob.type)),
        mimeType: blob.type,
    });
    if (targetMimeType.match('audio/wav')) {
        return encodeToWaw(file, sampleRate);
    }
    if (targetMimeType.match('audio/mp3')) {
        return encodeToMp3(file, sampleRate);
    }
    return Promise.resolve(blob);
};
