import { __assign, __spreadArray } from "tslib";
export var channelReducer = function (state, action) {
    var _a;
    switch (action.type) {
        case 'closeThread': {
            return __assign(__assign({}, state), { thread: null, threadLoadingMore: false, threadMessages: [] });
        }
        case 'copyMessagesFromChannel': {
            var channel = action.channel, parentId = action.parentId;
            return __assign(__assign({}, state), { messages: __spreadArray([], channel.state.messages, true), pinnedMessages: __spreadArray([], channel.state.pinnedMessages, true), 
                // copying messages from channel happens with new message - this resets the suppressAutoscroll
                suppressAutoscroll: false, threadMessages: parentId
                    ? __assign({}, channel.state.threads)[parentId] || []
                    : state.threadMessages });
        }
        case 'copyStateFromChannelOnEvent': {
            var channel = action.channel;
            return __assign(__assign({}, state), { members: __assign({}, channel.state.members), messages: __spreadArray([], channel.state.messages, true), pinnedMessages: __spreadArray([], channel.state.pinnedMessages, true), read: __assign({}, channel.state.read), watcherCount: channel.state.watcher_count, watchers: __assign({}, channel.state.watchers) });
        }
        case 'initStateFromChannel': {
            var channel = action.channel, hasMore = action.hasMore;
            return __assign(__assign({}, state), { hasMore: hasMore, loading: false, members: __assign({}, channel.state.members), messages: __spreadArray([], channel.state.messages, true), pinnedMessages: __spreadArray([], channel.state.pinnedMessages, true), read: __assign({}, channel.state.read), watcherCount: channel.state.watcher_count, watchers: __assign({}, channel.state.watchers) });
        }
        case 'jumpToLatestMessage': {
            return __assign(__assign({}, state), { hasMoreNewer: false, highlightedMessageId: undefined, loading: false, suppressAutoscroll: false });
        }
        case 'jumpToMessageFinished': {
            return __assign(__assign({}, state), { hasMoreNewer: action.hasMoreNewer, highlightedMessageId: action.highlightedMessageId });
        }
        case 'clearHighlightedMessage': {
            return __assign(__assign({}, state), { highlightedMessageId: undefined });
        }
        case 'loadMoreFinished': {
            var hasMore = action.hasMore, messages = action.messages;
            return __assign(__assign({}, state), { hasMore: hasMore, loadingMore: false, messages: messages, suppressAutoscroll: false });
        }
        case 'loadMoreNewerFinished': {
            var hasMoreNewer = action.hasMoreNewer, messages = action.messages;
            return __assign(__assign({}, state), { hasMoreNewer: hasMoreNewer, loadingMoreNewer: false, messages: messages });
        }
        case 'loadMoreThreadFinished': {
            var threadHasMore = action.threadHasMore, threadMessages = action.threadMessages;
            return __assign(__assign({}, state), { threadHasMore: threadHasMore, threadLoadingMore: false, threadMessages: threadMessages });
        }
        case 'openThread': {
            var channel = action.channel, message = action.message;
            return __assign(__assign({}, state), { thread: message, threadHasMore: true, threadMessages: message.id ? __assign({}, channel.state.threads)[message.id] || [] : [], threadSuppressAutoscroll: false });
        }
        case 'setError': {
            var error = action.error;
            return __assign(__assign({}, state), { error: error });
        }
        case 'setLoadingMore': {
            var loadingMore = action.loadingMore;
            // suppress the autoscroll behavior
            return __assign(__assign({}, state), { loadingMore: loadingMore, suppressAutoscroll: loadingMore });
        }
        case 'setLoadingMoreNewer': {
            var loadingMoreNewer = action.loadingMoreNewer;
            return __assign(__assign({}, state), { loadingMoreNewer: loadingMoreNewer });
        }
        case 'setThread': {
            var message = action.message;
            return __assign(__assign({}, state), { thread: message });
        }
        case 'setTyping': {
            var channel = action.channel;
            return __assign(__assign({}, state), { typing: __assign({}, channel.state.typing) });
        }
        case 'startLoadingThread': {
            return __assign(__assign({}, state), { threadLoadingMore: true, threadSuppressAutoscroll: true });
        }
        case 'updateThreadOnEvent': {
            var channel = action.channel, message = action.message;
            if (!state.thread)
                return state;
            return __assign(__assign({}, state), { thread: (message === null || message === void 0 ? void 0 : message.id) === state.thread.id ? channel.state.formatMessage(message) : state.thread, threadMessages: ((_a = state.thread) === null || _a === void 0 ? void 0 : _a.id) ? __assign({}, channel.state.threads)[state.thread.id] || [] : [] });
        }
        default:
            return state;
    }
};
export var initialState = {
    error: null,
    hasMore: true,
    hasMoreNewer: false,
    loading: true,
    loadingMore: false,
    members: {},
    messages: [],
    pinnedMessages: [],
    read: {},
    suppressAutoscroll: false,
    thread: null,
    threadHasMore: true,
    threadLoadingMore: false,
    threadMessages: [],
    threadSuppressAutoscroll: false,
    typing: {},
    watcherCount: 0,
    watchers: {},
};
