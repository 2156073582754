"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
exports.default = (0, react_1.styled)(react_native_1.Pressable, {
    ':focusVisible': {
        _web: {
            outlineWidth: 0,
            boxShadow: `$cyan.400 0px 0px 0px 2px`,
        },
    },
    '_dark': {
        ':focusVisible': {
            _web: {
                outlineWidth: 0,
                boxShadow: `$cyan.500 0px 0px 0px 2px`,
            },
        },
    },
}, {
    componentName: 'Pressable',
});
