"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.View, {
    ':invalid': {
        borderColor: '$error.500',
    },
    'borderWidth': 2,
    'borderRadius': '$full',
    'p': '$1',
    'bg': '$muted.50',
    'borderColor': '$muted.400',
    'my': '$1',
    'justifyContent': 'center',
    'alignItems': 'center',
}, {
    componentName: 'RadioIndicator',
    ancestorStyle: ['_indicator'],
    descendantStyle: ['_icon'],
});
