import { useCallback, useEffect, useState } from 'react';
/**
 * Controls the logic when an `UnreadMessagesNotification` component should be shown.
 * In virtualized message list there is no notion of being scrolled below or above `UnreadMessagesSeparator`.
 * Therefore, the `UnreadMessagesNotification` component is rendered based on timestamps.
 * If the there are unread messages in the channel and the `VirtualizedMessageList` renders
 * messages created later than the last read message in the channel, then the
 * `UnreadMessagesNotification` component is rendered. This is an approximate equivalent to being
 * scrolled below the `UnreadMessagesNotification` component.
 * @param lastRead
 * @param showAlways
 * @param unreadCount
 */
export var useUnreadMessagesNotificationVirtualized = function (_a) {
    var lastRead = _a.lastRead, showAlways = _a.showAlways, unreadCount = _a.unreadCount;
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var toggleShowUnreadMessagesNotification = useCallback(function (renderedMessages) {
        if (!unreadCount)
            return;
        var firstRenderedMessage = renderedMessages[0];
        var lastRenderedMessage = renderedMessages.slice(-1)[0];
        if (!(firstRenderedMessage && lastRenderedMessage))
            return;
        var scrolledBelowSeparator = !!lastRead &&
            new Date(firstRenderedMessage.created_at).getTime() > lastRead.getTime();
        var scrolledAboveSeparator = !!lastRead &&
            new Date(lastRenderedMessage.created_at).getTime() < lastRead.getTime();
        setShow(showAlways ? scrolledBelowSeparator || scrolledAboveSeparator : scrolledBelowSeparator);
    }, [lastRead, showAlways, unreadCount]);
    useEffect(function () {
        if (!unreadCount)
            setShow(false);
    }, [unreadCount]);
    return { show: show, toggleShowUnreadMessagesNotification: toggleShowUnreadMessagesNotification };
};
