import { __assign } from "tslib";
import React from 'react';
import { FileIcon } from './FileIcon';
import { LoadingIndicator } from './LoadingIndicator';
/**
 * Component that displays files which are being uploaded
 */
export var FilePreviewer = function (_a) {
    var _b = _a.fileIconProps, fileIconProps = _b === void 0 ? {} : _b, uploads = _a.uploads, handleRemove = _a.handleRemove, handleRetry = _a.handleRetry;
    return (React.createElement("div", { className: 'rfu-file-previewer' },
        React.createElement("ol", null, uploads === null || uploads === void 0 ? void 0 : uploads.map(function (upload) { return (React.createElement("li", { className: "rfu-file-previewer__file ".concat(upload.state === 'uploading' ? 'rfu-file-previewer__file--uploading' : '', " ").concat(upload.state === 'failed' ? 'rfu-file-previewer__file--failed' : ''), key: upload.id },
            React.createElement(FileIcon, __assign({ mimeType: upload.file.type }, fileIconProps)),
            React.createElement("a", { download: true, href: upload.url },
                upload.file.name,
                upload.state === 'failed' && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'rfu-file-previewer__failed', onClick: function () { return handleRetry === null || handleRetry === void 0 ? void 0 : handleRetry(upload.id); } }, "failed"),
                    React.createElement("div", { className: 'rfu-file-previewer__retry', onClick: function () { return handleRetry === null || handleRetry === void 0 ? void 0 : handleRetry(upload.id); } }, "retry")))),
            React.createElement("span", { className: 'rfu-file-previewer__close-button', onClick: handleRemove && (function () { return handleRemove(upload.id); }) }, "\u2718"),
            upload.state === 'uploading' && (React.createElement("div", { className: 'rfu-file-previewer__loading-indicator' },
                React.createElement(LoadingIndicator, null))))); }))));
};
