"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Box = void 0;
const react_1 = __importStar(require("react"));
const styled_components_1 = require("./styled-components");
const Text_1 = require("../Text");
const usePropResolution_1 = require("../../hooks/usePropResolution");
const Provider_1 = require("../Provider");
const hooks_1 = require("../../hooks");
const BoxTemp = (0, react_1.forwardRef)((_a, ref) => {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    var { children } = _a, props = __rest(_a, ["children"]);
    const GUIChildren = react_1.Children.map(children, (child) => {
        if (typeof child === 'string')
            return react_1.default.createElement(Text_1.Text, null, child);
        return child;
    });
    const resolvedPropForGluestack = (0, usePropResolution_1.usePropResolution)(props);
    const { config } = react_1.default.useContext(Provider_1.HooksContext);
    const Gradient = config === null || config === void 0 ? void 0 : config['linear-gradient'];
    const lightModeGradient = ((_c = (_b = props === null || props === void 0 ? void 0 : props._light) === null || _b === void 0 ? void 0 : _b.bg) === null || _c === void 0 ? void 0 : _c.linearGradient) ||
        ((_e = (_d = props === null || props === void 0 ? void 0 : props._light) === null || _d === void 0 ? void 0 : _d.background) === null || _e === void 0 ? void 0 : _e.linearGradient) ||
        ((_g = (_f = props === null || props === void 0 ? void 0 : props._light) === null || _f === void 0 ? void 0 : _f.bgColor) === null || _g === void 0 ? void 0 : _g.linearGradient) ||
        ((_j = (_h = props === null || props === void 0 ? void 0 : props._light) === null || _h === void 0 ? void 0 : _h.backgroundColor) === null || _j === void 0 ? void 0 : _j.linearGradient);
    const darkModeGradient = ((_l = (_k = props === null || props === void 0 ? void 0 : props._dark) === null || _k === void 0 ? void 0 : _k.bg) === null || _l === void 0 ? void 0 : _l.linearGradient) ||
        ((_o = (_m = props === null || props === void 0 ? void 0 : props._dark) === null || _m === void 0 ? void 0 : _m.background) === null || _o === void 0 ? void 0 : _o.linearGradient) ||
        ((_q = (_p = props === null || props === void 0 ? void 0 : props._dark) === null || _p === void 0 ? void 0 : _p.bgColor) === null || _q === void 0 ? void 0 : _q.linearGradient) ||
        ((_s = (_r = props === null || props === void 0 ? void 0 : props._dark) === null || _r === void 0 ? void 0 : _r.backgroundColor) === null || _s === void 0 ? void 0 : _s.linearGradient);
    const neutralGradient = ((_t = props === null || props === void 0 ? void 0 : props.bg) === null || _t === void 0 ? void 0 : _t.linearGradient) ||
        ((_u = props === null || props === void 0 ? void 0 : props.background) === null || _u === void 0 ? void 0 : _u.linearGradient) ||
        ((_v = props === null || props === void 0 ? void 0 : props.bgColor) === null || _v === void 0 ? void 0 : _v.linearGradient) ||
        ((_w = props === null || props === void 0 ? void 0 : props.backgroundColor) === null || _w === void 0 ? void 0 : _w.linearGradient);
    const colorModeGradient = (0, hooks_1.useColorModeValue)(lightModeGradient || neutralGradient, darkModeGradient || neutralGradient);
    if (colorModeGradient) {
        const lgrad = colorModeGradient;
        delete resolvedPropForGluestack.sx['@linearGradient'];
        if (Gradient) {
            let startObj = { x: 0, y: 0 };
            let endObj = { x: 0, y: 1 };
            if (lgrad.start && lgrad.start.length === 2) {
                startObj = {
                    x: lgrad.start[0],
                    y: lgrad.start[1],
                };
            }
            if (lgrad.end && lgrad.end.length === 2) {
                endObj = {
                    x: lgrad.end[0],
                    y: lgrad.end[1],
                };
            }
            return (react_1.default.createElement(styled_components_1.Root, Object.assign({ as: Gradient }, resolvedPropForGluestack, { colors: lgrad.colors, start: startObj, end: endObj, locations: lgrad.locations, ref: ref }), GUIChildren));
        }
    }
    return (react_1.default.createElement(styled_components_1.Root, Object.assign({}, resolvedPropForGluestack, { ref: ref }), GUIChildren));
});
const BoxNew = BoxTemp;
exports.Box = BoxNew;
