import React, { useMemo } from 'react';
import ImageGallery from 'react-image-gallery';
import { BaseImage } from './BaseImage';
import { useTranslationContext } from '../../context';
var onError = function (e) {
    // Prevent having alt attribute on img as the img takes the height of the alt text
    // instead of the CSS / element width & height when the CSS mask (fallback) is applied.
    e.target.alt = '';
};
var renderItem = function (_a) {
    var original = _a.original, originalAlt = _a.originalAlt;
    return (React.createElement(BaseImage, { alt: originalAlt, className: 'image-gallery-image', onError: onError, src: original }));
};
export var ModalGallery = function (props) {
    var images = props.images, index = props.index;
    var t = useTranslationContext('ModalGallery').t;
    var formattedArray = useMemo(function () {
        return images.map(function (image) {
            var imageSrc = image.image_url || image.thumb_url || '';
            return {
                original: imageSrc,
                originalAlt: t('User uploaded content'),
                source: imageSrc,
            };
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [images]);
    return (React.createElement(ImageGallery, { items: formattedArray, renderItem: renderItem, showIndex: true, showPlayButton: false, showThumbnails: false, startIndex: index }));
};
