import { __assign, __awaiter, __generator, __rest, __spreadArray } from "tslib";
import { useEffect, useRef } from 'react';
import { useChannelActionContext } from '../../../context/ChannelActionContext';
import { useChannelStateContext } from '../../../context/ChannelStateContext';
import { useTranslationContext } from '../../../context/TranslationContext';
import { LinkPreviewState } from '../types';
var getAttachmentTypeFromMime = function (mime) {
    if (mime.includes('video/'))
        return 'video';
    if (mime.includes('audio/'))
        return 'audio';
    return 'file';
};
export var useSubmitHandler = function (props, state, dispatch, numberOfUploads, enrichURLsController) {
    var clearEditingState = props.clearEditingState, message = props.message, overrideSubmitHandler = props.overrideSubmitHandler, parent = props.parent, publishTypingEvent = props.publishTypingEvent;
    var attachments = state.attachments, fileOrder = state.fileOrder, fileUploads = state.fileUploads, imageOrder = state.imageOrder, imageUploads = state.imageUploads, linkPreviews = state.linkPreviews, mentioned_users = state.mentioned_users, text = state.text;
    var cancelURLEnrichment = enrichURLsController.cancelURLEnrichment, findAndEnqueueURLsToEnrich = enrichURLsController.findAndEnqueueURLsToEnrich;
    var channel = useChannelStateContext('useSubmitHandler').channel;
    var _a = useChannelActionContext('useSubmitHandler'), addNotification = _a.addNotification, editMessage = _a.editMessage, sendMessage = _a.sendMessage;
    var t = useTranslationContext('useSubmitHandler').t;
    var textReference = useRef({ hasChanged: false, initialText: text });
    useEffect(function () {
        if (!textReference.current.initialText.length) {
            textReference.current.initialText = text;
            return;
        }
        textReference.current.hasChanged = text !== textReference.current.initialText;
    }, [text]);
    var getAttachmentsFromUploads = function () {
        var imageAttachments = imageOrder
            .map(function (id) { return imageUploads[id]; })
            .filter(function (upload) { return upload.state !== 'failed'; })
            .filter(function (_a, _, self) {
            var id = _a.id, url = _a.url;
            return self.every(function (upload) { return upload.id === id || upload.url !== url; });
        })
            .filter(function (upload) {
            // keep the OG attachments in case the text has not changed as the BE
            // won't re-enrich the message when only attachments have changed
            if (!textReference.current.hasChanged)
                return true;
            return !upload.og_scrape_url;
        })
            .map(function (_a) {
            var name = _a.file.name, url = _a.url, rest = __rest(_a, ["file", "url"]);
            return ({
                author_name: rest.author_name,
                fallback: name,
                image_url: url,
                og_scrape_url: rest.og_scrape_url,
                text: rest.text,
                title: rest.title,
                title_link: rest.title_link,
                type: 'image',
            });
        });
        var fileAttachments = fileOrder
            .map(function (id) { return fileUploads[id]; })
            .filter(function (upload) { return upload.state !== 'failed'; })
            .map(function (upload) { return ({
            asset_url: upload.url,
            file_size: upload.file.size,
            mime_type: upload.file.type,
            thumb_url: upload.thumb_url,
            title: upload.file.name,
            type: getAttachmentTypeFromMime(upload.file.type || ''),
        }); });
        var otherAttachments = attachments
            .filter(function (att) { var _a; return ((_a = att.localMetadata) === null || _a === void 0 ? void 0 : _a.uploadState) !== 'failed'; })
            .map(function (localAttachment) {
            var _ = localAttachment.localMetadata, attachment = __rest(localAttachment, ["localMetadata"]);
            return attachment;
        });
        return __spreadArray(__spreadArray(__spreadArray([], otherAttachments, true), imageAttachments, true), fileAttachments, true);
    };
    var handleSubmit = function (event, customMessageData, options) { return __awaiter(void 0, void 0, void 0, function () {
        var trimmedMessage, isEmptyMessage, someAttachmentsUploading, attachmentsFromUploads, attachmentsFromLinkPreviews, someLinkPreviewsLoading, someLinkPreviewsDismissed, newAttachments, actualMentionedUsers, updatedMessage, linkPreviewsEnabled, skip_enrich_url, sendOptions, err_1, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event === null || event === void 0 ? void 0 : event.preventDefault();
                    trimmedMessage = text.trim();
                    isEmptyMessage = trimmedMessage === '' ||
                        trimmedMessage === '>' ||
                        trimmedMessage === '``````' ||
                        trimmedMessage === '``' ||
                        trimmedMessage === '**' ||
                        trimmedMessage === '____' ||
                        trimmedMessage === '__' ||
                        trimmedMessage === '****';
                    if (isEmptyMessage && numberOfUploads === 0 && attachments.length === 0)
                        return [2 /*return*/];
                    someAttachmentsUploading = Object.values(imageUploads).some(function (upload) { return upload.state === 'uploading'; }) ||
                        Object.values(fileUploads).some(function (upload) { return upload.state === 'uploading'; }) ||
                        attachments.some(function (att) { var _a; return ((_a = att.localMetadata) === null || _a === void 0 ? void 0 : _a.uploadState) === 'uploading'; });
                    if (someAttachmentsUploading) {
                        return [2 /*return*/, addNotification(t('Wait until all attachments have uploaded'), 'error')];
                    }
                    attachmentsFromUploads = getAttachmentsFromUploads();
                    attachmentsFromLinkPreviews = [];
                    if (findAndEnqueueURLsToEnrich) {
                        // filter out all the attachments scraped before the message was edited - only if the scr
                        attachmentsFromUploads = attachmentsFromUploads.filter(function (attachment) { return !attachment.og_scrape_url; });
                        // prevent showing link preview in MessageInput after the message has been sent
                        cancelURLEnrichment();
                        someLinkPreviewsLoading = Array.from(linkPreviews.values()).some(function (linkPreview) {
                            return [LinkPreviewState.QUEUED, LinkPreviewState.LOADING].includes(linkPreview.state);
                        });
                        someLinkPreviewsDismissed = Array.from(linkPreviews.values()).some(function (linkPreview) { return linkPreview.state === LinkPreviewState.DISMISSED; });
                        if (!someLinkPreviewsLoading) {
                            attachmentsFromLinkPreviews = Array.from(linkPreviews.values())
                                .filter(function (linkPreview) {
                                return linkPreview.state === LinkPreviewState.LOADED &&
                                    !attachmentsFromUploads.find(function (attFromUpload) { return attFromUpload.og_scrape_url === linkPreview.og_scrape_url; });
                            })
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                .map(function (_a) {
                                var linkPreviewState = _a.state, ogAttachment = __rest(_a, ["state"]);
                                return ogAttachment;
                            });
                        }
                    }
                    newAttachments = __spreadArray(__spreadArray([], attachmentsFromUploads, true), attachmentsFromLinkPreviews, true);
                    actualMentionedUsers = Array.from(new Set(mentioned_users.filter(function (_a) {
                        var id = _a.id, name = _a.name;
                        return text.includes("@".concat(id)) || text.includes("@".concat(name));
                    })));
                    updatedMessage = {
                        attachments: newAttachments,
                        mentioned_users: actualMentionedUsers,
                        text: text,
                    };
                    linkPreviewsEnabled = !!findAndEnqueueURLsToEnrich;
                    skip_enrich_url = linkPreviewsEnabled &&
                        ((!someLinkPreviewsLoading && attachmentsFromLinkPreviews.length > 0) ||
                            someLinkPreviewsDismissed);
                    sendOptions = linkPreviewsEnabled || options
                        ? Object.assign(linkPreviewsEnabled ? { skip_enrich_url: skip_enrich_url } : {}, options !== null && options !== void 0 ? options : {})
                        : undefined;
                    if (!(message && message.type !== 'error')) return [3 /*break*/, 5];
                    delete message.i18n;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, editMessage(__assign(__assign(__assign({}, message), updatedMessage), customMessageData), sendOptions)];
                case 2:
                    _a.sent();
                    clearEditingState === null || clearEditingState === void 0 ? void 0 : clearEditingState();
                    dispatch({ type: 'clear' });
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    addNotification(t('Edit message request failed'), 'error');
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 13];
                case 5:
                    _a.trys.push([5, 12, , 13]);
                    dispatch({ type: 'clear' });
                    if (!overrideSubmitHandler) return [3 /*break*/, 7];
                    return [4 /*yield*/, overrideSubmitHandler(__assign(__assign({}, updatedMessage), { parent: parent }), channel.cid, customMessageData, sendOptions)];
                case 6:
                    _a.sent();
                    return [3 /*break*/, 9];
                case 7: return [4 /*yield*/, sendMessage(__assign(__assign({}, updatedMessage), { parent: parent }), customMessageData, sendOptions)];
                case 8:
                    _a.sent();
                    _a.label = 9;
                case 9:
                    if (!publishTypingEvent) return [3 /*break*/, 11];
                    return [4 /*yield*/, channel.stopTyping()];
                case 10:
                    _a.sent();
                    _a.label = 11;
                case 11: return [3 /*break*/, 13];
                case 12:
                    err_2 = _a.sent();
                    dispatch({
                        getNewText: function () { return text; },
                        type: 'setText',
                    });
                    actualMentionedUsers === null || actualMentionedUsers === void 0 ? void 0 : actualMentionedUsers.forEach(function (user) {
                        dispatch({ type: 'addMentionedUser', user: user });
                    });
                    addNotification(t('Send message request failed'), 'error');
                    return [3 /*break*/, 13];
                case 13: return [2 /*return*/];
            }
        });
    }); };
    return { handleSubmit: handleSubmit };
};
