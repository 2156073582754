import { useEffect, useRef, useState } from 'react';
export var useMessageSetKey = function (_a) {
    var messages = _a.messages;
    /**
     * Logic to update the key of the virtuoso component when the list jumps to a new location.
     */
    var _b = useState(+new Date()), messageSetKey = _b[0], setMessageSetKey = _b[1];
    var firstMessageId = useRef();
    useEffect(function () {
        var _a;
        var continuousSet = messages === null || messages === void 0 ? void 0 : messages.find(function (message) { return message.id === firstMessageId.current; });
        if (!continuousSet) {
            setMessageSetKey(+new Date());
        }
        firstMessageId.current = (_a = messages === null || messages === void 0 ? void 0 : messages[0]) === null || _a === void 0 ? void 0 : _a.id;
    }, [messages]);
    return {
        messageSetKey: messageSetKey,
    };
};
