"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.View, {
    alignItems: 'center',
    justifyContent: 'center',
}, {
    componentName: 'Center',
    descendantStyle: ['_text'],
});
