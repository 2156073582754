import React from 'react';
export var DownloadIcon = function (_a) {
    var className = _a.className;
    return (React.createElement("svg", { className: className, "data-testid": 'download', fill: 'none', height: '24', viewBox: '0 0 24 24', width: '24', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("path", { d: 'M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM19 18H6C3.79 18 2 16.21 2 14C2 11.95 3.53 10.24 5.56 10.03L6.63 9.92L7.13 8.97C8.08 7.14 9.94 6 12 6C14.62 6 16.88 7.86 17.39 10.43L17.69 11.93L19.22 12.04C20.78 12.14 22 13.45 22 15C22 16.65 20.65 18 19 18ZM13.45 10H10.55V13H8L12 17L16 13H13.45V10Z', fill: 'black' })));
};
export var PlayTriangleIcon = function () { return (React.createElement("svg", { fill: 'none', viewBox: '0 0 12 14', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { d: 'M0.5 0V14L11.5 7L0.5 0Z', fill: '#080707' }))); };
export var PauseIcon = function () { return (React.createElement("svg", { fill: 'none', viewBox: '0 0 12 14', xmlns: 'http://www.w3.org/2000/svg' },
    React.createElement("path", { d: 'M0 14H4V0H0V14ZM8 0V14H12V0H8Z', fill: '#080707' }))); };
