import { useChannelStateContext } from '../../../../context';
import { useEffect, useRef, useState } from 'react';
import { MESSAGE_LIST_MAIN_PANEL_CLASS } from '../../MessageListMainPanel';
import { UNREAD_MESSAGE_SEPARATOR_CLASS } from '../../UnreadMessagesSeparator';
var targetScrolledAboveVisibleContainerArea = function (element) {
    var targetBottom = element.getBoundingClientRect().bottom;
    return targetBottom < 0;
};
var targetScrolledBelowVisibleContainerArea = function (element, container) {
    var targetTop = element.getBoundingClientRect().top;
    var containerBottom = container.getBoundingClientRect().top;
    return targetTop > containerBottom;
};
export var useUnreadMessagesNotification = function (_a) {
    var isMessageListScrolledToBottom = _a.isMessageListScrolledToBottom, showAlways = _a.showAlways, unreadCount = _a.unreadCount;
    var messages = useChannelStateContext('UnreadMessagesNotification').messages;
    var _b = useState(false), show = _b[0], setShow = _b[1];
    var isScrolledAboveTargetTop = useRef(false);
    var intersectionObserverIsSupported = typeof IntersectionObserver !== 'undefined';
    useEffect(function () {
        if (!(unreadCount && intersectionObserverIsSupported)) {
            setShow(false);
            return;
        }
        var msgListPanel = document.querySelector(".".concat(MESSAGE_LIST_MAIN_PANEL_CLASS));
        if (!msgListPanel)
            return;
        var observedTarget = document.querySelector(".".concat(UNREAD_MESSAGE_SEPARATOR_CLASS));
        if (!observedTarget) {
            setShow(true);
            return;
        }
        var scrolledBelowSeparator = targetScrolledAboveVisibleContainerArea(observedTarget);
        var scrolledAboveSeparator = targetScrolledBelowVisibleContainerArea(observedTarget, msgListPanel);
        setShow(showAlways ? scrolledBelowSeparator || scrolledAboveSeparator : scrolledBelowSeparator);
        var observer = new IntersectionObserver(function (elements) {
            if (!elements.length)
                return;
            var _a = elements[0], boundingClientRect = _a.boundingClientRect, isIntersecting = _a.isIntersecting;
            if (isIntersecting) {
                setShow(false);
                return;
            }
            var separatorIsAboveContainerTop = boundingClientRect.bottom < 0;
            setShow(showAlways || separatorIsAboveContainerTop);
            isScrolledAboveTargetTop.current = separatorIsAboveContainerTop;
        }, { root: msgListPanel });
        observer.observe(observedTarget);
        return function () {
            observer.disconnect();
        };
    }, [
        intersectionObserverIsSupported,
        isMessageListScrolledToBottom,
        messages,
        showAlways,
        unreadCount,
    ]);
    useEffect(function () {
        /**
         * Handle situation when scrollToBottom is called from another component when the msg list is scrolled above the observed target (unread separator).
         * The intersection observer is not triggered when Element.scrollTo() is called. So we end up in a situation when we are scrolled to the bottom
         * and at the same time scrolled above the observed target.
         */
        if (unreadCount && isMessageListScrolledToBottom && isScrolledAboveTargetTop.current) {
            setShow(true);
            isScrolledAboveTargetTop.current = false;
        }
    }, [isMessageListScrolledToBottom, unreadCount]);
    return { show: show && intersectionObserverIsSupported };
};
