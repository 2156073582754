"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputRightAddon = exports.InputLeftAddon = exports.InputGroup = exports.Input = void 0;
const react_1 = __importStar(require("react"));
const input_1 = require("@gluestack-ui/input");
const styled_components_1 = require("./styled-components");
const Text_1 = require("../Text");
const usePropResolution_1 = require("../../hooks/usePropResolution");
const utils_1 = require("../../utils");
const AccessibleInput = (0, input_1.createInput)({
    Root: styled_components_1.Root,
    Icon: styled_components_1.Icon,
    Slot: styled_components_1.Slot,
    Input: styled_components_1.Input,
});
const InputTemp = (0, react_1.forwardRef)((_a, ref) => {
    var _b;
    var { variant, isHovered, isFocused, isDisabled, isInvalid, isRequired, isReadonly, InputLeftElement, InputRightElement, leftElement, rightElement, placeholder, type, onChangeText, onChange, value, wrapperRef, isFullWidth, _input = {}, _stack = {} } = _a, props = __rest(_a, ["variant", "isHovered", "isFocused", "isDisabled", "isInvalid", "isRequired", "isReadonly", "InputLeftElement", "InputRightElement", "leftElement", "rightElement", "placeholder", "type", "onChangeText", "onChange", "value", "wrapperRef", "isFullWidth", "_input", "_stack"]);
    const [stackProps, inputProps] = (0, utils_1.splitObject)(Object.assign({}, props));
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(Object.assign(Object.assign({}, _stack), stackProps));
    const resolvedPropsForInput = (0, usePropResolution_1.usePropResolution)(Object.assign(Object.assign({}, _input), inputProps));
    return (react_1.default.createElement(AccessibleInput, Object.assign({ ref: wrapperRef }, resolvedProps, { variant: variant, isHovered: isHovered, isFocused: isFocused, isDisabled: isDisabled, isInvalid: isInvalid, isRequired: isRequired, isReadonly: isReadonly, width: isFullWidth && '$full' }),
        InputLeftElement && InputLeftElement,
        leftElement && leftElement,
        react_1.default.createElement(AccessibleInput.Input, Object.assign({}, resolvedPropsForInput, { placeholder: placeholder, value: value, type: type, onChangeText: (_b = (onChangeText && onChangeText)) !== null && _b !== void 0 ? _b : (onChange && onChange), ref: ref })),
        InputRightElement && InputRightElement,
        rightElement && rightElement));
});
exports.Input = InputTemp;
const InputGroupTemp = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(props);
    const ChildrenStyled = react_1.Children.map(children, (child, index) => {
        if (index === 0)
            return (0, react_1.cloneElement)(child, {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
            });
        if (index === react_1.Children.count(children) - 1)
            return (0, react_1.cloneElement)(child, {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            });
        return (0, react_1.cloneElement)(child, {
            // @ts-ignore
            borderRadius: 0,
        });
    });
    return (react_1.default.createElement(styled_components_1.Group, Object.assign({}, resolvedProps, { ref: ref }), ChildrenStyled));
});
exports.InputGroup = InputGroupTemp;
const InputLeftAddonTemp = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(styled_components_1.LeftAddon, Object.assign({}, props, { ref: ref }), typeof children === 'string' ? react_1.default.createElement(Text_1.Text, null, children) : children));
});
exports.InputLeftAddon = InputLeftAddonTemp;
const InputRightAddonTemp = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(styled_components_1.RightAddon, Object.assign({}, props, { ref: ref }), typeof children === 'string' ? react_1.default.createElement(Text_1.Text, null, children) : children));
});
exports.InputRightAddon = InputRightAddonTemp;
