"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
const plugins_1 = require("../../../plugins");
exports.default = (0, react_1.styled)(react_native_1.Text, {
    mx: '$2',
    // @ts-ignore
    fontSize: '$md',
    fontFamily: '$body',
    fontWeight: '$normal',
    // @ts-ignore
    lineHeight: '$md',
    color: '$textLight.800',
    _dark: {
        color: '$textDark.100',
    },
    fontStyle: 'normal',
    letterSpacing: '$md',
}, {
    componentName: 'ActionsheetItemText',
    ancestorStyle: ['_text'],
}, {
    plugins: [new plugins_1.TextStyleResolver()],
});
