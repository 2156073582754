"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinusIcon = void 0;
const styled_components_1 = require("../styled-components");
const MinusIcon = (0, styled_components_1.createIcon)({
    viewBox: '0 0 24 24',
    d: 'M22 10.5H2V13H22V10.5Z',
});
exports.MinusIcon = MinusIcon;
MinusIcon.displayName = 'MinusIcon';
