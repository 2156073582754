import clsx from 'clsx';
import React from 'react';
export var Anchor = function (_a) {
    var children = _a.children, href = _a.href;
    var isEmail = href === null || href === void 0 ? void 0 : href.startsWith('mailto:');
    var isUrl = href === null || href === void 0 ? void 0 : href.startsWith('http');
    if (!href || (!isEmail && !isUrl))
        return React.createElement(React.Fragment, null, children);
    return (React.createElement("a", { className: clsx({ 'str-chat__message-url-link': isUrl }), href: href, rel: 'nofollow noreferrer noopener', target: '_blank' }, children));
};
