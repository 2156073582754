"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
const plugins_1 = require("../../../plugins");
exports.default = (0, react_1.styled)(react_native_1.View, {
    'flexDirection': 'row',
    'justifyContent': 'flex-start',
    'alignItems': 'center',
    // @ts-ignore
    'gap': '$2',
    // '_indicator': {
    //   backgroundColor: '$muted.50',
    //   borderColor: '$muted.400',
    // },
    // ':checked': {
    //   '_indicator': {
    //     borderColor: `$primary.600`,
    //   },
    //   '_icon': {
    //     color: `$primary.600`,
    //   },
    //   ':hover': {
    //     '_indicator': {
    //       borderColor: `$primary.700`,
    //     },
    //     '_icon': { color: `$primary.700` },
    //     ':disabled': {
    //       _indicator: {
    //         borderColor: `$primary.600`,
    //       },
    //       _icon: {
    //         color: `$primary.600`,
    //       },
    //     },
    //   },
    //   ':active': {
    //     _indicator: {
    //       borderColor: `$primary.800`,
    //     },
    //     _icon: { color: `$primary.800` },
    //   },
    // },
    // ':hover': {
    //   '_indicator': {
    //     borderColor: '$muted.500',
    //   },
    //   ':disabled': {
    //     _indicator: {
    //       borderColor: '$muted.400',
    //     },
    //   },
    //   ':checked': {
    //     _indicator: { borderColor: `$primary.600` },
    //   },
    // },
    // ':active': {
    //   _indicator: {
    //     borderColor: '$muted.600',
    //   },
    // },
    // ':invalid': {
    //   _indicator: {
    //     borderColor: '$error.600',
    //   },
    // },
    // // dark mode
    // '_dark': {
    //   '_indicator': {
    //     backgroundColor: '$muted.900',
    //     borderColor: '$muted.500',
    //   },
    //   ':checked': {
    //     '_indicator': {
    //       borderColor: `$primary.500`,
    //     },
    //     '_icon': {
    //       color: `$primary.500`,
    //     },
    //     ':hover': {
    //       '_indicator': {
    //         borderColor: `$primary.400`,
    //       },
    //       '_icon': { color: `$primary.400` },
    //       ':disabled': {
    //         _indicator: {
    //           borderColor: `$primary.500`,
    //         },
    //         _icon: {
    //           color: `$primary.500`,
    //         },
    //       },
    //     },
    //     ':active': {
    //       _indicator: {
    //         borderColor: `$primary.300`,
    //       },
    //       _icon: { color: `$primary.300` },
    //     },
    //   },
    //   ':hover': {
    //     '_indicator': {
    //       borderColor: '$muted.400',
    //     },
    //     ':disabled': {
    //       _indicator: {
    //         borderColor: '$muted.500',
    //       },
    //     },
    //     ':checked': {
    //       _indicator: { borderColor: `$primary.600` },
    //     },
    //   },
    //   ':active': {
    //     _indicator: {
    //       borderColor: '$muted.300',
    //     },
    //   },
    ':invalid': {
    // _indicator: {
    //   borderColor: '$error.500',
    // },
    },
    // },
    ':disabled': {
        _web: {
            cursor: 'not-allowed',
        },
        opacity: 0.6,
        _icon: {
            backgroundColor: 'transparent',
        },
    },
    '_web': {
        'cursor': 'pointer',
        ':focusVisible': {
            _indicator: {
                outlineWidth: '2px',
                outlineColor: '$error.500',
                outlineStyle: 'solid',
            },
        },
    },
    'variants': {
        colorScheme: {},
        size: {
            lg: {
                _text: {
                    // @ts-ignore
                    fontSize: '$lg',
                    // @ts-ignore
                    lineHeight: '$xl',
                },
                _indicator: {
                    height: '$7',
                    width: '$7',
                },
            },
            md: {
                _text: {
                    // @ts-ignore
                    fontSize: '$md',
                    // @ts-ignore
                    lineHeight: '$md',
                },
                _indicator: {
                    height: '$6',
                    width: '$6',
                },
            },
            sm: {
                _text: {
                    // @ts-ignore
                    fontSize: '$sm',
                    // @ts-ignore
                    lineHeight: '$sm',
                },
                _indicator: {
                    height: '$5',
                    width: '$5',
                },
            },
        },
    },
    'defaultProps': {
        size: 'md',
    },
}, {
    componentName: 'Radio',
    descendantStyle: ['_icon', '_text', '_indicator'],
    ancestorStyle: ['_radio'],
}, {
    plugins: [new plugins_1.ColorSchemeResolver(colorSchemeResolveFn)],
});
function colorSchemeResolveFn(_a) {
    var props = __rest(_a, []);
    let value = {};
    if (props.colorScheme) {
        const color = props.colorScheme;
        value = {
            ':checked': {
                '_indicator': {
                    borderColor: `$${color}.600`,
                },
                '_icon': {
                    color: `$${color}.600`,
                },
                ':hover': {
                    '_indicator': {
                        borderColor: `$${color}.700`,
                    },
                    '_icon': { color: `$${color}.700` },
                    ':disabled': {
                        _indicator: {
                            borderColor: `$${color}.600`,
                        },
                        _icon: {
                            color: `$${color}.600`,
                        },
                    },
                },
                ':active': {
                    _indicator: {
                        borderColor: `$${color}.800`,
                    },
                    _icon: { color: `$${color}.800` },
                },
            },
            '_dark': {
                ':checked': {
                    '_indicator': {
                        borderColor: `$${color}.500`,
                    },
                    '_icon': {
                        color: `$${color}.500`,
                    },
                    ':hover': {
                        '_indicator': {
                            borderColor: `$${color}.400`,
                        },
                        '_icon': { color: `$${color}.400` },
                        ':disabled': {
                            _indicator: {
                                borderColor: `$${color}.500`,
                            },
                            _icon: {
                                color: `$${color}.500`,
                            },
                        },
                    },
                    ':active': {
                        _indicator: {
                            borderColor: `$${color}.300`,
                        },
                        _icon: { color: `$${color}.300` },
                    },
                },
                // ':active': {
                //   _indicator: {
                //     borderColor: '$muted.300',
                //   },
                // },
                // ':invalid': {
                //   _indicator: {
                //     borderColor: '$error.500',
                //   },
                // },
            },
            '_web': {
                ':focusVisible': {
                    _indicator: {
                        outlineColor: `$${color}.400`,
                    },
                },
            },
        };
    }
    return value;
}
