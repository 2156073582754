"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_2 = require("@gluestack-style/react");
const Icon = (0, react_1.styled)(react_2.AsForwarder, {}, {});
exports.default = (0, react_1.styled)(Icon, {}, {
    ancestorStyle: ['_icon'],
    componentName: 'FabIcon',
}, {
    propertyTokenMap: {
        stroke: 'colors',
    },
});
