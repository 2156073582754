"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Alert = void 0;
const react_1 = __importStar(require("react"));
const alert_1 = require("@gluestack-ui/alert");
const styled_components_1 = require("./styled-components");
const usePropResolution_1 = require("../../hooks/usePropResolution");
const Icons_1 = require("../Icons");
const AccessibleAlert = (0, alert_1.createAlert)({
    Root: styled_components_1.Root,
    Text: styled_components_1.Text,
    Icon: styled_components_1.Icon,
});
const AlertContext = (0, react_1.createContext)({ status: '' });
const AlertNew = (0, react_1.forwardRef)((_a, ref) => {
    var { status: statusProp, colorScheme: colorSchemeProp = 'info', variant = 'subtle', children } = _a, props = __rest(_a, ["status", "colorScheme", "variant", "children"]);
    let status = {};
    let contextValue = { status: '' };
    if (colorSchemeProp) {
        status = { colorScheme: colorSchemeProp };
        contextValue = { status: colorSchemeProp };
    }
    if (statusProp) {
        status = { colorScheme: statusProp };
        contextValue = { status: statusProp };
    }
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(props);
    return (react_1.default.createElement(AlertContext.Provider, { value: contextValue },
        react_1.default.createElement(AccessibleAlert, Object.assign({}, resolvedProps, { ref: ref }, status, { variant: variant }), children)));
});
const AlertNewIcon = (0, react_1.forwardRef)((_a, ref) => {
    var { as } = _a, props = __rest(_a, ["as"]);
    let asIcon;
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(props);
    const { status } = (0, react_1.useContext)(AlertContext);
    if (as) {
        asIcon = as;
    }
    else if (status) {
        asIcon = Icons_1.InfoIcon;
        if (status === 'success')
            asIcon = Icons_1.CheckCircleIcon;
        if (status === 'warning')
            asIcon = Icons_1.WarningIcon;
        if (status === 'error')
            asIcon = Icons_1.WarningTwoIcon;
    }
    return react_1.default.createElement(AccessibleAlert.Icon, Object.assign({}, resolvedProps, { as: asIcon, ref: ref }));
});
const AlertTemp = AlertNew;
AlertTemp.Icon = AlertNewIcon;
exports.Alert = AlertTemp;
