"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
// @ts-nocheck
const react_1 = __importStar(require("react"));
const modal_1 = require("@gluestack-ui/modal");
const styled_components_1 = require("./styled-components");
const Heading_1 = require("../Heading");
const Text_1 = require("../Text");
const Icons_1 = require("../Icons");
const animation_resolver_1 = require("@gluestack-style/animation-resolver");
const AccessibleModal = (0, modal_1.createModal)({
    Root: styled_components_1.Root,
    Content: styled_components_1.Content,
    CloseButton: styled_components_1.CloseButton,
    Header: styled_components_1.Header,
    Footer: styled_components_1.Footer,
    Body: styled_components_1.Body,
    Backdrop: styled_components_1.Backdrop,
    //@ts-ignore
    AnimatePresence: animation_resolver_1.AnimatePresence,
});
const ModalNew = (0, react_1.forwardRef)((_a, ref) => {
    var { children, overlayVisible = true, backdropVisible = true } = _a, props = __rest(_a, ["children", "overlayVisible", "backdropVisible"]);
    return (react_1.default.createElement(AccessibleModal, Object.assign({}, props, { ref: ref }),
        overlayVisible && backdropVisible && react_1.default.createElement(AccessibleModal.Backdrop, null),
        children));
});
const AccessibleModalCloseButton = (0, react_1.forwardRef)((_a, ref) => {
    var props = __rest(_a, []);
    return (react_1.default.createElement(AccessibleModal.CloseButton, Object.assign({}, props, { ref: ref }),
        react_1.default.createElement(Icons_1.CloseIcon, null)));
});
const AccessibleModalHeader = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(AccessibleModal.Header, Object.assign({}, props, { ref: ref }), typeof children === 'string' ? (react_1.default.createElement(Heading_1.Heading, null, children)) : (children)));
});
const AccessibleModalFooter = AccessibleModal.Footer;
const AccessibleModalBody = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(AccessibleModal.Body, Object.assign({}, props, { ref: ref }), typeof children === 'string' ? react_1.default.createElement(Text_1.Text, null, children) : children));
});
ModalNew.Content = AccessibleModal.Content;
ModalNew.CloseButton = AccessibleModalCloseButton;
ModalNew.Header = AccessibleModalHeader;
ModalNew.Footer = AccessibleModalFooter;
ModalNew.Body = AccessibleModalBody;
exports.Modal = ModalNew;
// export const Modal = AccessibleModal;
