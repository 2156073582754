"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const plugins_1 = require("../../../plugins");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.Text, {
    // @ts-ignore
    fontSize: '$sm',
    fontWeight: '$medium',
    fontFamily: '$body',
    color: '$muted.900',
    _dark: {
        color: '$muted.50',
    },
}, {
    componentName: 'FormControlLabelText',
    ancestorStyle: ['_labelText'],
}, {
    plugins: [new plugins_1.TextStyleResolver()],
});
