import React, { useCallback } from 'react';
import { LoadingIndicator } from './LoadingIndicator';
import { Thumbnail } from './Thumbnail';
import { ThumbnailPlaceholder } from './ThumbnailPlaceholder';
import { RetryIcon } from './icons';
import clsx from 'clsx';
import { useTranslationContext } from '../../context';
export var ImagePreviewer = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, handleFiles = _a.handleFiles, handleRemove = _a.handleRemove, handleRetry = _a.handleRetry, imageUploads = _a.imageUploads, _c = _a.multiple, multiple = _c === void 0 ? true : _c;
    var t = useTranslationContext('ImagePreviewer').t;
    var onClose = useCallback(function (id, event) {
        if (!id)
            return console.warn("image.id of closed image was \"null\", this shouldn't happen");
        handleRemove === null || handleRemove === void 0 ? void 0 : handleRemove(id, event);
    }, [handleRemove]);
    return (React.createElement("div", { className: 'rfu-image-previewer' }, imageUploads === null || imageUploads === void 0 ? void 0 :
        imageUploads.map(function (image) {
            var url = image.url || image.previewUri;
            return (React.createElement("div", { className: clsx('rfu-image-previewer__image', image.state === 'finished' && 'rfu-image-previewer__image--loaded'), key: image.id },
                image.state === 'failed' && (React.createElement("button", { "aria-label": t('aria/Retry upload'), className: 'rfu-image-previewer__retry', onClick: function (event) { return handleRetry === null || handleRetry === void 0 ? void 0 : handleRetry(image.id, event); }, type: 'button' },
                    React.createElement(RetryIcon, null))),
                url && React.createElement(Thumbnail, { handleClose: function (event) { return onClose(image.id, event); }, image: url }),
                image.state === 'uploading' && (React.createElement(LoadingIndicator, { backgroundColor: '#ffffff19', color: '#ffffffb2' }))));
        }),
        handleFiles && !disabled && (React.createElement(ThumbnailPlaceholder, { handleFiles: handleFiles, multiple: multiple }))));
};
