import { escapeRegExp } from '../regex';
import { findAndReplace } from 'hast-util-find-and-replace';
import { u } from 'unist-builder';
import { visit } from 'unist-util-visit';
export var mentionsMarkdownPlugin = function (mentioned_users) { return function () {
    var mentioned_usernames = mentioned_users
        .map(function (user) { return user.name || user.id; })
        .filter(Boolean)
        .map(escapeRegExp);
    var mentionedUsersRegex = new RegExp(mentioned_usernames.map(function (username) { return "@".concat(username); }).join('|'), 'g');
    var replace = function (match) {
        var usernameOrId = match.replace('@', '');
        var user = mentioned_users.find(function (_a) {
            var id = _a.id, name = _a.name;
            return name === usernameOrId || id === usernameOrId;
        });
        return u('element', { mentionedUser: user, properties: {}, tagName: 'mention' }, [
            u('text', match),
        ]);
    };
    var transform = function (tree) {
        if (!mentioned_usernames.length)
            return;
        // handles special cases of mentions where user.name is an e-mail
        // Remark GFM translates all e-mail-like text nodes to links creating
        // two separate child nodes "@" and "your.name@as.email" instead of
        // keeping it as one text node with value "@your.name@as.email"
        // this piece finds these two separated nodes and merges them together
        // before "replace" function takes over
        visit(tree, function (node, index, parent) {
            var _a;
            if (typeof index === 'undefined')
                return;
            if (!parent)
                return;
            var nextChild = parent.children.at(index + 1);
            var nextChildHref = (_a = nextChild === null || nextChild === void 0 ? void 0 : nextChild.properties) === null || _a === void 0 ? void 0 : _a.href;
            if (node.type === 'text' &&
                // text value has to have @ sign at the end of the string
                // and no other characters except whitespace can precede it
                // valid cases:   "text @", "@", " @"
                // invalid cases: "text@", "@text",
                /.?\s?@$|^@$/.test(node.value) &&
                (nextChildHref === null || nextChildHref === void 0 ? void 0 : nextChildHref.startsWith('mailto:'))) {
                var newTextValue = node.value.replace(/@$/, '');
                var username = nextChildHref.replace('mailto:', '');
                parent.children[index] = u('text', newTextValue);
                parent.children[index + 1] = u('text', "@".concat(username));
            }
        });
        findAndReplace(tree, [mentionedUsersRegex, replace]);
    };
    return transform;
}; };
