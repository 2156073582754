"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Checkbox = void 0;
const react_1 = __importStar(require("react"));
const styled_components_1 = require("./styled-components");
const checkbox_1 = require("@gluestack-ui/checkbox");
const Icons_1 = require("../Icons");
const usePropResolution_1 = require("../../hooks/usePropResolution");
const AccessibleCheckbox = (0, checkbox_1.createCheckbox)({
    Root: styled_components_1.Root,
    Indicator: styled_components_1.Indicator,
    Icon: styled_components_1.Icon,
    Label: styled_components_1.Label,
    Group: styled_components_1.Group,
});
const CheckboxTemp = (0, react_1.forwardRef)((_a, ref) => {
    var { colorScheme = 'primary', children, icon: IconPassed } = _a, props = __rest(_a, ["colorScheme", "children", "icon"]);
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(props);
    return (react_1.default.createElement(AccessibleCheckbox, Object.assign({ colorScheme: colorScheme }, resolvedProps, { ref: ref }),
        react_1.default.createElement(AccessibleCheckbox.Indicator, null,
            react_1.default.createElement(AccessibleCheckbox.Icon, { as: IconPassed
                    ? () => (0, react_1.cloneElement)(IconPassed, {
                        h: 'full',
                        w: 'full',
                    })
                    : Icons_1.CheckIcon })),
        children ? (react_1.default.createElement(AccessibleCheckbox.Label, null, children)) : (react_1.default.createElement(react_1.default.Fragment, null))));
});
const CheckboxGroupTemp = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(props);
    return (react_1.default.createElement(AccessibleCheckbox.Group, Object.assign({ children: children }, resolvedProps, { ref: ref })));
});
const CheckboxNew = CheckboxTemp;
CheckboxNew.Group = CheckboxGroupTemp;
exports.Checkbox = CheckboxNew;
