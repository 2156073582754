"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Slide = void 0;
const react_1 = __importStar(require("react"));
const Box_1 = require("../Box");
const PresenceTransition_1 = require("./PresenceTransition");
const overlay_1 = require("@gluestack-ui/overlay");
const holderStyle = {
    top: {
        top: 0,
        right: 0,
        left: 0,
    },
    right: {
        right: 0,
        top: 0,
        bottom: 0,
    },
    bottom: {
        bottom: 0,
        right: 0,
        left: 0,
    },
    left: {
        left: 0,
        bottom: 0,
        top: 0,
    },
};
exports.Slide = (0, react_1.forwardRef)((_a, ref) => {
    var { children, in: visible, placement = 'top', overlay = true, duration, _overlay = { style: { overflow: 'hidden' }, isOpen: true } } = _a, props = __rest(_a, ["children", "in", "placement", "overlay", "duration", "_overlay"]);
    const [containerOpacity, setContainerOpacity] = react_1.default.useState(0);
    const [size, setSize] = react_1.default.useState(0);
    const provideSize = (layoutSize) => {
        if (placement === 'right' || placement === 'left')
            setSize(layoutSize.width);
        else
            setSize(layoutSize.height);
        setContainerOpacity(1);
    };
    const transition = { duration };
    const animationStyle = {
        top: {
            initial: {
                translateY: -size,
            },
            animate: {
                translateY: 0,
                transition,
            },
        },
        bottom: {
            initial: {
                translateY: size,
            },
            animate: {
                translateY: 0,
                transition,
            },
            exit: {
                translateY: size,
                transition,
            },
        },
        left: {
            initial: {
                translateX: -size,
            },
            animate: {
                translateX: 0,
                transition,
            },
        },
        right: {
            initial: {
                translateX: size,
            },
            animate: {
                translateX: 0,
                transition,
            },
        },
    };
    const style = [
        { position: 'absolute' },
        holderStyle[placement],
        { height: '100%' },
    ];
    const slideComponent = (react_1.default.createElement(PresenceTransition_1.PresenceTransition, Object.assign({ visible: visible }, animationStyle[placement], { style: style }),
        react_1.default.createElement(Box_1.Box, Object.assign({}, props, { opacity: containerOpacity, ref: ref, onLayout: (e) => provideSize(e.nativeEvent.layout) }), children)));
    if (overlay) {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(overlay_1.Overlay, Object.assign({ isKeyboardDismissable: false }, _overlay), slideComponent)));
    }
    else {
        return slideComponent;
    }
});
exports.default = exports.Slide;
