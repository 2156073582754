"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const styled_components_1 = require("../../Icons/styled-components");
exports.default = (0, react_1.styled)(styled_components_1.StyledIcon, {
    height: '$full',
    width: '$full',
}, {
    componentName: 'RadioIcon',
    ancestorStyle: ['_icon'],
    descendantStyle: ['_icon'],
    resolveProps: ['color'],
});
