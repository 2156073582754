import React, { useMemo } from 'react';
import { AudioRecordingPreview } from './AudioRecordingPreview';
import { AudioRecordingInProgress } from './AudioRecordingInProgress';
import { MediaRecordingState } from '../classes';
import { BinIcon, CheckSignIcon, LoadingIndicatorIcon, MicIcon, PauseIcon, SendIconV2, } from '../../MessageInput';
import { useMessageInputContext } from '../../../context';
export var AudioRecorder = function () {
    var _a, _b;
    var _c = useMessageInputContext().recordingController, completeRecording = _c.completeRecording, recorder = _c.recorder, recording = _c.recording, recordingState = _c.recordingState;
    var isUploadingFile = ((_a = recording === null || recording === void 0 ? void 0 : recording.localMetadata) === null || _a === void 0 ? void 0 : _a.uploadState) === 'uploading';
    var state = useMemo(function () { return ({
        paused: recordingState === MediaRecordingState.PAUSED,
        recording: recordingState === MediaRecordingState.RECORDING,
        stopped: recordingState === MediaRecordingState.STOPPED,
    }); }, [recordingState]);
    if (!recorder)
        return null;
    return (React.createElement("div", { className: 'str-chat__audio_recorder-container' },
        React.createElement("div", { className: 'str-chat__audio_recorder', "data-testid": 'audio-recorder' },
            React.createElement("button", { className: 'str-chat__audio_recorder__cancel-button', "data-testid": 'cancel-recording-audio-button', disabled: isUploadingFile, onClick: recorder.cancel },
                React.createElement(BinIcon, null)),
            state.stopped && (recording === null || recording === void 0 ? void 0 : recording.asset_url) ? (React.createElement(AudioRecordingPreview, { durationSeconds: (_b = recording.duration) !== null && _b !== void 0 ? _b : 0, mimeType: recording.mime_type, src: recording.asset_url, waveformData: recording.waveform_data })) : state.paused || state.recording ? (React.createElement(AudioRecordingInProgress, null)) : null,
            state.paused && (React.createElement("button", { className: 'str-chat__audio_recorder__resume-recording-button', onClick: recorder.resume },
                React.createElement(MicIcon, null))),
            state.recording && (React.createElement("button", { className: 'str-chat__audio_recorder__pause-recording-button', "data-testid": 'pause-recording-audio-button', onClick: recorder.pause },
                React.createElement(PauseIcon, null))),
            state.stopped ? (React.createElement("button", { className: 'str-chat__audio_recorder__complete-button', "data-testid": 'audio-recorder-complete-button', disabled: isUploadingFile, onClick: completeRecording }, isUploadingFile ? React.createElement(LoadingIndicatorIcon, null) : React.createElement(SendIconV2, null))) : (React.createElement("button", { className: 'str-chat__audio_recorder__stop-button', "data-testid": 'audio-recorder-stop-button', onClick: recorder.stop },
                React.createElement(CheckSignIcon, null))))));
};
