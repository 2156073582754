"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const animation_resolver_1 = require("@gluestack-style/animation-resolver");
const legend_motion_animation_driver_1 = require("@gluestack-style/legend-motion-animation-driver");
exports.default = (0, react_1.styled)(animation_resolver_1.AnimatedView, {
    // @ts-ignore
    'bg': '$muted.50',
    // @ts-ignore
    'rounded': '$md',
    // @ts-ignore
    'borderWidth': 1,
    'borderColor': '$muted.300',
    'overflow': 'hidden',
    //@ts-ignore
    ':initial': {
        opacity: 0,
    },
    ':animate': {
        opacity: 1,
    },
    ':exit': {
        opacity: 0,
    },
    ':transition': {
        type: 'spring',
        damping: 18,
        stiffness: 250,
        opacity: {
            type: 'timing',
            duration: 250,
        },
    },
    '_dark': {
        backgroundColor: '$muted.800',
        borderColor: '$muted.700',
    },
    'defaultProps': {
        shadow: '6',
    },
}, {
    componentName: 'PopoverContent',
    ancestorStyle: ['_content'],
}, {
    plugins: [new animation_resolver_1.AnimationResolver(legend_motion_animation_driver_1.MotionAnimationDriver)],
});
