import React, { useCallback, useMemo } from 'react';
import { FileAttachmentPreview } from './FileAttachmentPreview';
import { ImageAttachmentPreview } from './ImageAttachmentPreview';
import { useMessageInputContext } from '../../../context';
export var ImageUploadPreviewAdapter = function (_a) {
    var id = _a.id, _b = _a.Preview, Preview = _b === void 0 ? ImageAttachmentPreview : _b;
    var _c = useMessageInputContext('ImageUploadPreviewAdapter'), imageUploads = _c.imageUploads, removeImage = _c.removeImage, uploadImage = _c.uploadImage;
    var removeAttachments = useCallback(function (ids) { return removeImage(ids[0]); }, [removeImage]);
    var handleRetry = useCallback(function (attachment) {
        return attachment.localMetadata && uploadImage(attachment.localMetadata.id);
    }, [uploadImage]);
    var image = imageUploads[id];
    var attachment = useMemo(function () {
        var _a;
        // do not display scraped attachments
        return !image || image.og_scrape_url
            ? undefined
            : {
                image_url: (_a = image.previewUri) !== null && _a !== void 0 ? _a : image.url,
                localMetadata: {
                    file: image.file,
                    id: id,
                    uploadState: image.state,
                },
                title: image.file.name,
                type: 'image',
            };
    }, [id, image]);
    if (!attachment)
        return null;
    return (React.createElement(Preview, { attachment: attachment, handleRetry: handleRetry, removeAttachments: removeAttachments }));
};
export var FileUploadPreviewAdapter = function (_a) {
    var id = _a.id, _b = _a.Preview, Preview = _b === void 0 ? FileAttachmentPreview : _b;
    var _c = useMessageInputContext('FileUploadPreviewAdapter'), fileUploads = _c.fileUploads, removeFile = _c.removeFile, uploadFile = _c.uploadFile;
    var removeAttachments = useCallback(function (ids) {
        removeFile(ids[0]);
    }, [removeFile]);
    var handleRetry = useCallback(function (attachment) {
        return attachment.localMetadata && uploadFile(attachment.localMetadata.id);
    }, [uploadFile]);
    var file = fileUploads[id];
    var attachment = useMemo(function () {
        return !file
            ? undefined
            : {
                asset_url: file.url,
                localMetadata: {
                    file: file.file,
                    id: id,
                    uploadState: file.state,
                },
                mime_type: file.file.type,
                title: file.file.name,
                type: 'file',
            };
    }, [file, id]);
    if (!attachment)
        return null;
    return (React.createElement(Preview, { attachment: attachment, handleRetry: handleRetry, removeAttachments: removeAttachments }));
};
