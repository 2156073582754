import { __assign, __spreadArray } from "tslib";
import { BehaviorSubject } from '../observable/BehaviorSubject';
import { Subject } from '../observable/Subject';
import { mergeDeepUndefined } from '../../../utils/mergeDeep';
var MAX_FREQUENCY_AMPLITUDE = 255;
var logError = function (e) { return e && console.error('[AMPLITUDE RECORDER ERROR]', e); };
var rootMeanSquare = function (values) {
    return Math.sqrt(values.reduce(function (acc, val) { return acc + Math.pow(val, 2); }, 0) / values.length);
};
export var DEFAULT_AMPLITUDE_RECORDER_CONFIG = {
    analyserConfig: {
        fftSize: 32,
        maxDecibels: 0,
        minDecibels: -100,
    },
    sampleCount: 100,
    samplingFrequencyMs: 60,
};
export var AmplitudeRecorderState;
(function (AmplitudeRecorderState) {
    AmplitudeRecorderState["CLOSED"] = "closed";
    AmplitudeRecorderState["RECORDING"] = "recording";
    AmplitudeRecorderState["STOPPED"] = "stopped";
})(AmplitudeRecorderState || (AmplitudeRecorderState = {}));
var AmplitudeRecorder = /** @class */ (function () {
    function AmplitudeRecorder(_a) {
        var config = _a.config, stream = _a.stream;
        var _this = this;
        this.amplitudes = new BehaviorSubject([]);
        this.state = new BehaviorSubject(undefined);
        this.error = new Subject();
        this.start = function () {
            if (_this.state.value === AmplitudeRecorderState.CLOSED)
                return;
            if (!_this.stream) {
                throw new Error('Missing MediaStream instance. Cannot to start amplitude recording');
            }
            if (_this.state.value === AmplitudeRecorderState.RECORDING)
                _this.stop();
            if (!_this.analyserNode) {
                if (!_this.stream)
                    return;
                _this.init();
            }
            _this.state.next(AmplitudeRecorderState.RECORDING);
            _this.amplitudeSamplingInterval = setInterval(function () {
                if (!(_this.analyserNode && _this.state.value === AmplitudeRecorderState.RECORDING))
                    return;
                var frequencyBins = new Uint8Array(_this.analyserNode.frequencyBinCount);
                try {
                    _this.analyserNode.getByteFrequencyData(frequencyBins);
                }
                catch (e) {
                    logError(e);
                    _this.error.next(e);
                    return;
                }
                var normalizedSignalStrength = rootMeanSquare(frequencyBins) / MAX_FREQUENCY_AMPLITUDE;
                _this.amplitudes.next(__spreadArray(__spreadArray([], _this.amplitudes.value, true), [normalizedSignalStrength], false));
            }, _this.config.samplingFrequencyMs);
        };
        this.config = mergeDeepUndefined(__assign({}, config), DEFAULT_AMPLITUDE_RECORDER_CONFIG);
        this.stream = stream;
    }
    AmplitudeRecorder.prototype.init = function () {
        this.audioContext = new AudioContext();
        this.analyserNode = this.audioContext.createAnalyser();
        var analyserConfig = this.config.analyserConfig;
        this.analyserNode.fftSize = analyserConfig.fftSize;
        this.analyserNode.maxDecibels = analyserConfig.maxDecibels;
        this.analyserNode.minDecibels = analyserConfig.minDecibels;
        this.microphone = this.audioContext.createMediaStreamSource(this.stream);
        this.microphone.connect(this.analyserNode);
    };
    AmplitudeRecorder.prototype.stop = function () {
        clearInterval(this.amplitudeSamplingInterval);
        this.amplitudeSamplingInterval = undefined;
        this.state.next(AmplitudeRecorderState.STOPPED);
    };
    AmplitudeRecorder.prototype.close = function () {
        var _a, _b, _c, _d;
        if (this.state.value !== AmplitudeRecorderState.STOPPED)
            this.stop();
        this.state.next(AmplitudeRecorderState.CLOSED);
        this.amplitudes.next([]);
        (_a = this.microphone) === null || _a === void 0 ? void 0 : _a.disconnect();
        (_b = this.analyserNode) === null || _b === void 0 ? void 0 : _b.disconnect();
        if (((_c = this.audioContext) === null || _c === void 0 ? void 0 : _c.state) !== 'closed')
            (_d = this.audioContext) === null || _d === void 0 ? void 0 : _d.close();
    };
    return AmplitudeRecorder;
}());
export { AmplitudeRecorder };
