import { __assign, __awaiter, __generator, __rest } from "tslib";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState, } from 'react';
import debounce from 'lodash.debounce';
import defaultsDeep from 'lodash.defaultsdeep';
import throttle from 'lodash.throttle';
import { nanoid } from 'nanoid';
import clsx from 'clsx';
import { channelReducer, initialState } from './channelState';
import { useCreateChannelStateContext } from './hooks/useCreateChannelStateContext';
import { useCreateTypingContext } from './hooks/useCreateTypingContext';
import { useEditMessageHandler } from './hooks/useEditMessageHandler';
import { useIsMounted } from './hooks/useIsMounted';
import { useMentionsHandlers } from './hooks/useMentionsHandlers';
import { Attachment as DefaultAttachment } from '../Attachment/Attachment';
import { LoadingErrorIndicator as DefaultLoadingErrorIndicator, } from '../Loading';
import { LoadingChannel as DefaultLoadingIndicator } from './LoadingChannel';
import { MessageSimple } from '../Message/MessageSimple';
import { DropzoneProvider } from '../MessageInput/DropzoneProvider';
import { ChannelActionProvider, } from '../../context/ChannelActionContext';
import { ChannelStateProvider, } from '../../context/ChannelStateContext';
import { ComponentProvider } from '../../context/ComponentContext';
import { useChatContext } from '../../context/ChatContext';
import { useTranslationContext } from '../../context/TranslationContext';
import { TypingProvider } from '../../context/TypingContext';
import { DEFAULT_HIGHLIGHT_DURATION, DEFAULT_INITIAL_CHANNEL_PAGE_SIZE, DEFAULT_JUMP_TO_PAGE_SIZE, DEFAULT_NEXT_CHANNEL_PAGE_SIZE, DEFAULT_THREAD_PAGE_SIZE, } from '../../constants/limits';
import { hasMoreMessagesProbably, UnreadMessagesSeparator } from '../MessageList';
import { useChannelContainerClasses } from './hooks/useChannelContainerClasses';
import { findInMsgSetByDate, findInMsgSetById, makeAddNotifications } from './utils';
import { getChannel } from '../../utils';
import { getImageAttachmentConfiguration, getVideoAttachmentConfiguration, } from '../Attachment/attachment-sizing';
import { defaultReactionOptions } from '../Reactions';
import { EventComponent } from '../EventComponent';
import { DateSeparator } from '../DateSeparator';
var isUserResponseArray = function (output) { var _a; return ((_a = output[0]) === null || _a === void 0 ? void 0 : _a.id) != null; };
var UnMemoizedChannel = function (props) {
    var propsChannel = props.channel, _a = props.EmptyPlaceholder, EmptyPlaceholder = _a === void 0 ? null : _a, LoadingErrorIndicator = props.LoadingErrorIndicator, _b = props.LoadingIndicator, LoadingIndicator = _b === void 0 ? DefaultLoadingIndicator : _b;
    var _c = useChatContext('Channel'), contextChannel = _c.channel, channelsQueryState = _c.channelsQueryState, customClasses = _c.customClasses, theme = _c.theme;
    var _d = useChannelContainerClasses({
        customClasses: customClasses,
    }), channelClass = _d.channelClass, chatClass = _d.chatClass;
    var channel = propsChannel || contextChannel;
    var className = clsx(chatClass, theme, channelClass);
    if (channelsQueryState.queryInProgress === 'reload' && LoadingIndicator) {
        return (React.createElement("div", { className: className },
            React.createElement(LoadingIndicator, null)));
    }
    if (channelsQueryState.error && LoadingErrorIndicator) {
        return (React.createElement("div", { className: className },
            React.createElement(LoadingErrorIndicator, { error: channelsQueryState.error })));
    }
    if (!(channel === null || channel === void 0 ? void 0 : channel.cid)) {
        return React.createElement("div", { className: className }, EmptyPlaceholder);
    }
    return React.createElement(ChannelInner, __assign({}, props, { channel: channel, key: channel.cid }));
};
var ChannelInner = function (props) {
    var _a;
    var acceptedFiles = props.acceptedFiles, activeUnreadHandler = props.activeUnreadHandler, channel = props.channel, propChannelQueryOptions = props.channelQueryOptions, children = props.children, doDeleteMessageRequest = props.doDeleteMessageRequest, doMarkReadRequest = props.doMarkReadRequest, doSendMessageRequest = props.doSendMessageRequest, doUpdateMessageRequest = props.doUpdateMessageRequest, _b = props.dragAndDropWindow, dragAndDropWindow = _b === void 0 ? false : _b, enrichURLForPreviewConfig = props.enrichURLForPreviewConfig, _c = props.initializeOnMount, initializeOnMount = _c === void 0 ? true : _c, _d = props.LoadingErrorIndicator, LoadingErrorIndicator = _d === void 0 ? DefaultLoadingErrorIndicator : _d, _e = props.LoadingIndicator, LoadingIndicator = _e === void 0 ? DefaultLoadingIndicator : _e, _f = props.markReadOnMount, markReadOnMount = _f === void 0 ? true : _f, maxNumberOfFiles = props.maxNumberOfFiles, _g = props.multipleUploads, multipleUploads = _g === void 0 ? true : _g, onMentionsClick = props.onMentionsClick, onMentionsHover = props.onMentionsHover, _h = props.optionalMessageInputProps, optionalMessageInputProps = _h === void 0 ? {} : _h, skipMessageDataMemoization = props.skipMessageDataMemoization;
    var channelQueryOptions = useMemo(function () {
        return defaultsDeep(propChannelQueryOptions, {
            messages: { limit: DEFAULT_INITIAL_CHANNEL_PAGE_SIZE },
        });
    }, [propChannelQueryOptions]);
    var _j = useChatContext('Channel'), client = _j.client, customClasses = _j.customClasses, latestMessageDatesByChannels = _j.latestMessageDatesByChannels, mutes = _j.mutes, theme = _j.theme;
    var t = useTranslationContext('Channel').t;
    var _k = useChannelContainerClasses({ customClasses: customClasses }), channelClass = _k.channelClass, chatClass = _k.chatClass, chatContainerClass = _k.chatContainerClass, windowsEmojiClass = _k.windowsEmojiClass;
    var _l = useState(channel.getConfig()), channelConfig = _l[0], setChannelConfig = _l[1];
    var _m = useState([]), notifications = _m[0], setNotifications = _m[1];
    var _o = useState(), quotedMessage = _o[0], setQuotedMessage = _o[1];
    var _p = useState(), channelUnreadUiState = _p[0], _setChannelUnreadUiState = _p[1];
    var notificationTimeouts = useRef([]);
    var _q = useReducer(channelReducer, __assign(__assign({}, initialState), { loading: !channel.initialized })), state = _q[0], dispatch = _q[1];
    var isMounted = useIsMounted();
    var originalTitle = useRef('');
    var lastRead = useRef();
    var online = useRef(true);
    var channelCapabilitiesArray = (_a = channel.data) === null || _a === void 0 ? void 0 : _a.own_capabilities;
    var throttledCopyStateFromChannel = throttle(function () { return dispatch({ channel: channel, type: 'copyStateFromChannelOnEvent' }); }, 500, {
        leading: true,
        trailing: true,
    });
    var setChannelUnreadUiState = useMemo(function () {
        return throttle(_setChannelUnreadUiState, 200, {
            leading: true,
            trailing: false,
        });
    }, []);
    var markRead = useMemo(function () {
        return throttle(function (options) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, updateChannelUiUnreadState, markReadResponse, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = (options !== null && options !== void 0 ? options : {}).updateChannelUiUnreadState, updateChannelUiUnreadState = _a === void 0 ? true : _a;
                        if (channel.disconnected || !(channelConfig === null || channelConfig === void 0 ? void 0 : channelConfig.read_events)) {
                            return [2 /*return*/];
                        }
                        lastRead.current = new Date();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        if (!doMarkReadRequest) return [3 /*break*/, 2];
                        doMarkReadRequest(channel, updateChannelUiUnreadState ? setChannelUnreadUiState : undefined);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, channel.markRead()];
                    case 3:
                        markReadResponse = _b.sent();
                        if (updateChannelUiUnreadState && markReadResponse) {
                            _setChannelUnreadUiState({
                                last_read: lastRead.current,
                                last_read_message_id: markReadResponse.event.last_read_message_id,
                                unread_messages: 0,
                            });
                        }
                        _b.label = 4;
                    case 4:
                        if (activeUnreadHandler) {
                            activeUnreadHandler(0, originalTitle.current);
                        }
                        else if (originalTitle.current) {
                            document.title = originalTitle.current;
                        }
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _b.sent();
                        console.error(t('Failed to mark channel as read'));
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); }, 500, { leading: true, trailing: false });
    }, [activeUnreadHandler, channel, channelConfig, doMarkReadRequest, setChannelUnreadUiState, t]);
    var handleEvent = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var mainChannelUpdated, unread, messageDate, cid, oldestID;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    if (event.message) {
                        dispatch({
                            channel: channel,
                            message: event.message,
                            type: 'updateThreadOnEvent',
                        });
                    }
                    if (event.type === 'user.watching.start' || event.type === 'user.watching.stop')
                        return [2 /*return*/];
                    if (event.type === 'typing.start' || event.type === 'typing.stop') {
                        return [2 /*return*/, dispatch({ channel: channel, type: 'setTyping' })];
                    }
                    if (event.type === 'connection.changed' && typeof event.online === 'boolean') {
                        online.current = event.online;
                    }
                    if (event.type === 'message.new') {
                        mainChannelUpdated = !((_a = event.message) === null || _a === void 0 ? void 0 : _a.parent_id) || ((_b = event.message) === null || _b === void 0 ? void 0 : _b.show_in_channel);
                        if (mainChannelUpdated) {
                            if (document.hidden && (channelConfig === null || channelConfig === void 0 ? void 0 : channelConfig.read_events) && !channel.muteStatus().muted) {
                                unread = channel.countUnread(lastRead.current);
                                if (activeUnreadHandler) {
                                    activeUnreadHandler(unread, originalTitle.current);
                                }
                                else {
                                    document.title = "(".concat(unread, ") ").concat(originalTitle.current);
                                }
                            }
                        }
                        if (((_d = (_c = event.message) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.id) === client.userID &&
                            ((_e = event === null || event === void 0 ? void 0 : event.message) === null || _e === void 0 ? void 0 : _e.created_at) &&
                            ((_f = event === null || event === void 0 ? void 0 : event.message) === null || _f === void 0 ? void 0 : _f.cid)) {
                            messageDate = new Date(event.message.created_at);
                            cid = event.message.cid;
                            if (!latestMessageDatesByChannels[cid] ||
                                latestMessageDatesByChannels[cid].getTime() < messageDate.getTime()) {
                                latestMessageDatesByChannels[cid] = messageDate;
                            }
                        }
                    }
                    if (!(event.type === 'user.deleted')) return [3 /*break*/, 2];
                    oldestID = (_j = (_h = (_g = channel.state) === null || _g === void 0 ? void 0 : _g.messages) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.id;
                    /**
                     * As the channel state is not normalized we re-fetch the channel data. Thus, we avoid having to search for user references in the channel state.
                     */
                    // FIXME: we should use channelQueryOptions if they are available
                    return [4 /*yield*/, channel.query({
                            messages: { id_lt: oldestID, limit: DEFAULT_NEXT_CHANNEL_PAGE_SIZE },
                            watchers: { limit: DEFAULT_NEXT_CHANNEL_PAGE_SIZE },
                        })];
                case 1:
                    /**
                     * As the channel state is not normalized we re-fetch the channel data. Thus, we avoid having to search for user references in the channel state.
                     */
                    // FIXME: we should use channelQueryOptions if they are available
                    _k.sent();
                    _k.label = 2;
                case 2:
                    if (event.type === 'notification.mark_unread')
                        _setChannelUnreadUiState(function (prev) {
                            var _a;
                            if (!(event.last_read_at && event.user))
                                return prev;
                            return {
                                first_unread_message_id: event.first_unread_message_id,
                                last_read: new Date(event.last_read_at),
                                last_read_message_id: event.last_read_message_id,
                                unread_messages: (_a = event.unread_messages) !== null && _a !== void 0 ? _a : 0,
                            };
                        });
                    if (event.type === 'channel.truncated' && event.cid === channel.cid) {
                        _setChannelUnreadUiState(undefined);
                    }
                    throttledCopyStateFromChannel();
                    return [2 /*return*/];
            }
        });
    }); };
    // useLayoutEffect here to prevent spinner. Use Suspense when it is available in stable release
    useLayoutEffect(function () {
        var errored = false;
        var done = false;
        var channelInitializedExternally = true;
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            var members, _i, _a, member, userId, _b, user, user_id, config, e_2, _c, user, ownReadState;
            var _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!(!channel.initialized && initializeOnMount)) return [3 /*break*/, 4];
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 3, , 4]);
                        members = [];
                        if (!channel.id && ((_d = channel.data) === null || _d === void 0 ? void 0 : _d.members)) {
                            for (_i = 0, _a = channel.data.members; _i < _a.length; _i++) {
                                member = _a[_i];
                                userId = void 0;
                                if (typeof member === 'string') {
                                    userId = member;
                                }
                                else if (typeof member === 'object') {
                                    _b = member, user = _b.user, user_id = _b.user_id;
                                    userId = user_id || (user === null || user === void 0 ? void 0 : user.id);
                                }
                                if (userId) {
                                    members.push(userId);
                                }
                            }
                        }
                        return [4 /*yield*/, getChannel({ channel: channel, client: client, members: members, options: channelQueryOptions })];
                    case 2:
                        _f.sent();
                        config = channel.getConfig();
                        setChannelConfig(config);
                        channelInitializedExternally = false;
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _f.sent();
                        dispatch({ error: e_2, type: 'setError' });
                        errored = true;
                        return [3 /*break*/, 4];
                    case 4:
                        done = true;
                        originalTitle.current = document.title;
                        if (!errored) {
                            dispatch({
                                channel: channel,
                                hasMore: channelInitializedExternally ||
                                    hasMoreMessagesProbably(channel.state.messages.length, channelQueryOptions.messages.limit),
                                type: 'initStateFromChannel',
                            });
                            if (((_e = client.user) === null || _e === void 0 ? void 0 : _e.id) && channel.state.read[client.user.id]) {
                                _c = channel.state.read[client.user.id], user = _c.user, ownReadState = __rest(_c, ["user"]);
                                _setChannelUnreadUiState(ownReadState);
                            }
                            /**
                             * TODO: maybe pass last_read to the countUnread method to get proper value
                             * combined with channel.countUnread adjustment (_countMessageAsUnread)
                             * to allow counting own messages too
                             *
                             * const lastRead = channel.state.read[client.userID as string].last_read;
                             */
                            if (channel.countUnread() > 0 && markReadOnMount)
                                markRead({ updateChannelUiUnreadState: false });
                            // The more complex sync logic is done in Chat
                            client.on('connection.changed', handleEvent);
                            client.on('connection.recovered', handleEvent);
                            client.on('user.updated', handleEvent);
                            client.on('user.deleted', handleEvent);
                            channel.on(handleEvent);
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
        var notificationTimeoutsRef = notificationTimeouts.current;
        return function () {
            if (errored || !done)
                return;
            channel === null || channel === void 0 ? void 0 : channel.off(handleEvent);
            client.off('connection.changed', handleEvent);
            client.off('connection.recovered', handleEvent);
            client.off('user.deleted', handleEvent);
            notificationTimeoutsRef.forEach(clearTimeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        channel.cid,
        channelQueryOptions,
        doMarkReadRequest,
        channelConfig === null || channelConfig === void 0 ? void 0 : channelConfig.read_events,
        initializeOnMount,
    ]);
    useEffect(function () {
        var _a;
        if (!state.thread)
            return;
        var message = (_a = state.messages) === null || _a === void 0 ? void 0 : _a.find(function (m) { var _a; return m.id === ((_a = state.thread) === null || _a === void 0 ? void 0 : _a.id); });
        if (message)
            dispatch({ message: message, type: 'setThread' });
    }, [state.messages, state.thread]);
    /** MESSAGE */
    // Adds a temporary notification to message list, will be removed after 5 seconds
    var addNotification = useMemo(function () { return makeAddNotifications(setNotifications, notificationTimeouts.current); }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var loadMoreFinished = useCallback(debounce(function (hasMore, messages) {
        if (!isMounted.current)
            return;
        dispatch({ hasMore: hasMore, messages: messages, type: 'loadMoreFinished' });
    }, 2000, { leading: true, trailing: true }), []);
    var loadMore = function (limit) {
        if (limit === void 0) { limit = DEFAULT_NEXT_CHANNEL_PAGE_SIZE; }
        return __awaiter(void 0, void 0, void 0, function () {
            var oldestMessage, oldestID, perPage, queryResponse, e_3, hasMoreMessages;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!online.current || !window.navigator.onLine || !state.hasMore)
                            return [2 /*return*/, 0];
                        oldestMessage = (_a = state === null || state === void 0 ? void 0 : state.messages) === null || _a === void 0 ? void 0 : _a[0];
                        if (state.loadingMore || state.loadingMoreNewer || (oldestMessage === null || oldestMessage === void 0 ? void 0 : oldestMessage.status) !== 'received') {
                            return [2 /*return*/, 0];
                        }
                        dispatch({ loadingMore: true, type: 'setLoadingMore' });
                        oldestID = oldestMessage === null || oldestMessage === void 0 ? void 0 : oldestMessage.id;
                        perPage = limit;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, channel.query({
                                messages: { id_lt: oldestID, limit: perPage },
                                watchers: { limit: perPage },
                            })];
                    case 2:
                        queryResponse = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _b.sent();
                        console.warn('message pagination request failed with error', e_3);
                        dispatch({ loadingMore: false, type: 'setLoadingMore' });
                        return [2 /*return*/, 0];
                    case 4:
                        hasMoreMessages = queryResponse.messages.length === perPage;
                        loadMoreFinished(hasMoreMessages, channel.state.messages);
                        return [2 /*return*/, queryResponse.messages.length];
                }
            });
        });
    };
    var loadMoreNewer = function (limit) {
        if (limit === void 0) { limit = DEFAULT_NEXT_CHANNEL_PAGE_SIZE; }
        return __awaiter(void 0, void 0, void 0, function () {
            var newestMessage, newestId, perPage, queryResponse, e_4, hasMoreNewerMessages;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!online.current || !window.navigator.onLine || !state.hasMoreNewer)
                            return [2 /*return*/, 0];
                        newestMessage = (_a = state === null || state === void 0 ? void 0 : state.messages) === null || _a === void 0 ? void 0 : _a[((_b = state === null || state === void 0 ? void 0 : state.messages) === null || _b === void 0 ? void 0 : _b.length) - 1];
                        if (state.loadingMore || state.loadingMoreNewer)
                            return [2 /*return*/, 0];
                        dispatch({ loadingMoreNewer: true, type: 'setLoadingMoreNewer' });
                        newestId = newestMessage === null || newestMessage === void 0 ? void 0 : newestMessage.id;
                        perPage = limit;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, channel.query({
                                messages: { id_gt: newestId, limit: perPage },
                                watchers: { limit: perPage },
                            })];
                    case 2:
                        queryResponse = _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_4 = _c.sent();
                        console.warn('message pagination request failed with error', e_4);
                        dispatch({ loadingMoreNewer: false, type: 'setLoadingMoreNewer' });
                        return [2 /*return*/, 0];
                    case 4:
                        hasMoreNewerMessages = channel.state.messages !== channel.state.latestMessages;
                        dispatch({
                            hasMoreNewer: hasMoreNewerMessages,
                            messages: channel.state.messages,
                            type: 'loadMoreNewerFinished',
                        });
                        return [2 /*return*/, queryResponse.messages.length];
                }
            });
        });
    };
    var clearHighlightedMessageTimeoutId = useRef(null);
    var jumpToMessage = useCallback(function (messageId, messageLimit, highlightDuration) {
        if (messageLimit === void 0) { messageLimit = DEFAULT_JUMP_TO_PAGE_SIZE; }
        if (highlightDuration === void 0) { highlightDuration = DEFAULT_HIGHLIGHT_DURATION; }
        return __awaiter(void 0, void 0, void 0, function () {
            var indexOfMessage, hasMoreMessages;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        dispatch({ loadingMore: true, type: 'setLoadingMore' });
                        return [4 /*yield*/, channel.state.loadMessageIntoState(messageId, undefined, messageLimit)];
                    case 1:
                        _a.sent();
                        indexOfMessage = channel.state.messages.findIndex(function (message) { return message.id === messageId; });
                        hasMoreMessages = indexOfMessage >= Math.floor(messageLimit / 2);
                        loadMoreFinished(hasMoreMessages, channel.state.messages);
                        dispatch({
                            hasMoreNewer: channel.state.messages !== channel.state.latestMessages,
                            highlightedMessageId: messageId,
                            type: 'jumpToMessageFinished',
                        });
                        if (clearHighlightedMessageTimeoutId.current) {
                            clearTimeout(clearHighlightedMessageTimeoutId.current);
                        }
                        clearHighlightedMessageTimeoutId.current = setTimeout(function () {
                            clearHighlightedMessageTimeoutId.current = null;
                            dispatch({ type: 'clearHighlightedMessage' });
                        }, highlightDuration);
                        return [2 /*return*/];
                }
            });
        });
    }, [channel, loadMoreFinished]);
    var jumpToLatestMessage = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var hasMoreOlder;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, channel.state.loadMessageIntoState('latest')];
                case 1:
                    _a.sent();
                    hasMoreOlder = channel.state.messages.length >= 25;
                    loadMoreFinished(hasMoreOlder, channel.state.messages);
                    dispatch({
                        type: 'jumpToLatestMessage',
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [channel, loadMoreFinished]);
    var jumpToFirstUnreadMessage = useCallback(function (queryMessageLimit, highlightDuration) {
        if (queryMessageLimit === void 0) { queryMessageLimit = DEFAULT_JUMP_TO_PAGE_SIZE; }
        if (highlightDuration === void 0) { highlightDuration = DEFAULT_HIGHLIGHT_DURATION; }
        return __awaiter(void 0, void 0, void 0, function () {
            var lastReadMessageId, firstUnreadMessageId, isInCurrentMessageSet, hasMoreMessages, result, result, lastReadTimestamp, _a, lastReadMessageIndex, lastReadMessage, messages, e_5, firstMessageWithCreationDate, firstMessageTimestamp, result, targetId_1, indexOfTarget, e_6;
            var _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!(channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages))
                            return [2 /*return*/];
                        lastReadMessageId = channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.last_read_message_id;
                        firstUnreadMessageId = channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.first_unread_message_id;
                        isInCurrentMessageSet = false;
                        hasMoreMessages = true;
                        if (!firstUnreadMessageId) return [3 /*break*/, 1];
                        result = findInMsgSetById(firstUnreadMessageId, channel.state.messages);
                        isInCurrentMessageSet = result.index !== -1;
                        return [3 /*break*/, 8];
                    case 1:
                        if (!lastReadMessageId) return [3 /*break*/, 2];
                        result = findInMsgSetById(lastReadMessageId, channel.state.messages);
                        isInCurrentMessageSet = !!result.target;
                        firstUnreadMessageId =
                            result.index > -1 ? (_b = channel.state.messages[result.index + 1]) === null || _b === void 0 ? void 0 : _b.id : undefined;
                        return [3 /*break*/, 8];
                    case 2:
                        lastReadTimestamp = channelUnreadUiState.last_read.getTime();
                        _a = findInMsgSetByDate(channelUnreadUiState.last_read, channel.state.messages, true), lastReadMessageIndex = _a.index, lastReadMessage = _a.target;
                        if (!lastReadMessage) return [3 /*break*/, 3];
                        firstUnreadMessageId = (_c = channel.state.messages[lastReadMessageIndex + 1]) === null || _c === void 0 ? void 0 : _c.id;
                        isInCurrentMessageSet = !!firstUnreadMessageId;
                        lastReadMessageId = lastReadMessage.id;
                        return [3 /*break*/, 8];
                    case 3:
                        dispatch({ loadingMore: true, type: 'setLoadingMore' });
                        messages = void 0;
                        _f.label = 4;
                    case 4:
                        _f.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, channel.query({
                                messages: {
                                    created_at_around: channelUnreadUiState.last_read.toISOString(),
                                    limit: queryMessageLimit,
                                },
                            }, 'new')];
                    case 5:
                        messages = (_f.sent()).messages;
                        return [3 /*break*/, 7];
                    case 6:
                        e_5 = _f.sent();
                        addNotification(t('Failed to jump to the first unread message'), 'error');
                        loadMoreFinished(hasMoreMessages, channel.state.messages);
                        return [2 /*return*/];
                    case 7:
                        firstMessageWithCreationDate = messages.find(function (msg) { return msg.created_at; });
                        if (!firstMessageWithCreationDate) {
                            addNotification(t('Failed to jump to the first unread message'), 'error');
                            loadMoreFinished(hasMoreMessages, channel.state.messages);
                            return [2 /*return*/];
                        }
                        firstMessageTimestamp = new Date(firstMessageWithCreationDate.created_at).getTime();
                        if (lastReadTimestamp < firstMessageTimestamp) {
                            // whole channel is unread
                            firstUnreadMessageId = firstMessageWithCreationDate.id;
                            hasMoreMessages = false;
                        }
                        else {
                            result = findInMsgSetByDate(channelUnreadUiState.last_read, messages);
                            lastReadMessageId = (_d = result.target) === null || _d === void 0 ? void 0 : _d.id;
                            hasMoreMessages = result.index >= Math.floor(queryMessageLimit / 2);
                        }
                        loadMoreFinished(hasMoreMessages, channel.state.messages);
                        _f.label = 8;
                    case 8:
                        if (!firstUnreadMessageId && !lastReadMessageId) {
                            addNotification(t('Failed to jump to the first unread message'), 'error');
                            return [2 /*return*/];
                        }
                        if (!!isInCurrentMessageSet) return [3 /*break*/, 12];
                        dispatch({ loadingMore: true, type: 'setLoadingMore' });
                        _f.label = 9;
                    case 9:
                        _f.trys.push([9, 11, , 12]);
                        targetId_1 = (firstUnreadMessageId !== null && firstUnreadMessageId !== void 0 ? firstUnreadMessageId : lastReadMessageId);
                        return [4 /*yield*/, channel.state.loadMessageIntoState(targetId_1, undefined, queryMessageLimit)];
                    case 10:
                        _f.sent();
                        indexOfTarget = channel.state.messages.findIndex(function (message) { return message.id === targetId_1; });
                        hasMoreMessages = indexOfTarget >= Math.floor(queryMessageLimit / 2);
                        loadMoreFinished(hasMoreMessages, channel.state.messages);
                        firstUnreadMessageId =
                            firstUnreadMessageId !== null && firstUnreadMessageId !== void 0 ? firstUnreadMessageId : (_e = channel.state.messages[indexOfTarget + 1]) === null || _e === void 0 ? void 0 : _e.id;
                        return [3 /*break*/, 12];
                    case 11:
                        e_6 = _f.sent();
                        addNotification(t('Failed to jump to the first unread message'), 'error');
                        loadMoreFinished(hasMoreMessages, channel.state.messages);
                        return [2 /*return*/];
                    case 12:
                        if (!firstUnreadMessageId) {
                            addNotification(t('Failed to jump to the first unread message'), 'error');
                            return [2 /*return*/];
                        }
                        if (!channelUnreadUiState.first_unread_message_id)
                            _setChannelUnreadUiState(__assign(__assign({}, channelUnreadUiState), { first_unread_message_id: firstUnreadMessageId, last_read_message_id: lastReadMessageId }));
                        dispatch({
                            hasMoreNewer: channel.state.messages !== channel.state.latestMessages,
                            highlightedMessageId: firstUnreadMessageId,
                            type: 'jumpToMessageFinished',
                        });
                        if (clearHighlightedMessageTimeoutId.current) {
                            clearTimeout(clearHighlightedMessageTimeoutId.current);
                        }
                        clearHighlightedMessageTimeoutId.current = setTimeout(function () {
                            clearHighlightedMessageTimeoutId.current = null;
                            dispatch({ type: 'clearHighlightedMessage' });
                        }, highlightDuration);
                        return [2 /*return*/];
                }
            });
        });
    }, [addNotification, channel, loadMoreFinished, t, channelUnreadUiState]);
    var deleteMessage = useCallback(function (message) { return __awaiter(void 0, void 0, void 0, function () {
        var deletedMessage, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(message === null || message === void 0 ? void 0 : message.id)) {
                        throw new Error('Cannot delete a message - missing message ID.');
                    }
                    if (!doDeleteMessageRequest) return [3 /*break*/, 2];
                    return [4 /*yield*/, doDeleteMessageRequest(message)];
                case 1:
                    deletedMessage = _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, client.deleteMessage(message.id)];
                case 3:
                    result = _a.sent();
                    deletedMessage = result.message;
                    _a.label = 4;
                case 4: return [2 /*return*/, deletedMessage];
            }
        });
    }); }, [client, doDeleteMessageRequest]);
    var updateMessage = function (updatedMessage) {
        // add the message to the local channel state
        channel.state.addMessageSorted(updatedMessage, true);
        dispatch({
            channel: channel,
            parentId: state.thread && updatedMessage.parent_id,
            type: 'copyMessagesFromChannel',
        });
    };
    var doSendMessage = function (message, customMessageData, options) { return __awaiter(void 0, void 0, void 0, function () {
        var attachments, id, _a, mentioned_users, parent_id, text, mentions, messageData, messageResponse, existingMessage, i, msg, responseTimestamp, existingMessageTimestamp, responseIsTheNewest, error_1, stringError, parsedError;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    attachments = message.attachments, id = message.id, _a = message.mentioned_users, mentioned_users = _a === void 0 ? [] : _a, parent_id = message.parent_id, text = message.text;
                    mentions = isUserResponseArray(mentioned_users)
                        ? mentioned_users.map(function (_a) {
                            var id = _a.id;
                            return id;
                        })
                        : mentioned_users;
                    messageData = __assign({ attachments: attachments, id: id, mentioned_users: mentions, parent_id: parent_id, quoted_message_id: parent_id === (quotedMessage === null || quotedMessage === void 0 ? void 0 : quotedMessage.parent_id) ? quotedMessage === null || quotedMessage === void 0 ? void 0 : quotedMessage.id : undefined, text: text }, customMessageData);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 6, , 7]);
                    messageResponse = void 0;
                    if (!doSendMessageRequest) return [3 /*break*/, 3];
                    return [4 /*yield*/, doSendMessageRequest(channel, messageData, options)];
                case 2:
                    messageResponse = _d.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, channel.sendMessage(messageData, options)];
                case 4:
                    messageResponse = _d.sent();
                    _d.label = 5;
                case 5:
                    existingMessage = void 0;
                    for (i = channel.state.messages.length - 1; i >= 0; i--) {
                        msg = channel.state.messages[i];
                        if (msg.id && msg.id === messageData.id) {
                            existingMessage = msg;
                            break;
                        }
                    }
                    responseTimestamp = new Date(((_b = messageResponse === null || messageResponse === void 0 ? void 0 : messageResponse.message) === null || _b === void 0 ? void 0 : _b.updated_at) || 0).getTime();
                    existingMessageTimestamp = ((_c = existingMessage === null || existingMessage === void 0 ? void 0 : existingMessage.updated_at) === null || _c === void 0 ? void 0 : _c.getTime()) || 0;
                    responseIsTheNewest = responseTimestamp > existingMessageTimestamp;
                    // Replace the message payload after send is completed
                    // We need to check for the newest message payload, because on slow network, the response can arrive later than WS events message.new, message.updated.
                    // Always override existing message in status "sending"
                    if ((messageResponse === null || messageResponse === void 0 ? void 0 : messageResponse.message) &&
                        (responseIsTheNewest || (existingMessage === null || existingMessage === void 0 ? void 0 : existingMessage.status) === 'sending')) {
                        updateMessage(__assign(__assign({}, messageResponse.message), { status: 'received' }));
                    }
                    if (quotedMessage && parent_id === (quotedMessage === null || quotedMessage === void 0 ? void 0 : quotedMessage.parent_id))
                        setQuotedMessage(undefined);
                    return [3 /*break*/, 7];
                case 6:
                    error_1 = _d.sent();
                    stringError = JSON.stringify(error_1);
                    parsedError = (stringError
                        ? JSON.parse(stringError)
                        : {});
                    // Handle the case where the message already exists
                    // (typically, when retrying to send a message).
                    // If the message already exists, we can assume it was sent successfully,
                    // so we update the message status to "received".
                    // Right now, the only way to check this error is by checking
                    // the combination of the error code and the error description,
                    // since there is no special error code for duplicate messages.
                    if (parsedError.code === 4 &&
                        error_1 instanceof Error &&
                        error_1.message.includes('already exists')) {
                        updateMessage(__assign(__assign({}, message), { status: 'received' }));
                    }
                    else {
                        updateMessage(__assign(__assign({}, message), { error: parsedError, errorStatusCode: parsedError.status || undefined, status: 'failed' }));
                    }
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var sendMessage = function (_a, customMessageData, options) {
        var _b = _a.attachments, attachments = _b === void 0 ? [] : _b, _c = _a.mentioned_users, mentioned_users = _c === void 0 ? [] : _c, parent = _a.parent, _d = _a.text, text = _d === void 0 ? '' : _d;
        return __awaiter(void 0, void 0, void 0, function () {
            var messagePreview;
            var _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        channel.state.filterErrorMessages();
                        messagePreview = __assign({ __html: text, attachments: attachments, created_at: new Date(), html: text, id: (_e = customMessageData === null || customMessageData === void 0 ? void 0 : customMessageData.id) !== null && _e !== void 0 ? _e : "".concat(client.userID, "-").concat(nanoid()), mentioned_users: mentioned_users, reactions: [], status: 'sending', text: text, type: 'regular', user: client.user }, ((parent === null || parent === void 0 ? void 0 : parent.id) ? { parent_id: parent.id } : null));
                        updateMessage(messagePreview);
                        return [4 /*yield*/, doSendMessage(messagePreview, customMessageData, options)];
                    case 1:
                        _f.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    var retrySendMessage = function (message) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updateMessage(__assign(__assign({}, message), { errorStatusCode: undefined, status: 'sending' }));
                    if (message.attachments) {
                        // remove scraped attachments added during the message composition in MessageInput to prevent sync issues
                        message.attachments = message.attachments.filter(function (attachment) { return !attachment.og_scrape_url; });
                    }
                    return [4 /*yield*/, doSendMessage(message)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var removeMessage = function (message) {
        channel.state.removeMessage(message);
        dispatch({
            channel: channel,
            parentId: state.thread && message.parent_id,
            type: 'copyMessagesFromChannel',
        });
    };
    /** THREAD */
    var openThread = function (message, event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        setQuotedMessage(function (current) {
            if ((current === null || current === void 0 ? void 0 : current.parent_id) !== (message === null || message === void 0 ? void 0 : message.parent_id)) {
                return undefined;
            }
            else {
                return current;
            }
        });
        dispatch({ channel: channel, message: message, type: 'openThread' });
    };
    var closeThread = function (event) {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        dispatch({ type: 'closeThread' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var loadMoreThreadFinished = useCallback(debounce(function (threadHasMore, threadMessages) {
        dispatch({
            threadHasMore: threadHasMore,
            threadMessages: threadMessages,
            type: 'loadMoreThreadFinished',
        });
    }, 2000, { leading: true, trailing: true }), []);
    var loadMoreThread = function (limit) {
        if (limit === void 0) { limit = DEFAULT_THREAD_PAGE_SIZE; }
        return __awaiter(void 0, void 0, void 0, function () {
            var parentId, oldMessages, oldestMessageId, queryResponse, threadHasMoreMessages, newThreadMessages, e_7;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // FIXME: should prevent loading more, if state.thread.reply_count === channel.state.threads[parentID].length
                        if (state.threadLoadingMore || !state.thread || !state.threadHasMore)
                            return [2 /*return*/];
                        dispatch({ type: 'startLoadingThread' });
                        parentId = state.thread.id;
                        if (!parentId) {
                            return [2 /*return*/, dispatch({ type: 'closeThread' })];
                        }
                        oldMessages = channel.state.threads[parentId] || [];
                        oldestMessageId = (_a = oldMessages[0]) === null || _a === void 0 ? void 0 : _a.id;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, channel.getReplies(parentId, {
                                id_lt: oldestMessageId,
                                limit: limit,
                            })];
                    case 2:
                        queryResponse = _b.sent();
                        threadHasMoreMessages = hasMoreMessagesProbably(queryResponse.messages.length, limit);
                        newThreadMessages = channel.state.threads[parentId] || [];
                        // next set loadingMore to false so we can start asking for more data
                        loadMoreThreadFinished(threadHasMoreMessages, newThreadMessages);
                        return [3 /*break*/, 4];
                    case 3:
                        e_7 = _b.sent();
                        loadMoreThreadFinished(false, oldMessages);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    var onMentionsHoverOrClick = useMentionsHandlers(onMentionsHover, onMentionsClick);
    var editMessage = useEditMessageHandler(doUpdateMessageRequest);
    var typing = state.typing, restState = __rest(state, ["typing"]);
    var channelStateContextValue = useCreateChannelStateContext(__assign(__assign({}, restState), { acceptedFiles: acceptedFiles, channel: channel, channelCapabilitiesArray: channelCapabilitiesArray, channelConfig: channelConfig, channelUnreadUiState: channelUnreadUiState, debounceURLEnrichmentMs: enrichURLForPreviewConfig === null || enrichURLForPreviewConfig === void 0 ? void 0 : enrichURLForPreviewConfig.debounceURLEnrichmentMs, dragAndDropWindow: dragAndDropWindow, enrichURLForPreview: props.enrichURLForPreview, findURLFn: enrichURLForPreviewConfig === null || enrichURLForPreviewConfig === void 0 ? void 0 : enrichURLForPreviewConfig.findURLFn, giphyVersion: props.giphyVersion || 'fixed_height', imageAttachmentSizeHandler: props.imageAttachmentSizeHandler || getImageAttachmentConfiguration, maxNumberOfFiles: maxNumberOfFiles, multipleUploads: multipleUploads, mutes: mutes, notifications: notifications, onLinkPreviewDismissed: enrichURLForPreviewConfig === null || enrichURLForPreviewConfig === void 0 ? void 0 : enrichURLForPreviewConfig.onLinkPreviewDismissed, quotedMessage: quotedMessage, shouldGenerateVideoThumbnail: props.shouldGenerateVideoThumbnail || true, videoAttachmentSizeHandler: props.videoAttachmentSizeHandler || getVideoAttachmentConfiguration, watcher_count: state.watcherCount }));
    var channelActionContextValue = useMemo(function () { return ({
        addNotification: addNotification,
        closeThread: closeThread,
        deleteMessage: deleteMessage,
        dispatch: dispatch,
        editMessage: editMessage,
        jumpToFirstUnreadMessage: jumpToFirstUnreadMessage,
        jumpToLatestMessage: jumpToLatestMessage,
        jumpToMessage: jumpToMessage,
        loadMore: loadMore,
        loadMoreNewer: loadMoreNewer,
        loadMoreThread: loadMoreThread,
        markRead: markRead,
        onMentionsClick: onMentionsHoverOrClick,
        onMentionsHover: onMentionsHoverOrClick,
        openThread: openThread,
        removeMessage: removeMessage,
        retrySendMessage: retrySendMessage,
        sendMessage: sendMessage,
        setChannelUnreadUiState: setChannelUnreadUiState,
        setQuotedMessage: setQuotedMessage,
        skipMessageDataMemoization: skipMessageDataMemoization,
        updateMessage: updateMessage,
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        channel.cid,
        deleteMessage,
        enrichURLForPreviewConfig === null || enrichURLForPreviewConfig === void 0 ? void 0 : enrichURLForPreviewConfig.findURLFn,
        enrichURLForPreviewConfig === null || enrichURLForPreviewConfig === void 0 ? void 0 : enrichURLForPreviewConfig.onLinkPreviewDismissed,
        loadMore,
        loadMoreNewer,
        markRead,
        quotedMessage,
        jumpToFirstUnreadMessage,
        jumpToMessage,
        jumpToLatestMessage,
        setChannelUnreadUiState,
    ]);
    var componentContextValue = useMemo(function () {
        var _a;
        return ({
            Attachment: props.Attachment || DefaultAttachment,
            AttachmentPreviewList: props.AttachmentPreviewList,
            AudioRecorder: props.AudioRecorder,
            AutocompleteSuggestionHeader: props.AutocompleteSuggestionHeader,
            AutocompleteSuggestionItem: props.AutocompleteSuggestionItem,
            AutocompleteSuggestionList: props.AutocompleteSuggestionList,
            Avatar: props.Avatar,
            BaseImage: props.BaseImage,
            CooldownTimer: props.CooldownTimer,
            CustomMessageActionsList: props.CustomMessageActionsList,
            DateSeparator: props.DateSeparator || DateSeparator,
            EditMessageInput: props.EditMessageInput,
            EmojiPicker: props.EmojiPicker,
            emojiSearchIndex: props.emojiSearchIndex,
            EmptyStateIndicator: props.EmptyStateIndicator,
            FileUploadIcon: props.FileUploadIcon,
            GiphyPreviewMessage: props.GiphyPreviewMessage,
            HeaderComponent: props.HeaderComponent,
            Input: props.Input,
            LinkPreviewList: props.LinkPreviewList,
            LoadingIndicator: props.LoadingIndicator,
            Message: props.Message || MessageSimple,
            MessageBouncePrompt: props.MessageBouncePrompt,
            MessageDeleted: props.MessageDeleted,
            MessageListNotifications: props.MessageListNotifications,
            MessageNotification: props.MessageNotification,
            MessageOptions: props.MessageOptions,
            MessageRepliesCountButton: props.MessageRepliesCountButton,
            MessageStatus: props.MessageStatus,
            MessageSystem: props.MessageSystem || EventComponent,
            MessageTimestamp: props.MessageTimestamp,
            ModalGallery: props.ModalGallery,
            PinIndicator: props.PinIndicator,
            QuotedMessage: props.QuotedMessage,
            QuotedMessagePreview: props.QuotedMessagePreview,
            reactionOptions: (_a = props.reactionOptions) !== null && _a !== void 0 ? _a : defaultReactionOptions,
            ReactionSelector: props.ReactionSelector,
            ReactionsList: props.ReactionsList,
            SendButton: props.SendButton,
            StartRecordingAudioButton: props.StartRecordingAudioButton,
            ThreadHead: props.ThreadHead,
            ThreadHeader: props.ThreadHeader,
            ThreadStart: props.ThreadStart,
            Timestamp: props.Timestamp,
            TriggerProvider: props.TriggerProvider,
            TypingIndicator: props.TypingIndicator,
            UnreadMessagesNotification: props.UnreadMessagesNotification,
            UnreadMessagesSeparator: props.UnreadMessagesSeparator || UnreadMessagesSeparator,
            VirtualMessage: props.VirtualMessage,
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.reactionOptions]);
    var typingContextValue = useCreateTypingContext({
        typing: typing,
    });
    var className = clsx(chatClass, theme, channelClass);
    if (state.error) {
        return (React.createElement("div", { className: className },
            React.createElement(LoadingErrorIndicator, { error: state.error })));
    }
    if (state.loading) {
        return (React.createElement("div", { className: className },
            React.createElement(LoadingIndicator, null)));
    }
    if (!channel.watch) {
        return (React.createElement("div", { className: className },
            React.createElement("div", null, t('Channel Missing'))));
    }
    return (React.createElement("div", { className: clsx(className, windowsEmojiClass) },
        React.createElement(ChannelStateProvider, { value: channelStateContextValue },
            React.createElement(ChannelActionProvider, { value: channelActionContextValue },
                React.createElement(ComponentProvider, { value: componentContextValue },
                    React.createElement(TypingProvider, { value: typingContextValue },
                        React.createElement("div", { className: "".concat(chatContainerClass) },
                            dragAndDropWindow && (React.createElement(DropzoneProvider, __assign({}, optionalMessageInputProps), children)),
                            !dragAndDropWindow && React.createElement(React.Fragment, null, children))))))));
};
/**
 * A wrapper component that provides channel data and renders children.
 * The Channel component provides the following contexts:
 * - [ChannelStateContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_state_context/)
 * - [ChannelActionContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_action_context/)
 * - [ComponentContext](https://getstream.io/chat/docs/sdk/react/contexts/component_context/)
 * - [TypingContext](https://getstream.io/chat/docs/sdk/react/contexts/typing_context/)
 */
export var Channel = React.memo(UnMemoizedChannel);
