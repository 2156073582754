import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { useCallback, useState } from 'react';
import throttle from 'lodash.throttle';
import { searchLocalUsers } from './utils';
import { UserItem } from '../../UserItem/UserItem';
import { useChatContext } from '../../../context/ChatContext';
import { useChannelStateContext } from '../../../context/ChannelStateContext';
export var useUserTrigger = function (params) {
    var disableMentions = params.disableMentions, mentionAllAppUsers = params.mentionAllAppUsers, _a = params.mentionQueryParams, mentionQueryParams = _a === void 0 ? {} : _a, onSelectUser = params.onSelectUser, useMentionsTransliteration = params.useMentionsTransliteration;
    var _b = useState(false), searching = _b[0], setSearching = _b[1];
    var _c = useChatContext('useUserTrigger'), client = _c.client, mutes = _c.mutes, themeVersion = _c.themeVersion;
    var channel = useChannelStateContext('useUserTrigger').channel;
    var members = channel.state.members;
    var watchers = channel.state.watchers;
    var getMembersAndWatchers = useCallback(function () {
        var memberUsers = members ? Object.values(members).map(function (_a) {
            var user = _a.user;
            return user;
        }) : [];
        var watcherUsers = watchers ? Object.values(watchers) : [];
        var users = __spreadArray(__spreadArray([], memberUsers, true), watcherUsers, true);
        // make sure we don't list users twice
        var uniqueUsers = {};
        users.forEach(function (user) {
            if (user && !uniqueUsers[user.id]) {
                uniqueUsers[user.id] = user;
            }
        });
        return Object.values(uniqueUsers);
    }, [members, watchers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var queryMembersThrottled = useCallback(throttle(function (query, onReady) { return __awaiter(void 0, void 0, void 0, function () {
        var response, users, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, channel.queryMembers({
                            name: { $autocomplete: query },
                        })];
                case 1:
                    response = _a.sent();
                    users = response.members.map(function (member) { return member.user; });
                    if (onReady && users.length) {
                        onReady(users);
                    }
                    else {
                        onReady([]);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.log({ error: error_1 });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, 200), [channel]);
    var queryUsers = function (query, onReady) { return __awaiter(void 0, void 0, void 0, function () {
        var users, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!query || searching)
                        return [2 /*return*/];
                    setSearching(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, client.queryUsers(__assign({ $or: [{ id: { $autocomplete: query } }, { name: { $autocomplete: query } }], id: { $ne: client.userID } }, (typeof mentionQueryParams.filters === 'function'
                            ? mentionQueryParams.filters(query)
                            : mentionQueryParams.filters)), Array.isArray(mentionQueryParams.sort)
                            ? __spreadArray([{ id: 1 }], mentionQueryParams.sort, true) : __assign({ id: 1 }, mentionQueryParams.sort), __assign({ limit: 10 }, mentionQueryParams.options))];
                case 2:
                    users = (_a.sent()).users;
                    if (onReady && users.length) {
                        onReady(users);
                    }
                    else {
                        onReady([]);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.log({ error: error_2 });
                    return [3 /*break*/, 4];
                case 4:
                    setSearching(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var queryUsersThrottled = throttle(queryUsers, 200);
    return {
        callback: function (item) { return onSelectUser(item); },
        component: UserItem,
        dataProvider: function (query, text, onReady) {
            var _a, _b;
            if (disableMentions)
                return;
            var filterMutes = function (data) {
                if (text.includes('/unmute') && !mutes.length) {
                    return [];
                }
                if (!mutes.length)
                    return data;
                if (text.includes('/unmute')) {
                    return data.filter(function (suggestion) {
                        return mutes.some(function (mute) { return mute.target.id === suggestion.id; });
                    });
                }
                return data.filter(function (suggestion) { return mutes.every(function (mute) { return mute.target.id !== suggestion.id; }); });
            };
            if (mentionAllAppUsers) {
                return queryUsersThrottled(query, function (data) {
                    if (onReady)
                        onReady(filterMutes(data), query);
                });
            }
            /**
             * By default, we return maximum 100 members via queryChannels api call.
             * Thus it is safe to assume, that if number of members in channel.state is < 100,
             * then all the members are already available on client side and we don't need to
             * make any api call to queryMembers endpoint.
             */
            if (!query || Object.values(members || {}).length < 100) {
                var users = getMembersAndWatchers();
                var params_1 = {
                    ownUserId: client.userID,
                    query: query,
                    text: text,
                    useMentionsTransliteration: useMentionsTransliteration,
                    users: users,
                };
                var matchingUsers = searchLocalUsers(params_1);
                var usersToShow = (_b = (_a = mentionQueryParams.options) === null || _a === void 0 ? void 0 : _a.limit) !== null && _b !== void 0 ? _b : (themeVersion === '2' ? 7 : 10);
                var data = matchingUsers.slice(0, usersToShow);
                if (onReady)
                    onReady(filterMutes(data), query);
                return data;
            }
            return queryMembersThrottled(query, function (data) {
                if (onReady)
                    onReady(filterMutes(data), query);
            });
        },
        output: function (entity) { return ({
            caretPosition: 'next',
            key: entity.id,
            text: "@".concat(entity.name || entity.id),
        }); },
    };
};
