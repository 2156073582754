"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const animation_resolver_1 = require("@gluestack-style/animation-resolver");
const legend_motion_animation_driver_1 = require("@gluestack-style/legend-motion-animation-driver");
exports.default = (0, react_1.styled)(animation_resolver_1.AnimatedView, {
    //@ts-ignore
    ':initial': {
        opacity: 0,
        scale: 0.5,
    },
    ':animate': {
        opacity: 1,
        scale: 1,
    },
    ':exit': {
        opacity: 0,
        scale: 0.5,
    },
    ':transition': {
        type: 'spring',
        damping: 18,
        stiffness: 250,
        opacity: {
            type: 'timing',
            duration: 250,
        },
    },
    'py': '$1',
    'px': '$2',
    // @ts-ignore
    'borderRadius': '$sm',
    // @ts-ignore
    'bg': '$muted.800',
    '_text': {
        // @ts-ignore
        fontSize: '$sm',
        color: '$text.50',
    },
    '_dark': {
        backgroundColor: '$muted.50',
        _text: {
            color: '$text.900',
        },
    },
    'defaultProps': {
        shadow: '6',
    },
}, {
    componentName: 'TooltipContent',
    descendantStyle: ['_text'],
}, {
    plugins: [new animation_resolver_1.AnimationResolver(legend_motion_animation_driver_1.MotionAnimationDriver)],
});
