"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const legend_motion_animation_driver_1 = require("@gluestack-style/legend-motion-animation-driver");
const animation_resolver_1 = require("@gluestack-style/animation-resolver");
exports.default = (0, react_1.styled)(animation_resolver_1.AnimatedView, {
    m: '$3',
    padding: '$2',
    backgroundColor: `$muted.700`,
    _dark: {
        backgroundColor: `$muted.600`,
    },
    borderRadius: '$sm',
    flexDirection: 'row',
    _web: {
        pointerEvents: 'auto',
    },
    defaultProps: {
        shadow: '6',
    },
}, {
    componentName: 'ToastAnimationWrapper',
}, {
    plugins: [new animation_resolver_1.AnimationResolver(legend_motion_animation_driver_1.MotionAnimationDriver)],
});
