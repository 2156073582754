import { __assign } from "tslib";
import throttle from 'lodash.throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { ChannelPreviewMessenger } from './ChannelPreviewMessenger';
import { useIsChannelMuted } from './hooks/useIsChannelMuted';
import { useChannelPreviewInfo } from './hooks/useChannelPreviewInfo';
import { getLatestMessagePreview } from './utils';
import { useChatContext } from '../../context/ChatContext';
import { useTranslationContext } from '../../context/TranslationContext';
import { useMessageDeliveryStatus } from './hooks/useMessageDeliveryStatus';
export var ChannelPreview = function (props) {
    var channel = props.channel, _a = props.Preview, Preview = _a === void 0 ? ChannelPreviewMessenger : _a, channelUpdateCount = props.channelUpdateCount;
    var _b = useChatContext('ChannelPreview'), activeChannel = _b.channel, client = _b.client, setActiveChannel = _b.setActiveChannel;
    var _c = useTranslationContext('ChannelPreview'), t = _c.t, userLanguage = _c.userLanguage;
    var _d = useChannelPreviewInfo({ channel: channel }), displayImage = _d.displayImage, displayTitle = _d.displayTitle;
    var _e = useState(channel.state.messages[channel.state.messages.length - 1]), lastMessage = _e[0], setLastMessage = _e[1];
    var _f = useState(0), unread = _f[0], setUnread = _f[1];
    var messageDeliveryStatus = useMessageDeliveryStatus({
        channel: channel,
        lastMessage: lastMessage,
    }).messageDeliveryStatus;
    var isActive = (activeChannel === null || activeChannel === void 0 ? void 0 : activeChannel.cid) === channel.cid;
    var muted = useIsChannelMuted(channel).muted;
    useEffect(function () {
        var handleEvent = function (event) {
            if (!event.cid)
                return setUnread(0);
            if (channel.cid === event.cid)
                setUnread(0);
        };
        client.on('notification.mark_read', handleEvent);
        return function () { return client.off('notification.mark_read', handleEvent); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        var handleEvent = function (event) {
            var _a, _b;
            if (channel.cid !== event.cid)
                return;
            if (((_a = event.user) === null || _a === void 0 ? void 0 : _a.id) !== ((_b = client.user) === null || _b === void 0 ? void 0 : _b.id))
                return;
            setUnread(channel.countUnread());
        };
        channel.on('notification.mark_unread', handleEvent);
        return function () {
            channel.off('notification.mark_unread', handleEvent);
        };
    }, [channel, client]);
    var refreshUnreadCount = useMemo(function () {
        return throttle(function () {
            if (muted) {
                setUnread(0);
            }
            else {
                setUnread(channel.countUnread());
            }
        }, 400);
    }, [channel, muted]);
    useEffect(function () {
        refreshUnreadCount();
        var handleEvent = function (event) {
            if (event.message)
                setLastMessage(event.message);
            refreshUnreadCount();
        };
        channel.on('message.new', handleEvent);
        channel.on('message.updated', handleEvent);
        channel.on('message.deleted', handleEvent);
        return function () {
            channel.off('message.new', handleEvent);
            channel.off('message.updated', handleEvent);
            channel.off('message.deleted', handleEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshUnreadCount, channelUpdateCount]);
    if (!Preview)
        return null;
    var latestMessage = getLatestMessagePreview(channel, t, userLanguage);
    return (React.createElement(Preview, __assign({}, props, { active: isActive, displayImage: displayImage, displayTitle: displayTitle, lastMessage: lastMessage, latestMessage: latestMessage, messageDeliveryStatus: messageDeliveryStatus, setActiveChannel: setActiveChannel, unread: unread })));
};
