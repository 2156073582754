"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.config = void 0;
const NBsupport_1 = require("../utils/NBsupport");
exports.config = {
    componentPath: '/components',
    theme: {
        aliases: {
            bg: 'backgroundColor',
            bgColor: 'backgroundColor',
            h: 'height',
            w: 'width',
            p: 'padding',
            px: 'paddingHorizontal',
            py: 'paddingVertical',
            pt: 'paddingTop',
            pb: 'paddingBottom',
            pr: 'paddingRight',
            pl: 'paddingLeft',
            m: 'margin',
            mx: 'marginHorizontal',
            my: 'marginVertical',
            mt: 'marginTop',
            mb: 'marginBottom',
            mr: 'marginRight',
            ml: 'marginLeft',
            align: 'alignItems',
            justify: 'justifyContent',
            wrap: 'flexWrap',
            basis: 'flexBasis',
            grow: 'flexGrow',
            shrink: 'flexShrink',
            direction: 'flexDirection',
            flexDir: 'flexDirection',
            minH: 'minHeight',
            minW: 'minWidth',
            maxH: 'maxHeight',
            maxW: 'maxWidth',
            rounded: 'borderRadius',
            space: 'gap',
            borderTopLeftRadius: 'borderTopLeftRadius',
            borderTopRightRadius: 'borderTopRightRadius',
            borderBottomLeftRadius: 'borderBottomLeftRadius',
            borderBottomRightRadius: 'borderBottomRightRadius',
            roundedTop: ['borderTopLeftRadius', 'borderTopRightRadius'],
            roundedBottom: [
                'borderBottomLeftRadius',
                'borderBottomRightRadius',
            ],
            roundedRight: ['borderTopRightRadius', 'borderBottomRightRadius'],
            roundedLeft: ['borderTopLeftRadius', 'borderBottomLeftRadius'],
            borderTopRadius: ['borderTopLeftRadius', 'borderTopRightRadius'],
            borderBottomRadius: [
                'borderBottomLeftRadius',
                'borderBottomRightRadius',
            ],
            borderRightRadius: [
                'borderTopRightRadius',
                'borderBottomRightRadius',
            ],
            borderLeftRadius: [
                'borderTopLeftRadius',
                'borderBottomLeftRadius',
            ],
        },
        tokens: {
            colors: {
                'contrastThreshold': 7,
                //
                'rose.50': '#fff1f2',
                'rose.100': '#ffe4e6',
                'rose.200': '#fecdd3',
                'rose.300': '#fda4af',
                'rose.300.alpha0.1': '#fda4af1a',
                'rose.400': '#fb7185',
                'rose.400.alpha0.2': '#fb718533',
                'rose.500': '#f43f5e',
                'rose.500.alpha0.1': '#f43f5e1a',
                'rose.500.alpha0.2': '#f43f5e33',
                'rose.600': '#e11d48',
                'rose.600.alpha0.1': '#e11d481a',
                'rose.600.alpha0.2': '#e11d4833',
                'rose.600.alpha0.4': '#e11d4866',
                'rose.700': '#be123c',
                'rose.700.alpha0.4': '#be123c66',
                'rose.800': '#9f1239',
                'rose.900': '#881337',
                'pink.50': '#fdf2f8',
                'pink.100': '#fce7f3',
                'pink.200': '#fbcfe8',
                'pink.300': '#f9a8d4',
                'pink.300.alpha0.1': '#f9a8d41a',
                'pink.400': '#f472b6',
                'pink.400.alpha0.2': '#f472b633',
                'pink.500': '#ec4899',
                'pink.500.alpha0.1': '#ec48991a',
                'pink.500.alpha0.2': '#ec489933',
                'pink.600': '#db2777',
                'pink.600.alpha0.1': '#db27771a',
                'pink.600.alpha0.2': '#db277733',
                'pink.600.alpha0.4': '#db277766',
                'pink.700': '#be185d',
                'pink.700.alpha0.4': '#be185d66',
                'pink.800': '#9d174d',
                'pink.900': '#831843',
                'fuchsia.50': '#fdf4ff',
                'fuchsia.100': '#fae8ff',
                'fuchsia.200': '#f5d0fe',
                'fuchsia.300': '#f0abfc',
                'fuchsia.300.alpha0.1': '#f0abfc1a',
                'fuchsia.400': '#e879f9',
                'fuchsia.400.alpha0.2': '#e879f933',
                'fuchsia.500': '#d946ef',
                'fuchsia.500.alpha0.1': '#d946ef1a',
                'fuchsia.500.alpha0.2': '#d946ef33',
                'fuchsia.600': '#c026d3',
                'fuchsia.600.alpha0.1': '#c026d31a',
                'fuchsia.600.alpha0.2': '#c026d333',
                'fuchsia.600.alpha0.4': '#c026d366',
                'fuchsia.700': '#a21caf',
                'fuchsia.700.alpha0.4': '#a21caf66',
                'fuchsia.800': '#86198f',
                'fuchsia.900': '#701a75',
                'purple.50': '#faf5ff',
                'purple.100': '#f3e8ff',
                'purple.200': '#e9d5ff',
                'purple.300': '#d8b4fe',
                'purple.300.alpha0.1': '#d8b4fe1a',
                'purple.400': '#c084fc',
                'purple.400.alpha0.2': '#c084fc33',
                'purple.500': '#a855f7',
                'purple.500.alpha0.1': '#a855f71a',
                'purple.500.alpha0.2': '#a855f733',
                'purple.600': '#9333ea',
                'purple.600.alpha0.1': '#9333ea1a',
                'purple.600.alpha0.2': '#9333ea33',
                'purple.600.alpha0.4': '#9333ea66',
                'purple.700': '#7e22ce',
                'purple.700.alpha0.4': '#7e22ce66',
                'purple.800': '#6b21a8',
                'purple.900': '#581c87',
                'violet.50': '#f5f3ff',
                'violet.100': '#ede9fe',
                'violet.200': '#ddd6fe',
                'violet.300': '#c4b5fd',
                'violet.300.alpha0.1': '#c4b5fd1a',
                'violet.400': '#a78bfa',
                'violet.400.alpha0.2': '#a78bfa33',
                'violet.500': '#8b5cf6',
                'violet.500.alpha0.1': '#8b5cf61a',
                'violet.500.alpha0.2': '#8b5cf633',
                'violet.600': '#7c3aed',
                'violet.600.alpha0.1': '#7c3aed1a',
                'violet.600.alpha0.2': '#7c3aed33',
                'violet.600.alpha0.4': '#7c3aed66',
                'violet.700': '#6d28d9',
                'violet.700.alpha0.4': '#6d28d966',
                'violet.800': '#5b21b6',
                'violet.900': '#4c1d95',
                'indigo.50': '#eef2ff',
                'indigo.100': '#e0e7ff',
                'indigo.200': '#c7d2fe',
                'indigo.300': '#a5b4fc',
                'indigo.300.alpha0.1': '#a5b4fc1a',
                'indigo.400': '#818cf8',
                'indigo.400.alpha0.2': '#818cf833',
                'indigo.500': '#6366f1',
                'indigo.500.alpha0.1': '#6366f11a',
                'indigo.500.alpha0.2': '#6366f133',
                'indigo.600': '#4f46e5',
                'indigo.600.alpha0.1': '#4f46e51a',
                'indigo.600.alpha0.2': '#4f46e533',
                'indigo.600.alpha0.4': '#4f46e566',
                'indigo.700': '#4338ca',
                'indigo.700.alpha0.4': '#4338ca66',
                'indigo.800': '#3730a3',
                'indigo.900': '#312e81',
                'blue.50': '#eff6ff',
                'blue.100': '#dbeafe',
                'blue.200': '#bfdbfe',
                'blue.300': '#93c5fd',
                'blue.300.alpha0.1': '#93c5fd1a',
                'blue.400': '#60a5fa',
                'blue.400.alpha0.2': '#60a5fa33',
                'blue.500': '#3b82f6',
                'blue.500.alpha0.1': '#3b82f61a',
                'blue.500.alpha0.2': '#3b82f633',
                'blue.600': '#2563eb',
                'blue.600.alpha0.1': '#2563eb1a',
                'blue.600.alpha0.2': '#2563eb33',
                'blue.600.alpha0.4': '#2563eb66',
                'blue.700': '#1d4ed8',
                'blue.700.alpha0.4': '#1d4ed866',
                'blue.800': '#1e40af',
                'blue.900': '#1e3a8a',
                'lightBlue.50': '#f0f9ff',
                'lightBlue.100': '#e0f2fe',
                'lightBlue.200': '#bae6fd',
                'lightBlue.300': '#7dd3fc',
                'lightBlue.300.alpha0.1': '#7dd3fc1a',
                'lightBlue.400': '#38bdf8',
                'lightBlue.400.alpha0.2': '#38bdf833',
                'lightBlue.500': '#0ea5e9',
                'lightBlue.500.alpha0.1': '#0ea5e91a',
                'lightBlue.500.alpha0.2': '#0ea5e933',
                'lightBlue.600': '#0284c7',
                'lightBlue.600.alpha0.1': '#0284c71a',
                'lightBlue.600.alpha0.2': '#0284c733',
                'lightBlue.600.alpha0.4': '#0284c766',
                'lightBlue.700': '#0369a1',
                'lightBlue.700.alpha0.4': '#0369a166',
                'lightBlue.800': '#075985',
                'lightBlue.900': '#0c4a6e',
                'darkBlue.50': '#dbf4ff',
                'darkBlue.100': '#addbff',
                'darkBlue.200': '#7cc2ff',
                'darkBlue.300': '#4aa9ff',
                'darkBlue.300.alpha0.1': '#4aa9ff1a',
                'darkBlue.400': '#1a91ff',
                'darkBlue.400.alpha0.2': '#1a91ff33',
                'darkBlue.500': '#0077e6',
                'darkBlue.500.alpha0.1': '#0077e61a',
                'darkBlue.500.alpha0.2': '#0077e633',
                'darkBlue.600': '#005db4',
                'darkBlue.600.alpha0.1': '#005db41a',
                'darkBlue.600.alpha0.2': '#005db433',
                'darkBlue.600.alpha0.4': '#005db466',
                'darkBlue.700': '#004282',
                'darkBlue.700.alpha0.4': '#00428266',
                'darkBlue.800': '#002851',
                'darkBlue.900': '#000e21',
                'cyan.50': '#ecfeff',
                'cyan.100': '#cffafe',
                'cyan.200': '#a5f3fc',
                'cyan.300': '#67e8f9',
                'cyan.300.alpha0.1': '#67e8f91a',
                'cyan.400': '#22d3ee',
                'cyan.400.alpha0.2': '#22d3ee33',
                'cyan.500': '#06b6d4',
                'cyan.500.alpha0.1': '#06b6d41a',
                'cyan.500.alpha0.2': '#06b6d433',
                'cyan.600': '#0891b2',
                'cyan.600.alpha0.1': '#0891b21a',
                'cyan.600.alpha0.2': '#0891b233',
                'cyan.600.alpha0.4': '#0891b266',
                'cyan.700': '#0e7490',
                'cyan.700.alpha0.4': '#0e749066',
                'cyan.800': '#155e75',
                'cyan.900': '#164e63',
                'teal.50': '#f0fdfa',
                'teal.100': '#ccfbf1',
                'teal.200': '#99f6e4',
                'teal.300': '#5eead4',
                'teal.300.alpha0.1': '#5eead41a',
                'teal.400': '#2dd4bf',
                'teal.400.alpha0.2': '#2dd4bf33',
                'teal.500': '#14b8a6',
                'teal.500.alpha0.1': '#14b8a61a',
                'teal.500.alpha0.2': '#14b8a633',
                'teal.600': '#0d9488',
                'teal.600.alpha0.1': '#0d94881a',
                'teal.600.alpha0.2': '#0d948833',
                'teal.600.alpha0.4': '#0d948866',
                'teal.700': '#0f766e',
                'teal.700.alpha0.4': '#0f766e66',
                'teal.800': '#115e59',
                'teal.900': '#134e4a',
                'emerald.50': '#ecfdf5',
                'emerald.100': '#d1fae5',
                'emerald.200': '#a7f3d0',
                'emerald.300': '#6ee7b7',
                'emerald.300.alpha0.1': '#6ee7b71a',
                'emerald.400': '#34d399',
                'emerald.400.alpha0.2': '#34d39933',
                'emerald.500': '#10b981',
                'emerald.500.alpha0.1': '#10b9811a',
                'emerald.500.alpha0.2': '#10b98133',
                'emerald.600': '#059669',
                'emerald.600.alpha0.1': '#0596691a',
                'emerald.600.alpha0.2': '#05966933',
                'emerald.600.alpha0.4': '#05966966',
                'emerald.700': '#047857',
                'emerald.700.alpha0.4': '#04785766',
                'emerald.800': '#065f46',
                'emerald.900': '#064e3b',
                'green.50': '#f0fdf4',
                'green.100': '#dcfce7',
                'green.200': '#bbf7d0',
                'green.300': '#86efac',
                'green.300.alpha0.1': '#86efac1a',
                'green.400': '#4ade80',
                'green.400.alpha0.2': '#4ade8033',
                'green.500': '#22c55e',
                'green.500.alpha0.1': '#22c55e1a',
                'green.500.alpha0.2': '#22c55e33',
                'green.600': '#16a34a',
                'green.600.alpha0.1': '#16a34a1a',
                'green.600.alpha0.2': '#16a34a33',
                'green.600.alpha0.4': '#16a34a66',
                'green.700': '#15803d',
                'green.700.alpha0.4': '#15803d66',
                'green.800': '#166534',
                'green.900': '#14532d',
                'lime.50': '#f7fee7',
                'lime.100': '#ecfccb',
                'lime.200': '#d9f99d',
                'lime.300': '#bef264',
                'lime.300.alpha0.1': '#bef2641a',
                'lime.400': '#a3e635',
                'lime.400.alpha0.2': '#a3e63533',
                'lime.500': '#84cc16',
                'lime.500.alpha0.1': '#84cc161a',
                'lime.500.alpha0.2': '#84cc1633',
                'lime.600': '#65a30d',
                'lime.600.alpha0.1': '#65a30d1a',
                'lime.600.alpha0.2': '#65a30d33',
                'lime.600.alpha0.4': '#65a30d66',
                'lime.700': '#4d7c0f',
                'lime.700.alpha0.4': '#4d7c0f66',
                'lime.800': '#3f6212',
                'lime.900': '#365314',
                'yellow.50': '#fefce8',
                'yellow.100': '#fef9c3',
                'yellow.200': '#fef08a',
                'yellow.300': '#fde047',
                'yellow.300.alpha0.1': '#fde0471a',
                'yellow.400': '#facc15',
                'yellow.400.alpha0.2': '#facc1533',
                'yellow.500': '#eab308',
                'yellow.500.alpha0.1': '#eab3081a',
                'yellow.500.alpha0.2': '#eab30833',
                'yellow.600': '#ca8a04',
                'yellow.600.alpha0.1': '#ca8a041a',
                'yellow.600.alpha0.2': '#ca8a0433',
                'yellow.600.alpha0.4': '#ca8a0466',
                'yellow.700': '#a16207',
                'yellow.700.alpha0.4': '#a1620766',
                'yellow.800': '#854d0e',
                'yellow.900': '#713f12',
                'amber.50': '#fffbeb',
                'amber.100': '#fef3c7',
                'amber.200': '#fde68a',
                'amber.300': '#fcd34d',
                'amber.300.alpha0.1': '#fcd34d1a',
                'amber.400': '#fbbf24',
                'amber.400.alpha0.2': '#fbbf2433',
                'amber.500': '#f59e0b',
                'amber.500.alpha0.1': '#f59e0b1a',
                'amber.500.alpha0.2': '#f59e0b33',
                'amber.600': '#d97706',
                'amber.600.alpha0.1': '#d977061a',
                'amber.600.alpha0.2': '#d9770633',
                'amber.600.alpha0.4': '#d9770666',
                'amber.700': '#b45309',
                'amber.700.alpha0.4': '#b4530966',
                'amber.800': '#92400e',
                'amber.900': '#78350f',
                'orange.50': '#fff7ed',
                'orange.100': '#ffedd5',
                'orange.200': '#fed7aa',
                'orange.300': '#fdba74',
                'orange.300.alpha0.1': '#fdba741a',
                'orange.400': '#fb923c',
                'orange.400.alpha0.2': '#fb923c33',
                'orange.500': '#f97316',
                'orange.500.alpha0.1': '#f973161a',
                'orange.500.alpha0.2': '#f9731633',
                'orange.600': '#ea580c',
                'orange.600.alpha0.1': '#ea580c1a',
                'orange.600.alpha0.2': '#ea580c33',
                'orange.600.alpha0.4': '#ea580c66',
                'orange.700': '#c2410c',
                'orange.700.alpha0.4': '#c2410c66',
                'orange.800': '#9a3412',
                'orange.900': '#7c2d12',
                'red.50': '#fef2f2',
                'red.100': '#fee2e2',
                'red.200': '#fecaca',
                'red.300': '#fca5a5',
                'red.300.alpha0.1': '#fca5a51a',
                'red.400': '#f87171',
                'red.400.alpha0.2': '#f8717133',
                'red.500': '#ef4444',
                'red.500.alpha0.1': '#ef44441a',
                'red.500.alpha0.2': '#ef444433',
                'red.600': '#dc2626',
                'red.600.alpha0.1': '#dc26261a',
                'red.600.alpha0.2': '#dc262633',
                'red.600.alpha0.4': '#dc262666',
                'red.700': '#b91c1c',
                'red.700.alpha0.4': '#b91c1c66',
                'red.800': '#991b1b',
                'red.900': '#7f1d1d',
                'warmGray.50': '#fafaf9',
                'warmGray.100': '#f5f5f4',
                'warmGray.200': '#e7e5e4',
                'warmGray.300': '#d6d3d1',
                'warmGray.300.alpha0.1': '#d6d3d11a',
                'warmGray.400': '#a8a29e',
                'warmGray.400.alpha0.2': '#a8a29e33',
                'warmGray.500': '#78716c',
                'warmGray.500.alpha0.1': '#78716c1a',
                'warmGray.500.alpha0.2': '#78716c33',
                'warmGray.600': '#57534e',
                'warmGray.600.alpha0.1': '#57534e1a',
                'warmGray.600.alpha0.2': '#57534e33',
                'warmGray.600.alpha0.4': '#57534e66',
                'warmGray.700': '#44403c',
                'warmGray.700.alpha0.4': '#44403c66',
                'warmGray.800': '#292524',
                'warmGray.900': '#1c1917',
                'trueGray.50': '#fafafa',
                'trueGray.100': '#f5f5f5',
                'trueGray.200': '#e5e5e5',
                'trueGray.300': '#d4d4d4',
                'trueGray.300.alpha0.1': '#d4d4d41a',
                'trueGray.400': '#a3a3a3',
                'trueGray.400.alpha0.2': '#a3a3a333',
                'trueGray.500': '#737373',
                'trueGray.500.alpha0.1': '#7373731a',
                'trueGray.500.alpha0.2': '#73737333',
                'trueGray.600': '#525252',
                'trueGray.600.alpha0.1': '#5252521a',
                'trueGray.600.alpha0.2': '#52525233',
                'trueGray.600.alpha0.4': '#52525266',
                'trueGray.700': '#404040',
                'trueGray.700.alpha0.4': '#40404066',
                'trueGray.800': '#262626',
                'trueGray.900': '#171717',
                'gray.50': '#fafafa',
                'gray.100': '#f4f4f5',
                'gray.200': '#e4e4e7',
                'gray.300': '#d4d4d8',
                'gray.300.alpha0.1': '#d4d4d81a',
                'gray.400': '#a1a1aa',
                'gray.400.alpha0.2': '#a1a1aa33',
                'gray.500': '#71717a',
                'gray.500.alpha0.1': '#71717a1a',
                'gray.500.alpha0.2': '#71717a33',
                'gray.600': '#52525b',
                'gray.600.alpha0.1': '#52525b1a',
                'gray.600.alpha0.2': '#52525b33',
                'gray.600.alpha0.4': '#52525b66',
                'gray.700': '#3f3f46',
                'gray.700.alpha0.4': '#3f3f4666',
                'gray.800': '#27272a',
                'gray.900': '#18181b',
                'coolGray.50': '#f9fafb',
                'coolGray.100': '#f3f4f6',
                'coolGray.200': '#e5e7eb',
                'coolGray.300': '#d1d5db',
                'coolGray.300.alpha0.1': '#d1d5db1a',
                'coolGray.400': '#9ca3af',
                'coolGray.400.alpha0.2': '#9ca3af33',
                'coolGray.500': '#6b7280',
                'coolGray.500.alpha0.1': '#6b72801a',
                'coolGray.500.alpha0.2': '#6b728033',
                'coolGray.600': '#4b5563',
                'coolGray.600.alpha0.1': '#4b55631a',
                'coolGray.600.alpha0.2': '#4b556333',
                'coolGray.600.alpha0.4': '#4b556366',
                'coolGray.700': '#374151',
                'coolGray.700.alpha0.4': '#37415166',
                'coolGray.800': '#1f2937',
                'coolGray.900': '#111827',
                'blueGray.50': '#f8fafc',
                'blueGray.100': '#f1f5f9',
                'blueGray.200': '#e2e8f0',
                'blueGray.300': '#cbd5e1',
                'blueGray.300.alpha0.1': '#cbd5e11a',
                'blueGray.400': '#94a3b8',
                'blueGray.400.alpha0.2': '#94a3b833',
                'blueGray.500': '#64748b',
                'blueGray.500.alpha0.1': '#64748b1a',
                'blueGray.500.alpha0.2': '#64748b33',
                'blueGray.600': '#475569',
                'blueGray.600.alpha0.1': '#4755691a',
                'blueGray.600.alpha0.2': '#47556933',
                'blueGray.600.alpha0.4': '#47556966',
                'blueGray.700': '#334155',
                'blueGray.700.alpha0.4': '#33415566',
                'blueGray.800': '#1e293b',
                'blueGray.900': '#0f172a',
                'dark.50': '#18181b',
                'dark.100': '#27272a',
                'dark.200': '#3f3f46',
                'dark.300': '#52525b',
                'dark.300.alpha0.1': '#52525b1a',
                'dark.400': '#71717a',
                'dark.400.alpha0.2': '#71717a33',
                'dark.500': '#a1a1aa',
                'dark.500.alpha0.1': '#a1a1aa1a',
                'dark.500.alpha0.2': '#a1a1aa33',
                'dark.600': '#d4d4d8',
                'dark.600.alpha0.1': '#d4d4d81a',
                'dark.600.alpha0.2': '#d4d4d833',
                'dark.600.alpha0.4': '#d4d4d866',
                'dark.700': '#e4e4e7',
                'dark.700.alpha0.4': '#e4e4e766',
                'dark.800': '#f4f4f5',
                'dark.900': '#fafafa',
                //
                'text.50': '#fafafa',
                'text.100': '#f5f5f5',
                'text.200': '#e5e5e5',
                'text.300': '#d4d4d4',
                'text.300.alpha0.1': '#d4d4d41a',
                'text.400': '#a3a3a3',
                'text.400.alpha0.2': '#a3a3a333',
                'text.500': '#737373',
                'text.500.alpha0.1': '#7373731a',
                'text.500.alpha0.2': '#73737333',
                'text.600': '#525252',
                'text.600.alpha0.1': '#5252521a',
                'text.600.alpha0.2': '#52525233',
                'text.600.alpha0.4': '#52525266',
                'text.700': '#404040',
                'text.700.alpha0.4': '#40404066',
                'text.800': '#262626',
                'text.900': '#171717',
                //
                'white': '#FFFFFF',
                'black': '#000000',
                'lightText': '#FFFFFF',
                'darkText': '#000000',
                //
                //
                'danger.50': '#fff1f2',
                'danger.100': '#ffe4e6',
                'danger.200': '#fecdd3',
                'danger.300': '#fda4af',
                'danger.300.alpha0.1': '#fda4af1a',
                'danger.400': '#fb7185',
                'danger.400.alpha0.2': '#fb718533',
                'danger.500': '#f43f5e',
                'danger.500.alpha0.1': '#f43f5e1a',
                'danger.500.alpha0.2': '#f43f5e33',
                'danger.600': '#e11d48',
                'danger.600.alpha0.1': '#e11d481a',
                'danger.600.alpha0.2': '#e11d4833',
                'danger.600.alpha0.4': '#e11d4866',
                'danger.700': '#be123c',
                'danger.700.alpha0.4': '#be123c66',
                'danger.800': '#9f1239',
                'danger.900': '#881337',
                'error.50': '#fef2f2',
                'error.100': '#fee2e2',
                'error.200': '#fecaca',
                'error.300': '#fca5a5',
                'error.300.alpha0.1': '#fca5a51a',
                'error.400': '#f87171',
                'error.400.alpha0.2': '#f8717133',
                'error.500': '#ef4444',
                'error.500.alpha0.1': '#ef44441a',
                'error.500.alpha0.2': '#ef444433',
                'error.600': '#dc2626',
                'error.600.alpha0.1': '#dc26261a',
                'error.600.alpha0.2': '#dc262633',
                'error.600.alpha0.4': '#dc262666',
                'error.700': '#b91c1c',
                'error.700.alpha0.4': '#b91c1c66',
                'error.800': '#991b1b',
                'error.900': '#7f1d1d',
                'success.50': '#f0fdf4',
                'success.100': '#dcfce7',
                'success.200': '#bbf7d0',
                'success.300': '#86efac',
                'success.300.alpha0.1': '#86efac1a',
                'success.400': '#4ade80',
                'success.400.alpha0.2': '#4ade8033',
                'success.500': '#22c55e',
                'success.500.alpha0.1': '#22c55e1a',
                'success.500.alpha0.2': '#22c55e33',
                'success.600': '#16a34a',
                'success.600.alpha0.1': '#16a34a1a',
                'success.600.alpha0.2': '#16a34a33',
                'success.600.alpha0.4': '#16a34a66',
                'success.700': '#15803d',
                'success.700.alpha0.4': '#15803d66',
                'success.800': '#166534',
                'success.900': '#14532d',
                'warning.50': '#fff7ed',
                'warning.100': '#ffedd5',
                'warning.200': '#fed7aa',
                'warning.300': '#fdba74',
                'warning.300.alpha0.1': '#fdba741a',
                'warning.400': '#fb923c',
                'warning.400.alpha0.2': '#fb923c33',
                'warning.500': '#f97316',
                'warning.500.alpha0.1': '#f973161a',
                'warning.500.alpha0.2': '#f9731633',
                'warning.600': '#ea580c',
                'warning.600.alpha0.1': '#ea580c1a',
                'warning.600.alpha0.2': '#ea580c33',
                'warning.600.alpha0.4': '#ea580c66',
                'warning.700': '#c2410c',
                'warning.700.alpha0.4': '#c2410c66',
                'warning.800': '#9a3412',
                'warning.900': '#7c2d12',
                'muted.50': '#fafafa',
                'muted.100': '#f5f5f5',
                'muted.200': '#e5e5e5',
                'muted.300': '#d4d4d4',
                'muted.300.alpha0.1': '#d4d4d41a',
                'muted.400': '#a3a3a3',
                'muted.400.alpha0.2': '#a3a3a333',
                'muted.500': '#737373',
                'muted.500.alpha0.1': '#7373731a',
                'muted.500.alpha0.2': '#73737333',
                'muted.600': '#525252',
                'muted.600.alpha0.1': '#5252521a',
                'muted.600.alpha0.2': '#52525233',
                'muted.600.alpha0.4': '#52525266',
                'muted.700': '#404040',
                'muted.700.alpha0.4': '#40404066',
                'muted.800': '#262626',
                'muted.900': '#171717',
                'primary.50': '#ecfeff',
                'primary.100': '#cffafe',
                'primary.200': '#a5f3fc',
                'primary.300': '#67e8f9',
                'primary.300.alpha0.1': '#67e8f91a',
                'primary.400': '#22d3ee',
                'primary.400.alpha0.2': '#22d3ee33',
                'primary.500': '#06b6d4',
                'primary.500.alpha0.1': '#06b6d41a',
                'primary.500.alpha0.2': '#06b6d433',
                'primary.600': '#0891b2',
                'primary.600.alpha0.1': '#0891b21a',
                'primary.600.alpha0.2': '#0891b233',
                'primary.600.alpha0.4': '#0891b266',
                'primary.700': '#0e7490',
                'primary.700.alpha0.4': '#0e749066',
                'primary.800': '#155e75',
                'primary.900': '#164e63',
                'info.50': '#f0f9ff',
                'info.100': '#e0f2fe',
                'info.200': '#bae6fd',
                'info.300': '#7dd3fc',
                'info.300.alpha0.1': '#7dd3fc1a',
                'info.400': '#38bdf8',
                'info.400.alpha0.2': '#38bdf833',
                'info.500': '#0ea5e9',
                'info.500.alpha0.1': '#0ea5e91a',
                'info.500.alpha0.2': '#0ea5e933',
                'info.600': '#0284c7',
                'info.600.alpha0.1': '#0284c71a',
                'info.600.alpha0.2': '#0284c733',
                'info.600.alpha0.4': '#0284c766',
                'info.700': '#0369a1',
                'info.700.alpha0.4': '#0369a166',
                'info.800': '#075985',
                'info.900': '#0c4a6e',
                'secondary.50': '#fdf2f8',
                'secondary.100': '#fce7f3',
                'secondary.200': '#fbcfe8',
                'secondary.300': '#f9a8d4',
                'secondary.300.alpha0.1': '#f9a8d41a',
                'secondary.400': '#f472b6',
                'secondary.400.alpha0.2': '#f472b633',
                'secondary.500': '#ec4899',
                'secondary.500.alpha0.1': '#ec48991a',
                'secondary.500.alpha0.2': '#ec489933',
                'secondary.600': '#db2777',
                'secondary.600.alpha0.1': '#db27771a',
                'secondary.600.alpha0.2': '#db277733',
                'secondary.600.alpha0.4': '#db277766',
                'secondary.700': '#be185d',
                'secondary.700.alpha0.4': '#be185d66',
                'secondary.800': '#9d174d',
                'secondary.900': '#831843',
                'light.50': '#fafaf9',
                'light.100': '#f5f5f4',
                'light.200': '#e7e5e4',
                'light.300': '#d6d3d1',
                'light.300.alpha0.1': '#d6d3d11a',
                'light.400': '#a8a29e',
                'light.400.alpha0.2': '#a8a29e33',
                'light.500': '#78716c',
                'light.500.alpha0.1': '#78716c1a',
                'light.500.alpha0.2': '#78716c33',
                'light.600': '#57534e',
                'light.600.alpha0.1': '#57534e1a',
                'light.600.alpha0.2': '#57534e33',
                'light.600.alpha0.4': '#57534e66',
                'light.700': '#44403c',
                'light.700.alpha0.4': '#44403c66',
                'light.800': '#292524',
                'light.900': '#1c1917',
                'tertiary.50': '#ecfdf5',
                'tertiary.100': '#d1fae5',
                'tertiary.200': '#a7f3d0',
                'tertiary.300': '#6ee7b7',
                'tertiary.300.alpha0.1': '#6ee7b71a',
                'tertiary.400': '#34d399',
                'tertiary.400.alpha0.2': '#34d39933',
                'tertiary.500': '#10b981',
                'tertiary.500.alpha0.1': '#10b9811a',
                'tertiary.500.alpha0.2': '#10b98133',
                'tertiary.600': '#059669',
                'tertiary.600.alpha0.1': '#0596691a',
                'tertiary.600.alpha0.2': '#05966933',
                'tertiary.600.alpha0.4': '#05966966',
                'tertiary.700': '#047857',
                'tertiary.700.alpha0.4': '#04785766',
                'tertiary.800': '#065f46',
                'tertiary.900': '#064e3b',
                'textLight.0': '#FCFCFC',
                'textLight.50': '#F5F5F5',
                'textLight.100': '#E5E5E5',
                'textLight.200': '#DBDBDB',
                'textLight.300': '#D4D4D4',
                'textLight.400': '#A3A3A3',
                'textLight.500': '#8C8C8C',
                'textLight.600': '#737373',
                'textLight.600.alpha0.1': '#7373731a',
                'textLight.600.alpha0.2': '#73737333',
                'textLight.600.alpha0.4': '#73737366',
                'textLight.700': '#525252',
                'textLight.700.alpha0.4': '#52525266',
                'textLight.800': '#404040',
                'textLight.900': '#262626',
                'textLight.950': '#171717',
                'textDark.0': '#FCFCFC',
                'textDark.50': '#F5F5F5',
                'textDark.100': '#E5E5E5',
                'textDark.200': '#DBDBDB',
                'textDark.300': '#D4D4D4',
                'textDark.400': '#A3A3A3',
                'textDark.500': '#8C8C8C',
                'textDark.600': '#737373',
                'textDark.600.alpha0.1': '#7373731a',
                'textDark.600.alpha0.2': '#73737333',
                'textDark.600.alpha0.4': '#73737366',
                'textDark.700': '#525252',
                'textDark.700.alpha0.4': '#52525266',
                'textDark.800': '#404040',
                'textDark.900': '#262626',
                'textDark.950': '#171717',
                'borderDark.0': '#FCFCFC',
                'borderDark.50': '#F5F5F5',
                'borderDark.100': '#E5E5E5',
                'borderDark.200': '#DBDBDB',
                'borderDark.300': '#D4D4D4',
                'borderDark.400': '#A3A3A3',
                'borderDark.500': '#8C8C8C',
                'borderDark.600': '#737373',
                'borderDark.600.alpha0.1': '#7373731a',
                'borderDark.600.alpha0.2': '#73737333',
                'borderDark.600.alpha0.4': '#73737366',
                'borderDark.700': '#525252',
                'borderDark.700.alpha0.4': '#52525266',
                'borderDark.800': '#404040',
                'borderDark.900': '#262626',
                'borderDark.950': '#171717',
                'borderLight.0': '#FCFCFC',
                'borderLight.50': '#F5F5F5',
                'borderLight.100': '#E5E5E5',
                'borderLight.200': '#DBDBDB',
                'borderLight.300': '#D4D4D4',
                'borderLight.400': '#A3A3A3',
                'borderLight.500': '#8C8C8C',
                'borderLight.600': '#737373',
                'borderLight.600.alpha0.1': '#7373731a',
                'borderLight.600.alpha0.2': '#73737333',
                'borderLight.600.alpha0.4': '#73737366',
                'borderLight.700': '#525252',
                'borderLight.700.alpha0.4': '#52525266',
                'borderLight.800': '#404040',
                'borderLight.900': '#262626',
                'borderLight.950': '#171717',
                'backgroundDark.0': '#FCFCFC',
                'backgroundDark.50': '#F5F5F5',
                'backgroundDark.100': '#F1F1F1',
                'backgroundDark.200': '#DBDBDB',
                'backgroundDark.300': '#D4D4D4',
                'backgroundDark.400': '#A3A3A3',
                'backgroundDark.500': '#8C8C8C',
                'backgroundDark.600': '#737373',
                'backgroundDark.600.alpha0.1': '#7373731a',
                'backgroundDark.600.alpha0.2': '#73737333',
                'backgroundDark.600.alpha0.4': '#73737366',
                'backgroundDark.700': '#525252',
                'backgroundDark.700.alpha0.4': '#52525266',
                'backgroundDark.800': '#404040',
                'backgroundDark.900': '#262626',
                'backgroundDark.950': '#171717',
                'backgroundLight.0': '#FCFCFC',
                'backgroundLight.50': '#F5F5F5',
                'backgroundLight.100': '#F1F1F1',
                'backgroundLight.200': '#DBDBDB',
                'backgroundLight.300': '#D4D4D4',
                'backgroundLight.400': '#A3A3A3',
                'backgroundLight.500': '#8C8C8C',
                'backgroundLight.600': '#737373',
                'backgroundLight.600.alpha0.1': '#7373731a',
                'backgroundLight.600.alpha0.2': '#73737333',
                'backgroundLight.600.alpha0.4': '#73737366',
                'backgroundLight.700': '#525252',
                'backgroundLight.700.alpha0.4': '#52525266',
                'backgroundLight.800': '#404040',
                'backgroundLight.900': '#262626',
                'backgroundLight.950': '#171717',
                'backgroundLight.Error': '#FEF1F1',
                'backgroundDark.Error': '#422B2B',
                'backgroundLight.Warning': '#FFF4EB',
                'backgroundDark.Warning': '#412F23',
                'backgroundLight.Success': '#EDFCF2',
                'backgroundDark.Success': '#1C2B21',
                'backgroundLight.Info': '#EBF8FE',
                'backgroundDark.Info': '#1A282E',
                'backgroundLight.Muted': '#F6F6F7',
                'backgroundDark.Muted': '#252526',
            },
            space: {
                'px': '1px',
                '0': 0,
                '0.5': 2,
                '1': 4,
                '1.5': 6,
                '2': 8,
                '2.5': 10,
                '3': 12,
                '3.5': 14,
                '4': 16,
                '5': 20,
                '6': 24,
                '7': 28,
                '8': 32,
                '9': 36,
                '10': 40,
                '11': 44,
                '12': 48,
                '16': 64,
                '20': 80,
                '24': 96,
                '32': 128,
                '40': 160,
                '48': 192,
                '56': 224,
                '64': 256,
                '72': 288,
                '80': 320,
                '96': 384,
                '1/2': '50%',
                '1/3': '33.333%',
                '2/3': '66.666%',
                '1/4': '25%',
                '2/4': '50%',
                '3/4': '75%',
                '1/5': '20%',
                '2/5': '40%',
                '3/5': '60%',
                '4/5': '80%',
                '1/6': '16.666%',
                '2/6': '33.333%',
                '3/6': '50%',
                '4/6': '66.666%',
                '5/6': '83.333%',
                'full': '100%',
                '3xs': 0.25,
                '2xs': 0.5,
                'xs': 1,
                'sm': 2,
                'md': 3,
                'lg': 4,
                'xl': 6,
                '2xl': 7,
            },
            borderWidths: {
                '0': 0,
                '1': 1,
                '2': 2,
                '4': 4,
                '8': 8,
            },
            radii: {
                'none': 0,
                'xs': 2,
                'sm': 4,
                'md': 6,
                'lg': 8,
                'xl': 12,
                '2xl': 16,
                '3xl': 24,
                'full': 9999,
            },
            breakpoints: {
                'base': 0,
                'sm': 480,
                'md': 768,
                'lg': 992,
                'xl': 1280,
                '2xl': 1536,
            },
            mediaQueries: {
                'base': '@media screen and (min-width: 0)',
                'xs': '@media screen and (min-width: 400px)',
                'sm': '@media screen and (min-width: 480px)',
                'md': '@media screen and (min-width: 768px)',
                'lg': '@media screen and (min-width: 992px)',
                'xl': '@media screen and (min-width: 1280px)',
                '2xl': '@media screen and (min-width: 1536px)',
            },
            letterSpacings: {
                'xs': '-0.05em',
                'sm': '-0.025em',
                'md': 0,
                'lg': '0.025em',
                'xl': '0.05em',
                '2xl': '0.1em',
                // 'xs': -0.8,
                // 'sm': -0.4,
                // 'md': 0,
                // 'lg': 0.4,
                // 'xl': 0.8,
                // '2xl': 1.6,
            },
            lineHeights: {
                '2xs': '1em',
                'xs': '1.125em',
                'sm': '1.25em',
                'md': '1.375em',
                'lg': '1.5em',
                'xl': '1.75em',
                '2xl': '2em',
                '3xl': '2.5em',
                '4xl': '3em',
                '5xl': '4em',
                // '2xs': 16,
                // 'xs': 18,
                // 'sm': 20,
                // 'md': 22,
                // 'lg': 24,
                // 'xl': 28,
                // '2xl': 32,
                // '3xl': 40,
                // '4xl': 48,
                // '5xl': 64,
            },
            fontWeights: {
                hairline: '100',
                thin: '200',
                light: '300',
                normal: '400',
                medium: '500',
                semibold: '600',
                bold: '700',
                extrabold: '800',
                black: '900',
                extraBlack: '950',
            },
            fonts: {
                heading: undefined,
                body: undefined,
                mono: undefined,
            },
            fontSizes: {
                '2xs': 10,
                'xs': 12,
                'sm': 14,
                'md': 16,
                'lg': 18,
                'xl': 20,
                '2xl': 24,
                '3xl': 30,
                '4xl': 36,
                '5xl': 48,
                '6xl': 60,
                '7xl': 72,
                '8xl': 96,
                '9xl': 128,
            },
            opacity: {
                0: 0,
                5: 0.05,
                10: 0.1,
                20: 0.2,
                25: 0.25,
                30: 0.3,
                40: 0.4,
                50: 0.5,
                60: 0.6,
                70: 0.7,
                75: 0.75,
                80: 0.8,
                90: 0.9,
                95: 0.95,
                100: 1,
            },
        },
        globalStyle: {
            variants: {
                shadow: {
                    'none': {
                        shadowColor: 'transparent',
                        shadowOffset: {
                            width: 0,
                            height: 0,
                        },
                        shadowOpacity: 0,
                        shadowRadius: 0,
                        elevation: 0,
                    },
                    '0': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 1,
                        },
                        shadowOpacity: 0.18,
                        shadowRadius: 1.0,
                        elevation: 1,
                    },
                    '1': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 1,
                        },
                        shadowOpacity: 0.2,
                        shadowRadius: 1.41,
                        elevation: 2,
                    },
                    '2': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 1,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 2.22,
                        elevation: 3,
                    },
                    '3': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.23,
                        shadowRadius: 2.62,
                        elevation: 4,
                    },
                    '4': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                        elevation: 5,
                    },
                    '5': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 3,
                        },
                        shadowOpacity: 0.27,
                        shadowRadius: 4.65,
                        elevation: 6,
                    },
                    '6': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 3,
                        },
                        shadowOpacity: 0.29,
                        shadowRadius: 4.65,
                        elevation: 7,
                    },
                    '7': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 4,
                        },
                        shadowOpacity: 0.3,
                        shadowRadius: 4.65,
                        elevation: 8,
                    },
                    '8': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 4,
                        },
                        shadowOpacity: 0.32,
                        shadowRadius: 5.46,
                        elevation: 9,
                    },
                    '9': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 5,
                        },
                        shadowOpacity: 0.34,
                        shadowRadius: 6.27,
                        elevation: 10,
                    },
                },
                hardShadow: {
                    '1': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: -2,
                            height: 2,
                        },
                        shadowRadius: 8,
                        shadowOpacity: 0.5,
                        elevation: 10,
                    },
                    '2': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 3,
                        },
                        shadowRadius: 8,
                        shadowOpacity: 0.5,
                        elevation: 10,
                    },
                    '3': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 2,
                            height: 2,
                        },
                        shadowRadius: 8,
                        shadowOpacity: 0.5,
                        elevation: 10,
                    },
                    '4': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: -3,
                        },
                        shadowRadius: 8,
                        shadowOpacity: 0.5,
                        elevation: 10,
                    },
                    // this 5th version is only for toast shadow
                    // temporary
                    '5': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 3,
                        },
                        shadowRadius: 8,
                        shadowOpacity: 0.2,
                        elevation: 10,
                    },
                },
                softShadow: {
                    '1': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 0,
                        },
                        shadowRadius: 10,
                        shadowOpacity: 0.1,
                        _android: {
                            shadowColor: '$light.500',
                            elevation: 5,
                            shadowOpacity: 0.05,
                        },
                    },
                    '2': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 0,
                        },
                        shadowRadius: 20,
                        elevation: 3,
                        shadowOpacity: 0.1,
                        _android: {
                            shadowColor: '$light.500',
                            elevation: 10,
                            shadowOpacity: 0.1,
                        },
                    },
                    '3': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 0,
                        },
                        shadowRadius: 30,
                        shadowOpacity: 0.1,
                        elevation: 4,
                        _android: {
                            shadowColor: '$light.500',
                            elevation: 15,
                            shadowOpacity: 0.15,
                        },
                    },
                    '4': {
                        shadowColor: '$light.900',
                        shadowOffset: {
                            width: 0,
                            height: 0,
                        },
                        shadowRadius: 40,
                        shadowOpacity: 0.1,
                        elevation: 10,
                        _android: {
                            shadowColor: '$light.500',
                            elevation: 20,
                            shadowOpacity: 0.2,
                        },
                    },
                },
            },
        },
        plugins: [],
        propertyResolver: {
            backgroundColor: NBsupport_1.resolveAlphaColors,
            color: NBsupport_1.resolveAlphaColors,
            borderColor: NBsupport_1.resolveAlphaColors,
        },
        components: {},
    },
};
