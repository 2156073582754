var Subscription = /** @class */ (function () {
    function Subscription(unsubscribe) {
        this.closed = false;
        this._unsubscribe = unsubscribe;
    }
    Subscription.prototype.unsubscribe = function () {
        var _a;
        this.closed = true;
        (_a = this._unsubscribe) === null || _a === void 0 ? void 0 : _a.call(this);
    };
    return Subscription;
}());
export { Subscription };
