import { __awaiter, __generator } from "tslib";
import { useChatContext, useTranslationContext } from '../../../context';
export var MAX_MESSAGE_REACTIONS_TO_FETCH = 1000;
export function useReactionsFetcher(message, notifications) {
    var _this = this;
    if (notifications === void 0) { notifications = {}; }
    var client = useChatContext('useRectionsFetcher').client;
    var t = useTranslationContext('useReactionFetcher').t;
    var getErrorNotification = notifications.getErrorNotification, notify = notifications.notify;
    return function (reactionType, sort) { return __awaiter(_this, void 0, void 0, function () {
        var e_1, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetchMessageReactions(client, message.id, reactionType, sort)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    e_1 = _a.sent();
                    errorMessage = getErrorNotification === null || getErrorNotification === void 0 ? void 0 : getErrorNotification(message);
                    notify === null || notify === void 0 ? void 0 : notify(errorMessage || t('Error fetching reactions'), 'error');
                    throw e_1;
                case 3: return [2 /*return*/];
            }
        });
    }); };
}
function fetchMessageReactions(client, messageId, reactionType, sort) {
    return __awaiter(this, void 0, void 0, function () {
        var reactions, limit, next, hasNext, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    reactions = [];
                    limit = 25;
                    hasNext = true;
                    _a.label = 1;
                case 1:
                    if (!(hasNext && reactions.length < MAX_MESSAGE_REACTIONS_TO_FETCH)) return [3 /*break*/, 3];
                    return [4 /*yield*/, client.queryReactions(messageId, reactionType ? { type: reactionType } : {}, sort, { limit: limit, next: next })];
                case 2:
                    response = _a.sent();
                    reactions.push.apply(reactions, response.reactions);
                    next = response.next;
                    hasNext = Boolean(next);
                    return [3 /*break*/, 1];
                case 3: return [2 /*return*/, reactions];
            }
        });
    });
}
