"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTheme = void 0;
const react_1 = require("@gluestack-style/react");
const utils_1 = require("../utils");
const useTheme = () => {
    const { config } = (0, react_1.useStyled)();
    const theme = (0, utils_1.modifyConfig)(config);
    return theme;
};
exports.useTheme = useTheme;
