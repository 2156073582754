import React, { useEffect, useState } from 'react';
import { useTimeElapsed } from '../../MessageInput/hooks/useTimeElapsed';
import { useMessageInputContext } from '../../../context';
import { RecordingTimer } from './RecordingTimer';
var AudioRecordingWaveform = function (_a) {
    var _b = _a.maxDataPointsDrawn, maxDataPointsDrawn = _b === void 0 ? 100 : _b;
    var recorder = useMessageInputContext().recordingController.recorder;
    var _c = useState([]), amplitudes = _c[0], setAmplitudes = _c[1];
    useEffect(function () {
        if (!(recorder === null || recorder === void 0 ? void 0 : recorder.amplitudeRecorder))
            return;
        var amplitudesSubscription = recorder.amplitudeRecorder.amplitudes.subscribe(setAmplitudes);
        return function () {
            amplitudesSubscription.unsubscribe();
        };
    }, [recorder]);
    if (!recorder)
        return null;
    return (React.createElement("div", { className: 'str-chat__waveform-box-container' },
        React.createElement("div", { className: 'str-chat__audio_recorder__waveform-box' }, amplitudes.slice(-maxDataPointsDrawn).map(function (amplitude, i) { return (React.createElement("div", { className: 'str-chat__wave-progress-bar__amplitude-bar', key: "amplitude-".concat(i, "-voice-recording"), style: {
                '--str-chat__wave-progress-bar__amplitude-bar-height': amplitude
                    ? amplitude * 100 + '%'
                    : '0%',
            } })); }))));
};
export var AudioRecordingInProgress = function () {
    var _a = useTimeElapsed(), secondsElapsed = _a.secondsElapsed, startCounter = _a.startCounter, stopCounter = _a.stopCounter;
    var recorder = useMessageInputContext().recordingController.recorder;
    useEffect(function () {
        if (!(recorder === null || recorder === void 0 ? void 0 : recorder.mediaRecorder))
            return;
        var mediaRecorder = recorder.mediaRecorder;
        mediaRecorder.addEventListener('start', startCounter);
        mediaRecorder.addEventListener('resume', startCounter);
        mediaRecorder.addEventListener('stop', stopCounter);
        mediaRecorder.addEventListener('pause', stopCounter);
        return function () {
            mediaRecorder.removeEventListener('start', startCounter);
            mediaRecorder.removeEventListener('resume', startCounter);
            mediaRecorder.removeEventListener('stop', stopCounter);
            mediaRecorder.removeEventListener('pause', stopCounter);
        };
    }, [recorder, startCounter, stopCounter]);
    return (React.createElement(React.Fragment, null,
        React.createElement(RecordingTimer, { durationSeconds: secondsElapsed }),
        React.createElement(AudioRecordingWaveform, null)));
};
