"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
exports.default = (0, react_1.styled)(react_native_1.View, {
    padding: '$2',
    borderLeftWidth: 0,
    // @ts-ignore
    borderWidth: '$1',
    borderColor: '$muted.300',
    // @ts-ignore
    borderRadius: '$sm',
    alignItems: 'center',
    justifyContent: 'center',
    _dark: {
        borderColor: '$muted.700',
        backgroundColor: '$muted.800',
    },
}, {
    componentName: 'InputRightAddon',
    descendantStyle: ['_text'],
    ancestorStyle: ['_inputrightaddon'],
});
