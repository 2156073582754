"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
function createSkeleton(Root, AnimatedView) {
    return (0, react_1.forwardRef)((_a, ref) => {
        var { fadeDuration = 0.1, speed = 1, startColor, endColor, children, isLoaded = false } = _a, props = __rest(_a, ["fadeDuration", "speed", "startColor", "endColor", "children", "isLoaded"]);
        let startClr = {};
        let endClr = {};
        if (startColor)
            startClr = { bg: `$${startColor}` };
        if (endColor)
            endClr = { bg: `$${endColor}` };
        const blinkAnim = new react_native_1.Animated.Value(0);
        const animationDuration = (fadeDuration * 10000) / speed; // Convert seconds to milliseconds
        const blink = react_native_1.Animated.sequence([
            react_native_1.Animated.timing(blinkAnim, {
                toValue: 1,
                duration: animationDuration,
                useNativeDriver: react_native_1.Platform.OS !== 'web',
            }),
            react_native_1.Animated.timing(blinkAnim, {
                toValue: 0,
                duration: animationDuration,
                useNativeDriver: react_native_1.Platform.OS !== 'web',
            }),
        ]);
        if (!isLoaded) {
            const style = {
                opacity: blinkAnim, // Bind opacity to animated value
            };
            react_native_1.Animated.loop(blink).start();
            return (react_1.default.createElement(Root, Object.assign({}, endClr, props, { ref: ref }),
                react_1.default.createElement(AnimatedView, Object.assign({ style: style }, startClr))));
        }
        else {
            react_native_1.Animated.loop(blink).stop();
            return children;
        }
    });
}
exports.default = createSkeleton;
