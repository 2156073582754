"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
const plugins_1 = require("../../../plugins");
exports.default = (0, react_1.styled)(react_native_1.Text, {
    color: '$text.50',
    _dark: {
        color: '$text.50',
    },
    fontFamily: '$body',
    fontWeight: '$semibold',
    // @ts-ignore
    fontSize: '$xl',
    overflow: 'hidden',
    textTransform: 'uppercase',
    _web: {
        cursor: 'default',
    },
    fontStyle: 'normal',
    letterSpacing: '$md',
    lineHeight: '$lg',
}, {
    componentName: 'AvatarFallbackText',
    ancestorStyle: ['_text'],
}, {
    plugins: [new plugins_1.TextStyleResolver()],
});
