import { __assign } from "tslib";
import * as v1 from './FileIconSet/v1';
import * as v2 from './FileIconSet/v2';
import { archiveFileTypes, codeFileTypes, excelMimeTypes, powerpointMimeTypes, wordMimeTypes, } from './mimeTypes';
function generateMimeTypeToIconMap(_a) {
    var FileArchiveIcon = _a.FileArchiveIcon, FileCodeIcon = _a.FileCodeIcon, FileExcelIcon = _a.FileExcelIcon, FilePdfIcon = _a.FilePdfIcon, FilePowerPointIcon = _a.FilePowerPointIcon, FileWordIcon = _a.FileWordIcon;
    var mimeTypeToIconMap = {
        'application/pdf': FilePdfIcon,
    };
    for (var _i = 0, wordMimeTypes_1 = wordMimeTypes; _i < wordMimeTypes_1.length; _i++) {
        var type = wordMimeTypes_1[_i];
        mimeTypeToIconMap[type] = FileWordIcon;
    }
    for (var _b = 0, excelMimeTypes_1 = excelMimeTypes; _b < excelMimeTypes_1.length; _b++) {
        var type = excelMimeTypes_1[_b];
        mimeTypeToIconMap[type] = FileExcelIcon;
    }
    for (var _c = 0, powerpointMimeTypes_1 = powerpointMimeTypes; _c < powerpointMimeTypes_1.length; _c++) {
        var type = powerpointMimeTypes_1[_c];
        mimeTypeToIconMap[type] = FilePowerPointIcon;
    }
    for (var _d = 0, archiveFileTypes_1 = archiveFileTypes; _d < archiveFileTypes_1.length; _d++) {
        var type = archiveFileTypes_1[_d];
        mimeTypeToIconMap[type] = FileArchiveIcon;
    }
    for (var _e = 0, codeFileTypes_1 = codeFileTypes; _e < codeFileTypes_1.length; _e++) {
        var type = codeFileTypes_1[_e];
        mimeTypeToIconMap[type] = FileCodeIcon;
    }
    return mimeTypeToIconMap;
}
function generateGeneralTypeToIconMap(_a) {
    var FileAltIcon = _a.FileAltIcon, FileAudioIcon = _a.FileAudioIcon, FileImageIcon = _a.FileImageIcon, FileVideoIcon = _a.FileVideoIcon;
    return {
        'audio/': FileAudioIcon,
        'image/': FileImageIcon,
        'text/': FileAltIcon,
        'video/': FileVideoIcon,
    };
}
export var iconMap = {
    '1': {
        alt: {},
        standard: __assign(__assign(__assign({}, generateMimeTypeToIconMap({
            FileArchiveIcon: v1.FileArchiveIcon,
            FileCodeIcon: v1.FileCodeIcon,
            FileExcelIcon: v1.FileExcelIcon,
            FilePdfIcon: v1.FilePdfIcon,
            FilePowerPointIcon: v1.FilePowerPointIcon,
            FileWordIcon: v1.FileWordIcon,
        })), generateGeneralTypeToIconMap({
            FileAltIcon: v1.FileAltIcon,
            FileAudioIcon: v1.FileAudioIcon,
            FileImageIcon: v1.FileImageIcon,
            FileVideoIcon: v1.FileVideoIcon,
        })), { fallback: v1.FileFallbackIcon }),
    },
    '2': {
        alt: __assign(__assign(__assign({}, generateMimeTypeToIconMap({
            FileArchiveIcon: v2.FileArchiveIconAlt,
            FileCodeIcon: v2.FileCodeIconAlt,
            FileExcelIcon: v2.FileExcelIconAlt,
            FilePdfIcon: v2.FilePdfIcon,
            FilePowerPointIcon: v2.FilePowerPointIconAlt,
            FileWordIcon: v2.FileWordIconAlt,
        })), generateGeneralTypeToIconMap({
            FileAltIcon: v2.FileFallbackIcon,
            FileAudioIcon: v2.FileAudioIconAlt,
            FileImageIcon: v2.FileImageIcon,
            FileVideoIcon: v2.FileVideoIconAlt,
        })), { fallback: v2.FileFallbackIcon }),
        standard: __assign(__assign(__assign({}, generateMimeTypeToIconMap({
            FileArchiveIcon: v2.FileArchiveIcon,
            FileCodeIcon: v2.FileCodeIcon,
            FileExcelIcon: v2.FileExcelIcon,
            FilePdfIcon: v2.FilePdfIcon,
            FilePowerPointIcon: v2.FilePowerPointIcon,
            FileWordIcon: v2.FileWordIcon,
        })), generateGeneralTypeToIconMap({
            FileAltIcon: v2.FileFallbackIcon,
            FileAudioIcon: v2.FileAudioIcon,
            FileImageIcon: v2.FileImageIcon,
            FileVideoIcon: v2.FileVideoIcon,
        })), { fallback: v2.FileFallbackIcon }),
    },
};
