"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Menu = exports.AccessibleMenu = void 0;
const menu_1 = require("@gluestack-ui/menu");
const styled_components_1 = require("./styled-components");
const react_1 = __importStar(require("react"));
const usePropResolution_1 = require("../../hooks/usePropResolution");
const animation_resolver_1 = require("@gluestack-style/animation-resolver");
const Box_1 = require("../Box");
// import { View, Pressable } from 'react-native';
exports.AccessibleMenu = (0, menu_1.createMenu)({
    Root: styled_components_1.Root,
    Item: styled_components_1.Item,
    Label: styled_components_1.Label,
    Backdrop: styled_components_1.Backdrop,
    //@ts-ignore
    AnimatePresence: animation_resolver_1.AnimatePresence,
});
const NewMenu = (0, react_1.forwardRef)((_a, ref) => {
    var { children, 
    // isLoading,
    // isDisabled,
    // isLoadingText,
    colorScheme = 'primary', variant = 'solid', 
    // onClose,
    trigger } = _a, props = __rest(_a, ["children", "colorScheme", "variant", "trigger"]);
    const resolvedPropForGluestack = (0, usePropResolution_1.usePropResolution)(props);
    const MenuItemChildren = react_1.Children.map(children, (child, index) => {
        return react_1.default.createElement(exports.AccessibleMenu.Item, { key: index }, child);
    });
    return (react_1.default.createElement(exports.AccessibleMenu, Object.assign({ colorScheme: colorScheme, variant: variant, trigger: trigger }, resolvedPropForGluestack, { ref: ref }), MenuItemChildren));
});
const AccessibleMenuItem = (0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(exports.AccessibleMenu.ItemLabel, Object.assign({}, props, { ref: ref }), children));
});
const AccessibleMenuGroup = (0, react_1.forwardRef)((_a, ref) => {
    var props = __rest(_a, []);
    return react_1.default.createElement(Box_1.Box, Object.assign({}, props, { ref: ref }));
});
const AccessibleMenuOptionGroup = (0, react_1.forwardRef)((_a, ref) => {
    var props = __rest(_a, []);
    return react_1.default.createElement(Box_1.Box, Object.assign({}, props, { ref: ref }));
});
const AccessibleMenuItemOption = (0, react_1.forwardRef)((_a, ref) => {
    var props = __rest(_a, []);
    return react_1.default.createElement(Box_1.Box, Object.assign({}, props, { ref: ref }));
});
const MenuNew = NewMenu;
MenuNew.Item = AccessibleMenuItem;
MenuNew.Group = AccessibleMenuGroup;
MenuNew.OptionGroup = AccessibleMenuOptionGroup;
MenuNew.ItemOption = AccessibleMenuItemOption;
exports.Menu = MenuNew;
// export const MenuItem = Menu.Item;
// export const MenuItemLabel = Menu.ItemLabel;
