"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const plugins_1 = require("../../../plugins");
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.Text, {
    color: '$text.900',
    _dark: {
        color: '$text.50',
    },
    fontFamily: '$body',
    fontStyle: 'normal',
    letterSpacing: '$md',
    fontSize: '$xs',
    fontWeight: '$medium',
    lineHeight: '$lg',
}, {
    componentName: 'BadgeText',
    ancestorStyle: ['_text'],
}, {
    plugins: [new plugins_1.TextStyleResolver()],
});
