"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
exports.default = (0, react_1.styled)(react_native_1.View, {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    mt: '$1',
    gap: '$1',
    _icon: {
        textAlign: 'center',
        justifyContent: 'center',
        color: '$error.600',
        _dark: {
            color: '$error.500',
        },
        height: '$4',
        width: '$4',
    },
}, {
    componentName: 'FormControlError',
    descendantStyle: ['_icon'],
});
