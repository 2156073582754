import { __awaiter, __generator } from "tslib";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslationContext } from '../../../context';
import { MediaRecorderController } from '../classes';
export var useMediaRecorder = function (_a) {
    var asyncMessagesMultiSendEnabled = _a.asyncMessagesMultiSendEnabled, enabled = _a.enabled, generateRecordingTitle = _a.generateRecordingTitle, handleSubmit = _a.handleSubmit, recordingConfig = _a.recordingConfig, uploadAttachment = _a.uploadAttachment;
    var t = useTranslationContext('useMediaRecorder').t;
    var _b = useState(), recording = _b[0], setRecording = _b[1];
    var _c = useState(), recordingState = _c[0], setRecordingState = _c[1];
    var _d = useState(), permissionState = _d[0], setPermissionState = _d[1];
    var _e = useState(false), isScheduledForSubmit = _e[0], scheduleForSubmit = _e[1];
    var recorder = useMemo(function () {
        return enabled
            ? new MediaRecorderController({
                config: recordingConfig !== null && recordingConfig !== void 0 ? recordingConfig : {},
                generateRecordingTitle: generateRecordingTitle,
                t: t,
            })
            : undefined;
    }, [recordingConfig, enabled, generateRecordingTitle, t]);
    var completeRecording = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var recording;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!recorder)
                        return [2 /*return*/];
                    return [4 /*yield*/, recorder.stop()];
                case 1:
                    recording = _a.sent();
                    if (!recording)
                        return [2 /*return*/];
                    return [4 /*yield*/, uploadAttachment(recording)];
                case 2:
                    _a.sent();
                    if (!asyncMessagesMultiSendEnabled) {
                        // FIXME: cannot call handleSubmit() directly as the function has stale reference to attachments
                        scheduleForSubmit(true);
                    }
                    recorder.cleanUp();
                    return [2 /*return*/];
            }
        });
    }); }, [asyncMessagesMultiSendEnabled, recorder, uploadAttachment]);
    useEffect(function () {
        if (!isScheduledForSubmit)
            return;
        handleSubmit();
        scheduleForSubmit(false);
    }, [handleSubmit, isScheduledForSubmit]);
    useEffect(function () {
        if (!recorder)
            return;
        recorder.permission.watch();
        var recordingSubscription = recorder.recording.subscribe(setRecording);
        var recordingStateSubscription = recorder.recordingState.subscribe(setRecordingState);
        var permissionStateSubscription = recorder.permission.state.subscribe(setPermissionState);
        return function () {
            recorder.cancel();
            recorder.permission.unwatch();
            recordingSubscription.unsubscribe();
            recordingStateSubscription.unsubscribe();
            permissionStateSubscription.unsubscribe();
        };
    }, [recorder]);
    return {
        completeRecording: completeRecording,
        permissionState: permissionState,
        recorder: recorder,
        recording: recording,
        recordingState: recordingState,
    };
};
