import { __assign, __rest } from "tslib";
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
export var Tooltip = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (React.createElement("div", __assign({ className: 'str-chat__tooltip' }, rest), children));
};
export var PopperTooltip = function (_a) {
    var children = _a.children, _b = _a.offset, offset = _b === void 0 ? [0, 10] : _b, referenceElement = _a.referenceElement, _c = _a.placement, placement = _c === void 0 ? 'top' : _c, _d = _a.visible, visible = _d === void 0 ? false : _d;
    var _e = useState(null), popperElement = _e[0], setPopperElement = _e[1];
    var _f = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: offset,
                },
            },
        ],
        placement: placement,
    }), attributes = _f.attributes, styles = _f.styles;
    if (!visible)
        return null;
    return (React.createElement("div", __assign({ className: 'str-chat__tooltip', ref: setPopperElement, style: styles.popper }, attributes.popper), children));
};
