import React from 'react';
import clsx from 'clsx';
import { useChannelStateContext } from '../../context/ChannelStateContext';
var UnMemoizedWindow = function (props) {
    var children = props.children, _a = props.hideOnThread, hideOnThread = _a === void 0 ? false : _a, propThread = props.thread;
    var contextThread = useChannelStateContext('Window').thread;
    return (React.createElement("div", { className: clsx('str-chat__main-panel', {
            'str-chat__main-panel--hideOnThread': hideOnThread && (contextThread || propThread),
        }) }, children));
};
/**
 * A UI component for conditionally displaying a Thread or Channel
 */
export var Window = React.memo(UnMemoizedWindow);
