"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddIcon = void 0;
const styled_components_1 = require("../styled-components");
const AddIcon = (0, styled_components_1.createIcon)({
    viewBox: '0 0 24 24',
    d: 'M13.25 10.75V2H10.75V10.75H2V13.25H10.75V22H13.25V13.25H22V10.75H13.25Z',
});
exports.AddIcon = AddIcon;
AddIcon.displayName = 'AddIcon';
