import { __assign, __awaiter, __generator } from "tslib";
import { useCallback, useEffect } from 'react';
import { checkUploadPermissions } from './utils';
import { useChannelActionContext } from '../../../context/ChannelActionContext';
import { useChannelStateContext } from '../../../context/ChannelStateContext';
import { useChatContext } from '../../../context/ChatContext';
import { useTranslationContext } from '../../../context/TranslationContext';
export var useImageUploads = function (props, state, dispatch) {
    var doImageUploadRequest = props.doImageUploadRequest, errorHandler = props.errorHandler;
    var imageUploads = state.imageUploads;
    var channel = useChannelStateContext('useImageUploads').channel;
    var getAppSettings = useChatContext('useImageUploads').getAppSettings;
    var addNotification = useChannelActionContext('useImageUploads').addNotification;
    var t = useTranslationContext('useImageUploads').t;
    var removeImage = useCallback(function (id) {
        dispatch({ id: id, type: 'removeImageUpload' });
        // TODO: cancel upload if still uploading
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var uploadImage = useCallback(function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var img, file, canUpload, response, error_1, errorMessage, alreadyRemoved;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    img = imageUploads[id];
                    if (!img)
                        return [2 /*return*/];
                    file = img.file;
                    if (img.state !== 'uploading') {
                        dispatch({ id: id, state: 'uploading', type: 'setImageUpload' });
                    }
                    return [4 /*yield*/, checkUploadPermissions({
                            addNotification: addNotification,
                            file: file,
                            getAppSettings: getAppSettings,
                            t: t,
                            uploadType: 'image',
                        })];
                case 1:
                    canUpload = _b.sent();
                    if (!canUpload)
                        return [2 /*return*/, removeImage(id)];
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 7, , 8]);
                    if (!doImageUploadRequest) return [3 /*break*/, 4];
                    return [4 /*yield*/, doImageUploadRequest(file, channel)];
                case 3:
                    response = _b.sent();
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, channel.sendImage(file)];
                case 5:
                    response = _b.sent();
                    _b.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    error_1 = _b.sent();
                    errorMessage = typeof error_1.message === 'string'
                        ? error_1.message
                        : t('Error uploading image');
                    addNotification(errorMessage, 'error');
                    alreadyRemoved = false;
                    if (!imageUploads[id]) {
                        alreadyRemoved = true;
                    }
                    else {
                        dispatch({ id: id, state: 'failed', type: 'setImageUpload' });
                    }
                    if (!alreadyRemoved && errorHandler) {
                        // TODO: verify if the parameters passed to the error handler actually make sense
                        errorHandler(error_1, 'upload-image', __assign(__assign({}, file), { id: id }));
                    }
                    return [2 /*return*/];
                case 8:
                    // If doImageUploadRequest returns any falsy value, then don't create the upload preview.
                    // This is for the case if someone wants to handle failure on app level.
                    if (!response) {
                        removeImage(id);
                        return [2 /*return*/];
                    }
                    if (img.previewUri)
                        (_a = URL.revokeObjectURL) === null || _a === void 0 ? void 0 : _a.call(URL, img.previewUri);
                    dispatch({
                        id: id,
                        previewUri: undefined,
                        state: 'finished',
                        type: 'setImageUpload',
                        url: response.file,
                    });
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageUploads, channel, doImageUploadRequest, errorHandler, removeImage]);
    useEffect(function () {
        var upload = Object.values(imageUploads).find(function (imageUpload) { return imageUpload.state === 'uploading' && imageUpload.file; });
        if (!upload)
            return;
        uploadImage(upload.id);
    }, [imageUploads, uploadImage]);
    return {
        removeImage: removeImage,
        uploadImage: uploadImage,
    };
};
