import { __awaiter, __generator } from "tslib";
import { useEffect, useState } from 'react';
import { useMessageContext } from '../../../context';
export function useFetchReactions(options) {
    var _this = this;
    var contextHandleFetchReactions = useMessageContext('useFetchReactions').handleFetchReactions;
    var _a = useState([]), reactions = _a[0], setReactions = _a[1];
    var propHandleFetchReactions = options.handleFetchReactions, reactionType = options.reactionType, shouldFetch = options.shouldFetch, sort = options.sort;
    var _b = useState(shouldFetch), isLoading = _b[0], setIsLoading = _b[1];
    var handleFetchReactions = propHandleFetchReactions !== null && propHandleFetchReactions !== void 0 ? propHandleFetchReactions : contextHandleFetchReactions;
    useEffect(function () {
        if (!shouldFetch) {
            return;
        }
        var cancel = false;
        (function () { return __awaiter(_this, void 0, void 0, function () {
            var reactions_1, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        setIsLoading(true);
                        return [4 /*yield*/, handleFetchReactions(reactionType, sort)];
                    case 1:
                        reactions_1 = _a.sent();
                        if (!cancel) {
                            setReactions(reactions_1);
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        if (!cancel) {
                            setReactions([]);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        if (!cancel) {
                            setIsLoading(false);
                        }
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); })();
        return function () {
            cancel = true;
        };
    }, [handleFetchReactions, reactionType, shouldFetch, sort]);
    return { isLoading: isLoading, reactions: reactions };
}
