"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColorModeValue = void 0;
const react_1 = require("react");
const Provider_1 = require("../components/Provider");
function useColorModeValue(prop1, prop2) {
    const { colorMode } = (0, react_1.useContext)(Provider_1.HooksContext);
    if (colorMode === 'light')
        return prop1;
    return prop2;
}
exports.useColorModeValue = useColorModeValue;
