import { __assign } from "tslib";
import React, { useState } from 'react';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { BaseImage as DefaultBaseImage } from './BaseImage';
import { Modal } from '../Modal';
import { ModalGallery as DefaultModalGallery } from './ModalGallery';
import { useComponentContext } from '../../context';
/**
 * A simple component that displays an image.
 */
export var ImageComponent = function (props) {
    var _a = props.dimensions, dimensions = _a === void 0 ? {} : _a, fallback = props.fallback, image_url = props.image_url, thumb_url = props.thumb_url, innerRef = props.innerRef, previewUrl = props.previewUrl, style = props.style;
    var _b = useState(false), modalIsOpen = _b[0], setModalIsOpen = _b[1];
    var _c = useComponentContext('ImageComponent'), _d = _c.BaseImage, BaseImage = _d === void 0 ? DefaultBaseImage : _d, _e = _c.ModalGallery, ModalGallery = _e === void 0 ? DefaultModalGallery : _e;
    var imageSrc = sanitizeUrl(previewUrl || image_url || thumb_url);
    var toggleModal = function () { return setModalIsOpen(function (modalIsOpen) { return !modalIsOpen; }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(BaseImage, __assign({ alt: fallback, className: 'str-chat__message-attachment--img', "data-testid": 'image-test', onClick: toggleModal, src: imageSrc, style: style, tabIndex: 0, title: fallback }, dimensions, (innerRef && { ref: innerRef }))),
        React.createElement(Modal, { className: 'str-chat__image-modal', onClose: toggleModal, open: modalIsOpen },
            React.createElement(ModalGallery, { images: [props], index: 0 }))));
};
