import { __assign, __rest } from "tslib";
import React from 'react';
import { Modal } from '../Modal';
import { MessageBounceProvider } from '../../context';
export function MessageBounceModal(_a) {
    var MessageBouncePrompt = _a.MessageBouncePrompt, modalProps = __rest(_a, ["MessageBouncePrompt"]);
    return (React.createElement(Modal, __assign({ className: 'str-chat__message-bounce-modal' }, modalProps),
        React.createElement(MessageBounceProvider, null,
            React.createElement(MessageBouncePrompt, { onClose: modalProps.onClose }))));
}
