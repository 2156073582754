"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const html_elements_1 = require("@expo/html-elements");
const plugins_1 = require("../../../plugins");
exports.default = (0, react_1.styled)(html_elements_1.H3, {
    color: '$text.900',
    _dark: {
        color: '$text.50',
    },
    fontWeight: '$bold',
    fontFamily: '$heading',
    lineHeight: '$sm',
    // Overrides expo-html default styling
    marginVertical: 0,
    variants: {
        isTruncated: {
            true: {
                props: {
                    numberOfLines: 1,
                    ellipsizeMode: 'tail',
                },
            },
        },
        bold: {
            true: {
                fontWeight: '$bold',
            },
        },
        underline: {
            true: {
                textDecorationLine: 'underline',
            },
        },
        strikeThrough: {
            true: {
                textDecorationLine: 'line-through',
            },
        },
        sub: {
            true: {
                fontSize: '$xs',
                lineHeight: '$xs',
            },
        },
        italic: {
            true: {
                fontStyle: 'italic',
            },
        },
        highlight: {
            true: {
                backgroundColor: '$yellow.500',
            },
        },
        size: {
            '4xl': {
                //@ts-ignore
                'props': { as: html_elements_1.H1 },
                '@base': {
                    fontSize: '$6xl',
                },
                '@md': {
                    fontSize: '$7xl',
                },
                'letterSpacing': '$xl',
            },
            '3xl': {
                //@ts-ignore
                'props': { as: html_elements_1.H1 },
                '@base': {
                    fontSize: '$5xl',
                },
                '@md': {
                    fontSize: '$6xl',
                },
                'letterSpacing': '$xl',
            },
            '2xl': {
                //@ts-ignore
                'props': { as: html_elements_1.H2 },
                '@base': {
                    fontSize: '$4xl',
                },
                '@md': {
                    fontSize: '$5xl',
                },
            },
            'xl': {
                'props': { as: html_elements_1.H3 },
                '@base': {
                    fontSize: '$3xl',
                },
                '@md': {
                    fontSize: '$4xl',
                },
            },
            'lg': {
                'props': { as: html_elements_1.H4 },
                '@base': {
                    fontSize: '$2xl',
                },
                '@md': {
                    fontSize: '$3xl',
                },
            },
            'md': {
                //@ts-ignore
                props: { as: html_elements_1.H5 },
                fontSize: '$xl',
            },
            'sm': {
                //@ts-ignore
                props: { as: html_elements_1.H6 },
                fontSize: '$md',
            },
            'xs': {
                //@ts-ignore
                props: { as: html_elements_1.H6 },
                fontSize: '$sm',
            },
        },
    },
    defaultProps: {
        size: 'lg',
    },
}, {
    componentName: 'Heading',
    descendantStyle: ['_text'],
}, {
    plugins: [new plugins_1.TextChildStyleResolver()],
});
