import { __assign } from "tslib";
import React from 'react';
import { Message } from '../Message';
import { ThreadStart as DefaultThreadStart } from './ThreadStart';
import { useComponentContext } from '../../context';
export var ThreadHead = function (props) {
    var _a = useComponentContext('ThreadHead').ThreadStart, ThreadStart = _a === void 0 ? DefaultThreadStart : _a;
    return (React.createElement("div", { className: 'str-chat__parent-message-li' },
        React.createElement(Message, __assign({ initialMessage: true, threadList: true }, props)),
        React.createElement(ThreadStart, null)));
};
