"use strict";
// @ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.extendTheme = exports.convertTheme = exports.flattenTokens = void 0;
const index_1 = require("./index");
const gluestack_ui_config_1 = require("../components/gluestack-ui.config");
const flattenTokens = (token) => {
    const flattenToken = {};
    Object.keys(token).forEach((key) => {
        const tokenObj = token[key];
        if (typeof tokenObj === 'object') {
            Object.keys(tokenObj).forEach((tokenKey) => {
                //@ts-ignore
                flattenToken[`${key}.${tokenKey}`] = tokenObj[tokenKey];
            });
        }
    });
    return flattenToken;
};
exports.flattenTokens = flattenTokens;
const convertTheme = (theme = {}) => {
    const gluestackTheme = {
        tokens: {},
        aliases: {},
        components: {},
    };
    Object.keys(theme !== null && theme !== void 0 ? theme : {}).forEach((key) => {
        if (key === 'components') {
            gluestackTheme.components = theme[key];
        }
        else if (key === 'config') {
        }
        else {
            gluestackTheme.tokens[key] = (0, exports.flattenTokens)(theme[key]);
        }
    });
    return gluestackTheme;
};
exports.convertTheme = convertTheme;
function convertNBColorsToGluestackColors(colors) {
    const gluestackColors = {};
    Object.keys(colors).map((key) => {
        if (typeof colors[key] === 'object') {
            Object.keys(colors[key]).map((token) => {
                gluestackColors[`${key}.${token}`] = colors[key][token];
            });
        }
    });
    return gluestackColors;
}
function extendTheme(tempTheme) {
    const theme = tempTheme;
    if (Object.keys(theme).length === 0) {
        return;
    }
    const finalTheme = {};
    const gluestackStyles = theme;
    const clonedConfig = JSON.parse(JSON.stringify(gluestack_ui_config_1.config));
    if (theme.components) {
        const componentTheme = theme.components;
        delete gluestackStyles.components;
        const componentsTheme = {};
        Object.keys(componentTheme).map((component) => {
            componentsTheme[component] = { theme: {} };
            componentsTheme[component].theme = Object.assign({}, (0, index_1.transformTheme)(componentTheme[component], clonedConfig));
        });
        finalTheme.components = componentsTheme;
    }
    if (theme.config) {
        finalTheme.config = theme.config;
        delete gluestackStyles.config;
    }
    let updatedColor = {};
    if (gluestackStyles.colors) {
        Object.keys(gluestackStyles.colors).map((color) => {
            // @ts-ignore
            if (typeof gluestackStyles.colors[color] === 'object') {
                updatedColor = Object.assign(Object.assign({}, updatedColor), convertNBColorsToGluestackColors(gluestackStyles.colors));
            }
            else {
                updatedColor = Object.assign(Object.assign({}, updatedColor), { 
                    // @ts-ignore
                    [color]: gluestackStyles.colors[color] });
            }
        });
    }
    gluestackStyles.colors = updatedColor;
    const mergedTheme = (0, index_1.deepMerge)((0, index_1.deepMerge)(clonedConfig.theme, (0, exports.convertTheme)(finalTheme)), { tokens: gluestackStyles });
    mergedTheme.config = finalTheme === null || finalTheme === void 0 ? void 0 : finalTheme.config;
    return mergedTheme;
}
exports.extendTheme = extendTheme;
