import { __assign } from "tslib";
import throttle from 'lodash.throttle';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, } from 'react';
import clsx from 'clsx';
import { resampleWaveformData } from '../audioSampling';
export var WaveProgressBar = function (_a) {
    var _b = _a.amplitudesCount, amplitudesCount = _b === void 0 ? 40 : _b, _c = _a.progress, progress = _c === void 0 ? 0 : _c, _d = _a.relativeAmplitudeBarWidth, relativeAmplitudeBarWidth = _d === void 0 ? 2 : _d, _e = _a.relativeAmplitudeGap, relativeAmplitudeGap = _e === void 0 ? 1 : _e, seek = _a.seek, waveformData = _a.waveformData;
    var _f = useState(null), progressIndicator = _f[0], setProgressIndicator = _f[1];
    var isDragging = useRef(false);
    var _g = useState(null), root = _g[0], setRoot = _g[1];
    var _h = useState(), trackAxisX = _h[0], setTrackAxisX = _h[1];
    var lastRootWidth = useRef();
    var handleDragStart = function (e) {
        e.preventDefault();
        if (!progressIndicator)
            return;
        isDragging.current = true;
        progressIndicator.style.cursor = 'grabbing';
    };
    var handleDrag = function (e) {
        if (!isDragging.current)
            return;
        // Due to throttling of seek, it is necessary to create a copy (snapshot) of the event.
        // Otherwise, the event would be nullified at the point when the throttled function is executed.
        seek(__assign({}, e));
    };
    var handleDragStop = useCallback(function () {
        if (!progressIndicator)
            return;
        isDragging.current = false;
        progressIndicator.style.removeProperty('cursor');
    }, [progressIndicator]);
    var getTrackAxisX = useMemo(function () {
        return throttle(function (rootWidth) {
            if (rootWidth === lastRootWidth.current)
                return;
            lastRootWidth.current = rootWidth;
            var possibleAmpCount = Math.floor(rootWidth / (relativeAmplitudeGap + relativeAmplitudeBarWidth));
            var tooManyAmplitudesToRender = possibleAmpCount < amplitudesCount;
            var barCount = tooManyAmplitudesToRender ? possibleAmpCount : amplitudesCount;
            var amplitudeBarWidthToGapRatio = relativeAmplitudeBarWidth / (relativeAmplitudeBarWidth + relativeAmplitudeGap);
            var barWidth = barCount && (rootWidth / barCount) * amplitudeBarWidthToGapRatio;
            setTrackAxisX({
                barCount: barCount,
                barWidth: barWidth,
                gap: barWidth * (relativeAmplitudeGap / relativeAmplitudeBarWidth),
            });
        }, 1);
    }, [relativeAmplitudeBarWidth, relativeAmplitudeGap, amplitudesCount]);
    var resampledWaveformData = useMemo(function () { return (trackAxisX ? resampleWaveformData(waveformData, trackAxisX.barCount) : []); }, [trackAxisX, waveformData]);
    useEffect(function () {
        document.addEventListener('pointerup', handleDragStop);
        return function () {
            document.removeEventListener('pointerup', handleDragStop);
        };
    }, [handleDragStop]);
    useEffect(function () {
        if (!root || typeof ResizeObserver === 'undefined')
            return;
        var observer = new ResizeObserver(function (_a) {
            var entry = _a[0];
            getTrackAxisX(entry.contentRect.width);
        });
        observer.observe(root);
        return function () {
            observer.disconnect();
        };
    }, [getTrackAxisX, root]);
    useLayoutEffect(function () {
        if (!root)
            return;
        var rootWidth = root.getBoundingClientRect().width;
        getTrackAxisX(rootWidth);
    }, [getTrackAxisX, root]);
    if (!waveformData.length || (trackAxisX === null || trackAxisX === void 0 ? void 0 : trackAxisX.barCount) === 0)
        return null;
    return (React.createElement("div", { className: 'str-chat__wave-progress-bar__track', "data-testid": 'wave-progress-bar-track', onClick: seek, onPointerDown: handleDragStart, onPointerMove: handleDrag, onPointerUp: handleDragStop, ref: setRoot, role: 'progressbar', style: {
            '--str-chat__voice-recording-amplitude-bar-gap-width': (trackAxisX === null || trackAxisX === void 0 ? void 0 : trackAxisX.gap) + 'px',
        } },
        resampledWaveformData.map(function (amplitude, i) {
            var _a;
            return (React.createElement("div", { className: clsx('str-chat__wave-progress-bar__amplitude-bar', (_a = {},
                    _a['str-chat__wave-progress-bar__amplitude-bar--active'] = progress > (i / resampledWaveformData.length) * 100,
                    _a)), "data-testid": 'amplitude-bar', key: "amplitude-".concat(i), style: {
                    '--str-chat__voice-recording-amplitude-bar-width': (trackAxisX === null || trackAxisX === void 0 ? void 0 : trackAxisX.barWidth) + 'px',
                    '--str-chat__wave-progress-bar__amplitude-bar-height': amplitude
                        ? amplitude * 100 + '%'
                        : '0%',
                } }));
        }),
        React.createElement("div", { className: 'str-chat__wave-progress-bar__progress-indicator', "data-testid": 'wave-progress-bar-progress-indicator', ref: setProgressIndicator, style: { left: "".concat(progress < 0 ? 0 : progress > 100 ? 100 : progress, "%") } })));
};
