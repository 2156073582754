"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.View, {
    width: '$5',
    height: '$5',
    backgroundColor: '$success.600',
    // @ts-ignore
    borderRadius: '$full',
    position: 'absolute',
    right: 0,
    bottom: 0,
    borderColor: '$muted.50',
    _dark: {
        borderColor: '$muted.900',
    },
    // @ts-ignore
    borderWidth: 2,
    zIndex: 900,
}, {
    componentName: 'AvatarBadge',
    ancestorStyle: ['_badge'],
});
