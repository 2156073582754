"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.View, {
    backgroundColor: '$muted.300',
    _dark: {
        backgroundColor: '$muted.600',
    },
    variants: {
        orientation: {
            vertical: {
                width: 1,
                height: '100%',
            },
            horizontal: {
                height: 1,
                width: '100%',
            },
        },
    },
    defaultProps: {
        orientation: 'horizontal',
    },
}, {
    componentName: 'Divider',
});
