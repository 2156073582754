import { __awaiter, __generator } from "tslib";
import { useCallback } from 'react';
import { dataTransferItemsHaveFiles, dataTransferItemsToFiles, } from '../../ReactFileUtilities';
import { SetLinkPreviewMode } from '../types';
export var usePasteHandler = function (uploadNewFiles, insertText, isUploadEnabled, findAndEnqueueURLsToEnrich) {
    var onPaste = useCallback(function (clipboardEvent) {
        if (!isUploadEnabled)
            return;
        (function (event) { return __awaiter(void 0, void 0, void 0, function () {
            var items, plainTextPromise, _loop_1, i, state_1, fileLikes, pastedText;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        items = event.clipboardData.items;
                        if (!dataTransferItemsHaveFiles(Array.from(items)))
                            return [2 /*return*/];
                        event.preventDefault();
                        plainTextPromise = undefined;
                        _loop_1 = function (i) {
                            var item = items[i];
                            if (item.kind === 'string' && item.type === 'text/plain') {
                                plainTextPromise = new Promise(function (resolve) {
                                    item.getAsString(function (string) {
                                        resolve(string);
                                    });
                                });
                                return "break";
                            }
                        };
                        for (i = 0; i < items.length; i++) {
                            state_1 = _loop_1(i);
                            if (state_1 === "break")
                                break;
                        }
                        return [4 /*yield*/, dataTransferItemsToFiles(Array.from(items))];
                    case 1:
                        fileLikes = _a.sent();
                        if (fileLikes.length) {
                            uploadNewFiles(fileLikes);
                            return [2 /*return*/];
                        }
                        if (!plainTextPromise) return [3 /*break*/, 3];
                        return [4 /*yield*/, plainTextPromise];
                    case 2:
                        pastedText = _a.sent();
                        insertText(pastedText);
                        findAndEnqueueURLsToEnrich === null || findAndEnqueueURLsToEnrich === void 0 ? void 0 : findAndEnqueueURLsToEnrich(pastedText, SetLinkPreviewMode.UPSERT);
                        findAndEnqueueURLsToEnrich === null || findAndEnqueueURLsToEnrich === void 0 ? void 0 : findAndEnqueueURLsToEnrich.flush();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); })(clipboardEvent);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [insertText, uploadNewFiles]);
    return { onPaste: onPaste };
};
