"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VStack = void 0;
const vstack_1 = require("@gluestack-ui/vstack");
const styled_components_1 = require("./styled-components");
const usePropResolution_1 = require("../../hooks/usePropResolution");
const react_1 = __importStar(require("react"));
const Text_1 = require("../Text");
const hooks_1 = require("../../hooks");
const Provider_1 = require("../Provider");
const AccessibleVStack = (0, vstack_1.createVStack)({
    Root: styled_components_1.Root,
});
const VStackTemp = (0, react_1.forwardRef)((_a, ref) => {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    var { children, divider, direction } = _a, props = __rest(_a, ["children", "divider", "direction"]);
    props.flexDirection =
        (_d = (_c = (_b = props.flexDirection) !== null && _b !== void 0 ? _b : props.flexDir) !== null && _c !== void 0 ? _c : direction) !== null && _d !== void 0 ? _d : 'column';
    const resolvedPropForGluestack = (0, usePropResolution_1.usePropResolution)(props);
    const { config } = react_1.default.useContext(Provider_1.HooksContext);
    const Gradient = config === null || config === void 0 ? void 0 : config['linear-gradient'];
    const lightModeGradient = ((_f = (_e = props === null || props === void 0 ? void 0 : props._light) === null || _e === void 0 ? void 0 : _e.bg) === null || _f === void 0 ? void 0 : _f.linearGradient) ||
        ((_h = (_g = props === null || props === void 0 ? void 0 : props._light) === null || _g === void 0 ? void 0 : _g.background) === null || _h === void 0 ? void 0 : _h.linearGradient) ||
        ((_k = (_j = props === null || props === void 0 ? void 0 : props._light) === null || _j === void 0 ? void 0 : _j.bgColor) === null || _k === void 0 ? void 0 : _k.linearGradient) ||
        ((_m = (_l = props === null || props === void 0 ? void 0 : props._light) === null || _l === void 0 ? void 0 : _l.backgroundColor) === null || _m === void 0 ? void 0 : _m.linearGradient);
    const darkModeGradient = ((_p = (_o = props === null || props === void 0 ? void 0 : props._dark) === null || _o === void 0 ? void 0 : _o.bg) === null || _p === void 0 ? void 0 : _p.linearGradient) ||
        ((_r = (_q = props === null || props === void 0 ? void 0 : props._dark) === null || _q === void 0 ? void 0 : _q.background) === null || _r === void 0 ? void 0 : _r.linearGradient) ||
        ((_t = (_s = props === null || props === void 0 ? void 0 : props._dark) === null || _s === void 0 ? void 0 : _s.bgColor) === null || _t === void 0 ? void 0 : _t.linearGradient) ||
        ((_v = (_u = props === null || props === void 0 ? void 0 : props._dark) === null || _u === void 0 ? void 0 : _u.backgroundColor) === null || _v === void 0 ? void 0 : _v.linearGradient);
    const neutralGradient = ((_w = props === null || props === void 0 ? void 0 : props.bg) === null || _w === void 0 ? void 0 : _w.linearGradient) ||
        ((_x = props === null || props === void 0 ? void 0 : props.background) === null || _x === void 0 ? void 0 : _x.linearGradient) ||
        ((_y = props === null || props === void 0 ? void 0 : props.bgColor) === null || _y === void 0 ? void 0 : _y.linearGradient) ||
        ((_z = props === null || props === void 0 ? void 0 : props.backgroundColor) === null || _z === void 0 ? void 0 : _z.linearGradient);
    const colorModeGradient = (0, hooks_1.useColorModeValue)(lightModeGradient || neutralGradient, darkModeGradient || neutralGradient);
    if (colorModeGradient) {
        const lgrad = colorModeGradient;
        delete resolvedPropForGluestack.sx['@linearGradient'];
        if (Gradient) {
            let startObj = { x: 0, y: 0 };
            let endObj = { x: 0, y: 1 };
            if (lgrad.start && lgrad.start.length === 2) {
                startObj = {
                    x: lgrad.start[0],
                    y: lgrad.start[1],
                };
            }
            if (lgrad.end && lgrad.end.length === 2) {
                endObj = {
                    x: lgrad.end[0],
                    y: lgrad.end[1],
                };
            }
            return (react_1.default.createElement(AccessibleVStack, Object.assign({ as: Gradient }, resolvedPropForGluestack, { colors: lgrad.colors, start: startObj, end: endObj, locations: lgrad.locations, ref: ref }), react_1.Children.map(children, (child, index) => {
                if (index !== 0)
                    return (react_1.default.createElement(react_1.default.Fragment, null,
                        divider &&
                            (0, react_1.cloneElement)(divider, {
                                orientation: (props === null || props === void 0 ? void 0 : props.flexDirection) &&
                                    (props === null || props === void 0 ? void 0 : props.flexDirection[0]) === 'r'
                                    ? 'vertical'
                                    : 'horizontal',
                            }),
                        typeof child === 'string' ? react_1.default.createElement(Text_1.Text, null, child) : child));
                return react_1.default.createElement(react_1.default.Fragment, null, child);
            })));
        }
    }
    return (react_1.default.createElement(AccessibleVStack, Object.assign({}, resolvedPropForGluestack, { ref: ref }), react_1.Children.map(children, (child, index) => {
        if (index !== 0)
            return (react_1.default.createElement(react_1.default.Fragment, null,
                divider &&
                    (0, react_1.cloneElement)(divider, {
                        orientation: (props === null || props === void 0 ? void 0 : props.flexDirection) && (props === null || props === void 0 ? void 0 : props.flexDirection[0]) === 'r'
                            ? 'vertical'
                            : 'horizontal',
                    }),
                typeof child === 'string' ? react_1.default.createElement(Text_1.Text, null, child) : child));
        return react_1.default.createElement(react_1.default.Fragment, null, child);
    })));
});
exports.VStack = VStackTemp;
