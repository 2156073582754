"use strict";
// @ts-nocheck
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterProps = exports.transformFactoryToStyled = exports.resolveAlphaColors = exports.colorScheme = void 0;
const usePropResolution_1 = require("../hooks/usePropResolution");
exports.colorScheme = [
    'rose',
    'pink',
    'fuchsia',
    'purple',
    'violet',
    'indigo',
    'blue',
    'lightBlue',
    'darkBlue',
    'cyan',
    'teal',
    'emerald',
    'green',
    'lime',
    'yellow',
    'amber',
    'orange',
    'red',
    'warmGray',
    'trueGray',
    'gray',
    'coolGray',
    'blueGray',
    'dark',
    'danger',
    'error',
    'success',
    'warning',
    'muted',
    'primary',
    'info',
    'secondary',
    'light',
    'tertiary',
];
function hexToRgba(hex, alpha) {
    let r = '', g = '', b = '';
    if (hex.length === 4) {
        r = '0x' + hex[1] + hex[1];
        g = '0x' + hex[2] + hex[2];
        b = '0x' + hex[3] + hex[3];
    }
    else if (hex.length === 7) {
        r = '0x' + hex[1] + hex[2];
        g = '0x' + hex[3] + hex[4];
        b = '0x' + hex[5] + hex[6];
    }
    return 'rgba(' + +r + ',' + +g + ',' + +b + ',' + alpha.toString() + ')';
}
function resolveAlphaColors(rawValue, resolver) {
    if (typeof rawValue === 'string' && rawValue.includes(':alpha.')) {
        const opacity = resolver(rawValue.split(':alpha.')[1], 'opacity');
        const value = rawValue.split(':alpha.')[0];
        return hexToRgba(resolver(value), opacity);
    }
    else {
        return resolver(rawValue);
    }
}
exports.resolveAlphaColors = resolveAlphaColors;
function transformFactoryToStyled(factoryStyle) {
    let transformedTheme = {
        variants: {
            variant: {},
            size: {},
        },
        defaultProps: {},
    };
    if (factoryStyle)
        Object.keys(factoryStyle).forEach((key) => {
            switch (key) {
                case 'baseStyle':
                    const _a = (0, usePropResolution_1.usePropResolution)(factoryStyle.baseStyle), { sx: sxBS } = _a, baseStyle = __rest(_a, ["sx"]);
                    transformedTheme = Object.assign(Object.assign(Object.assign({}, transformedTheme), baseStyle), sxBS);
                    break;
                case 'defaultProps':
                    const _b = (0, usePropResolution_1.usePropResolution)(factoryStyle.defaultProps), { sx: sxDP } = _b, defaultProps = __rest(_b, ["sx"]);
                    transformedTheme = Object.assign(Object.assign({}, transformedTheme), { defaultProps: Object.assign(Object.assign({}, defaultProps), sxDP) });
                    break;
                case 'variants':
                    const Variants = factoryStyle.variants;
                    Object.keys(Variants).forEach((variant) => {
                        const _a = (0, usePropResolution_1.usePropResolution)(Variants[variant]), { sx: sxVariant } = _a, styleVariant = __rest(_a, ["sx"]);
                        transformedTheme.variants.variant[variant] = Object.assign(Object.assign({}, styleVariant), sxVariant);
                    });
                    break;
                case 'sizes':
                    const Sizes = factoryStyle.sizes;
                    Object.keys(Sizes).forEach((size) => {
                        const _a = (0, usePropResolution_1.usePropResolution)(Variants[size]), { sx: sxStyle } = _a, styleSize = __rest(_a, ["sx"]);
                        transformedTheme.variants.variant[size] = Object.assign(Object.assign({}, styleSize), sxStyle);
                    });
                    break;
                default:
                // nothing
            }
        });
    return transformedTheme;
}
exports.transformFactoryToStyled = transformFactoryToStyled;
const permittedProps = [
    'color',
    'fontSize',
    'fontFamily',
    'fontStyle',
    'fontWeight',
    'letterSpacing',
    'lineHeight',
    'isTruncated',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'sub',
    'highlight',
    'textAlign',
    'textDecorationLine',
];
function filterProps(obj) {
    const filteredObj = {};
    for (const key of Object.keys(obj)) {
        if (permittedProps.includes(key)) {
            filteredObj[key] = obj[key];
        }
        else if (typeof obj[key] === 'object') {
            const filteredNested = filterProps(obj[key]);
            if (Object.keys(filteredNested).length > 0) {
                filteredObj[key] = filteredNested;
            }
        }
    }
    return filteredObj;
}
exports.filterProps = filterProps;
