"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Root = void 0;
const html_elements_1 = require("@expo/html-elements");
const animation_resolver_1 = require("@gluestack-style/animation-resolver");
const legend_motion_animation_driver_1 = require("@gluestack-style/legend-motion-animation-driver");
const react_1 = require("@gluestack-style/react");
const motion_1 = require("@legendapp/motion");
const MotionUL = (0, motion_1.createMotionAnimatedComponent)(html_elements_1.UL);
exports.Root = (0, react_1.styled)(MotionUL, {
    //@ts-ignore
    ':initial': {
        opacity: 0,
    },
    ':animate': {
        opacity: 1,
    },
    ':exit': {
        opacity: 0,
    },
    ':transition': {
        type: 'spring',
        damping: 18,
        stiffness: 250,
        opacity: {
            type: 'timing',
            duration: 200,
        },
    },
    'minWidth': 200,
    'py': '$2',
    // @ts-ignore
    'rounded': '$sm',
    // @ts-ignore
    'bg': '$backgroundLight.0',
    '_dark': {
        backgroundColor: '$backgroundDark.900',
    },
    'defaultProps': {
        softShadow: '3',
    },
}, {
    componentName: 'Menu',
}, {
    plugins: [new animation_resolver_1.AnimationResolver(legend_motion_animation_driver_1.MotionAnimationDriver)],
});
