import React, { createContext, useContext, } from 'react';
export var ChannelListContext = createContext(undefined);
/**
 * Context provider for components rendered within the `ChannelList`
 */
export var ChannelListContextProvider = function (_a) {
    var children = _a.children, value = _a.value;
    return (React.createElement(ChannelListContext.Provider, { value: value }, children));
};
export var useChannelListContext = function (componentName) {
    var contextValue = useContext(ChannelListContext);
    if (!contextValue) {
        console.warn("The useChannelListContext hook was called outside of the ChannelListContext provider. Make sure this hook is called within the ChannelList component. The errored call is located in the ".concat(componentName, " component."));
        return {};
    }
    return contextValue;
};
