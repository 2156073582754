"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
exports.default = (0, react_1.styled)(react_native_1.View, {
    flexDirection: 'column',
    variants: {
        space: {
            'gutter': { gap: '$0' },
            '2xs': { gap: '$1' },
            'xs': { gap: '$2' },
            'sm': { gap: '$3' },
            'md': { gap: '$4' },
            'lg': { gap: '$6' },
            'xl': { gap: '$7' },
            '2xl': { gap: '$8' },
        },
        reversed: {
            true: {
                flexDirection: 'column-reverse',
            },
        },
    },
}, {
    componentName: 'VStack',
    descendantStyle: ['_text'],
});
