import React, { createContext, useContext } from 'react';
export var MessageListContext = createContext(undefined);
/**
 * Context provider for components rendered within the `MessageList`
 */
export var MessageListContextProvider = function (_a) {
    var children = _a.children, value = _a.value;
    return (React.createElement(MessageListContext.Provider, { value: value }, children));
};
export var useMessageListContext = function (componentName) {
    var contextValue = useContext(MessageListContext);
    if (!contextValue) {
        console.warn("The useMessageListContext hook was called outside of the MessageListContext provider. Make sure this hook is called within the MessageList component. The errored call is located in the ".concat(componentName, " component."));
        return {};
    }
    return contextValue;
};
