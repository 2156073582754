"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
exports.default = (0, react_1.styled)(react_native_1.Pressable, {
    flexDirection: 'row',
    _web: {
        ':disabled': {
            cursor: 'not-allowed',
        },
    },
    variants: {
        isUnderlined: {
            true: {
                _linkText: {
                    textDecorationLine: 'underline',
                },
            },
            false: {
                _linkText: {
                    textDecorationLine: 'none',
                },
            },
        },
    },
    defaultProps: {
        isUnderlined: 'true',
    },
}, {
    componentName: 'Link',
    descendantStyle: ['_linkText', '_text'],
});
