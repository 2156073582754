import { __assign, __rest, __spreadArray } from "tslib";
import React, { useMemo } from 'react';
import { isAudioAttachment, isFileAttachment, isMediaAttachment, isScrapedContent, isUploadedImage, isVoiceRecordingAttachment, } from './utils';
import { AudioContainer, CardContainer, FileContainer, GalleryContainer, ImageContainer, MediaContainer, UnsupportedAttachmentContainer, VoiceRecordingContainer, } from './AttachmentContainer';
var CONTAINER_MAP = {
    audio: AudioContainer,
    card: CardContainer,
    file: FileContainer,
    media: MediaContainer,
    unsupported: UnsupportedAttachmentContainer,
    voiceRecording: VoiceRecordingContainer,
};
export var ATTACHMENT_GROUPS_ORDER = [
    'card',
    'gallery',
    'image',
    'media',
    'audio',
    'voiceRecording',
    'file',
    'unsupported',
];
/**
 * A component used for rendering message attachments. By default, the component supports: AttachmentActions, Audio, Card, File, Gallery, Image, and Video
 */
export var Attachment = function (props) {
    var attachments = props.attachments;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var groupedAttachments = useMemo(function () { return renderGroupedAttachments(props); }, [attachments]);
    return (React.createElement("div", { className: 'str-chat__attachment-list' }, ATTACHMENT_GROUPS_ORDER.reduce(function (acc, groupName) { return __spreadArray(__spreadArray([], acc, true), groupedAttachments[groupName], true); }, [])));
};
var renderGroupedAttachments = function (_a) {
    var attachments = _a.attachments, rest = __rest(_a, ["attachments"]);
    var uploadedImages = attachments.filter(function (attachment) {
        return isUploadedImage(attachment);
    });
    var containers = attachments
        .filter(function (attachment) { return !isUploadedImage(attachment); })
        .reduce(function (typeMap, attachment) {
        var attachmentType = getAttachmentType(attachment);
        var Container = CONTAINER_MAP[attachmentType];
        typeMap[attachmentType].push(React.createElement(Container, __assign({ key: "".concat(attachmentType, "-").concat(typeMap[attachmentType].length) }, rest, { attachment: attachment })));
        return typeMap;
    }, {
        audio: [],
        card: [],
        file: [],
        media: [],
        unsupported: [],
        // not used in reduce
        // eslint-disable-next-line sort-keys
        image: [],
        // eslint-disable-next-line sort-keys
        gallery: [],
        voiceRecording: [],
    });
    if (uploadedImages.length > 1) {
        containers['gallery'] = [
            React.createElement(GalleryContainer, __assign({ key: 'gallery-container' }, rest, { attachment: {
                    images: uploadedImages,
                    type: 'gallery',
                } })),
        ];
    }
    else if (uploadedImages.length === 1) {
        containers['image'] = [
            React.createElement(ImageContainer, __assign({ key: 'image-container' }, rest, { attachment: uploadedImages[0] })),
        ];
    }
    return containers;
};
var getAttachmentType = function (attachment) {
    if (isScrapedContent(attachment)) {
        return 'card';
    }
    else if (isMediaAttachment(attachment)) {
        return 'media';
    }
    else if (isAudioAttachment(attachment)) {
        return 'audio';
    }
    else if (isVoiceRecordingAttachment(attachment)) {
        return 'voiceRecording';
    }
    else if (isFileAttachment(attachment)) {
        return 'file';
    }
    return 'unsupported';
};
