import { __awaiter, __generator } from "tslib";
import { isUserMuted, validateAndGetMessage } from '../utils';
import { useChannelStateContext } from '../../../context/ChannelStateContext';
import { useChatContext } from '../../../context/ChatContext';
import { useTranslationContext } from '../../../context/TranslationContext';
export var missingUseMuteHandlerParamsWarning = 'useMuteHandler was called but it is missing one or more necessary parameter.';
export var useMuteHandler = function (message, notifications) {
    if (notifications === void 0) { notifications = {}; }
    var mutes = useChannelStateContext('useMuteHandler').mutes;
    var client = useChatContext('useMuteHandler').client;
    var t = useTranslationContext('useMuteHandler').t;
    return function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var getErrorNotification, getSuccessNotification, notify, successMessage, e_1, errorMessage, fallbackMessage, successMessage, e_2, errorMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    getErrorNotification = notifications.getErrorNotification, getSuccessNotification = notifications.getSuccessNotification, notify = notifications.notify;
                    if (!t || !(message === null || message === void 0 ? void 0 : message.user) || !notify || !client) {
                        console.warn(missingUseMuteHandlerParamsWarning);
                        return [2 /*return*/];
                    }
                    if (!!isUserMuted(message, mutes)) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, client.muteUser(message.user.id)];
                case 2:
                    _a.sent();
                    successMessage = getSuccessNotification && validateAndGetMessage(getSuccessNotification, [message.user]);
                    notify(successMessage ||
                        t("{{ user }} has been muted", {
                            user: message.user.name || message.user.id,
                        }), 'success');
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    errorMessage = getErrorNotification && validateAndGetMessage(getErrorNotification, [message.user]);
                    notify(errorMessage || t('Error muting a user ...'), 'error');
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 8];
                case 5:
                    _a.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, client.unmuteUser(message.user.id)];
                case 6:
                    _a.sent();
                    fallbackMessage = t("{{ user }} has been unmuted", {
                        user: message.user.name || message.user.id,
                    });
                    successMessage = (getSuccessNotification &&
                        validateAndGetMessage(getSuccessNotification, [message.user])) ||
                        fallbackMessage;
                    if (typeof successMessage === 'string') {
                        notify(successMessage, 'success');
                    }
                    return [3 /*break*/, 8];
                case 7:
                    e_2 = _a.sent();
                    errorMessage = (getErrorNotification && validateAndGetMessage(getErrorNotification, [message.user])) ||
                        t('Error unmuting a user ...');
                    if (typeof errorMessage === 'string') {
                        notify(errorMessage, 'error');
                    }
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
};
