"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledIcon = void 0;
const react_1 = require("@gluestack-style/react");
exports.StyledIcon = (0, react_1.styled)(react_1.AsForwarder, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: '$muted.500',
    _dark: {
        color: '$muted.400',
    },
    variants: {
        size: {
            '2xs': {
                height: '$2',
                width: '$2',
                props: {
                    // @ts-ignore
                    size: 8,
                },
            },
            'xs': {
                height: '$3',
                width: '$3',
                props: {
                    // @ts-ignore
                    size: 12,
                },
            },
            'sm': {
                height: '$4',
                width: '$4',
                props: {
                    // @ts-ignore
                    size: 16,
                },
            },
            'md': {
                height: '$5',
                width: '$5',
                props: {
                    // @ts-ignore
                    size: 20,
                },
            },
            'lg': {
                height: '$6',
                width: '$6',
                props: {
                    // @ts-ignore
                    size: 24,
                },
            },
            'xl': {
                height: '$7',
                width: '$7',
                props: {
                    // @ts-ignore
                    size: 28,
                },
            },
            '2xl': {
                height: '$8',
                width: '$8',
                props: {
                    // @ts-ignore
                    size: 32,
                },
            },
            '3xl': {
                height: '$9',
                width: '$9',
                props: {
                    // @ts-ignore
                    size: 36,
                },
            },
            '4xl': {
                height: '$10',
                width: '$10',
                props: {
                    // @ts-ignore
                    size: 40,
                },
            },
            '5xl': {
                height: '$12',
                width: '$12',
                props: {
                    // @ts-ignore
                    size: 48,
                },
            },
            '6xl': {
                height: '$16',
                width: '$16',
                props: {
                    // @ts-ignore
                    size: 64,
                },
            },
        },
    },
    defaultProps: {
        size: 'md',
    },
}, {
    componentName: 'Icon',
    resolveProps: ['stroke', 'fill'],
    ancestorStyle: ['_icon'],
}, {
    propertyTokenMap: {
        stroke: 'colors',
        fill: 'colors',
    },
});
exports.default = (0, react_1.styled)(exports.StyledIcon, {
    textAlign: 'center',
    color: '$muted.500',
    _dark: {
        color: '$muted.400',
    },
    variants: {
        size: {
            '2xs': {
                height: '$2',
                width: '$2',
                props: {
                    // @ts-ignore
                    size: 8,
                },
            },
            'xs': {
                height: '$3',
                width: '$3',
                props: {
                    // @ts-ignore
                    size: 12,
                },
            },
            'sm': {
                height: '$4',
                width: '$4',
                props: {
                    // @ts-ignore
                    size: 16,
                },
            },
            'md': {
                height: '$5',
                width: '$5',
                props: {
                    // @ts-ignore
                    size: 20,
                },
            },
            'lg': {
                height: '$6',
                width: '$6',
                props: {
                    // @ts-ignore
                    size: 24,
                },
            },
            'xl': {
                height: '$7',
                width: '$7',
                props: {
                    // @ts-ignore
                    size: 28,
                },
            },
            '2xl': {
                height: '$8',
                width: '$8',
                props: {
                    // @ts-ignore
                    size: 32,
                },
            },
            '3xl': {
                height: '$9',
                width: '$9',
                props: {
                    // @ts-ignore
                    size: 36,
                },
            },
            '4xl': {
                height: '$10',
                width: '$10',
                props: {
                    // @ts-ignore
                    size: 40,
                },
            },
            '5xl': {
                height: '$12',
                width: '$12',
                props: {
                    // @ts-ignore
                    size: 48,
                },
            },
            '6xl': {
                height: '$16',
                width: '$16',
                props: {
                    // @ts-ignore
                    size: 64,
                },
            },
        },
    },
    defaultProps: {
        size: 'md',
    },
    props: {
        //@ts-ignore
        fill: 'none',
    },
}, {
    ancestorStyle: ['_icon'],
    resolveProps: ['stroke', 'fill'],
}, {
    propertyTokenMap: {
        stroke: 'colors',
        fill: 'colors',
    },
});
