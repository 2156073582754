"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoonIcon = void 0;
const styled_components_1 = require("../styled-components");
const MoonIcon = (0, styled_components_1.createIcon)({
    viewBox: '0 0 24 24',
    d: 'M20.8562 13.4434C20.1167 13.6274 19.3772 13.7194 18.6378 13.7194C14.0162 13.7194 10.3189 10.0391 10.3189 5.43876C10.3189 4.7027 10.4113 3.96665 10.5962 3.23059C10.6886 2.95457 10.5962 2.58654 10.3189 2.31052C10.0416 2.0345 9.7643 1.9425 9.39457 2.0345C5.05026 3.3226 2 7.3709 2 11.8792C2 17.4917 6.52917 22 12.1675 22C16.6967 22 20.7637 18.9638 21.9653 14.5474C22.0578 14.2714 21.9653 13.9034 21.688 13.6274C21.5032 13.4434 21.1334 13.3514 20.8562 13.4434Z',
});
exports.MoonIcon = MoonIcon;
MoonIcon.displayName = 'MoonIcon';
