"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Item = void 0;
const react_1 = require("@gluestack-style/react");
const html_elements_1 = require("@expo/html-elements");
exports.Item = (0, react_1.styled)(html_elements_1.LI, {
    // @ts-ignore
    'p': '$3',
    'flexDirection': 'row',
    'alignItems': 'center',
    ':hover': {
        backgroundColor: '$backgroundLight.100',
    },
    ':disabled': {
        'opacity': 0.4,
        // @ts-ignore
        'cursor': 'not-allowed',
        ':focus': {
            backgroundColor: 'transparent',
        },
        '_dark': {
            ':focus': {
                backgroundColor: 'transparent',
            },
        },
    },
    ':active': {
        backgroundColor: '$backgroundLight.200',
    },
    ':focus': {
        backgroundColor: '$backgroundLight.100',
        // @ts-ignore
        outlineWidth: '$0',
        outlineStyle: 'none',
    },
    '_dark': {
        ':hover': {
            backgroundColor: '$backgroundDark.800',
        },
        ':active': {
            backgroundColor: '$backgroundDark.700',
        },
        ':focus': {
            backgroundColor: '$backgroundDark.800',
        },
    },
    ':focusVisible': {
        // @ts-ignore
        outlineWidth: '$0.5',
        outlineColor: '$primary.700',
        outlineStyle: 'solid',
        _dark: {
            outlineColor: '$primary.300',
        },
    },
    'cursor': 'pointer',
}, {
    descendantStyle: ['_text'],
});
