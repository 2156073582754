import { __assign, __rest } from "tslib";
import React from 'react';
import { iconMap } from './iconMap';
export function mimeTypeToIcon(type, version, mimeType) {
    var _a;
    if (type === void 0) { type = 'standard'; }
    if (version === void 0) { version = '1'; }
    var theMap = ((_a = iconMap[version]) === null || _a === void 0 ? void 0 : _a[type]) || iconMap[version]['standard'];
    if (!mimeType)
        return theMap.fallback;
    var icon = theMap[mimeType];
    if (icon)
        return icon;
    if (mimeType.startsWith('audio/'))
        return theMap['audio/'];
    if (mimeType.startsWith('video/'))
        return theMap['video/'];
    if (mimeType.startsWith('image/'))
        return theMap['image/'];
    if (mimeType.startsWith('text/'))
        return theMap['text/'];
    return theMap.fallback;
}
export var FileIcon = function (props) {
    var _a = props.big, big = _a === void 0 ? false : _a, mimeType = props.mimeType, _b = props.size, size = _b === void 0 ? 50 : _b, _c = props.sizeSmall, sizeSmall = _c === void 0 ? 20 : _c, _d = props.type, type = _d === void 0 ? 'standard' : _d, _e = props.version, version = _e === void 0 ? '1' : _e, rest = __rest(props, ["big", "mimeType", "size", "sizeSmall", "type", "version"]);
    var Icon = mimeTypeToIcon(type, version, mimeType);
    return React.createElement(Icon, __assign({}, rest, { size: big ? size : sizeSmall }));
};
