import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import uniqBy from 'lodash.uniqby';
import { isChannel } from '../utils';
import { useChatContext } from '../../../context/ChatContext';
export var useChannelSearch = function (_a) {
    var _b = _a.channelType, channelType = _b === void 0 ? 'messaging' : _b, _c = _a.clearSearchOnClickOutside, clearSearchOnClickOutside = _c === void 0 ? true : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, onSearchCallback = _a.onSearch, onSearchExit = _a.onSearchExit, onSelectResult = _a.onSelectResult, _e = _a.searchDebounceIntervalMs, searchDebounceIntervalMs = _e === void 0 ? 300 : _e, _f = _a.searchForChannels, searchForChannels = _f === void 0 ? false : _f, searchFunction = _a.searchFunction, searchQueryParams = _a.searchQueryParams, setChannels = _a.setChannels;
    var _g = useChatContext('useChannelSearch'), client = _g.client, setActiveChannel = _g.setActiveChannel, themeVersion = _g.themeVersion;
    var _h = useState(false), inputIsFocused = _h[0], setInputIsFocused = _h[1];
    var _j = useState(''), query = _j[0], setQuery = _j[1];
    var _k = useState([]), results = _k[0], setResults = _k[1];
    var _l = useState(false), searching = _l[0], setSearching = _l[1];
    var searchQueryPromiseInProgress = useRef();
    var shouldIgnoreQueryResults = useRef(false);
    var inputRef = useRef(null);
    var searchBarRef = useRef(null);
    var clearState = useCallback(function () {
        setQuery('');
        setResults([]);
        setSearching(false);
        if (searchQueryPromiseInProgress.current) {
            shouldIgnoreQueryResults.current = true;
        }
    }, []);
    var activateSearch = useCallback(function () {
        setInputIsFocused(true);
    }, []);
    var exitSearch = useCallback(function () {
        var _a;
        setInputIsFocused(false);
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        clearState();
        onSearchExit === null || onSearchExit === void 0 ? void 0 : onSearchExit();
    }, [clearState, onSearchExit]);
    useEffect(function () {
        if (disabled)
            return;
        var clickListener = function (event) {
            var _a, _b;
            if (!(event.target instanceof HTMLElement))
                return;
            var isInputClick = themeVersion === '2'
                ? (_a = searchBarRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)
                : (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.contains(event.target);
            if (isInputClick)
                return;
            if ((inputIsFocused && !query) || clearSearchOnClickOutside) {
                exitSearch();
            }
        };
        document.addEventListener('click', clickListener);
        return function () { return document.removeEventListener('click', clickListener); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, inputIsFocused, query, exitSearch, clearSearchOnClickOutside]);
    useEffect(function () {
        if (!inputRef.current || disabled)
            return;
        var handleKeyDown = function (event) {
            if (event.key === 'Escape')
                return exitSearch();
        };
        inputRef.current.addEventListener('keydown', handleKeyDown);
        return function () {
            var _a;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);
    var selectResult = useCallback(function (result) { return __awaiter(void 0, void 0, void 0, function () {
        var selectedChannel, newChannel;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!client.userID)
                        return [2 /*return*/];
                    if (!onSelectResult) return [3 /*break*/, 2];
                    return [4 /*yield*/, onSelectResult({
                            setQuery: setQuery,
                            setResults: setResults,
                            setSearching: setSearching,
                        }, result)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2:
                    if (!isChannel(result)) return [3 /*break*/, 3];
                    setActiveChannel(result);
                    selectedChannel = result;
                    return [3 /*break*/, 5];
                case 3:
                    newChannel = client.channel(channelType, { members: [client.userID, result.id] });
                    return [4 /*yield*/, newChannel.watch()];
                case 4:
                    _a.sent();
                    setActiveChannel(newChannel);
                    selectedChannel = newChannel;
                    _a.label = 5;
                case 5:
                    setChannels === null || setChannels === void 0 ? void 0 : setChannels(function (channels) { return uniqBy(__spreadArray([selectedChannel], channels, true), 'cid'); });
                    if (clearSearchOnClickOutside) {
                        exitSearch();
                    }
                    return [2 /*return*/];
            }
        });
    }); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearSearchOnClickOutside, client, exitSearch, onSelectResult, setActiveChannel, setChannels]);
    var getChannels = useCallback(function (text) { return __awaiter(void 0, void 0, void 0, function () {
        var results, userQueryPromise, users, channelQueryPromise, _a, channels, users, error_1;
        var _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    results = [];
                    _h.label = 1;
                case 1:
                    _h.trys.push([1, 6, , 7]);
                    userQueryPromise = client.queryUsers(__assign({ $or: [{ id: { $autocomplete: text } }, { name: { $autocomplete: text } }], id: { $ne: client.userID } }, (_b = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.userFilters) === null || _b === void 0 ? void 0 : _b.filters), __assign({ id: 1 }, (_c = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.userFilters) === null || _c === void 0 ? void 0 : _c.sort), __assign({ limit: 8 }, (_d = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.userFilters) === null || _d === void 0 ? void 0 : _d.options));
                    if (!!searchForChannels) return [3 /*break*/, 3];
                    searchQueryPromiseInProgress.current = userQueryPromise;
                    return [4 /*yield*/, searchQueryPromiseInProgress.current];
                case 2:
                    users = (_h.sent()).users;
                    results = users;
                    return [3 /*break*/, 5];
                case 3:
                    channelQueryPromise = client.queryChannels(__assign({ name: { $autocomplete: text } }, (_e = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.channelFilters) === null || _e === void 0 ? void 0 : _e.filters), ((_f = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.channelFilters) === null || _f === void 0 ? void 0 : _f.sort) || {}, __assign({ limit: 5 }, (_g = searchQueryParams === null || searchQueryParams === void 0 ? void 0 : searchQueryParams.channelFilters) === null || _g === void 0 ? void 0 : _g.options));
                    searchQueryPromiseInProgress.current = Promise.all([
                        channelQueryPromise,
                        userQueryPromise,
                    ]);
                    return [4 /*yield*/, searchQueryPromiseInProgress.current];
                case 4:
                    _a = _h.sent(), channels = _a[0], users = _a[1].users;
                    results = __spreadArray(__spreadArray([], channels, true), users, true);
                    _h.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    error_1 = _h.sent();
                    console.error(error_1);
                    return [3 /*break*/, 7];
                case 7:
                    setSearching(false);
                    if (!shouldIgnoreQueryResults.current) {
                        setResults(results);
                    }
                    else {
                        shouldIgnoreQueryResults.current = false;
                    }
                    searchQueryPromiseInProgress.current = undefined;
                    return [2 /*return*/];
            }
        });
    }); }, [client, searchForChannels, searchQueryParams]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var scheduleGetChannels = useCallback(debounce(getChannels, searchDebounceIntervalMs), [
        getChannels,
        searchDebounceIntervalMs,
    ]);
    var onSearch = useCallback(function (event) {
        event.preventDefault();
        if (disabled)
            return;
        if (searchFunction) {
            searchFunction({
                setQuery: setQuery,
                setResults: setResults,
                setSearching: setSearching,
            }, event);
        }
        else if (event.target.value) {
            setSearching(true);
            setQuery(event.target.value);
            scheduleGetChannels(event.target.value);
        }
        else if (!event.target.value) {
            clearState();
            scheduleGetChannels.cancel();
        }
        onSearchCallback === null || onSearchCallback === void 0 ? void 0 : onSearchCallback(event);
    }, [clearState, disabled, scheduleGetChannels, onSearchCallback, searchFunction]);
    return {
        activateSearch: activateSearch,
        clearState: clearState,
        exitSearch: exitSearch,
        inputIsFocused: inputIsFocused,
        inputRef: inputRef,
        onSearch: onSearch,
        query: query,
        results: results,
        searchBarRef: searchBarRef,
        searching: searching,
        selectResult: selectResult,
    };
};
