"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const styled_components_1 = require("../../Icons/styled-components");
exports.default = (0, react_1.styled)(styled_components_1.StyledIcon, {
    justifyContent: 'center',
    alignItems: 'center',
    mr: '$3',
    variants: {
        size: {
            xs: {
                height: 12,
                width: 12,
            },
            sm: {
                height: 16,
                width: 16,
            },
            md: {
                height: 18,
                width: 18,
            },
            lg: {
                height: 20,
                width: 20,
            },
            xl: {
                height: 24,
                width: 24,
            },
        },
    },
    defaultProps: {
        size: 'md',
    },
}, {
    ancestorStyle: ['_icon'],
}, {
    propertyTokenMap: {
        stroke: 'colors',
    },
});
