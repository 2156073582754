import { __awaiter, __generator } from "tslib";
import { renderAudio, toAudioBuffer } from './audioProcessing';
var ENCODING_BIT_RATE = 128; // kbps;
var COUNT_SAMPLES_PER_ENCODED_BLOCK = 1152;
var float32ArrayToInt16Array = function (float32Arr) {
    var int16Arr = new Int16Array(float32Arr.length);
    for (var i = 0; i < float32Arr.length; i++) {
        var float32Value = float32Arr[i];
        // Clamp the float value between -1 and 1
        var clampedValue = Math.max(-1, Math.min(1, float32Value));
        // Convert the float value to a signed 16-bit integer
        int16Arr[i] = Math.round(clampedValue * 32767);
    }
    return int16Arr;
};
var splitDataByChannel = function (audioBuffer) {
    return Array.from({ length: audioBuffer.numberOfChannels }, function (_, i) { return audioBuffer.getChannelData(i); }).map(float32ArrayToInt16Array);
};
export function encodeToMp3(file, sampleRate) {
    return __awaiter(this, void 0, void 0, function () {
        var lameJs, audioBuffer, _a, channelCount, dataByChannel, mp3Encoder, dataBuffer, remaining, _loop_1, i, lastBlock;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, import('@breezystack/lamejs')];
                case 1:
                    lameJs = _b.sent();
                    _a = renderAudio;
                    return [4 /*yield*/, toAudioBuffer(file)];
                case 2: return [4 /*yield*/, _a.apply(void 0, [_b.sent(), sampleRate])];
                case 3:
                    audioBuffer = _b.sent();
                    channelCount = audioBuffer.numberOfChannels;
                    dataByChannel = splitDataByChannel(audioBuffer);
                    mp3Encoder = new lameJs.Mp3Encoder(channelCount, sampleRate, ENCODING_BIT_RATE);
                    dataBuffer = [];
                    remaining = dataByChannel[0].length;
                    _loop_1 = function (i) {
                        var _c = dataByChannel.map(function (channel) {
                            return channel.subarray(i, i + COUNT_SAMPLES_PER_ENCODED_BLOCK);
                        }), leftChannelBlock = _c[0], rightChannelBlock = _c[1];
                        dataBuffer.push(new Int8Array(mp3Encoder.encodeBuffer(leftChannelBlock, rightChannelBlock)));
                        remaining -= COUNT_SAMPLES_PER_ENCODED_BLOCK;
                    };
                    for (i = 0; remaining >= COUNT_SAMPLES_PER_ENCODED_BLOCK; i += COUNT_SAMPLES_PER_ENCODED_BLOCK) {
                        _loop_1(i);
                    }
                    lastBlock = mp3Encoder.flush();
                    if (lastBlock.length)
                        dataBuffer.push(new Int8Array(lastBlock));
                    return [2 /*return*/, new Blob(dataBuffer, { type: 'audio/mp3;sbu_type=voice' })];
            }
        });
    });
}
