import { __assign } from "tslib";
import React, { Fragment } from 'react';
import { isDateSeparatorMessage } from './utils';
import { Message } from '../Message';
import { CUSTOM_MESSAGE_TYPE } from '../../constants/messageTypes';
export function defaultRenderMessages(_a) {
    var channelUnreadUiState = _a.channelUnreadUiState, components = _a.components, customClasses = _a.customClasses, lastReceivedId = _a.lastReceivedMessageId, messageGroupStyles = _a.messageGroupStyles, messages = _a.messages, readData = _a.readData, messageProps = _a.sharedMessageProps;
    var DateSeparator = components.DateSeparator, HeaderComponent = components.HeaderComponent, MessageSystem = components.MessageSystem, UnreadMessagesSeparator = components.UnreadMessagesSeparator;
    var renderedMessages = [];
    var firstMessage;
    for (var index = 0; index < messages.length; index++) {
        var message = messages[index];
        if (isDateSeparatorMessage(message)) {
            renderedMessages.push(React.createElement("li", { key: "".concat(message.date.toISOString(), "-i") },
                React.createElement(DateSeparator, { date: message.date, formatDate: messageProps.formatDate, unread: message.unread })));
        }
        else if (message.customType === CUSTOM_MESSAGE_TYPE.intro && HeaderComponent) {
            renderedMessages.push(React.createElement("li", { key: 'intro' },
                React.createElement(HeaderComponent, null)));
        }
        else if (message.type === 'system') {
            renderedMessages.push(React.createElement("li", { "data-message-id": message.id, key: message.id || message.created_at },
                React.createElement(MessageSystem, { message: message })));
        }
        else {
            if (!firstMessage) {
                firstMessage = message;
            }
            var groupStyles = messageGroupStyles[message.id] || '';
            var messageClass = (customClasses === null || customClasses === void 0 ? void 0 : customClasses.message) || "str-chat__li str-chat__li--".concat(groupStyles);
            var createdAtTimestamp = message.created_at && new Date(message.created_at).getTime();
            var lastReadTimestamp = channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.last_read.getTime();
            var isFirstMessage = (firstMessage === null || firstMessage === void 0 ? void 0 : firstMessage.id) && firstMessage.id === message.id;
            var isNewestMessage = index === messages.length - 1;
            var isLastReadMessage = (channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.last_read_message_id) === message.id ||
                (!(channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages) && createdAtTimestamp === lastReadTimestamp);
            var isFirstUnreadMessage = (channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.first_unread_message_id) === message.id ||
                (!!(channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages) &&
                    !!createdAtTimestamp &&
                    !!lastReadTimestamp &&
                    createdAtTimestamp > lastReadTimestamp &&
                    isFirstMessage);
            var showUnreadSeparatorAbove = !(channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.last_read_message_id) && isFirstUnreadMessage;
            var showUnreadSeparatorBelow = isLastReadMessage &&
                !isNewestMessage &&
                ((channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.first_unread_message_id) || !!(channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages)); // this part has to be here as we do not mark channel read when sending a message
            renderedMessages.push(React.createElement(Fragment, { key: message.id || message.created_at },
                showUnreadSeparatorAbove && UnreadMessagesSeparator && (React.createElement("li", { className: 'str-chat__li str-chat__unread-messages-separator-wrapper' },
                    React.createElement(UnreadMessagesSeparator, { unreadCount: channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages }))),
                React.createElement("li", { className: messageClass, "data-message-id": message.id, "data-testid": messageClass },
                    React.createElement(Message, __assign({ groupStyles: [groupStyles], lastReceivedId: lastReceivedId, message: message, readBy: readData[message.id] || [] }, messageProps))),
                showUnreadSeparatorBelow && UnreadMessagesSeparator && (React.createElement("li", { className: 'str-chat__li str-chat__unread-messages-separator-wrapper' },
                    React.createElement(UnreadMessagesSeparator, { unreadCount: channelUnreadUiState === null || channelUnreadUiState === void 0 ? void 0 : channelUnreadUiState.unread_messages })))));
        }
    }
    return renderedMessages;
}
