import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import { useChannelActionContext } from '../../../context/ChannelActionContext';
import { useChannelStateContext } from '../../../context/ChannelStateContext';
import { useChatContext } from '../../../context/ChatContext';
export var reactionHandlerWarning = "Reaction handler was called, but it is missing one of its required arguments.\nMake sure the ChannelAction and ChannelState contexts are properly set and the hook is initialized with a valid message.";
export var useReactionHandler = function (message) {
    var updateMessage = useChannelActionContext('useReactionHandler').updateMessage;
    var _a = useChannelStateContext('useReactionHandler'), channel = _a.channel, channelCapabilities = _a.channelCapabilities;
    var client = useChatContext('useReactionHandler').client;
    var createMessagePreview = useCallback(function (add, reaction, message) {
        var _a, _b;
        var newReactionGroups = (message === null || message === void 0 ? void 0 : message.reaction_groups) || {};
        var reactionType = reaction.type;
        var hasReaction = !!newReactionGroups[reactionType];
        if (add) {
            var timestamp = new Date().toISOString();
            newReactionGroups[reactionType] = hasReaction
                ? __assign(__assign({}, newReactionGroups[reactionType]), { count: newReactionGroups[reactionType].count + 1 }) : {
                count: 1,
                first_reaction_at: timestamp,
                last_reaction_at: timestamp,
                sum_scores: 1,
            };
        }
        else {
            if (hasReaction && newReactionGroups[reactionType].count > 1) {
                newReactionGroups[reactionType] = __assign(__assign({}, newReactionGroups[reactionType]), { count: newReactionGroups[reactionType].count - 1 });
            }
            else {
                delete newReactionGroups[reactionType];
            }
        }
        var newReactions = add
            ? __spreadArray([reaction], ((message === null || message === void 0 ? void 0 : message.latest_reactions) || []), true) : (_a = message.latest_reactions) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return !(item.type === reaction.type && item.user_id === reaction.user_id); });
        var newOwnReactions = add
            ? __spreadArray([reaction], ((message === null || message === void 0 ? void 0 : message.own_reactions) || []), true) : (_b = message === null || message === void 0 ? void 0 : message.own_reactions) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return item.type !== reaction.type; });
        return __assign(__assign({}, message), { latest_reactions: newReactions || message.latest_reactions, own_reactions: newOwnReactions, reaction_groups: newReactionGroups });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client.user, client.userID]);
    var createReactionPreview = function (type) {
        var _a;
        return ({
            message_id: message === null || message === void 0 ? void 0 : message.id,
            score: 1,
            type: type,
            user: client.user,
            user_id: (_a = client.user) === null || _a === void 0 ? void 0 : _a.id,
        });
    };
    var toggleReaction = throttle(function (id, type, add) { return __awaiter(void 0, void 0, void 0, function () {
        var newReaction, tempMessage, messageResponse, _a, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!message || !channelCapabilities['send-reaction'])
                        return [2 /*return*/];
                    newReaction = createReactionPreview(type);
                    tempMessage = createMessagePreview(add, newReaction, message);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, , 7]);
                    updateMessage(tempMessage);
                    if (!add) return [3 /*break*/, 3];
                    return [4 /*yield*/, channel.sendReaction(id, { type: type })];
                case 2:
                    _a = _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, channel.deleteReaction(id, type)];
                case 4:
                    _a = _b.sent();
                    _b.label = 5;
                case 5:
                    messageResponse = _a;
                    updateMessage(messageResponse.message);
                    return [3 /*break*/, 7];
                case 6:
                    error_1 = _b.sent();
                    // revert to the original message if the API call fails
                    updateMessage(message);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); }, 1000);
    return function (reactionType, event) { return __awaiter(void 0, void 0, void 0, function () {
        var userExistingReaction, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (event === null || event === void 0 ? void 0 : event.preventDefault) {
                        event.preventDefault();
                    }
                    if (!message) {
                        return [2 /*return*/, console.warn(reactionHandlerWarning)];
                    }
                    userExistingReaction = null;
                    if (message.own_reactions) {
                        message.own_reactions.forEach(function (reaction) {
                            // own user should only ever contain the current user id
                            // just in case we check to prevent bugs with message updates from breaking reactions
                            if (reaction.user && client.userID === reaction.user.id && reaction.type === reactionType) {
                                userExistingReaction = reaction;
                            }
                            else if (reaction.user && client.userID !== reaction.user.id) {
                                console.warn("message.own_reactions contained reactions from a different user, this indicates a bug");
                            }
                        });
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    if (!userExistingReaction) return [3 /*break*/, 3];
                    return [4 /*yield*/, toggleReaction(message.id, userExistingReaction.type, false)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, toggleReaction(message.id, reactionType, true)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    error_2 = _a.sent();
                    console.log({ error: error_2 });
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
};
export var useReactionClick = function (message, reactionSelectorRef, messageWrapperRef, closeReactionSelectorOnClick) {
    var _a = useChannelStateContext('useReactionClick').channelCapabilities, channelCapabilities = _a === void 0 ? {} : _a;
    var _b = useState(false), showDetailedReactions = _b[0], setShowDetailedReactions = _b[1];
    var hasListener = useRef(false);
    var isReactionEnabled = channelCapabilities['send-reaction'];
    var messageDeleted = !!(message === null || message === void 0 ? void 0 : message.deleted_at);
    var closeDetailedReactions = useCallback(function (event) {
        var _a;
        if (event.target instanceof HTMLElement &&
            ((_a = reactionSelectorRef === null || reactionSelectorRef === void 0 ? void 0 : reactionSelectorRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) &&
            !closeReactionSelectorOnClick) {
            return;
        }
        setShowDetailedReactions(false);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setShowDetailedReactions, reactionSelectorRef]);
    useEffect(function () {
        var messageWrapper = messageWrapperRef === null || messageWrapperRef === void 0 ? void 0 : messageWrapperRef.current;
        if (showDetailedReactions && !hasListener.current) {
            hasListener.current = true;
            document.addEventListener('click', closeDetailedReactions);
            if (messageWrapper) {
                messageWrapper.addEventListener('mouseleave', closeDetailedReactions);
            }
        }
        if (!showDetailedReactions && hasListener.current) {
            document.removeEventListener('click', closeDetailedReactions);
            if (messageWrapper) {
                messageWrapper.removeEventListener('mouseleave', closeDetailedReactions);
            }
            hasListener.current = false;
        }
        return function () {
            if (hasListener.current) {
                document.removeEventListener('click', closeDetailedReactions);
                if (messageWrapper) {
                    messageWrapper.removeEventListener('mouseleave', closeDetailedReactions);
                }
                hasListener.current = false;
            }
        };
    }, [showDetailedReactions, closeDetailedReactions, messageWrapperRef]);
    useEffect(function () {
        var messageWrapper = messageWrapperRef === null || messageWrapperRef === void 0 ? void 0 : messageWrapperRef.current;
        if (messageDeleted && hasListener.current) {
            document.removeEventListener('click', closeDetailedReactions);
            if (messageWrapper) {
                messageWrapper.removeEventListener('mouseleave', closeDetailedReactions);
            }
            hasListener.current = false;
        }
    }, [messageDeleted, closeDetailedReactions, messageWrapperRef]);
    var onReactionListClick = function (event) {
        var _a;
        (_a = event === null || event === void 0 ? void 0 : event.stopPropagation) === null || _a === void 0 ? void 0 : _a.call(event);
        setShowDetailedReactions(function (prev) { return !prev; });
    };
    return {
        isReactionEnabled: isReactionEnabled,
        onReactionListClick: onReactionListClick,
        showDetailedReactions: showDetailedReactions,
    };
};
