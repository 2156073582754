import React, { useMemo } from 'react';
import { QuotedMessage as DefaultQuotedMessage } from './QuotedMessage';
import { isOnlyEmojis, messageHasAttachments } from './utils';
import { useComponentContext, useMessageContext, useTranslationContext } from '../../context';
import { renderText as defaultRenderText } from './renderText';
import { MessageErrorText } from './MessageErrorText';
var UnMemoizedMessageTextComponent = function (props) {
    var _a, _b;
    var customInnerClass = props.customInnerClass, _c = props.customWrapperClass, customWrapperClass = _c === void 0 ? '' : _c, propMessage = props.message, propsRenderText = props.renderText, _d = props.theme, theme = _d === void 0 ? 'simple' : _d;
    var _e = useComponentContext('MessageText').QuotedMessage, QuotedMessage = _e === void 0 ? DefaultQuotedMessage : _e;
    var _f = useMessageContext('MessageText'), contextMessage = _f.message, onMentionsClickMessage = _f.onMentionsClickMessage, onMentionsHoverMessage = _f.onMentionsHoverMessage, contextRenderText = _f.renderText, unsafeHTML = _f.unsafeHTML;
    var renderText = (_a = propsRenderText !== null && propsRenderText !== void 0 ? propsRenderText : contextRenderText) !== null && _a !== void 0 ? _a : defaultRenderText;
    var userLanguage = useTranslationContext('MessageText').userLanguage;
    var message = propMessage || contextMessage;
    var hasAttachment = messageHasAttachments(message);
    var messageTextToRender = ((_b = message.i18n) === null || _b === void 0 ? void 0 : _b["".concat(userLanguage, "_text")]) || message.text;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var messageText = useMemo(function () { return renderText(messageTextToRender, message.mentioned_users); }, [
        message.mentioned_users,
        messageTextToRender,
    ]);
    var wrapperClass = customWrapperClass || 'str-chat__message-text';
    var innerClass = customInnerClass || "str-chat__message-text-inner str-chat__message-".concat(theme, "-text-inner");
    if (!messageTextToRender && !message.quoted_message)
        return null;
    return (React.createElement("div", { className: wrapperClass, tabIndex: 0 },
        React.createElement("div", { className: "\n          ".concat(innerClass, "\n          ").concat(hasAttachment ? " str-chat__message-".concat(theme, "-text-inner--has-attachment") : '', "\n          ").concat(isOnlyEmojis(message.text) && !message.quoted_message
                ? " str-chat__message-".concat(theme, "-text-inner--is-emoji")
                : '', "\n        ").trim(), "data-testid": 'message-text-inner-wrapper', onClick: onMentionsClickMessage, onMouseOver: onMentionsHoverMessage },
            message.quoted_message && React.createElement(QuotedMessage, null),
            React.createElement(MessageErrorText, { message: message, theme: theme }),
            unsafeHTML && message.html ? (React.createElement("div", { dangerouslySetInnerHTML: { __html: message.html } })) : (React.createElement("div", null, messageText)))));
};
export var MessageText = React.memo(UnMemoizedMessageTextComponent);
