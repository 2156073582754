import { __awaiter, __generator } from "tslib";
import { EmoticonItem } from '../../EmoticonItem/EmoticonItem';
import { useChatContext } from '../../../context/ChatContext';
export var useEmojiTrigger = function (emojiSearchIndex) {
    var themeVersion = useChatContext('useEmojiTrigger').themeVersion;
    return {
        component: EmoticonItem,
        dataProvider: function (query, _, onReady) { return __awaiter(void 0, void 0, void 0, function () {
            var emojis, result;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (query.length === 0 || query.charAt(0).match(/[^a-zA-Z0-9+-]/)) {
                            return [2 /*return*/, onReady([], query)];
                        }
                        return [4 /*yield*/, (emojiSearchIndex === null || emojiSearchIndex === void 0 ? void 0 : emojiSearchIndex.search(query))];
                    case 1:
                        emojis = (_a = (_b.sent())) !== null && _a !== void 0 ? _a : [];
                        result = emojis
                            .filter(Boolean)
                            .slice(0, themeVersion === '2' ? 7 : 10)
                            .map(function (_a) {
                            var id = _a.id, name = _a.name, native = _a.native, _b = _a.skins, skins = _b === void 0 ? [] : _b;
                            var firstSkin = skins[0];
                            return {
                                id: id,
                                name: name,
                                native: native !== null && native !== void 0 ? native : firstSkin.native,
                            };
                        });
                        if (onReady)
                            onReady(result, query);
                        return [2 /*return*/];
                }
            });
        }); },
        output: function (entity) { return ({
            caretPosition: 'next',
            key: entity.id,
            text: "".concat('native' in entity ? entity.native : ''),
        }); },
    };
};
