import { findAndReplace } from 'hast-util-find-and-replace';
import { u } from 'unist-builder';
import emojiRegex from 'emoji-regex';
export var emojiMarkdownPlugin = function () {
    var replace = function (match) {
        return u('element', { properties: {}, tagName: 'emoji' }, [u('text', match)]);
    };
    var transform = function (node) { return findAndReplace(node, [emojiRegex(), replace]); };
    return transform;
};
