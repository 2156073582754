import React from 'react';
import { useTranslationContext } from '../../context';
export var UNREAD_MESSAGE_SEPARATOR_CLASS = 'str-chat__unread-messages-separator';
export var UnreadMessagesSeparator = function (_a) {
    var showCount = _a.showCount, unreadCount = _a.unreadCount;
    var t = useTranslationContext('UnreadMessagesSeparator').t;
    return (React.createElement("div", { className: UNREAD_MESSAGE_SEPARATOR_CLASS, "data-testid": 'unread-messages-separator' }, unreadCount && showCount
        ? t('unreadMessagesSeparatorText', { count: unreadCount })
        : t('Unread messages')));
};
