import { __spreadArray } from "tslib";
import { nanoid } from 'nanoid';
export var makeAddNotifications = function (setNotifications, notificationTimeouts) { return function (text, type) {
    if (typeof text !== 'string' || (type !== 'success' && type !== 'error')) {
        return;
    }
    var id = nanoid();
    setNotifications(function (prevNotifications) { return __spreadArray(__spreadArray([], prevNotifications, true), [{ id: id, text: text, type: type }], false); });
    var timeout = setTimeout(function () {
        return setNotifications(function (prevNotifications) {
            return prevNotifications.filter(function (notification) { return notification.id !== id; });
        });
    }, 5000);
    notificationTimeouts.push(timeout);
}; };
/**
 * Utility function for jumpToFirstUnreadMessage
 * @param targetId
 * @param msgSet
 */
export var findInMsgSetById = function (targetId, msgSet) {
    for (var i = msgSet.length - 1; i >= 0; i--) {
        var item = msgSet[i];
        if (item.id === targetId) {
            return {
                index: i,
                target: item,
            };
        }
    }
    return {
        index: -1,
    };
};
/**
 * Utility function for jumpToFirstUnreadMessage
 * @param targetDate
 * @param msgSet
 * @param exact
 */
export var findInMsgSetByDate = function (targetDate, msgSet, exact) {
    var _a, _b;
    if (exact === void 0) { exact = false; }
    var targetTimestamp = targetDate.getTime();
    var left = 0;
    var middle = 0;
    var right = msgSet.length - 1;
    while (left <= right) {
        middle = Math.floor((right + left) / 2);
        var middleTimestamp = new Date(msgSet[middle].created_at).getTime();
        var middleLeftTimestamp = ((_a = msgSet[middle - 1]) === null || _a === void 0 ? void 0 : _a.created_at) &&
            new Date(msgSet[middle - 1].created_at).getTime();
        var middleRightTimestamp = ((_b = msgSet[middle + 1]) === null || _b === void 0 ? void 0 : _b.created_at) &&
            new Date(msgSet[middle + 1].created_at).getTime();
        if (middleTimestamp === targetTimestamp ||
            (middleLeftTimestamp &&
                middleRightTimestamp &&
                middleLeftTimestamp < targetTimestamp &&
                targetTimestamp < middleRightTimestamp)) {
            return { index: middle, target: msgSet[middle] };
        }
        if (middleTimestamp < targetTimestamp)
            left = middle + 1;
        else
            right = middle - 1;
    }
    if (!exact || new Date(msgSet[left].created_at).getTime() === targetTimestamp) {
        return { index: left, target: msgSet[left] };
    }
    return { index: -1 };
};
