"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Switch = void 0;
const switch_1 = require("@gluestack-ui/switch");
const styled_components_1 = require("./styled-components");
const usePropResolution_1 = require("../../hooks/usePropResolution");
const react_1 = __importStar(require("react"));
const utils_1 = require("../../utils");
const AccessibleSwitch = (0, switch_1.createSwitch)({
    Root: styled_components_1.Root,
});
// type ISwitchProps = React.ComponentProps<typeof AccessibleSwitch>;
// type IColorProps = React.ComponentProps<typeof AccessibleSwitch>['bgColor'];
// type IExtraProps = {
//   offTrackColor: IColorProps;
//   onTrackColor: IColorProps;
//   onThumbColor: IColorProps;
//   offThumbColor: IColorProps;
//   colorScheme: IColorSchemes;
// };
const SwitchTemp = (0, react_1.forwardRef)((_a, 
// }: ISwitchProps & IExtraProps,
ref) => {
    // TODO: make a better flow
    var { colorScheme, offTrackColor, onTrackColor, onThumbColor, offThumbColor, isChecked, defaultIsChecked } = _a, props = __rest(_a, ["colorScheme", "offTrackColor", "onTrackColor", "onThumbColor", "offThumbColor", "isChecked", "defaultIsChecked"]);
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(props);
    const sx = {
        props: {
            trackColor: {
                false: offTrackColor ? `$${offTrackColor}` : '',
                true: onTrackColor ? `$${onTrackColor}` : '',
            },
            thumbColor: offThumbColor ? `$${offThumbColor}` : '',
            activeThumbColor: onThumbColor ? `$${onThumbColor}` : '',
            ios_backgroundColor: offTrackColor ? `$${offTrackColor}` : '',
        },
    };
    resolvedProps.sx = (0, utils_1.deepMerge)(resolvedProps.sx, sx);
    return (react_1.default.createElement(AccessibleSwitch, Object.assign({ componentName: "switch", value: isChecked, defaultValue: defaultIsChecked, colorScheme: colorScheme !== null && colorScheme !== void 0 ? colorScheme : 'primary' }, resolvedProps, { ref: ref })));
});
exports.Switch = SwitchTemp;
