import { useEffect, useRef } from 'react';
import { usePopper } from 'react-popper';
export function useMessageActionsBoxPopper(_a) {
    var open = _a.open, placement = _a.placement, referenceElement = _a.referenceElement;
    var popperElementRef = useRef(null);
    var _b = usePopper(referenceElement, popperElementRef.current, {
        modifiers: [
            {
                name: 'eventListeners',
                options: {
                    // It's not safe to update popper position on resize and scroll, since popper's
                    // reference element might not be visible at the time.
                    resize: false,
                    scroll: false,
                },
            },
        ],
        placement: placement,
    }), attributes = _b.attributes, styles = _b.styles, update = _b.update;
    useEffect(function () {
        if (open) {
            // Since the popper's reference element might not be (and usually is not) visible
            // all the time, it's safer to force popper update before showing it.
            update === null || update === void 0 ? void 0 : update();
        }
    }, [open, update]);
    return {
        attributes: attributes,
        popperElementRef: popperElementRef,
        styles: styles,
    };
}
