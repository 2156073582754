"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
const plugins_1 = require("../../../plugins");
exports.default = (0, react_1.styled)(react_native_1.View, {
    'flexDirection': 'row',
    'justifyContent': 'flex-start',
    'alignItems': 'center',
    //@ts-ignore
    'gap': '$4',
    ':checked': {
        _icon: {
            color: '$muted.50',
            _dark: {
                color: '$muted.900',
            },
        },
    },
    ':disabled': {
        _web: {
            cursor: 'not-allowed',
        },
    },
    'variants': {
        size: {
            lg: {
                _text: {
                    // @ts-ignore
                    fontSize: '$lg',
                    // @ts-ignore
                    lineHeight: '$xl',
                },
                _indicator: {
                    height: '$6',
                    width: '$6',
                },
            },
            md: {
                _text: {
                    // @ts-ignore
                    fontSize: '$md',
                    // @ts-ignore
                    lineHeight: '$md',
                },
                _indicator: {
                    height: '$5',
                    width: '$5',
                },
            },
            sm: {
                _text: {
                    // @ts-ignore
                    fontSize: '$sm',
                    // @ts-ignore
                    lineHeight: '$sm',
                },
                _indicator: {
                    height: '$4',
                    width: '$4',
                },
            },
        },
    },
    'defaultProps': {
        size: 'md',
    },
    '_web': {
        'cursor': 'pointer',
        ':disabled': {
            cursor: 'not-allowed',
        },
    },
}, {
    componentName: 'Checkbox',
    descendantStyle: ['_icon', '_text', '_indicator'],
}, {
    plugins: [new plugins_1.ColorSchemeResolver(colorSchemeResolveFn)],
});
function colorSchemeResolveFn(_a) {
    var props = __rest(_a, []);
    let value = {};
    if (props.colorScheme) {
        const color = props.colorScheme;
        value = {
            _indicator: {
                ':checked': {
                    'borderColor': `$${color}.600`,
                    // @ts-ignore
                    'bg': `$${color}.600`,
                    ':hover': {
                        'borderColor': `$${color}.700`,
                        // @ts-ignore
                        'bg': `$${color}.700`,
                        ':disabled': {
                            borderColor: `$${color}.600`,
                            backgroundColor: `$${color}.600`,
                        },
                    },
                    ':active': {
                        borderColor: `$${color}.800`,
                        backgroundColor: `$${color}.800`,
                    },
                },
                ':dark': {
                    ':checked': {
                        'borderColor': `$${color}.500`,
                        // @ts-ignore
                        'bg': `$${color}.500`,
                        ':hover': {
                            'borderColor': `$${color}.400`,
                            // @ts-ignore
                            'bg': `$${color}.400`,
                            ':disabled': {
                                borderColor: `$${color}.500`,
                                backgroundColor: `$${color}.500`,
                            },
                        },
                        ':active': {
                            borderColor: `$${color}.300`,
                            backgroundColor: `$${color}.300`,
                        },
                    },
                },
            },
        };
    }
    return value;
}
