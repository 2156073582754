import React from 'react';
import { AttachmentIcon } from './icons';
import { UploadButton } from './UploadButton';
import { useTranslationContext } from '../../context';
/**
 * @deprecated will be removed in the next major release
 */
export var FileUploadButton = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.multiple, multiple = _c === void 0 ? false : _c, _d = _a.children, children = _d === void 0 ? React.createElement(AttachmentIcon, null) : _d, handleFiles = _a.handleFiles, accepts = _a.accepts, _e = _a.resetOnChange, resetOnChange = _e === void 0 ? true : _e;
    var t = useTranslationContext('FileUploadButton').t;
    var className = 'rfu-file-upload-button';
    if (disabled) {
        className = "".concat(className, " rfu-file-upload-button--disabled");
    }
    return (React.createElement("div", { className: className },
        React.createElement("label", null,
            React.createElement(UploadButton, { accept: Array.isArray(accepts) ? accepts.join(',') : accepts, "aria-label": t('aria/File input'), className: 'rfu-file-input', disabled: disabled, multiple: multiple, onFileChange: handleFiles, resetOnChange: resetOnChange }),
            children)));
};
