"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
const plugins_1 = require("../../../plugins");
exports.default = (0, react_1.styled)(react_native_1.Text, {
    color: '$textLight.500',
    // @ts-ignore
    fontSize: '$sm',
    fontFamily: '$body',
    fontWeight: '$bold',
    // @ts-ignore
    lineHeight: '$xs',
    textTransform: 'uppercase',
    padding: '$3',
    _dark: {
        color: '$textDark400',
    },
}, {
    ancestorStyle: ['_sectionHeaderBackground'],
}, {
    plugins: [new plugins_1.TextStyleResolver()],
});
