"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const plugins_1 = require("../../../plugins");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.Text, {
    color: '$error.600',
    _dark: {
        color: '$error.500',
    },
}, {
    componentName: 'FormControlErrorText',
    ancestorStyle: ['_labelAstrick'],
}, {
    plugins: [new plugins_1.TextStyleResolver()],
});
