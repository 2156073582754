"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const plugins_1 = require("../../../plugins");
const Heading_1 = require("../../Heading");
exports.default = (0, react_1.styled)(Heading_1.AccessibleHeading, {
    color: '$textLight500',
    fontSize: '$sm',
    fontFamily: '$body',
    fontWeight: '$bold',
    lineHeight: '$xs',
    textTransform: 'uppercase',
    padding: '$3',
    _dark: {
        color: '$textDark.400',
    },
}, {
    componentName: 'ActionsheetSectionHeaderText',
    ancestorStyle: ['_sectionHeaderText'],
}, {
    plugins: [new plugins_1.TextStyleResolver()],
});
