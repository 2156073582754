import { __extends } from "tslib";
import { Observable } from './Observable';
import { Subscription } from './Subscription';
import { createObserver } from './Observer';
var Subject = /** @class */ (function (_super) {
    __extends(Subject, _super);
    function Subject() {
        var _this = _super.call(this) || this;
        _this._observers = new Map();
        _this._observerCounter = 0;
        return _this;
    }
    Object.defineProperty(Subject.prototype, "observers", {
        get: function () {
            return Array.from(this._observers.values());
        },
        enumerable: false,
        configurable: true
    });
    Subject.prototype.next = function (value) {
        if (this.closed)
            return;
        var observers = this.observers;
        for (var i = 0; i < observers.length; i++) {
            observers[i].next(value);
        }
    };
    Subject.prototype.error = function (err) {
        var _a, _b;
        if (this.closed)
            return;
        this.thrownError = err;
        var observers = this.observers;
        for (var i = 0; i < observers.length; i++) {
            (_b = (_a = observers[i]).error) === null || _b === void 0 ? void 0 : _b.call(_a, err);
        }
        this._observers.clear();
    };
    Subject.prototype.complete = function () {
        var _a, _b;
        if (this.closed)
            return;
        this._closed = true;
        var observers = this.observers;
        for (var i = 0; i < observers.length; i++) {
            (_b = (_a = observers[i]).complete) === null || _b === void 0 ? void 0 : _b.call(_a);
        }
        this._observers.clear();
    };
    Subject.prototype.subscribe = function (observerOrNext) {
        var _this = this;
        var observer = createObserver(observerOrNext);
        if (this.thrownError || this.closed) {
            var subscription = new Subscription();
            subscription.closed = true;
            return subscription;
        }
        var observerId = this._observerCounter++;
        this._observers.set(observerId, observer);
        return new Subscription(function () {
            _this._observers.delete(observerId);
        });
    };
    Subject.prototype.unsubscribe = function () {
        this._closed = true;
        this._observers.clear();
    };
    return Subject;
}(Observable));
export { Subject };
