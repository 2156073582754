"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const animation_resolver_1 = require("@gluestack-style/animation-resolver");
const legend_motion_animation_driver_1 = require("@gluestack-style/legend-motion-animation-driver");
exports.default = (0, react_1.styled)(animation_resolver_1.AnimatedPressable, {
    //@ts-ignore
    ':initial': {
        opacity: 0,
    },
    ':animate': {
        opacity: 0.5,
    },
    ':exit': {
        opacity: 0,
    },
    ':transition': {
        type: 'spring',
        damping: 18,
        stiffness: 250,
        opacity: {
            type: 'timing',
            duration: 250,
        },
    },
    'position': 'absolute',
    'left': 0,
    'top': 0,
    'right': 0,
    'bottom': 0,
    'bg': 'black',
    '_web': {
        cursor: 'default',
    },
}, {
    componentName: 'ModalBackdrop',
}, {
    plugins: [new animation_resolver_1.AnimationResolver(legend_motion_animation_driver_1.MotionAnimationDriver)],
});
