"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertDialog = void 0;
// @ts-nocheck
const react_1 = __importStar(require("react"));
const alert_dialog_1 = require("@gluestack-ui/alert-dialog");
const styled_components_1 = require("./styled-components");
const Heading_1 = require("../Heading");
const Text_1 = require("../Text");
const Icons_1 = require("../Icons");
const animation_resolver_1 = require("@gluestack-style/animation-resolver");
const react_2 = require("react");
const AccessibleAlertDialog = (0, alert_dialog_1.createAlertDialog)({
    Root: styled_components_1.Root,
    Content: styled_components_1.Content,
    CloseButton: styled_components_1.CloseButton,
    Header: styled_components_1.Header,
    Footer: styled_components_1.Footer,
    Body: styled_components_1.Body,
    Backdrop: styled_components_1.Backdrop,
    //@ts-ignore
    AnimatePresence: animation_resolver_1.AnimatePresence,
});
// export default () => (
//   <AlertDialog>
//     <AlertDialogBackdrop />
//     <AlertDialogContent>
//       <AlertDialogHeader>
//         <AlertDialogCloseButton />
//       </AlertDialogHeader>
//       <AlertDialogBody />
//       <AlertDialogFooter />
//     </AlertDialogContent>
//   </AlertDialog>
// )
// export const AlertDialog = AccessibleAlertDialog;
const AlertDialogTemp = (0, react_2.memo)((0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(AccessibleAlertDialog, Object.assign({}, props, { ref: ref }),
        react_1.default.createElement(AccessibleAlertDialog.Backdrop, null),
        children));
}));
const AccessibleAlertDialogContent = (0, react_2.memo)((0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(AccessibleAlertDialog.Content, Object.assign({}, props, { ref: ref }), children));
}));
const AccessibleAlertDialogCloseButton = (0, react_2.memo)((0, react_1.forwardRef)((_a, ref) => {
    var props = __rest(_a, []);
    return (react_1.default.createElement(AccessibleAlertDialog.CloseButton, Object.assign({}, props, { ref: ref }),
        react_1.default.createElement(Icons_1.CloseIcon, null)));
}));
const AccessibleAlertDialogHeader = (0, react_2.memo)((0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(AccessibleAlertDialog.Header, Object.assign({}, props, { ref: ref }), typeof children === 'string' ? (react_1.default.createElement(Heading_1.Heading, null, children)) : (children)));
}));
const AccessibleAlertDialogFooter = (0, react_2.memo)((0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(AccessibleAlertDialog.Footer, Object.assign({}, props, { ref: ref }), children));
}));
const AccessibleAlertDialogBody = (0, react_2.memo)((0, react_1.forwardRef)((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (react_1.default.createElement(AccessibleAlertDialog.Body, Object.assign({}, props, { ref: ref }), typeof children === 'string' ? react_1.default.createElement(Text_1.Text, null, children) : children));
}));
const AlertDialogNew = AlertDialogTemp;
AlertDialogNew.Content = AccessibleAlertDialogContent;
AlertDialogNew.CloseButton = AccessibleAlertDialogCloseButton;
AlertDialogNew.Header = AccessibleAlertDialogHeader;
AlertDialogNew.Footer = AccessibleAlertDialogFooter;
AlertDialogNew.Body = AccessibleAlertDialogBody;
exports.AlertDialog = AlertDialogNew;
