import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useMessageContext } from './MessageContext';
import { useChannelActionContext } from './ChannelActionContext';
import { isMessageBounced } from '../components';
var MessageBounceContext = createContext(undefined);
export function useMessageBounceContext(componentName) {
    var contextValue = useContext(MessageBounceContext);
    if (!contextValue) {
        console.warn("The useMessageBounceContext hook was called outside of the MessageBounceContext provider. The errored call is located in the ".concat(componentName, " component."));
        return {};
    }
    return contextValue;
}
export function MessageBounceProvider(_a) {
    var children = _a.children;
    var _b = useMessageContext('MessageBounceProvider'), doHandleRetry = _b.handleRetry, message = _b.message, setEditingState = _b.setEditingState;
    if (!isMessageBounced(message)) {
        console.warn("The MessageBounceProvider was rendered for a message that is not bounced. Have you missed the \"isMessageBounced\" check?");
    }
    var removeMessage = useChannelActionContext('MessageBounceProvider').removeMessage;
    var handleDelete = useCallback(function () {
        removeMessage(message);
    }, [message, removeMessage]);
    var handleEdit = useCallback(function (e) {
        setEditingState(e);
    }, [setEditingState]);
    var handleRetry = useCallback(function () {
        doHandleRetry(message);
    }, [doHandleRetry, message]);
    var value = useMemo(function () { return ({
        handleDelete: handleDelete,
        handleEdit: handleEdit,
        handleRetry: handleRetry,
        message: message,
    }); }, [handleDelete, handleEdit, handleRetry, message]);
    return React.createElement(MessageBounceContext.Provider, { value: value }, children);
}
