"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.View, {
    flexDirection: 'row-reverse',
    position: 'relative',
    _avatar: {
        marginLeft: -10,
        // @ts-ignore
        borderWidth: 2,
        borderColor: 'white',
        _dark: {
            borderColor: '$trueGray.900',
        },
    },
}, {
    componentName: 'AvatarGroup',
    descendantStyle: ['_avatar'],
});
