import { __assign } from "tslib";
import { useMemo } from 'react';
import { useLastReadData } from '../useLastReadData';
import { getLastReceived } from '../../utils';
import { useChatContext } from '../../../../context/ChatContext';
import { useComponentContext } from '../../../../context/ComponentContext';
export var useMessageListElements = function (props) {
    var channelUnreadUiState = props.channelUnreadUiState, enrichedMessages = props.enrichedMessages, internalMessageProps = props.internalMessageProps, messageGroupStyles = props.messageGroupStyles, read = props.read, renderMessages = props.renderMessages, returnAllReadData = props.returnAllReadData, threadList = props.threadList;
    var _a = useChatContext('useMessageListElements'), client = _a.client, customClasses = _a.customClasses;
    var components = useComponentContext('useMessageListElements');
    // get the readData, but only for messages submitted by the user themselves
    var readData = useLastReadData({
        messages: enrichedMessages,
        read: read,
        returnAllReadData: returnAllReadData,
        userID: client.userID,
    });
    var lastReceivedMessageId = useMemo(function () { return getLastReceived(enrichedMessages); }, [
        enrichedMessages,
    ]);
    var elements = useMemo(function () {
        return renderMessages({
            channelUnreadUiState: channelUnreadUiState,
            components: components,
            customClasses: customClasses,
            lastReceivedMessageId: lastReceivedMessageId,
            messageGroupStyles: messageGroupStyles,
            messages: enrichedMessages,
            readData: readData,
            sharedMessageProps: __assign(__assign({}, internalMessageProps), { threadList: threadList }),
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        enrichedMessages,
        internalMessageProps,
        lastReceivedMessageId,
        messageGroupStyles,
        channelUnreadUiState,
        readData,
        renderMessages,
        threadList,
    ]);
    return elements;
};
