"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tabs = void 0;
const tabs_1 = require("@gluestack-ui/tabs");
const styled_components_1 = require("./styled-components");
exports.Tabs = (0, tabs_1.createTabs)({
    Root: styled_components_1.Root,
    Tab: styled_components_1.Tab,
    TabPanels: styled_components_1.TabPanels,
    TabPanel: styled_components_1.TabPanel,
    TabList: styled_components_1.TabList,
    TabTitle: styled_components_1.TabTitle,
    TabIcon: styled_components_1.TabIcon,
});
// export const TabsTab = Tabs.Tab;
// export const TabsTabPanels = Tabs.TabPanels;
// export const TabsTabPanel = Tabs.TabPanel;
// export const TabsTabList = Tabs.TabList;
// export const TabsTabTitle = Tabs.TabTitle;
// export const TabsTabIcon = Tabs.TabIcon;
