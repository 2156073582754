import { __awaiter, __generator } from "tslib";
import { useChannelActionContext } from '../../../context/ChannelActionContext';
export var useRetryHandler = function (customRetrySendMessage) {
    var contextRetrySendMessage = useChannelActionContext('useRetryHandler').retrySendMessage;
    var retrySendMessage = customRetrySendMessage || contextRetrySendMessage;
    return function (message) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!message) return [3 /*break*/, 2];
                    return [4 /*yield*/, retrySendMessage(message)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
};
