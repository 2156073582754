export var isMutableRef = function (ref) {
    if (ref) {
        return ref.current !== undefined;
    }
    return false;
};
export var getImageDimensions = function (source) {
    return new Promise(function (resolve, reject) {
        var image = new Image();
        image.addEventListener('load', function () {
            resolve([image.width, image.height]);
        }, { once: true });
        image.addEventListener('error', function () { return reject("Couldn't load image from ".concat(source)); }, {
            once: true,
        });
        image.src = source;
    });
};
