import { __assign, __spreadArray } from "tslib";
import { useCallback, useReducer, useState } from 'react';
import { nanoid } from 'nanoid';
import { useChannelStateContext } from '../../../context/ChannelStateContext';
import { useAttachments } from './useAttachments';
import { useLinkPreviews } from './useLinkPreviews';
import { useMessageInputText } from './useMessageInputText';
import { useSubmitHandler } from './useSubmitHandler';
import { usePasteHandler } from './usePasteHandler';
import { useMediaRecorder } from '../../MediaRecorder/hooks/useMediaRecorder';
import { LinkPreviewState, SetLinkPreviewMode } from '../types';
import { mergeDeep } from '../../../utils/mergeDeep';
var makeEmptyMessageInputState = function () { return ({
    attachments: [],
    fileOrder: [],
    fileUploads: {},
    imageOrder: [],
    imageUploads: {},
    linkPreviews: new Map(),
    mentioned_users: [],
    setText: function () { return null; },
    text: '',
}); };
/**
 * Initializes the state. Empty if the message prop is falsy.
 */
var initState = function (message) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (!message) {
        return makeEmptyMessageInputState();
    }
    // if message prop is defined, get image uploads, file uploads, text, etc.
    var imageUploads = (_b = (_a = message.attachments) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
        var type = _a.type;
        return type === 'image';
    }).reduce(function (acc, _a) {
        var author_name = _a.author_name, _b = _a.fallback, fallback = _b === void 0 ? '' : _b, image_url = _a.image_url, og_scrape_url = _a.og_scrape_url, text = _a.text, title = _a.title, title_link = _a.title_link;
        var id = nanoid();
        acc[id] = {
            author_name: author_name,
            file: {
                name: fallback,
            },
            id: id,
            og_scrape_url: og_scrape_url,
            state: 'finished',
            text: text,
            title: title,
            title_link: title_link,
            url: image_url,
        };
        return acc;
    }, {})) !== null && _b !== void 0 ? _b : {};
    var fileUploads = (_d = (_c = message.attachments) === null || _c === void 0 ? void 0 : _c.filter(function (_a) {
        var type = _a.type;
        return type === 'file';
    }).reduce(function (acc, _a) {
        var asset_url = _a.asset_url, file_size = _a.file_size, mime_type = _a.mime_type, thumb_url = _a.thumb_url, _b = _a.title, title = _b === void 0 ? '' : _b;
        var id = nanoid();
        acc[id] = {
            file: {
                name: title,
                size: file_size,
                type: mime_type,
            },
            id: id,
            state: 'finished',
            thumb_url: thumb_url,
            url: asset_url,
        };
        return acc;
    }, {})) !== null && _d !== void 0 ? _d : {};
    var linkPreviews = (_f = (_e = message.attachments) === null || _e === void 0 ? void 0 : _e.reduce(function (acc, attachment) {
        if (!attachment.og_scrape_url)
            return acc;
        acc.set(attachment.og_scrape_url, __assign(__assign({}, attachment), { state: LinkPreviewState.LOADED }));
        return acc;
    }, new Map())) !== null && _f !== void 0 ? _f : new Map();
    var imageOrder = Object.keys(imageUploads);
    var fileOrder = Object.keys(fileUploads);
    var attachments = ((_g = message.attachments) === null || _g === void 0 ? void 0 : _g.filter(function (_a) {
        var type = _a.type;
        return type !== 'file' && type !== 'image';
    }).map(function (att) {
        return (__assign(__assign({}, att), { localMetadata: { id: nanoid() } }));
    })) || [];
    var mentioned_users = message.mentioned_users || [];
    return {
        attachments: attachments,
        fileOrder: fileOrder,
        fileUploads: fileUploads,
        imageOrder: imageOrder,
        imageUploads: imageUploads,
        linkPreviews: linkPreviews,
        mentioned_users: mentioned_users,
        setText: function () { return null; },
        text: message.text || '',
    };
};
/**
 * MessageInput state reducer
 */
var messageInputReducer = function (state, action) {
    var _a, _b;
    switch (action.type) {
        case 'setText':
            return __assign(__assign({}, state), { text: action.getNewText(state.text) });
        case 'clear':
            return makeEmptyMessageInputState();
        case 'upsertAttachments': {
            var attachments_1 = __spreadArray([], state.attachments, true);
            action.attachments.forEach(function (actionAttachment) {
                var _a;
                var attachmentIndex = state.attachments.findIndex(function (att) { var _a, _b, _c; return ((_a = att.localMetadata) === null || _a === void 0 ? void 0 : _a.id) && ((_b = att.localMetadata) === null || _b === void 0 ? void 0 : _b.id) === ((_c = actionAttachment.localMetadata) === null || _c === void 0 ? void 0 : _c.id); });
                if (attachmentIndex === -1) {
                    attachments_1.push(actionAttachment);
                }
                else {
                    var upsertedAttachment = mergeDeep((_a = state.attachments[attachmentIndex]) !== null && _a !== void 0 ? _a : {}, actionAttachment);
                    attachments_1.splice(attachmentIndex, 1, upsertedAttachment);
                }
            });
            return __assign(__assign({}, state), { attachments: attachments_1 });
        }
        case 'removeAttachments': {
            return __assign(__assign({}, state), { attachments: state.attachments.filter(function (att) { var _a; return !action.ids.includes((_a = att.localMetadata) === null || _a === void 0 ? void 0 : _a.id); }) });
        }
        case 'setImageUpload': {
            var imageAlreadyExists = state.imageUploads[action.id];
            if (!imageAlreadyExists && !action.file)
                return state;
            var imageOrder = imageAlreadyExists ? state.imageOrder : state.imageOrder.concat(action.id);
            var newUploadFields = __assign({}, action);
            delete newUploadFields.type;
            return __assign(__assign({}, state), { imageOrder: imageOrder, imageUploads: __assign(__assign({}, state.imageUploads), (_a = {}, _a[action.id] = __assign(__assign({}, state.imageUploads[action.id]), newUploadFields), _a)) });
        }
        case 'setFileUpload': {
            var fileAlreadyExists = state.fileUploads[action.id];
            if (!fileAlreadyExists && !action.file)
                return state;
            var fileOrder = fileAlreadyExists ? state.fileOrder : state.fileOrder.concat(action.id);
            var newUploadFields = __assign({}, action);
            delete newUploadFields.type;
            return __assign(__assign({}, state), { fileOrder: fileOrder, fileUploads: __assign(__assign({}, state.fileUploads), (_b = {}, _b[action.id] = __assign(__assign({}, state.fileUploads[action.id]), newUploadFields), _b)) });
        }
        case 'setLinkPreviews': {
            var linkPreviews_1 = new Map(state.linkPreviews);
            if (action.mode === SetLinkPreviewMode.REMOVE) {
                Array.from(action.linkPreviews.keys()).forEach(function (key) {
                    linkPreviews_1.delete(key);
                });
            }
            else {
                Array.from(action.linkPreviews.values()).reduce(function (acc, linkPreview) {
                    var existingPreview = acc.get(linkPreview.og_scrape_url);
                    var alreadyEnqueued = linkPreview.state === LinkPreviewState.QUEUED &&
                        (existingPreview === null || existingPreview === void 0 ? void 0 : existingPreview.state) !== LinkPreviewState.FAILED;
                    if (existingPreview && alreadyEnqueued)
                        return acc;
                    acc.set(linkPreview.og_scrape_url, linkPreview);
                    return acc;
                }, linkPreviews_1);
                if (action.mode === SetLinkPreviewMode.SET) {
                    Array.from(state.linkPreviews.keys()).forEach(function (key) {
                        if (!action.linkPreviews.get(key))
                            linkPreviews_1.delete(key);
                    });
                }
            }
            return __assign(__assign({}, state), { linkPreviews: linkPreviews_1 });
        }
        case 'removeImageUpload': {
            if (!state.imageUploads[action.id])
                return state; // cannot remove anything
            var newImageUploads = __assign({}, state.imageUploads);
            delete newImageUploads[action.id];
            return __assign(__assign({}, state), { imageOrder: state.imageOrder.filter(function (_id) { return _id !== action.id; }), imageUploads: newImageUploads });
        }
        case 'removeFileUpload': {
            if (!state.fileUploads[action.id])
                return state; // cannot remove anything
            var newFileUploads = __assign({}, state.fileUploads);
            delete newFileUploads[action.id];
            return __assign(__assign({}, state), { fileOrder: state.fileOrder.filter(function (_id) { return _id !== action.id; }), fileUploads: newFileUploads });
        }
        case 'addMentionedUser':
            return __assign(__assign({}, state), { mentioned_users: state.mentioned_users.concat(action.user) });
        default:
            return state;
    }
};
/**
 * hook for MessageInput state
 */
export var useMessageInputState = function (props) {
    var _a;
    var additionalTextareaProps = props.additionalTextareaProps, asyncMessagesMultiSendEnabled = props.asyncMessagesMultiSendEnabled, audioRecordingConfig = props.audioRecordingConfig, audioRecordingEnabled = props.audioRecordingEnabled, getDefaultValue = props.getDefaultValue, message = props.message, urlEnrichmentConfig = props.urlEnrichmentConfig;
    var _b = useChannelStateContext('useMessageInputState'), _c = _b.channelCapabilities, channelCapabilities = _c === void 0 ? {} : _c, channelConfig = _b.channelConfig, enrichURLForPreviewChannelContext = _b.enrichURLForPreview;
    var defaultValue = (getDefaultValue === null || getDefaultValue === void 0 ? void 0 : getDefaultValue()) || (additionalTextareaProps === null || additionalTextareaProps === void 0 ? void 0 : additionalTextareaProps.defaultValue);
    var initialStateValue = message ||
        (Array.isArray(defaultValue)
            ? { text: defaultValue.join('') }
            : { text: defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.toString() });
    var _d = useReducer(messageInputReducer, initialStateValue, initState), state = _d[0], dispatch = _d[1];
    var enrichURLsController = useLinkPreviews(__assign(__assign({ dispatch: dispatch, linkPreviews: state.linkPreviews }, urlEnrichmentConfig), { enrichURLForPreview: (_a = urlEnrichmentConfig === null || urlEnrichmentConfig === void 0 ? void 0 : urlEnrichmentConfig.enrichURLForPreview) !== null && _a !== void 0 ? _a : enrichURLForPreviewChannelContext }));
    var _e = useMessageInputText(props, state, dispatch, enrichURLsController.findAndEnqueueURLsToEnrich), handleChange = _e.handleChange, insertText = _e.insertText, textareaRef = _e.textareaRef;
    var _f = useState(false), showCommandsList = _f[0], setShowCommandsList = _f[1];
    var _g = useState(false), showMentionsList = _g[0], setShowMentionsList = _g[1];
    var openCommandsList = function () {
        dispatch({
            getNewText: function () { return '/'; },
            type: 'setText',
        });
        setShowCommandsList(true);
    };
    var closeCommandsList = function () { return setShowCommandsList(false); };
    var openMentionsList = function () {
        setShowMentionsList(true);
    };
    var closeMentionsList = function () { return setShowMentionsList(false); };
    var _h = useAttachments(props, state, dispatch, textareaRef), maxFilesLeft = _h.maxFilesLeft, numberOfUploads = _h.numberOfUploads, removeAttachments = _h.removeAttachments, removeFile = _h.removeFile, removeImage = _h.removeImage, uploadAttachment = _h.uploadAttachment, uploadFile = _h.uploadFile, uploadImage = _h.uploadImage, uploadNewFiles = _h.uploadNewFiles, upsertAttachments = _h.upsertAttachments;
    var handleSubmit = useSubmitHandler(props, state, dispatch, numberOfUploads, enrichURLsController).handleSubmit;
    var recordingController = useMediaRecorder({
        asyncMessagesMultiSendEnabled: asyncMessagesMultiSendEnabled,
        enabled: !!audioRecordingEnabled,
        handleSubmit: handleSubmit,
        recordingConfig: audioRecordingConfig,
        uploadAttachment: uploadAttachment,
    });
    // todo: remove the check for channelConfig?.uploads
    var isUploadEnabled = (channelConfig === null || channelConfig === void 0 ? void 0 : channelConfig.uploads) !== false && channelCapabilities['upload-file'] !== false;
    var onPaste = usePasteHandler(uploadNewFiles, insertText, isUploadEnabled, enrichURLsController.findAndEnqueueURLsToEnrich).onPaste;
    var onSelectUser = useCallback(function (item) {
        dispatch({ type: 'addMentionedUser', user: item });
    }, []);
    var setText = useCallback(function (text) {
        dispatch({ getNewText: function () { return text; }, type: 'setText' });
    }, []);
    return __assign(__assign(__assign({}, state), enrichURLsController), { closeCommandsList: closeCommandsList, closeMentionsList: closeMentionsList, handleChange: handleChange, handleSubmit: handleSubmit, insertText: insertText, isUploadEnabled: isUploadEnabled, maxFilesLeft: maxFilesLeft, numberOfUploads: numberOfUploads, onPaste: onPaste, onSelectUser: onSelectUser, openCommandsList: openCommandsList, openMentionsList: openMentionsList, recordingController: recordingController, removeAttachments: removeAttachments, removeFile: removeFile, removeImage: removeImage, setText: setText, showCommandsList: showCommandsList, showMentionsList: showMentionsList, textareaRef: textareaRef, uploadAttachment: uploadAttachment, uploadFile: uploadFile, uploadImage: uploadImage, uploadNewFiles: uploadNewFiles, upsertAttachments: upsertAttachments });
};
