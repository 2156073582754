"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
exports.default = (0, react_1.styled)(react_native_1.View, {
    height: '$1',
    width: '$16',
    backgroundColor: '$backgroundLight.400',
    // @ts-ignore
    rounded: '$full',
    _dark: {
        backgroundColor: '$backgroundDark.500',
    },
}, {});
