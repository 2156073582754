"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const plugins_1 = require("../../../plugins");
const react_native_1 = require("react-native");
const react_1 = require("@gluestack-style/react");
exports.default = (0, react_1.styled)(react_native_1.Text, {
    'color': '$muted.600',
    ':checked': {
        color: '$muted.900',
    },
    ':hover': {
        'color': '$muted.900',
        ':checked': {
            'color': '$muted.900',
            ':disabled': {
                color: '$muted.900',
            },
        },
        ':disabled': {
            color: '$muted.600',
        },
    },
    ':active': {
        'color': '$muted.900',
        ':checked': {
            color: '$muted.900',
        },
    },
    ':disabled': {
        opacity: 0.4,
    },
    '_web': {
        MozUserSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
    },
    '_dark': {
        'color': '$muted.400',
        ':checked': {
            color: '$muted.100',
        },
        ':hover': {
            'color': '$muted.100',
            ':checked': {
                'color': '$muted.100',
                ':disabled': {
                    color: '$muted.100',
                },
            },
        },
        ':disabled': {
            color: '$muted.100',
        },
        ':active': {
            'color': '$muted.100',
            ':checked': {
                color: '$muted.100',
            },
        },
    },
}, {
    componentName: 'CheckboxLabel',
    ancestorStyle: ['_text'],
}, {
    plugins: [new plugins_1.TextStyleResolver()],
});
