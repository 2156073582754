import React from 'react';
import { useTranslationContext } from '../../context';
/**
 * This is simply a button wrapper, adds a div with `role="button"` and a onClick
 */
export var IconButton = function (_a) {
    var children = _a.children, onClick = _a.onClick;
    var t = useTranslationContext('IconButton').t;
    return (React.createElement("button", { "aria-label": t('aria/Cancel upload'), className: 'rfu-icon-button', "data-testid": 'cancel-upload-button', onClick: onClick, type: 'button' }, children));
};
