import { __awaiter, __generator, __spreadArray } from "tslib";
/**
 * prevent from duplicate invocation of channel.watch()
 * when events 'notification.message_new' and 'notification.added_to_channel' arrive at the same time
 */
var WATCH_QUERY_IN_PROGRESS_FOR_CHANNEL = {};
/**
 * Calls channel.watch() if it was not already recently called. Waits for watch promise to resolve even if it was invoked previously.
 * @param client
 * @param members
 * @param options
 * @param type
 * @param id
 * @param channel
 */
export var getChannel = function (_a) {
    var channel = _a.channel, client = _a.client, id = _a.id, members = _a.members, options = _a.options, type = _a.type;
    return __awaiter(void 0, void 0, void 0, function () {
        var theChannel, originalCid, queryPromise;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!channel && !type) {
                        throw new Error('Channel or channel type have to be provided to query a channel.');
                    }
                    theChannel = channel || client.channel(type, id, { members: members });
                    originalCid = (theChannel === null || theChannel === void 0 ? void 0 : theChannel.id)
                        ? theChannel.cid
                        : members && members.length
                            ? generateChannelTempCid(theChannel.type, members)
                            : undefined;
                    if (!originalCid) {
                        throw new Error('Channel ID or channel members array have to be provided to query a channel.');
                    }
                    queryPromise = WATCH_QUERY_IN_PROGRESS_FOR_CHANNEL[originalCid];
                    if (!queryPromise) return [3 /*break*/, 2];
                    return [4 /*yield*/, queryPromise];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 2:
                    WATCH_QUERY_IN_PROGRESS_FOR_CHANNEL[originalCid] = theChannel.watch(options);
                    return [4 /*yield*/, WATCH_QUERY_IN_PROGRESS_FOR_CHANNEL[originalCid]];
                case 3:
                    _b.sent();
                    delete WATCH_QUERY_IN_PROGRESS_FOR_CHANNEL[originalCid];
                    _b.label = 4;
                case 4: return [2 /*return*/, theChannel];
            }
        });
    });
};
// Channels created without ID need to be referenced by an identifier until the back-end generates the final ID.
var generateChannelTempCid = function (channelType, members) {
    if (!members)
        return;
    var membersStr = __spreadArray([], members, true).sort().join(',');
    return "".concat(channelType, ":!members-").concat(membersStr);
};
