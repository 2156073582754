import { __assign, __awaiter, __generator } from "tslib";
import fixWebmDuration from 'fix-webm-duration';
import { nanoid } from 'nanoid';
import { AmplitudeRecorder, DEFAULT_AMPLITUDE_RECORDER_CONFIG, } from './AmplitudeRecorder';
import { BrowserPermission } from './BrowserPermission';
import { BehaviorSubject, Subject } from '../observable';
import { transcode } from '../transcode';
import { resampleWaveformData } from '../../Attachment';
import { createFileFromBlobs, getExtensionFromMimeType, getRecordedMediaTypeFromMimeType, } from '../../ReactFileUtilities';
import { defaultTranslatorFunction } from '../../../i18n';
import { isSafari } from '../../../utils/browsers';
import { mergeDeepUndefined } from '../../../utils/mergeDeep';
var RECORDED_MIME_TYPE_BY_BROWSER = {
    audio: {
        others: 'audio/webm',
        safari: 'audio/mp4;codecs=mp4a.40.2',
    },
};
export var POSSIBLE_TRANSCODING_MIME_TYPES = ['audio/wav', 'audio/mp3'];
export var DEFAULT_MEDIA_RECORDER_CONFIG = {
    mimeType: isSafari()
        ? RECORDED_MIME_TYPE_BY_BROWSER.audio.safari
        : RECORDED_MIME_TYPE_BY_BROWSER.audio.others,
};
export var DEFAULT_AUDIO_TRANSCODER_CONFIG = {
    sampleRate: 16000,
    targetMimeType: 'audio/mp3',
};
var disposeOfMediaStream = function (stream) {
    if (!(stream === null || stream === void 0 ? void 0 : stream.active))
        return;
    stream.getTracks().forEach(function (track) {
        track.stop();
        stream.removeTrack(track);
    });
};
var logError = function (e) { return e && console.error('[MEDIA RECORDER ERROR]', e); };
export var MediaRecordingState;
(function (MediaRecordingState) {
    MediaRecordingState["PAUSED"] = "paused";
    MediaRecordingState["RECORDING"] = "recording";
    MediaRecordingState["STOPPED"] = "stopped";
})(MediaRecordingState || (MediaRecordingState = {}));
export var RecordingAttachmentType;
(function (RecordingAttachmentType) {
    RecordingAttachmentType["VOICE_RECORDING"] = "voiceRecording";
})(RecordingAttachmentType || (RecordingAttachmentType = {}));
var MediaRecorderController = /** @class */ (function () {
    function MediaRecorderController(_a) {
        var _b = _a === void 0 ? {} : _a, config = _b.config, generateRecordingTitle = _b.generateRecordingTitle, t = _b.t;
        var _this = this;
        this.recordedChunkDurations = [];
        this.recordedData = [];
        this.recordingState = new BehaviorSubject(undefined);
        this.recording = new BehaviorSubject(undefined);
        this.error = new Subject();
        this.notification = new Subject();
        this.generateRecordingTitle = function (mimeType) {
            if (_this.customGenerateRecordingTitle) {
                return _this.customGenerateRecordingTitle(mimeType);
            }
            return "".concat(_this.mediaType, "_recording_").concat(new Date().toISOString(), ".").concat(getExtensionFromMimeType(mimeType)); // extension needed so that desktop Safari can play the asset
        };
        this.makeVoiceRecording = function () { return __awaiter(_this, void 0, void 0, function () {
            var mimeType, blob, file;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this.recordingUri)
                            URL.revokeObjectURL(this.recordingUri);
                        if (!this.recordedData.length)
                            return [2 /*return*/];
                        mimeType = this.mediaRecorderConfig.mimeType;
                        blob = new Blob(this.recordedData, { type: mimeType });
                        if (!mimeType.match('audio/webm')) return [3 /*break*/, 2];
                        return [4 /*yield*/, fixWebmDuration(blob, this.durationMs, {
                                logger: function () { return null; }, // prevents polluting the browser console
                            })];
                    case 1:
                        // The browser does not include duration metadata with the recorded blob
                        blob = _c.sent();
                        _c.label = 2;
                    case 2:
                        if (!!mimeType.match('audio/mp4')) return [3 /*break*/, 4];
                        return [4 /*yield*/, transcode(__assign({ blob: blob }, this.transcoderConfig))];
                    case 3:
                        blob = _c.sent();
                        _c.label = 4;
                    case 4:
                        if (!blob)
                            return [2 /*return*/];
                        this.recordingUri = URL.createObjectURL(blob);
                        file = createFileFromBlobs({
                            blobsArray: [blob],
                            fileName: this.generateRecordingTitle(blob.type),
                            mimeType: blob.type,
                        });
                        return [2 /*return*/, {
                                asset_url: this.recordingUri,
                                duration: this.durationMs / 1000,
                                file_size: blob.size,
                                localMetadata: {
                                    file: file,
                                    id: nanoid(),
                                },
                                mime_type: blob.type,
                                title: file.name,
                                type: RecordingAttachmentType.VOICE_RECORDING,
                                waveform_data: resampleWaveformData((_b = (_a = this.amplitudeRecorder) === null || _a === void 0 ? void 0 : _a.amplitudes.value) !== null && _b !== void 0 ? _b : [], this.amplitudeRecorderConfig.sampleCount),
                            }];
                }
            });
        }); };
        this.handleErrorEvent = function (e) {
            var error = e.error;
            logError(error);
            _this.error.next(error);
            _this.notification.next({
                text: _this.t('An error has occurred during recording'),
                type: 'error',
            });
        };
        this.handleDataavailableEvent = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var recording, e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!e.data.size)
                            return [2 /*return*/];
                        if (this.mediaType !== 'audio')
                            return [2 /*return*/];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        this.recordedData.push(e.data);
                        return [4 /*yield*/, this.makeVoiceRecording()];
                    case 2:
                        recording = _b.sent();
                        if (!recording)
                            return [2 /*return*/];
                        (_a = this.signalRecordingReady) === null || _a === void 0 ? void 0 : _a.call(this, recording);
                        this.recording.next(recording);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        logError(e_1);
                        this.error.next(e_1);
                        this.notification.next({
                            text: this.t('An error has occurred during the recording processing'),
                            type: 'error',
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.resetRecordingState = function () {
            _this.recordedData = [];
            _this.recording.next(undefined);
            _this.recordingState.next(undefined);
            _this.recordedChunkDurations = [];
            _this.startTime = undefined;
        };
        this.cleanUp = function () {
            var _a;
            _this.resetRecordingState();
            if (_this.recordingUri)
                URL.revokeObjectURL(_this.recordingUri);
            (_a = _this.amplitudeRecorder) === null || _a === void 0 ? void 0 : _a.close();
            if (_this.mediaRecorder) {
                disposeOfMediaStream(_this.mediaRecorder.stream);
                _this.mediaRecorder.removeEventListener('dataavailable', _this.handleDataavailableEvent);
                _this.mediaRecorder.removeEventListener('error', _this.handleErrorEvent);
            }
        };
        this.start = function () { return __awaiter(_this, void 0, void 0, function () {
            var error, error, error, stream, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if ([MediaRecordingState.RECORDING, MediaRecordingState.PAUSED].includes(this.recordingState.value)) {
                            error = new Error('Cannot start recording. Recording already in progress');
                            logError(error);
                            this.error.next(error);
                            return [2 /*return*/];
                        }
                        // account for requirement on iOS as per this bug report: https://bugs.webkit.org/show_bug.cgi?id=252303
                        if (!navigator.mediaDevices) {
                            error = new Error('Media recording is not supported');
                            logError(error);
                            this.error.next(error);
                            this.notification.next({ text: this.t('Error starting recording'), type: 'error' });
                            return [2 /*return*/];
                        }
                        if (this.mediaType === 'video') {
                            error = new Error("Video recording is not supported. Provided MIME type: ".concat(this.mediaRecorderConfig.mimeType));
                            logError(error);
                            this.error.next(error);
                            this.notification.next({ text: this.t('Error starting recording'), type: 'error' });
                            return [2 /*return*/];
                        }
                        if (!!this.permission.state.value) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.permission.check()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (this.permission.state.value === 'denied') {
                            logError(new Error('Permission denied'));
                            return [2 /*return*/];
                        }
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, navigator.mediaDevices.getUserMedia({ audio: true })];
                    case 4:
                        stream = _a.sent();
                        this.mediaRecorder = new MediaRecorder(stream, this.mediaRecorderConfig);
                        this.mediaRecorder.addEventListener('dataavailable', this.handleDataavailableEvent);
                        this.mediaRecorder.addEventListener('error', this.handleErrorEvent);
                        this.startTime = new Date().getTime();
                        this.mediaRecorder.start();
                        if (this.mediaType === 'audio' && stream) {
                            this.amplitudeRecorder = new AmplitudeRecorder({
                                config: this.amplitudeRecorderConfig,
                                stream: stream,
                            });
                            this.amplitudeRecorder.start();
                        }
                        this.recordingState.next(MediaRecordingState.RECORDING);
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        logError(error_1);
                        this.cancel();
                        this.error.next(error_1);
                        this.notification.next({ text: this.t('Error starting recording'), type: 'error' });
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        this.pause = function () {
            var _a, _b;
            if (_this.recordingState.value !== MediaRecordingState.RECORDING)
                return;
            if (_this.startTime) {
                _this.recordedChunkDurations.push(new Date().getTime() - _this.startTime);
                _this.startTime = undefined;
            }
            (_a = _this.mediaRecorder) === null || _a === void 0 ? void 0 : _a.pause();
            (_b = _this.amplitudeRecorder) === null || _b === void 0 ? void 0 : _b.stop();
            _this.recordingState.next(MediaRecordingState.PAUSED);
        };
        this.resume = function () {
            var _a, _b;
            if (_this.recordingState.value !== MediaRecordingState.PAUSED)
                return;
            _this.startTime = new Date().getTime();
            (_a = _this.mediaRecorder) === null || _a === void 0 ? void 0 : _a.resume();
            (_b = _this.amplitudeRecorder) === null || _b === void 0 ? void 0 : _b.start();
            _this.recordingState.next(MediaRecordingState.RECORDING);
        };
        this.stop = function () {
            var _a, _b, _c;
            var recording = _this.recording.value;
            if (recording)
                return Promise.resolve(recording);
            if (![MediaRecordingState.PAUSED, MediaRecordingState.RECORDING].includes((((_a = _this.mediaRecorder) === null || _a === void 0 ? void 0 : _a.state) || '')))
                return Promise.resolve(undefined);
            if (_this.startTime) {
                _this.recordedChunkDurations.push(new Date().getTime() - _this.startTime);
                _this.startTime = undefined;
            }
            var result = new Promise(function (res) {
                _this.signalRecordingReady = res;
            });
            (_b = _this.mediaRecorder) === null || _b === void 0 ? void 0 : _b.stop();
            (_c = _this.amplitudeRecorder) === null || _c === void 0 ? void 0 : _c.stop();
            _this.recordingState.next(MediaRecordingState.STOPPED);
            return result;
        };
        this.cancel = function () {
            _this.stop();
            _this.cleanUp();
        };
        this.t = t || defaultTranslatorFunction;
        this.amplitudeRecorderConfig = mergeDeepUndefined(__assign({}, config === null || config === void 0 ? void 0 : config.amplitudeRecorderConfig), DEFAULT_AMPLITUDE_RECORDER_CONFIG);
        this.mediaRecorderConfig = mergeDeepUndefined(__assign({}, config === null || config === void 0 ? void 0 : config.mediaRecorderConfig), DEFAULT_MEDIA_RECORDER_CONFIG);
        this.transcoderConfig = mergeDeepUndefined(__assign({}, config === null || config === void 0 ? void 0 : config.transcoderConfig), DEFAULT_AUDIO_TRANSCODER_CONFIG);
        if (!POSSIBLE_TRANSCODING_MIME_TYPES.includes(this.transcoderConfig.targetMimeType)) {
            this.transcoderConfig.targetMimeType = DEFAULT_AUDIO_TRANSCODER_CONFIG.targetMimeType;
        }
        var mediaType = getRecordedMediaTypeFromMimeType(this.mediaRecorderConfig.mimeType);
        if (!mediaType) {
            throw new Error("Unsupported media type (supported audio or video only). Provided mimeType: ".concat(this.mediaRecorderConfig.mimeType));
        }
        this.mediaType = mediaType;
        this.permission = new BrowserPermission({ mediaType: mediaType });
        this.customGenerateRecordingTitle = generateRecordingTitle;
    }
    Object.defineProperty(MediaRecorderController.prototype, "durationMs", {
        get: function () {
            return this.recordedChunkDurations.reduce(function (acc, val) { return acc + val; }, 0);
        },
        enumerable: false,
        configurable: true
    });
    return MediaRecorderController;
}());
export { MediaRecorderController };
