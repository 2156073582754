"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createIcon = exports.Icon = void 0;
/* eslint-disable react-hooks/rules-of-hooks */
// @ts-nocheck
const react_1 = __importStar(require("react"));
const icon_1 = require("@gluestack-ui/icon");
const styled_components_1 = require("./styled-components");
Object.defineProperty(exports, "createIcon", { enumerable: true, get: function () { return styled_components_1.createIcon; } });
const usePropResolution_1 = require("../../hooks/usePropResolution");
const hooks_1 = require("../../hooks");
const AccessibleIcon = (0, icon_1.createIcon)({
    Root: styled_components_1.Root,
});
const IconTemp = (0, react_1.forwardRef)((_a, ref) => {
    var _b;
    var { children, 
    // @ts-ignore
    as, viewBox } = _a, props = __rest(_a, ["children", "as", "viewBox"]);
    const GuiChildren = react_1.Children.map(children, (child, index) => {
        var _a, _b, _c, _d;
        return (0, react_1.cloneElement)(child, {
            fill: (0, hooks_1.useToken)('colors', ((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.fill) && `${(_b = child === null || child === void 0 ? void 0 : child.props) === null || _b === void 0 ? void 0 : _b.fill}`),
            stroke: (0, hooks_1.useToken)('colors', ((_c = child === null || child === void 0 ? void 0 : child.props) === null || _c === void 0 ? void 0 : _c.stroke) && `${(_d = child === null || child === void 0 ? void 0 : child.props) === null || _d === void 0 ? void 0 : _d.stroke}`),
            key: index,
        });
    });
    const resolvedProps = (0, usePropResolution_1.usePropResolution)(props);
    const { size } = resolvedProps;
    const tokenizedFontSize = (0, hooks_1.useToken)('space', size);
    let sizeStyle = {};
    let sizeProp = {};
    if (size &&
        ((typeof size === 'number' && !isNaN(size)) ||
            (typeof size === 'string' && !isNaN(Number(size))))) {
        sizeStyle = {
            fontSize: tokenizedFontSize,
            lineHeight: tokenizedFontSize,
        };
    }
    else {
        sizeProp = { size: size };
    }
    let IconForward;
    let ClonedIcon;
    if (as) {
        IconForward = as;
    }
    else if (typeof viewBox === 'string') {
        const NewIcon = (0, styled_components_1.createIcon)({
            viewBox: viewBox,
            // @ts-ignore
            path: GuiChildren,
        }, true);
        IconForward = NewIcon;
    }
    else if (children) {
        IconForward = children;
    }
    const isJSX = (_b = (0, react_1.isValidElement)(IconForward)) !== null && _b !== void 0 ? _b : false;
    if (isJSX) {
        ClonedIcon = (propsResolved) => {
            return (0, react_1.cloneElement)(IconForward, Object.assign(Object.assign(Object.assign({}, propsResolved), sizeStyle), sizeProp));
        };
    }
    return (react_1.default.createElement(AccessibleIcon, Object.assign({ as: ClonedIcon !== null && ClonedIcon !== void 0 ? ClonedIcon : IconForward }, resolvedProps, sizeProp, { style: sizeStyle, ref: ref })));
});
__exportStar(require("./Icons"), exports);
exports.Icon = IconTemp;
