import React from 'react';
import { UnsupportedAttachmentPreview as DefaultUnknownAttachmentPreview, } from './UnsupportedAttachmentPreview';
import { VoiceRecordingPreview as DefaultVoiceRecordingPreview, } from './VoiceRecordingPreview';
import { FileAttachmentPreview as DefaultFilePreview, } from './FileAttachmentPreview';
import { FileUploadPreviewAdapter, ImageUploadPreviewAdapter } from './UploadPreviewItem';
import { ImageAttachmentPreview as DefaultImagePreview, } from './ImageAttachmentPreview';
import { isLocalAttachment, isLocalAudioAttachment, isLocalFileAttachment, isLocalImageAttachment, isLocalMediaAttachment, isLocalVoiceRecordingAttachment, isScrapedContent, } from '../../Attachment';
import { useMessageInputContext } from '../../../context';
export var AttachmentPreviewList = function (_a) {
    var _b = _a.AudioAttachmentPreview, AudioAttachmentPreview = _b === void 0 ? DefaultFilePreview : _b, _c = _a.FileAttachmentPreview, FileAttachmentPreview = _c === void 0 ? DefaultFilePreview : _c, _d = _a.ImageAttachmentPreview, ImageAttachmentPreview = _d === void 0 ? DefaultImagePreview : _d, _e = _a.UnsupportedAttachmentPreview, UnsupportedAttachmentPreview = _e === void 0 ? DefaultUnknownAttachmentPreview : _e, _f = _a.VideoAttachmentPreview, VideoAttachmentPreview = _f === void 0 ? DefaultFilePreview : _f, _g = _a.VoiceRecordingPreview, VoiceRecordingPreview = _g === void 0 ? DefaultVoiceRecordingPreview : _g;
    var _h = useMessageInputContext('AttachmentPreviewList'), attachments = _h.attachments, fileOrder = _h.fileOrder, imageOrder = _h.imageOrder, removeAttachments = _h.removeAttachments, uploadAttachment = _h.uploadAttachment;
    return (React.createElement("div", { className: 'str-chat__attachment-preview-list' },
        React.createElement("div", { className: 'str-chat__attachment-list-scroll-container', "data-testid": 'attachment-list-scroll-container' },
            attachments.map(function (attachment) {
                if (isScrapedContent(attachment))
                    return null;
                if (isLocalVoiceRecordingAttachment(attachment)) {
                    return (React.createElement(VoiceRecordingPreview, { attachment: attachment, handleRetry: uploadAttachment, key: attachment.localMetadata.id || attachment.asset_url, removeAttachments: removeAttachments }));
                }
                else if (isLocalAudioAttachment(attachment)) {
                    return (React.createElement(AudioAttachmentPreview, { attachment: attachment, handleRetry: uploadAttachment, key: attachment.localMetadata.id || attachment.asset_url, removeAttachments: removeAttachments }));
                }
                else if (isLocalMediaAttachment(attachment)) {
                    return (React.createElement(VideoAttachmentPreview, { attachment: attachment, handleRetry: uploadAttachment, key: attachment.localMetadata.id || attachment.asset_url, removeAttachments: removeAttachments }));
                }
                else if (isLocalImageAttachment(attachment)) {
                    return (React.createElement(ImageAttachmentPreview, { attachment: attachment, handleRetry: uploadAttachment, key: attachment.localMetadata.id || attachment.image_url, removeAttachments: removeAttachments }));
                }
                else if (isLocalFileAttachment(attachment)) {
                    return (React.createElement(FileAttachmentPreview, { attachment: attachment, handleRetry: uploadAttachment, key: attachment.localMetadata.id || attachment.asset_url, removeAttachments: removeAttachments }));
                }
                else if (isLocalAttachment(attachment)) {
                    return (React.createElement(UnsupportedAttachmentPreview, { attachment: attachment, handleRetry: uploadAttachment, key: attachment.localMetadata.id, removeAttachments: removeAttachments }));
                }
                return null;
            }),
            imageOrder.map(function (id) { return (React.createElement(ImageUploadPreviewAdapter, { id: id, key: id, Preview: ImageAttachmentPreview })); }),
            fileOrder.map(function (id) { return (React.createElement(FileUploadPreviewAdapter, { id: id, key: id, Preview: FileAttachmentPreview })); }))));
};
