"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePropResolution = void 0;
const react_1 = require("@gluestack-style/react");
// import { useMemo } from 'react';
const utils_1 = require("../utils");
function resolveProps(props, flag, styledContext) {
    if (flag)
        return props;
    if (props) {
        let sizeProp = {};
        if (props.size &&
            ((typeof props.size === 'number' && !isNaN(props.size)) ||
                (typeof props.size === 'string' &&
                    (!isNaN(Number(props.size)) || /(px|rem|em)$/.test(props.size))))) {
            sizeProp = {
                height: props.size,
                width: props.size,
            };
        }
        props = Object.assign(Object.assign({}, props), sizeProp);
        props = (0, utils_1.getFlattendMultiAliasesProps)(props, styledContext.config); // Flattens aliases that contains array of strings, like roundedTop or roundedLeft etc.
        const propsWithDollarSigns = (0, utils_1.addDollarSignsToProps)(props, styledContext.config);
        const sxProps = (0, utils_1.convertToSXForStateColorModeMediaQuery)(propsWithDollarSigns, styledContext.config);
        if (!sxProps.hasOwnProperty('sx')) {
            sxProps.sx = {};
        }
        Object.keys(sxProps).forEach((key) => {
            const propName = key;
            const propValue = sxProps[key];
            if (propName.startsWith('_') ||
                propName.startsWith(':') ||
                propName.startsWith('@')) {
                sxProps.sx[propName] = propValue;
                delete sxProps[propName];
            }
        });
        Object.keys(sxProps).forEach((key) => {
            const propName = key;
            const propValue = sxProps[key];
            if (propName.startsWith('_') ||
                propName.startsWith(':') ||
                propName.startsWith('@')) {
                sxProps.sx[propName] = propValue;
                delete sxProps[propName];
            }
        });
        return sxProps;
    }
}
function usePropResolution(props, flag = false) {
    const styledContext = (0, react_1.useStyled)();
    return resolveProps(props, flag, styledContext);
}
exports.usePropResolution = usePropResolution;
