import { __awaiter, __generator } from "tslib";
import { useCallback } from 'react';
export var useHandleFileChangeWrapper = function (resetOnChange, handler) {
    if (resetOnChange === void 0) { resetOnChange = false; }
    return useCallback(function (_a) {
        var currentTarget = _a.currentTarget;
        var files = currentTarget.files;
        if (!files)
            return;
        try {
            handler === null || handler === void 0 ? void 0 : handler(Array.from(files));
        }
        catch (error) {
            console.error(error);
        }
        if (resetOnChange)
            currentTarget.value = '';
    }, [handler, resetOnChange]);
};
export function dataTransferItemsHaveFiles(items) {
    if (!items || !items.length) {
        return false;
    }
    for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
        var item = items_1[_i];
        if (item.kind === 'file' || item.type === 'text/html') {
            return true;
        }
    }
    return false;
}
export function dataTransferItemsToFiles(items) {
    return __awaiter(this, void 0, void 0, function () {
        var fileLikes, blobPromises, _loop_1, _i, items_2, item;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!items || !items.length) {
                        return [2 /*return*/, []];
                    }
                    fileLikes = getFileLikes(items);
                    if (fileLikes.length) {
                        return [2 /*return*/, fileLikes];
                    }
                    blobPromises = [];
                    _loop_1 = function (item) {
                        if (item.type === 'text/html') {
                            blobPromises.push(new Promise(function (accept) {
                                item.getAsString(function (s) { return __awaiter(_this, void 0, void 0, function () {
                                    var imagePromises;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                imagePromises = extractImageSources(s).map(function (src) {
                                                    return getImageSource(fileLikes, src);
                                                });
                                                return [4 /*yield*/, Promise.all(imagePromises)];
                                            case 1:
                                                _a.sent();
                                                accept();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); });
                            }));
                        }
                    };
                    for (_i = 0, items_2 = items; _i < items_2.length; _i++) {
                        item = items_2[_i];
                        _loop_1(item);
                    }
                    return [4 /*yield*/, Promise.all(blobPromises)];
                case 1:
                    _a.sent();
                    return [2 /*return*/, fileLikes];
            }
        });
    });
}
function getFileLikes(items) {
    var fileLikes = [];
    for (var _i = 0, items_3 = items; _i < items_3.length; _i++) {
        var item = items_3[_i];
        if (item.kind === 'file') {
            var file = item.getAsFile();
            if (file) {
                fileLikes.push(file);
            }
        }
    }
    return fileLikes;
}
function getImageSource(fileLikes, src) {
    return __awaiter(this, void 0, void 0, function () {
        var res, e_1, contentType, buf, blob;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, fetch(src)];
                case 1:
                    res = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    return [2 /*return*/];
                case 3:
                    contentType = res.headers.get('Content-type') || 'application/octet-stream';
                    return [4 /*yield*/, res.arrayBuffer()];
                case 4:
                    buf = _a.sent();
                    blob = new Blob([buf], { type: contentType });
                    fileLikes.push(blob);
                    return [2 /*return*/];
            }
        });
    });
}
var extractImageSources = function (s) {
    var imageTags = new DOMParser().parseFromString(s, 'text/html').getElementsByTagName('img');
    return Array.from(imageTags, function (tag) { return tag.src; }).filter(function (tag) { return tag; });
};
export var createFileFromBlobs = function (_a) {
    var blobsArray = _a.blobsArray, fileName = _a.fileName, mimeType = _a.mimeType;
    var concatenatedBlob = new Blob(blobsArray, { type: mimeType });
    return new File([concatenatedBlob], fileName, { type: concatenatedBlob.type });
};
export var getExtensionFromMimeType = function (mimeType) {
    var match = mimeType.match(/\/([^/;]+)/);
    return match && match[1];
};
export var getRecordedMediaTypeFromMimeType = function (mimeType) {
    var match = mimeType.match(/^(audio|video)\/.*$/);
    return match && match[1];
};
export var readFileAsArrayBuffer = function (file) {
    return new Promise(function (resolve, reject) {
        var fileReader = new FileReader();
        fileReader.onload = function () {
            resolve(fileReader.result);
        };
        fileReader.onerror = function () {
            reject(fileReader.error);
        };
        fileReader.readAsArrayBuffer(file);
    });
};
