import React from 'react';
import { CloseIcon } from './icons';
import { useChannelActionContext, useTranslationContext } from '../../context';
export var UnreadMessagesNotification = function (_a) {
    var queryMessageLimit = _a.queryMessageLimit, showCount = _a.showCount, unreadCount = _a.unreadCount;
    var _b = useChannelActionContext('UnreadMessagesNotification'), jumpToFirstUnreadMessage = _b.jumpToFirstUnreadMessage, markRead = _b.markRead;
    var t = useTranslationContext('UnreadMessagesNotification').t;
    return (React.createElement("div", { className: 'str-chat__unread-messages-notification', "data-testid": 'unread-messages-notification' },
        React.createElement("button", { onClick: function () { return jumpToFirstUnreadMessage(queryMessageLimit); } }, unreadCount && showCount
            ? t('{{count}} unread', { count: unreadCount !== null && unreadCount !== void 0 ? unreadCount : 0 })
            : t('Unread messages')),
        React.createElement("button", { onClick: function () { return markRead(); } },
            React.createElement(CloseIcon, null))));
};
