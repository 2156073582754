import { divMod } from './utils';
export var resampleWaveformData = function (waveformData, amplitudesCount) {
    return waveformData.length === amplitudesCount
        ? waveformData
        : waveformData.length > amplitudesCount
            ? downSample(waveformData, amplitudesCount)
            : upSample(waveformData, amplitudesCount);
};
/**
 * The downSample function uses the Largest-Triangle-Three-Buckets (LTTB) algorithm.
 * See the thesis Downsampling Time Series for Visual Representation by Sveinn Steinarsson for more (https://skemman.is/bitstream/1946/15343/3/SS_MSthesis.pdf)
 * @param data
 * @param targetOutputSize
 */
export function downSample(data, targetOutputSize) {
    if (data.length <= targetOutputSize || targetOutputSize === 0) {
        return data;
    }
    if (targetOutputSize === 1)
        return [mean(data)];
    var result = [];
    // bucket size adjusted due to the fact that the first and the last item in the original data array is kept in target output
    var bucketSize = (data.length - 2) / (targetOutputSize - 2);
    var lastSelectedPointIndex = 0;
    result.push(data[lastSelectedPointIndex]); // Always add the first point
    var maxAreaPoint, maxArea, triangleArea;
    for (var bucketIndex = 1; bucketIndex < targetOutputSize - 1; bucketIndex++) {
        var previousBucketRefPoint = data[lastSelectedPointIndex];
        var nextBucketMean = getNextBucketMean(data, bucketIndex, bucketSize);
        var currentBucketStartIndex = Math.floor((bucketIndex - 1) * bucketSize) + 1;
        var nextBucketStartIndex = Math.floor(bucketIndex * bucketSize) + 1;
        var countUnitsBetweenAtoC = 1 + nextBucketStartIndex - currentBucketStartIndex;
        maxArea = triangleArea = -1;
        for (var currentPointIndex = currentBucketStartIndex; currentPointIndex < nextBucketStartIndex; currentPointIndex++) {
            var countUnitsBetweenAtoB = Math.abs(currentPointIndex - currentBucketStartIndex) + 1;
            var countUnitsBetweenBtoC = countUnitsBetweenAtoC - countUnitsBetweenAtoB;
            var currentPointValue = data[currentPointIndex];
            triangleArea = triangleAreaHeron(triangleBase(Math.abs(previousBucketRefPoint - currentPointValue), countUnitsBetweenAtoB), triangleBase(Math.abs(currentPointValue - nextBucketMean), countUnitsBetweenBtoC), triangleBase(Math.abs(previousBucketRefPoint - nextBucketMean), countUnitsBetweenAtoC));
            if (triangleArea > maxArea) {
                maxArea = triangleArea;
                maxAreaPoint = data[currentPointIndex];
                lastSelectedPointIndex = currentPointIndex;
            }
        }
        if (typeof maxAreaPoint !== 'undefined')
            result.push(maxAreaPoint);
    }
    result.push(data[data.length - 1]); // Always add the last point
    return result;
}
var triangleAreaHeron = function (a, b, c) {
    var s = (a + b + c) / 2;
    return Math.sqrt(s * (s - a) * (s - b) * (s - c));
};
var triangleBase = function (a, b) { return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2)); };
var mean = function (values) { return values.reduce(function (acc, value) { return acc + value; }, 0) / values.length; };
var getNextBucketMean = function (data, currentBucketIndex, bucketSize) {
    var nextBucketStartIndex = Math.floor(currentBucketIndex * bucketSize) + 1;
    var nextNextBucketStartIndex = Math.floor((currentBucketIndex + 1) * bucketSize) + 1;
    nextNextBucketStartIndex =
        nextNextBucketStartIndex < data.length ? nextNextBucketStartIndex : data.length;
    return mean(data.slice(nextBucketStartIndex, nextNextBucketStartIndex));
};
export var upSample = function (values, targetSize) {
    if (!values.length) {
        console.warn('Cannot extend empty array of amplitudes.');
        return values;
    }
    if (values.length > targetSize) {
        console.warn('Requested to extend the waveformData that is longer than the target list size');
        return values;
    }
    if (targetSize === values.length)
        return values;
    // eslint-disable-next-line prefer-const
    var _a = divMod(targetSize, values.length), bucketSize = _a[0], remainder = _a[1];
    var result = [];
    for (var i = 0; i < values.length; i++) {
        var extra = remainder && remainder-- ? 1 : 0;
        result.push.apply(result, Array(bucketSize + extra).fill(values[i]));
    }
    return result;
};
