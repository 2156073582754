"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transition = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const transformStylesMap = {
    translateY: true,
    translateX: true,
    scale: true,
    scaleX: true,
    scaleY: true,
    rotate: true,
};
const defaultStyles = {
    opacity: 1,
    translateY: 0,
    translateX: 0,
    scale: 1,
    scaleX: 1,
    scaleY: 1,
    rotate: '0deg',
};
const getAnimatedStyles = (animateValue) => (initial, to) => {
    var _a;
    const styles = {
        transform: [],
    };
    for (const key in initial) {
        if (key === 'transition') {
            continue;
        }
        if (key in transformStylesMap) {
            (_a = styles.transform) === null || _a === void 0 ? void 0 : _a.push({
                [key]: animateValue.interpolate({
                    inputRange: [0, 1],
                    outputRange: [initial[key], to[key]],
                }),
            });
        }
        else {
            styles[key] = animateValue.interpolate({
                inputRange: [0, 1],
                outputRange: [initial[key], to[key]],
            });
        }
    }
    return styles;
};
const defaultTransitionConfig = {
    type: 'timing',
    useNativeDriver: true,
    duration: 250,
    delay: 0,
};
exports.Transition = (0, react_1.forwardRef)((_a, ref) => {
    var { children, onTransitionComplete, visible = false, initial, animate, exit, style, as } = _a, rest = __rest(_a, ["children", "onTransitionComplete", "visible", "initial", "animate", "exit", "style", "as"]);
    const animateValue = react_1.default.useRef(new react_native_1.Animated.Value(0)).current;
    const Component = react_1.default.useMemo(() => {
        if (as) {
            return react_native_1.Animated.createAnimatedComponent(as);
        }
        return react_native_1.Animated.View;
    }, [as]);
    const [animationState, setAnimationState] = react_1.default.useState('');
    const prevVisible = react_1.default.useRef(visible);
    react_1.default.useEffect(() => {
        var _a;
        if (animationState === 'entering' || animationState === 'exiting') {
            const entryTransition = Object.assign(Object.assign({}, defaultTransitionConfig), animate === null || animate === void 0 ? void 0 : animate.transition);
            const exitTransition = Object.assign(Object.assign({}, defaultTransitionConfig), exit === null || exit === void 0 ? void 0 : exit.transition);
            const startAnimation = animationState === 'entering' ? 1 : 0;
            const transition = startAnimation ? entryTransition : exitTransition;
            react_native_1.Animated.sequence([
                // @ts-ignore - delay is present in defaultTransitionConfig
                react_native_1.Animated.delay(transition.delay),
                react_native_1.Animated[(_a = transition.type) !== null && _a !== void 0 ? _a : 'timing'](animateValue, Object.assign({ toValue: startAnimation, useNativeDriver: true }, transition)),
            ]).start(() => {
                if (animationState === 'entering') {
                    setAnimationState('entered');
                }
                else if (animationState === 'exiting') {
                    setAnimationState('exited');
                }
            });
            // });
        }
        if (animationState === 'exited') {
            onTransitionComplete && onTransitionComplete('exited');
        }
        else if (animationState === 'entered') {
            onTransitionComplete && onTransitionComplete('entered');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animationState, onTransitionComplete]);
    react_1.default.useEffect(() => {
        if (prevVisible.current !== visible && !visible) {
            setAnimationState('exiting');
        }
        if (visible) {
            setAnimationState('entering');
        }
        prevVisible.current = visible;
    }, [visible]);
    // If exit animation is present and state is exiting, we replace 'initial' with 'exit' animation
    const initialState = animationState === 'exited' && exit
        ? Object.assign(Object.assign({}, defaultStyles), exit) : Object.assign(Object.assign({}, defaultStyles), initial);
    const animateState = Object.assign(Object.assign({}, defaultStyles), animate);
    const styles = react_1.default.useMemo(() => {
        // console.log('display state here', initial);
        return [
            getAnimatedStyles(animateValue)(initialState, animateState),
            style,
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animateValue, initial, animate, style]);
    return (react_1.default.createElement(Component, Object.assign({ pointerEvents: !visible ? 'none' : 'box-none', style: [styles], ref: ref }, rest), children));
});
