"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@gluestack-style/react");
const react_native_1 = require("react-native");
const plugins_1 = require("../../../plugins");
exports.default = (0, react_1.styled)(react_native_1.View, {
    backgroundColor: '$muted.200',
    _dark: {
        backgroundColor: '$muted.700',
    },
    // @ts-ignore
    borderRadius: '$full',
    width: '100%',
    variants: {
        size: {
            'xs': {
                height: '$1',
            },
            'sm': {
                height: '$2',
            },
            'md': {
                height: '$3',
            },
            'lg': {
                height: '$4',
            },
            'xl': {
                height: '$5',
            },
            '2xl': {
                height: '$6',
            },
        },
    },
    defaultProps: {
        size: 'md',
        colorScheme: 'primary',
    },
}, {
    componentName: 'Progress',
    descendantStyle: ['_filledTrack'],
}, {
    plugins: [new plugins_1.ColorSchemeResolver(colorSchemeResolveFn)],
});
function colorSchemeResolveFn(_a) {
    var props = __rest(_a, []);
    if (props.colorScheme) {
        const color = props.colorScheme;
        const value = {
            _filledTrack: {
                backgroundColor: `$${color}.600`,
            },
            _dark: {
                _filledTrack: {
                    backgroundColor: `$${color}.400`,
                },
            },
        };
        return value;
    }
    return {};
}
