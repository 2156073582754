import React from 'react';
export var CustomMessageActionsList = function (props) {
    var customMessageActions = props.customMessageActions, message = props.message;
    if (!customMessageActions)
        return null;
    var customActionsArray = Object.keys(customMessageActions);
    return (React.createElement(React.Fragment, null, customActionsArray.map(function (customAction) {
        var customHandler = customMessageActions[customAction];
        return (React.createElement("button", { "aria-selected": 'false', className: 'str-chat__message-actions-list-item str-chat__message-actions-list-item-button', key: customAction, onClick: function (event) { return customHandler(message, event); }, role: 'option' }, customAction));
    })));
};
